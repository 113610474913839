import React, { createContext, useState } from 'react';

// Create a new context for the language
const LanguageContext = createContext();

// Create a provider for the language context
export const LanguageProvider = ({ children }) => {


    const [language, setLanguage] = useState(sessionStorage.getItem("session_lang") === null ? "nl" : sessionStorage.getItem("session_lang"));
    //const [language, setLanguage] = useState('nl');

    // Provide the language and updateLanguage function to the children
    return (
        <LanguageContext.Provider value={{ language, setLanguage }}>
            {children}
        </LanguageContext.Provider>
    );
};

export default LanguageContext;
