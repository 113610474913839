import { Outlet } from "react-router-dom";
import React, { useState, useEffect, useContext } from "react";

import useLanguageContext from "../hooks/useLanguageContext";

import WebsiteDict from "../data/WebsiteDict";

import { NavLink, Link, useNavigate, useLocation } from "react-router-dom";
import Container from "@mui/material/Container";
import { Box } from "@mui/material";
import "../App.css";
import { styled } from "@mui/material/styles";
import {
    AppBar,
    Toolbar,
    Avatar,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    makeStyles,
    CssBaseline,
    Drawer,
    Typography,
    Button,
    FormControl,
    InputLabel,
    Card,
} from "@mui/material";

import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";

import MenuIcon from "@mui/icons-material/Menu";
import ListItemButton from "@mui/material/ListItemButton";
import { IndeterminateCheckBox } from "@mui/icons-material";

import Select from "@mui/material/Select";

const Layout = () => {
    const { language, setLanguage } = useLanguageContext();

    const [dict, setDict] = useState(WebsiteDict);

    useEffect(() => {
        setDict(WebsiteDict);
    }, [WebsiteDict]);

    const navItemsTop = {
        en: [
            { href: "/home", label: "Home" },
            { href: "/about", label: "About" },
            { href: "/faq", label: "FAQ" },
            { href: "/vacatures", label: "Vacancies" },
            { href: "/contact", label: "Contact" },
            { href: "https://dmz.photobizz.be/WebApp_Scholen/ContentPages/Logon.aspx", label: "Login for schools" },
        ],
        nl: [
            { href: "/home", label: "Home" },
            { href: "/about", label: "Over ons" },
            { href: "/faq", label: "FAQ" },
            { href: "/vacatures", label: "Vacatures" },
            { href: "/contact", label: "Contact" },
            { href: "https://dmz.photobizz.be/WebApp_Scholen/ContentPages/Logon.aspx", label: "Login voor scholen" },
        ],
        fr: [
            { href: "/home", label: "Home" },
            { href: "/about", label: "À propos" },
            { href: "/faq", label: "FAQ" },
            { href: "/vacatures", label: "Postes vacants" },
            { href: "/contact", label: "Contact" },
            { href: "https://dmz.photobizz.be/WebApp_Scholen/ContentPages/Logon.aspx", label: "Connexion pour les écoles" },
        ],
    };

    const navItemsBottom = {
        en: [
            { href: "/home", label: "Home" },
            { href: "/about", label: "About" },
            { href: "/faq", label: "FAQ" },
            { href: "/vacatures", label: "Vacancies" },
            { href: "/contact", label: "Contact" },
        ],
        nl: [
            { href: "/home", label: "Home" },
            { href: "/about", label: "Over ons" },
            { href: "/faq", label: "FAQ" },
            { href: "/vacatures", label: "Vacatures" },
            { href: "/contact", label: "Contact" },
        ],
        fr: [
            { href: "/home", label: "Home" },
            { href: "/about", label: "À propos" },
            { href: "/faq", label: "FAQ" },
            { href: "/vacatures", label: "Postes vacants" },
            { href: "/contact", label: "Contact" },
        ],
    };

    const [isButtonVisible, setIsButtonVisible] = useState(false);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === "F2") {
                setIsButtonVisible(true);
            }
        };

        window.addEventListener("keydown", handleKeyDown);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    function handleLanguageChange(event) {
        //setSelectedLanguage(event.target.value);
        sessionStorage.setItem("session_lang", event.target.value);
        setLanguage(event.target.value);
    }

    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
            <Box
                component="img"
                sx={{ height: 30, paddingTop: "10px", paddingBottom: "10px" }}
                alt="Photobizz image"
                src={require("../images/Photobizz_logo_trans.png")}
                // mb={1}
            />
            <Divider />
            <List>
                {navItemsTop[language].map((item) => (
                    <NavLink to={item.href} key={item.label} className={({ isActive }) => (isActive ? "web_sm-link-active" : "web_sm-link")}>
                        <ListItem key={item.label} disablePadding>
                            <ListItemButton sx={{ textAlign: "center" }}>
                                <ListItemText primary={item.label} />
                            </ListItemButton>
                        </ListItem>
                    </NavLink>
                ))}
                <NavLink to={"/login"} className={({ isActive }) => (isActive ? "web_sm-link-active" : "web_sm-link")}>
                    <ListItem disablePadding>
                        <ListItemButton sx={{ textAlign: "center" }}>
                            {language === "en" ? <ListItemText primary={"My pictures"} /> : null}
                            {language === "nl" ? <ListItemText primary={"Mijn foto's"} /> : null}
                            {language === "fr" ? <ListItemText primary={"Mes photos"} /> : null}
                        </ListItemButton>
                    </ListItem>
                </NavLink>
            </List>
        </Box>
    );

    return (
        <>
            <Container maxWidth="lg">
                <AppBar position="fixed" style={{ height: "50px", backgroundColor: "#023047" }}>
                    <Toolbar>
                        <Grid container spacing={2} pt={5} pb={3}>
                            {/* <Grid item xs={6} md={8}>
                                <Box

                                />
                            </Grid> */}

                            <Grid item xs={12} md={12}>
                                <Box
                                    component="img"
                                    sx={{ height: 30 }}
                                    alt="Photobizz image"
                                    src={require("../images/photobizz_logo_dark.png")}
                                    mb={2}
                                    align="right"
                                    pl={3}
                                />
                                <Link to="https://www.smool.be" className="web_link">
                                    <Box
                                        component="img"
                                        sx={{ height: 30 }}
                                        alt="Photobizz image"
                                        src={require("../images/smool_logo_dark.png")}
                                        mb={3}
                                        align="right"
                                        // to="https://www.smool.be"
                                    />
                                </Link>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>

                <AppBar position="fixed" style={{ top: "50px", height: "60px", backgroundColor: "#fff" }}>
                    <Toolbar>
                        <IconButton
                            color="secondary"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{ mr: 2, display: { md: "none" } }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Box variant="h6" component="div" sx={{ flexGrow: 1, display: { xs: "none", md: "block" } }}>
                            <Box
                                component="img"
                                sx={{ height: 30 }}
                                alt="Photobizz image"
                                src={require("../images/Photobizz_logo_trans.png")}
                                // mb={1}
                            />
                        </Box>

                        <Box sx={{ display: { xs: "none", md: "block" } }}>
                            {navItemsTop[language].map((item) => (
                                <NavLink to={item.href} key={item.label} className={({ isActive }) => (isActive ? "web-link-active" : "web-link")}>
                                    <Button
                                        className={({ isActive }) => (isActive ? "web-btn-link-active" : "web-btn-link")}
                                        sx={{ textDecoration: "none" }}
                                    >
                                        {item.label}
                                    </Button>
                                </NavLink>
                            ))}
                            {/* {LanguageChange} */}
                            <FormControl sx={{ pl: 2 }}>
                                {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={language}
                                    label="Age"
                                    onChange={handleLanguageChange}
                                    size="small"
                                    variant="standard"
                                    sx={{ paddingTop: "4px", width: "50px", fontSize: "13px" }}
                                >
                                    <MenuItem value={"en"}>EN</MenuItem>
                                    <MenuItem value={"nl"}>NL</MenuItem>
                                    <MenuItem value={"fr"}>FR</MenuItem>
                                </Select>
                            </FormControl>
                            {/* <NavLink to="/demo" >
                                <Button className="btn-link" sx={{ textDecoration: 'none' }}>
                                    Ask for free demo
                                </Button>
                            </NavLink> */}

                            {/* <Box >
                                <NavLink to="https://dmz.photobizz.be/WebApp_Scholen/ContentPages/Logon.aspx" className={({ isActive }) => (isActive ? "web-link-active" : "web-link")}>
                                    <Button
                                        className={({ isActive }) => (isActive ? "web-btn-link-active" : "web-btn-link")}
                                        sx={{ textDecoration: 'none' }}>
                                        {dict["Labels"]["Login"][language]}
                                    </Button>
                                </NavLink>
                            </Box> */}

                            {/* <Box paddingLeft={3} sx={{ display: { md: 'inline', lg: 'none' } }}>
                                <Button variant="contained" color="primary" target="_blank" href="https://dmz.photobizz.be/WebApp_Scholen/ContentPages/Logon.aspx" sx={{ mt: 0 }}>
                                    {dict["Labels"]["Login"][language]}
                                </Button>
                            </Box>
                            <Box paddingLeft={3} sx={{ display: { md: 'none', lg: 'inline' } }}>
                                <Button variant="contained" color="primary" target="_blank" href="https://dmz.photobizz.be/WebApp_Scholen/ContentPages/Logon.aspx" sx={{ mt: 0 }}>
                                    {dict["Labels"]["Login"][language]}
                                </Button>
                            </Box> */}
                            <Box paddingLeft={3} sx={{ display: { md: "inline", lg: "none" } }}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    // target="_blank"
                                    // href="https://dmz.photobizz.be/Webshop_2020/index"
                                    href="/login"
                                    sx={{ mt: 0 }}
                                >
                                    {dict["Labels"]["MyPhotos"][language]}
                                </Button>
                            </Box>

                            {/* todo : when I will give it to Bernard to check it I will take these remarks out */}
                            <Box paddingLeft={3} sx={{ display: { md: "none", lg: "inline" } }}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    // target="_blank"
                                    // href="https://dmz.photobizz.be/Webshop_2020/index"
                                    href="/login"
                                    sx={{ mt: 0 }}
                                >
                                    {dict["Labels"]["MyPhotos"][language]}
                                </Button>
                            </Box>

                            {/* todo : when I will give it to Bernard to check it I will put remarks on this in line 380 there is a hidden link he can use */}
                            {/* Here i will place the button for the new webshop */}
                            {/* <Box paddingLeft={3} sx={{ display: { md: 'none', lg: 'inline' } }}>
                                <NavLink to="login" key="login" className={({ isActive }) => (isActive ? "web-link-active" : "web-link")}>
                                    <Button variant="contained" color="secondary" sx={{ mt: 0 }}
                                        className={({ isActive }) => (isActive ? "web-btn-link-active" : "web-btn-link")}
                                    >
                                        {dict["Labels"]["MyPhotos"][language]}
                                    </Button>
                                </NavLink>
                            </Box> */}
                        </Box>

                        {/* <Button href="/school">Schools</Button> */}
                    </Toolbar>
                </AppBar>

                <Box component="nav">
                    <Drawer
                        //container={container}
                        variant="temporary"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                        sx={{
                            display: { xs: "block", md: "none" },
                            "& .MuiDrawer-paper": { boxSizing: "border-box", width: 250 },
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Box>

                {/* <Box sx={{ bgcolor: "#bbdefb", p: 1 }}> */}
                <Box sx={{ bgcolor: "white", p: 1 }}>
                    <Toolbar />
                    <Toolbar />

                    <div>
                        <main className="App">
                            <Outlet />
                        </main>
                    </div>

                    <Grid container spacing={2} pt={5} pb={3} maxWidth={1050} margin={"auto"}>
                        <Grid item xs={12} md={5}>
                            <Box
                                component="img"
                                sx={{ width: 200 }}
                                alt="Photobizz image"
                                src={require("../images/Photobizz_logo_trans.png")}
                                mb={2}
                            />
                            <Typography>Stationsstraat 75</Typography>
                            <Typography>BE-8780 Oostrozebeke</Typography>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Typography variant="h6" mb={2}>
                                Menu
                            </Typography>

                            {navItemsBottom[language].map((item) => (
                                <NavLink to={item.href} key={item.label} className={"web-link"}>
                                    <Typography color={"black"}>{item.label}</Typography>
                                </NavLink>
                            ))}
                            {isButtonVisible && (
                                <NavLink to="/login" key="login" className={"web-link"}>
                                    <Typography color={"black"}>Login development</Typography>
                                </NavLink>
                            )}
                        </Grid>
                        <Grid item xs={10} sm={6} md={3}>
                            <Typography variant="h6" mb={2}>
                                {dict["Labels"]["Contacteer_ons"][language]}
                            </Typography>
                            <Box>
                                <Card>
                                    <Grid container spacing={2}>
                                        <Grid item xs={3} md={3}>
                                            <Box component="img" sx={{ width: 35 }} alt="Envelope" src={require("../images/Envelope.png")} m={1} />
                                        </Grid>
                                        <Grid item xs={9} md={9}>
                                            <Typography mt={1} variant="body2">
                                                E-mail:
                                            </Typography>
                                            <Typography variant="body2">info@photobizz.be</Typography>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Box>
                            <Box mt={2}>
                                <Card>
                                    <Grid container spacing={2}>
                                        <Grid item xs={3} md={3}>
                                            <Box component="img" sx={{ width: 35 }} alt="Phone" src={require("../images/Phone.png")} m={1} />
                                        </Grid>
                                        <Grid item xs={9} md={9}>
                                            <Typography mt={1} variant="body2">
                                                {dict["Labels"]["Telephone"][language]}:
                                            </Typography>
                                            <Typography variant="body2">
                                                {/* download file link from 'C:\inetpub\wwwroot\PhotobizzSite\WelcomeBack_full.png' */}
                                                {/* <a href="C:\inetpub\wwwroot\PhotobizzSite\WelcomeBack_full.png" download></a> */}

                                                    +32 56 66 33 88
                                                {/* <NavLink to="/login" key="login" className={"web-link"}>
                                                    +32 56 66 33 88
                                                </NavLink> */}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Box>
                        </Grid>
                    </Grid>

                    <Divider sx={{ my: 2 }} />
                    <Typography variant="body2" color="text.secondary" align="center">
                        {dict["Labels"]["Copyright"][language]}
                    </Typography>
                    <NavLink to="/login" key="login" className={"web-link"}>
                        <Divider sx={{ my: 2 }} />
                    </NavLink>
                </Box>
            </Container>
        </>
    );
};

export default Layout;
