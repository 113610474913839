import axios from "../api/axiosPhotobizz";

import useLanguageContext from "../hooks/useLanguageContext";
import useAccount from "../hooks/useAccount";
import useEnvironment from "../hooks/useEnvironment";
import usePicture from "../hooks/usePicture";
import useProducts from "../hooks/useProducts";
import useOrder from "../hooks/useOrder";

import React, { useState, useEffect, useRef } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import { Box, Typography, Stack, Grid, Button, Card, CardMedia, CardContent, TextField } from "@mui/material";
import { Paper } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Carousel from "react-material-ui-carousel";

//Dialog background
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CloseIcon from "@mui/icons-material/Close";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import Skeleton from "@mui/material/Skeleton";

import IconButton from "@mui/material/IconButton";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import ShoppingBasketOutlinedIcon from "@mui/icons-material/ShoppingBasketOutlined";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import InfoIcon from "@mui/icons-material/Info";

import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";

import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Slide, { SlideProps } from "@mui/material/Slide";

import WebsiteDict from "../data/WebsiteDict";
import ZoomModal from "../components/ZoomModal";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

// Line 1860:34:    'FormControl' is not defined           react/jsx-no-undef
// Line 1861:38:    'FormLabel' is not defined             react/jsx-no-undef
// Line 1862:38:    'RadioGroup' is not defined            react/jsx-no-undef
// Line 1866:51:  'handleSelectKlasFoto' is not defined  no-undef
// Line 1868:42:    'FormControlLabel' is not defined      react/jsx-no-undef
// Line 1870:55:    'Radio' is not defined                 react/jsx-no-undef
// Line 1873:42:    'FormControlLabel' is not defined      react/jsx-no-undef
// Line 1875:55:    'Radio' is not defined

import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from "@mui/material";

//import e from "cors";

const WS_Order = () => {
    const { language, setLanguage } = useLanguageContext();
    const [dict, setDict] = useState(WebsiteDict);

    const { account, setAccount } = useAccount();
    const { picture, setPicture } = usePicture();
    const { products, setProducts } = useProducts();
    const { order, setOrder } = useOrder();
    const { environment, setEnvironment } = useEnvironment();

    const navigate = useNavigate();
    const scrollRef = useRef(null);

    const [openMessage, setOpenMessage] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [severity, setSeverity] = useState("success");

    const [loading, setLoading] = useState(false);
    const [loadingProducts, setLoadingProducts] = useState(false);
    const [loadingArticles, setLoadingArticles] = useState(false);
    const [openZoomModal, setOpenZoomModal] = useState(false);

    const [arrayYears, setArrayYears] = useState([]);
    const [currentSchoolYear, setCurrentSchoolYear] = useState("");

    const [photoCode, setPhotoCode] = useState("");

    const [view, setView] = useState("groups");

    const [updatingArticle, setUpdatingArticle] = useState(false);

    const [openYesNo, setOpenYesNo] = React.useState(false);
    const [openInfo, setOpenInfo] = React.useState(false);

    const [dialogTitle, setDialogTitle] = useState("");
    const [dialogMessage1, setDialogMessage1] = useState("");
    const [dialogMessage2, setDialogMessage2] = useState("");
    const [dialogMessage3, setDialogMessage3] = useState("");
    const [dialogMessage4, setDialogMessage4] = useState("");
    const [showSelectKlasFoto, setShowSelectKlasFoto] = useState(false);
    const [selectedKlasFoto, setSelectedKlasFoto] = useState();
    const [klassFotos, setKlassFotos] = useState([]);

    const [clickedArticle, setClickedArticle] = useState({});

    const [openDialogBackground, setOpenDialogBackground] = useState(false);
    const [backgrounds, setBackgrounds] = useState();
    const [preselectedBG, setPreselectedBG] = useState();
    const [openBackdropLoader, setOpenBackdropLoader] = useState(false);

    const [reloadTrigger, setReloadTrigger] = useState(false);

    useEffect(() => {
        setDict(WebsiteDict);
    }, [WebsiteDict]);

    // async function fetchPhotos() {

    //     try {

    //         var sessAcc = account.Session.Session_ID + "/" + account.Session.Account_ID;

    //         const response = await axios.get("/Picture/GetPictures/" + sessAcc);
    //         if (response.status === 200) {
    //             setPicture(response.data);
    //             setArrayYears(findYears(response.data.Pictures));
    //         }
    //         else {
    //             setSnackbarMessage(dict["WS_Messages"]["Login failed."][language]);
    //             setSeverity("error");
    //             setOpenMessage(true);
    //         }
    //     } catch (error) {
    //         console.log(error);
    //         setSnackbarMessage(dict["WS_Messages"]["Login failed."][language]);
    //         setSeverity("error");
    //         setOpenMessage(true);
    //     }
    //     setLoadingProducts(false);

    // }

    // const findYears = (pictures) => {
    //     let years = [];
    //     pictures.forEach(photo => {
    //         let year = photo.Schooljaar;
    //         if (!years.includes(year)) {
    //             years.push(year);
    //         }
    //     });

    //     //if current month is bigger than 9 (september) then build 'schoolyear' as thisyear+" "+nextyear and add it to array 'years' if it doesn't exist
    //     let d = new Date();
    //     let n = d.getMonth();
    //     if (n > 7) {
    //         let thisyear = d.getFullYear();
    //         let nextyear = thisyear + 1;
    //         let schoolyear = thisyear + "-" + nextyear;
    //         setCurrentSchoolYear(schoolyear);
    //         if (!years.includes(schoolyear)) {
    //             years.push(schoolyear);
    //         }
    //     } else {
    //         let thisyear = d.getFullYear();
    //         let lastyear = thisyear - 1;
    //         let schoolyear = lastyear + "-" + thisyear;
    //         setCurrentSchoolYear(schoolyear);
    //         if (!years.includes(schoolyear)) {
    //             years.push(schoolyear);
    //         }
    //     }

    //     //sort array 'years' in descending order
    //     years.sort((a, b) => {
    //         return b.localeCompare(a);
    //     });

    //     return years;
    // }

    useEffect(() => {
        // =================== Fetching photos ===================

        //if there is no environment.picture, navigate to home
        if (environment.Picture === null || environment.Picture === undefined || Object.keys(environment.Picture).length === 0) {
            // if session 'session_environment is empty, navigate to home
            if (
                JSON.parse(sessionStorage.getItem("session_environment")) === null ||
                JSON.parse(sessionStorage.getItem("session_environment")) === undefined ||
                Object.keys(JSON.parse(sessionStorage.getItem("session_environment"))).length === 0
            ) {
                navigate("/webshop/home?warning=NoPictureSelected");
            } else {
                setEnvironment(JSON.parse(sessionStorage.getItem("session_environment")));
            }
        } else {
            // sessionStorage.setItem("session_environment", JSON.stringify(environment));
        }

        if (products === null || products === undefined || Object.keys(products).length === 0) {
            setLoadingProducts(true);
            //fetchProducts(environment.selectedColor);

            // if (JSON.parse(sessionStorage.getItem("session_products")) !== null && JSON.parse(sessionStorage.getItem("session_products")) !== undefined && Object.keys(JSON.parse(sessionStorage.getItem("session_products"))).length !== 0) {
            //     setProducts(JSON.parse(sessionStorage.getItem("session_products")));
            // } else {
            //     setLoadingProducts(true);
            //     fetchProducts();
            // }
        } else {
            sessionStorage.setItem("session_products", JSON.stringify(products));
        }

        setBackgrounds([]);
        setEnvironment({ ...environment, selectedView: "groups" });

        //setProducts({})
    }, []);

    useEffect(() => {
        // if (document.getElementById("scrollRef")) {
        //     document.getElementById("scrollRef").scrollIntoView({ behavior: "smooth" });
        // }
    }, [arrayYears]);

    // =================== Fetching product groups ===================
    async function fetchProductGroups(_colorCode) {
        let p = environment.Picture;

        try {
            var sessAcc = account.Session.Session_ID + "/" + account.Session.Account_ID;
            var photoDetails =
                "/" + environment.Picture.Fotomap_nr + "/" + environment.Picture.Foto_file + "/" + _colorCode + "/" + environment.selectedBackground;

            const response = await axios.get("/Picture/GetArtikelGroepen/" + sessAcc + photoDetails + "/" + language.toUpperCase());
            if (response.status === 200) {
                return response.data.Picture_ArticleGroups;
                // fetchProductSubGroups();
                // fetchPhotos();
            } else {
                setSnackbarMessage(dict["WS_Messages"]["Loading groups failed."][language]);
                setSeverity("error");
                setOpenMessage(true);
            }
        } catch (error) {
            console.log(error);
            setSnackbarMessage(dict["WS_Messages"]["Loading groups failed."][language]);
            setSeverity("error");
            setOpenMessage(true);
        }
    }

    // =================== Fetching product subgroups ===================
    async function fetchProductSubGroups(group, _colorCode) {
        try {
            var sessAcc = account.Session.Session_ID + "/" + account.Session.Account_ID;
            var photoDetails =
                "/" +
                environment.Picture.Fotomap_nr +
                "/" +
                environment.Picture.Foto_file +
                "/" +
                (group.Artikel_Groep_ID || group.Artikel_Subgroep_ID) +
                "/" +
                _colorCode +
                "/" +
                environment.selectedBackground;

            const response = await axios.get("/Picture/GetArtikelSubgroepen/" + sessAcc + photoDetails + "/" + language.toUpperCase());
            if (response.status === 200) {
                return response.data.Picture_ArticleSubgroups;
            } else {
                setSnackbarMessage(dict["WS_Messages"]["Loading subgroups failed."][language]);
                setSeverity("error");
                setOpenMessage(true);
            }
        } catch (error) {
            console.log(error);
            setSnackbarMessage(dict["WS_Messages"]["Loading subgroups failed."][language]);
            setSeverity("error");
            setOpenMessage(true);
        }
    }

    // =================== Fetching products ===================
    async function fetchProducts(_colorCode) {
        // 1. fetch products group list
        // 2. and then fetch products sub group list
        // 3. and then fetch get products details for every product in group and subgroup

        try {
            var groups = await fetchProductGroups(_colorCode);

            //for every object group in products.groups, fetch subgroups
            for (let group of groups) {
                var subgroups = await fetchProductSubGroups(group, _colorCode);

                //if one of the subgroups has a property 'ArrImage' and it is bigger that 50000 then reformat it to become less than 50000
                for (let subgroup of subgroups) {
                    if (subgroup.ArrImage && subgroup.ArrImage.length > 50000) {
                        subgroup.ArrImage = subgroup.ArrImage.slice(0, 50000);
                    }
                }

                group.subGroups = subgroups;
                console.log(group);
            }

            console.log(groups);
            setProducts({ groups: groups });

            //if the is environment.Group, then find the group in 'groups' and update environment.Group
            if (view === "subgroups" && environment.Group) {
                let group = groups.find((x) => x.Artikel_Groep_ID === environment.Group.Artikel_Groep_ID);
                setEnvironment({ ...environment, Group: group, selectedColor: _colorCode });
            }

            if (groups.length === 1) {
                groupSelected(groups[0], _colorCode);
            }

            //sessionStorage.setItem("session_products", JSON.stringify({ groups: groups }));
        } catch (error) {
            console.log(error);
            setSnackbarMessage(dict["WS_Messages"]["Loading products failed."][language]);
            setSeverity("error");
            setOpenMessage(true);
        }
        setLoadingProducts(false);
    }

    function buttonGeneralGroupsClicked() {
        setView("groups");
        //remove property 'subgroup' from environment
        let obj = { ...environment };
        delete obj.Group;
        delete obj.SubGroup;
        delete obj.Articles;

        setEnvironment({ ...obj, selectedView: "groups" });

        if (products.groups.length === 1) {
            groupSelected(environment.Group, environment.selectedColor);
        }
    }

    function buttonMostOrderedClicked() {
        setView("most_ordered");
        setEnvironment({ ...environment, selectedView: "most_ordered" });
    }

    function buttonPromotionsClicked() {
        setView("promotions");
        setEnvironment({ ...environment, selectedView: "promotions" });
    }

    async function groupSelected(group, _colorCode) {
        console.log(group);

        var obj = {};

        obj = { ...environment, Group: group, groupWindowScroll: { x: window.scrollX, y: window.scrollY }, selectedColor: _colorCode };

        if (group.subGroups.length === 1) {
            setView("products");
            setLoadingArticles(true);
            var articles = await fetchArticles(group.Artikel_Groep_ID, group.subGroups[0].Artikel_Subgroep_ID, _colorCode);
            obj = { ...obj, Articles: articles, selectedView: "products" };

            //if group name is "Paketten en promoties" 
            //and none of the articles has property 'Pakket_Bevat_Klasfoto' equal to true
            //and if environment.Picture.Srt_Foto is not equal to 2 (Familiefoto) then show warning
            if (group.Artikel_Groep_Naam === "Paketten en promoties" && !articles.some((article) => article.Pakket_Bevat_Klasfoto) && environment.Picture.Srt_Foto !== 2) {
                setSnackbarMessage(dict["WS_Messages"]["No classphotos in packages."][language]);
                setSeverity("warning");
                setOpenMessage(true);
            }
        } else {
            setView("subgroups");
            obj = { ...obj, selectedView: "subgroups" };
        }

        let objWithNumberOf = await buildPreOrder(obj);

        setEnvironment(objWithNumberOf);
        setLoadingArticles(false);

        // sessionStorage.setItem("session_environment", JSON.stringify(obj));

        //navigate("/order");
    }

    async function subgroupSelected(subgroup) {
        console.log(subgroup);

        var obj = {};

        obj = { ...environment, SubGroup: subgroup, subgroupWindowScroll: { x: window.scrollX, y: window.scrollY } };

        setView("products");
        setLoadingArticles(true);
        var articles = await fetchArticles(environment.Group.Artikel_Groep_ID, subgroup.Artikel_Subgroep_ID, environment.selectedColor);

        obj = { ...obj, Articles: articles, selectedView: "products" };

        let objWithNumberOf = await buildPreOrder(obj);

        setEnvironment(objWithNumberOf);
        setLoadingArticles(false);
    }

    async function fetchArticles(_groupID, _subgroupID, _colorCode) {
        var sessAcc = "";
        var photoDetails = "";

        sessAcc = account.Session.Session_ID + "/" + account.Session.Account_ID;
        photoDetails =
            "/" +
            environment.Picture.Fotomap_nr +
            "/" +
            environment.Picture.Foto_file +
            "/" +
            _groupID +
            "/" +
            _subgroupID +
            "/" +
            _colorCode +
            "/" +
            environment.selectedBackground;

        const response = await axios.get("Picture/GetArtikels/" + sessAcc + photoDetails + "/" + language.toUpperCase());
        if (response.status === 200) {
            return response.data.Picture_Articles;
        } else {
            setSnackbarMessage(dict["WS_Messages"]["Loading articles failed."][language]);
            setSeverity("error");
            setOpenMessage(true);
        }
    }

    function goBack() {
        if (view === "subgroups") {
            setView("groups");

            //remove property 'subgroup' from environment
            let obj = { ...environment };
            delete obj.SubGroup;
            delete obj.Articles;

            setEnvironment({ obj, selectedView: "groups" });
        } else if (view === "products") {
            let obj = { ...environment };

            if (environment.Group.subGroups.length === 1) {
                setView("groups");
                obj = { ...obj, selectedView: "groups" };
            } else {
                setView("subgroups");
                obj = { ...obj, selectedView: "subgroups" };
            }
            //remove property 'articles' from environment
            delete obj.Articles;
            setEnvironment(obj);
        } else if (view === "most_ordered") {
            setView("groups");
            setEnvironment({ ...environment, selectedView: "groups" });
        } else if (view === "promotions") {
            setView("most_ordered");
            setEnvironment({ ...environment, selectedView: "most_ordered" });
        } else {
            setEnvironment({ ...environment, selectedView: "" });
            navigate("/webshop/home");
        }
    }

    function goForward() {
        if (view === "groups" || view === "subgroups" || view === "products") {
            setView("most_ordered");
            setEnvironment({ ...environment, selectedView: "most_ordered" });
        } else if (view === "most_ordered") {
            setView("promotions");
            setEnvironment({ ...environment, selectedView: "promotions" });
        }
    }

    useEffect(() => {
        console.log("View:", view);

        let envObj = { ...environment };

        if (view === "groups") {
            delete envObj.SubGroup;
            delete envObj.Articles;
        }

        if (view === "subgroups") {
            delete envObj.Articles;
        }

        if (view === "most_ordered") {
            delete envObj.Group;
            delete envObj.SubGroup;
            delete envObj.Articles;
            // delete envObj.Specials;

            specialsSelected("Popular");
        }

        if (view === "promotions") {
            delete envObj.Group;
            delete envObj.SubGroup;
            delete envObj.Articles;
            // delete envObj.Specials;

            specialsSelected("Promo");
        }

        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });

        setEnvironment(envObj);
    }, [view]);

    function buildPreOrder(_obj) {
        //for every article in environment.Articles, add a property 'numberOfPieces' with value 0
        let articles = _obj.Articles;
        if (articles === null || articles === undefined || Object.keys(articles).length === 0) {
            return _obj;
        }
        articles.forEach((article) => {
            article.numberOfPieces = 1;
        });
        return _obj;
    }

    function addOnePieceClicked(article) {
        let articles = environment.Articles;
        let index = articles.findIndex((x) => x.Artikel_nr === article.Artikel_nr);
        articles[index].numberOfPieces = articles[index].numberOfPieces + 1;
        let obj = { ...environment, Articles: articles };
        setEnvironment(obj);
    }

    function removeOnePieceClicked(article) {
        let articles = environment.Articles;
        let index = articles.findIndex((x) => x.Artikel_nr === article.Artikel_nr);
        if (articles[index].numberOfPieces > 1) {
            articles[index].numberOfPieces = articles[index].numberOfPieces - 1;
            let obj = { ...environment, Articles: articles };
            setEnvironment(obj);
        }
    }

    async function specialsSelected(special) {
        var obj = {};

        setLoadingArticles(true);
        var articles = await fetchSpecials(special, environment.selectedColor);

        obj = { ...environment, Articles: articles };

        let objWithNumberOf = await buildPreOrder(obj);

        setEnvironment(objWithNumberOf);
        setLoadingArticles(false);
    }

    async function fetchSpecials(special, _colorCode) {
        var sessAcc = account.Session.Session_ID + "/" + account.Session.Account_ID;
        var photoDetails =
            "/" +
            environment.Picture.Fotomap_nr +
            "/" +
            environment.Picture.Foto_file +
            "/" +
            special +
            "/" +
            _colorCode +
            "/" +
            environment.selectedBackground;

        try {
            const response = await axios.get("Picture/GetPictureSpecials/" + sessAcc + photoDetails + "/" + language.toUpperCase());
            if (response.status === 200) {
                console.log(response.data);
                return response.data.Picture_Articles;
            } else {
                setSnackbarMessage(dict["WS_Messages"]["Loading articles failed."][language]);
                setSeverity("error");
                setOpenMessage(true);
            }
        } catch (error) {
            console.error("Failed to fetch data:", error);
            setSnackbarMessage(dict["WS_Messages"]["Loading articles failed."][language]);
            setSeverity("error");
            setOpenMessage(true);
        }
    }

    // async function colorButtonClicked(article, color) {
    //     console.log(article, color);

    //     //setUpdatingArticle(true);
    //     if (color === "color") {
    //         color = "KL";
    //     } else if (color === "sephia") {
    //         color = "SE";
    //     } else if (color === "black_white") {
    //         color = "ZW";
    //     }

    //     var updatedArticle = await fetchArticle(article, color);
    //     var articles = environment.Articles;
    //     var index = articles.findIndex(x => x.Artikel_nr === article.Artikel_nr);
    //     articles[index] = updatedArticle;
    //     var obj = { ...environment, Articles: articles };
    //     setEnvironment(obj);
    //     //setUpdatingArticle(false);
    // }

    async function fetchArticle(article, colorCode) {
        var sessAcc = account.Session.Session_ID + "/" + account.Session.Account_ID;
        var photoDetails =
            "/" +
            environment.Picture.Fotomap_nr +
            "/" +
            environment.Picture.Foto_file +
            "/" +
            article.Artikel_nr +
            "/" +
            colorCode +
            "/" +
            article.Achtergrond_ID;

        const response = await axios.get("Picture/GetArtikel/" + sessAcc + photoDetails + "/" + language.toUpperCase());
        if (response.status === 200) {
            return response.data.Picture_Articles[0];
        } else {
            setSnackbarMessage(dict["WS_Messages"]["Loading articles failed."][language]);
            setSeverity("error");
            setOpenMessage(true);
        }
    }

    async function add_remove_favorite(article) {
        console.log(article);

        //if article is in account.Favorites, then function removeFavorite else function addFavorite
        if (
            account.Favorites.some(
                (favorite) => favorite.Picture_Article.Artikel_nr === article.Artikel_nr && favorite.Picture_Article.Foto_file === article.Foto_file
            )
        ) {
            //find favorite in account.Favorites
            let favorite = account.Favorites.find(
                (favorite) => favorite.Picture_Article.Artikel_nr === article.Artikel_nr && favorite.Picture_Article.Foto_file === article.Foto_file
            );
            await removeFavorite(favorite);
        } else {
            await addFavorite(article);
        }
    }

    async function removeFavorite(favorite) {
        let sessAcc = account.Session.Session_ID + "/" + account.Session.Account_ID;

        try {
            const response = await axios.delete("Picture/DeleteFavorite/" + sessAcc + "/" + favorite.Favorite_ID);
            if (response.status === 200) {
                console.log(response.data);
                let newFavorites = await fetchFavorites();
                setAccount({ ...account, Favorites: newFavorites });
                setSnackbarMessage(dict["WS_Messages"]["Favorite removed."][language]);
                setSeverity("success");
                setOpenMessage(true);
            } else {
                setSnackbarMessage(dict["WS_Messages"]["Removing favorite failed."][language]);
                setSeverity("error");
                setOpenMessage(true);
            }
        } catch (error) {
            console.error("Failed to fetch data:", error);
            setSnackbarMessage(dict["WS_Messages"]["Removing favorite failed."][language]);
            setSeverity("error");
            setOpenMessage(true);
        }
    }

    async function addFavorite(_article) {
        var body = {
            Session: {
                Session_ID: account.Session.Session_ID,
                Account_ID: account.Session.Account_ID,
            },
            Fotomap_nr: _article.Fotomap_nr,
            Foto_file: _article.Foto_file,
            Webshop_artikel_ID: _article.Webshop_Artikel_ID,
            Achtergrond_ID: _article.Achtergrond_ID,
            Commentaar: "",
        };

        const response = await axios.post("Picture/PostAddFavorite", body);
        if (response.status === 200) {
            console.log(response.data);
            let newFavorites = await fetchFavorites();
            setAccount({ ...account, Favorites: newFavorites });
            setSnackbarMessage(dict["WS_Messages"]["Favorite added."][language]);
            setSeverity("success");
            setOpenMessage(true);
        } else {
            setSnackbarMessage(dict["WS_Messages"]["Adding favorite failed."][language]);
            setSeverity("error");
            setOpenMessage(true);
        }
    }

    async function fetchFavorites() {
        var sessAcc = "";

        sessAcc = account.Session.Session_ID + "/" + account.Session.Account_ID;
        try {
            const response = await axios.get("Picture/GetFavorieten/" + sessAcc);
            if (response.status === 200) {
                console.log(response.data.Picture_Article_Favorite);
                return response.data.Picture_Article_Favorite;
            } else {
                return {};
            }
        } catch (error) {
            console.error("Failed to fetch data:", error);
            return {};
        }
    }

    //Modal Yes No ========================
    const handleClose = () => {
        setOpenYesNo(false);
        setOpenInfo(false);
    };

    async function fetchPhoto(photo) {
        //Picture/GetPicture/{Session_ID}/{Account_ID}/{Fotomap_nr}/{Foto_file}/{KL_SE_ZW}/{Achtergrond_ID}

        try {
            var sessAcc = account.Session.Session_ID + "/" + account.Session.Account_ID;
            const response = await axios.get(
                "Picture/GetPicture/" + sessAcc + "/" + photo.Fotomap_Nr + "/" + photo.Foto_File + "/KL/" + environment.selectedBackground
            );
            if (response.status === 200) {
                console.log(response.data);
                return response.data.Pictures[0];
            } else {
                setSnackbarMessage(dict["WS_Messages"]["Loading backgrounds failed."][language]);
                setSeverity("error");
                setOpenMessage(true);
            }
        } catch (error) {
            console.error("Failed to fetch data:", error);
            setSnackbarMessage(dict["WS_Messages"]["Loading backgrounds failed."][language]);
            setSeverity("error");
            setOpenMessage(true);
        }
    }

    async function fetchClassPhoto(_article) {
        //Order/GetGekoppeldeKlasfotos/{Session_ID}/{Account_ID}/{Fotomap_nr}/{Foto_file}
        var p = picture;

        var sessAcc = account.Session.Session_ID + "/" + account.Session.Account_ID;
        var photoDetails = "/" + _article.Fotomap_nr + "/" + _article.Foto_file;

        try {
            const response = await axios.get("Order/GetGekoppeldeKlasfotos/" + sessAcc + photoDetails + "/");
            if (response.status === 200) {
                console.log(response.data.GekoppeldeKlasfotos);

                var klasFotoArr = [];

                for (var i = 0; i < p.Pictures.length; i++) {
                    for (var j = 0; j < response.data.GekoppeldeKlasfotos.length; j++) {
                        if (
                            p.Pictures[i].Fotomap_nr === response.data.GekoppeldeKlasfotos[j].Fotomap_Nr &&
                            p.Pictures[i].Foto_file === response.data.GekoppeldeKlasfotos[j].Foto_File
                        ) {
                            klasFotoArr.push(p.Pictures[i]);
                        }
                    }
                }

                return klasFotoArr;
            } else {
                setSnackbarMessage(dict["WS_Messages"]["Loading classphotos failed."][language]);
                setSeverity("error");
                setOpenMessage(true);
            }
        } catch (error) {
            console.error("Failed to fetch data:", error);
            setSnackbarMessage(dict["WS_Messages"]["Loading classphotos failed."][language]);
            setSeverity("error");
            setOpenMessage(true);
        }
    }

    async function add_article_to_cart(_article) {
        if (_article.numberOfPieces === 0) {
            setSnackbarMessage(dict["WS_Messages"]["Please select a quantity."][language]);
            setSeverity("error");
            setOpenMessage(true);
            return;
        }

        if (_article.Pakket_Bevat_Klasfoto) {
            console.log("Pakket bevat klasfoto");
            setShowSelectKlasFoto(true);
            var classPhotoArr = await fetchClassPhoto(_article);
            setKlassFotos(classPhotoArr);
            setSelectedKlasFoto(classPhotoArr[0].Foto_file);
        } else {
            setShowSelectKlasFoto(false);
            setKlassFotos([]);
            setSelectedKlasFoto();
        }

        setDialogTitle(dict["WS_Messages"]["Dialog_Title_Add_to_cart"][language]);

        //find current school year
        let currentSchoolYear = "";
        let d = new Date();
        let n = d.getMonth();
        if (n > 7) {
            let thisyear = d.getFullYear();
            let nextyear = thisyear + 1;
            let schoolyear = thisyear + "-" + nextyear;
            currentSchoolYear = schoolyear;
        } else {
            let thisyear = d.getFullYear();
            let lastyear = thisyear - 1;
            let schoolyear = lastyear + "-" + thisyear;
            currentSchoolYear = schoolyear;
        }

        //if picture in Pictures.Pictures with properties Foto_file and Fotomap_nr is the same as _article.Foto_file and _article.Fotomap_nr then set it to currentPicture
        let currentPicture = picture.Pictures.find((photo) => photo.Foto_file === _article.Foto_file && photo.Fotomap_nr === _article.Fotomap_nr);

        var msg1 = "";
        var newLine = "";
        if (currentPicture.Schooljaar !== currentSchoolYear) {
            msg1 = dict["WS_Messages"]["Dialog Message Attention Picture is from other schoolyear"][language] + " ";
            newLine = "\r\n";
        }

        if (_article.Warning_GeenPasfoto) {
            msg1 = msg1 + newLine + dict["WS_Messages"]["Dialog Message Attention there are no passport fotos"][language];
        }

        setDialogMessage1(msg1);

        setDialogMessage2(
            dict["WS_Messages"]["Article details:"][language] + " " + _article.Artikel_naam_lang + " (" + _article.Artikel_Subgroep_Naam + ")"
        );
        setDialogMessage3(dict["WS_Messages"]["Number of articles:"][language] + " " + _article.numberOfPieces);
        setDialogMessage4(dict["WS_Messages"]["Do you want to add this article to your cart?"][language]);

        setClickedArticle(_article);

        setOpenYesNo(true);
    }

    async function handleValidateAddOrderLine() {
        var _article = clickedArticle;

        var s = selectedKlasFoto;

        setOpenYesNo(false);

        var _order = undefined;

        if (account.Order === null || account.Order === undefined || Object.keys(account.Order).length === 0) {
            _order = await fetchNewOrder(_article);
        } else {
            _order = account.Order;
        }

        var _session = await createOrderLijn(_order, _article);

        if (areObjectsEqual(_session, account.Session)) {
            var _orderCombo = await fetchOpenOrder();
            setAccount({ ...account, Order: _orderCombo.Order, Cart: _orderCombo.Order_Lijnen });

            setSnackbarMessage(dict["WS_Messages"]["Article added to cart."][language]);
            setSeverity("success");
            setOpenMessage(true);
        }
    }

    function areObjectsEqual(obj1, obj2) {
        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);

        if (keys1.length !== keys2.length) {
            return false;
        }

        for (let key of keys1) {
            if (obj1[key] !== obj2[key]) {
                return false;
            }
        }

        return true;
    }

    async function fetchOpenOrder() {
        var sessAcc = "";
        var _orderCombo = {};

        sessAcc = account.Session.Session_ID + "/" + account.Session.Account_ID;

        const response = await axios.get("Order/GetOpenOrder/" + sessAcc + "/" + language.toUpperCase());
        if (response.status === 200) {
            console.log(response.data);
            _orderCombo = response.data;
        }

        return _orderCombo;
    }

    async function fetchNewOrder(_article) {
        //body example
        // {
        //     "Session": {
        //           "Session_ID": "C5E0DEA6-1537-41A2-84FA-1590FF0260D7",
        //           "Account_ID": "BB7DD27A-C3E8-4FA6-AC65-E49132D19BB2"
        //     },
        //     "Fotomap_nr": 27861,
        //     "Foto_file": "0641.JPG"
        //   }

        var body = {
            Session: {
                Session_ID: account.Session.Session_ID,
                Account_ID: account.Session.Account_ID,
            },
            Fotomap_nr: _article.Fotomap_nr,
            Foto_file: _article.Foto_file,
        };

        try {
            const response = await axios.post("Order/PostCreateOrder", body);
            if (response.status === 200) {
                console.log(response.data.Order);
                return response.data.Order;
            } else {
                setSnackbarMessage(dict["WS_Messages"]["Creating order failed."][language]);
                setSeverity("error");
                setOpenMessage(true);
            }
        } catch (error) {
            console.error("Failed to fetch data:", error);
            setSnackbarMessage(dict["WS_Messages"]["Creating order failed."][language]);
            setSeverity("error");
            setOpenMessage(true);
        }
    }

    async function createOrderLijn(_order, _article) {
        //body example
        // {
        //     "Session": {
        //           "Session_ID": "C5E0DEA6-1537-41A2-84FA-1590FF0260D7",
        //           "Account_ID": "BB7DD27A-C3E8-4FA6-AC65-E49132D19BB2"
        //     },
        //     "Order_Nr": 5194,
        //     "Fotomap_Nr": 27861,
        //     "Foto_File": "0641.JPG",
        //     "Artikel_Nr": 8,
        //     "Artikel_Groep_ID": "FOTO",
        //     "Artikel_Subgroep_ID": "STFOTO",
        //     "Achtergrond_ID": ""+environment.selectedBackground,
        //     "KL_SE_ZW": "KL",
        //     "Aantal": 2,
        //     "Klasfoto": "",
        //   }

        var klassfoto = {};
        var klassFotoBody = "";
        if (showSelectKlasFoto) {
            if (selectedKlasFoto !== undefined && selectedKlasFoto !== null) {
                //find klasfoto in klassFotos with property 'Foto_file' equal to selectedKlasFoto
                klassfoto = klassFotos.find((kf) => kf.Foto_file === selectedKlasFoto);
                klassFotoBody = klassfoto.Fotomap_nr + "|" + klassfoto.Foto_file;
            }
        }

        var body = {
            Session: {
                Session_ID: account.Session.Session_ID,
                Account_ID: account.Session.Account_ID,
            },
            Order_Nr: _order.order_nr,
            Fotomap_Nr: _article.Fotomap_nr,
            Foto_File: _article.Foto_file,
            Artikel_Nr: _article.Artikel_nr,
            Artikel_Groep_ID: _article.Artikel_Groep_ID,
            Artikel_Subgroep_ID: _article.Artikel_Subgroep_ID,
            Achtergrond_ID: _article.Achtergrond_ID,
            KL_SE_ZW: _article.KL_SE_ZW,
            Aantal: _article.numberOfPieces,
            Klasfoto: klassFotoBody,
        };

        try {
            const response = await axios.post("Order/PostCreateOrderLijn", body);
            if (response.status === 200) {
                console.log(response.data);
                return response.data;
            } else {
                setSnackbarMessage(dict["WS_Messages"]["Adding article to cart failed."][language]);
                setSeverity("error");
                setOpenMessage(true);
            }
        } catch (error) {
            console.error("Failed to fetch data:", error);
            setSnackbarMessage(dict["WS_Messages"]["Adding article to cart failed."][language]);
            setSeverity("error");
            setOpenMessage(true);
        }
        setSelectedKlasFoto("");
        setShowSelectKlasFoto(false);
        setKlassFotos([]);
    }

    async function fetchBackgrounds() {
        var sessAcc = "";

        sessAcc = account.Session.Session_ID + "/" + account.Session.Account_ID;

        try {
            const response = await axios.get("Picture/GetAchtergronden/" + sessAcc + "/" + environment.Picture.Fotomap_nr);
            if (response.status === 200) {
                console.log(response.data.Picture_Achtergronden);
                setOpenBackdropLoader(false);
                return response.data.Picture_Achtergronden;
            } else {
                setSnackbarMessage(dict["WS_Messages"]["Loading backgrounds failed."][language]);
                setSeverity("error");
                setOpenMessage(true);
            }
        } catch (error) {
            console.error("Failed to fetch data:", error);
            setSnackbarMessage(dict["WS_Messages"]["Loading backgrounds failed."][language]);
            setSeverity("error");
            setOpenMessage(true);
        }
        setOpenBackdropLoader(false);
    }

    async function openBackgroundDialog() {
        var bg = [];

        if (backgrounds === null || backgrounds === undefined || Object.keys(backgrounds).length === 0) {
            setOpenBackdropLoader(true);
            bg = await fetchBackgrounds();

            //if environment.picture.Srt_Foto === 1 then keep only backgrounds from bg where there is property 'Image_Portrait'
            if (environment.Picture.Srt_Foto === 1 || environment.Picture.Srt_Foto === 3) {
                bg = bg.filter((bg) => bg.Image_Portrait !== undefined);
            }

            //if environment.picture.Srt_Foto === 2 then remove all backgrounds from bg where there is property 'Image_Landscape'
            if (environment.Picture.Srt_Foto === 2) {
                bg = bg.filter((bg) => bg.Image_Landscape !== undefined);
            }

            await setBackgrounds(bg);
        } else {
            bg = backgrounds;
        }

        if (environment.selectedBackground !== "00000000-0000-0000-0000-000000000000") {
            let backgr = bg.find((bg) => bg.Achtergrond_ID === environment.selectedBackground);

            if (backgr === undefined) {
                setPreselectedBG(bg[0]);
            } else {
                setPreselectedBG(bg.find((bg) => bg.Achtergrond_ID === environment.selectedBackground));
            }
        } else {
            setPreselectedBG(bg[0]);
        }
        setOpenDialogBackground(true);
    }

    async function handleCloseDialogBackground() {
        setOpenDialogBackground(false);
        // setOpenBackdropLoader(false);
    }

    async function handleSaveSelectionDialogBackground() {
        console.log(preselectedBG);
        setEnvironment({ ...environment, selectedBackground: preselectedBG.Achtergrond_ID });
        setOpenDialogBackground(false);
        setPreselectedBG(undefined);
        //setOpenBackdropLoader(false);
    }

    async function colorButtonClicked(color) {
        var colorCode = await getColorCode(color);

        if (view === "products") {
            setLoadingArticles(true);

            setEnvironment({ ...environment, Articles: [] });
            getArticles_wait(colorCode);

            getGroups_async(colorCode);
        }

        if (view === "most_ordered" || view === "promotions") {
            setLoadingArticles(true);

            setEnvironment({ ...environment, Articles: [] });
            getSpecials_wait(colorCode);

            getGroups_async(colorCode);
        }

        if (view === "groups" || view === "subgroups") {
            setProducts({});
            setEnvironment({ ...environment });
            setLoadingProducts(true);
            getGroups_async(colorCode);
        }

        setReloadTrigger(prev => !prev);
    }

    async function getColorCode(color) {
        if (color === "color") {
            return "KL";
        } else if (color === "sephia") {
            return "SE";
        } else if (color === "black_white") {
            return "ZW";
        }
    }

    useEffect(() => {

        // setOpenBackdropLoader(true);
        if (view === "products" && environment.selectedBackground !== "00000000-0000-0000-0000-000000000000") {
            setLoadingArticles(true);

            setEnvironment({ ...environment, Articles: [] });
            getArticles_wait(environment.selectedColor);

            getGroups_async(environment.selectedColor);
        }

        if (view === "most_ordered" || view === "promotions") {
            setLoadingArticles(true);
            setEnvironment({ ...environment, Articles: [] });
            getSpecials_wait(environment.selectedColor);
            getGroups_async(environment.selectedColor);
        }

        if (view === "groups" || view === "subgroups") {
            setProducts({});
            setLoadingProducts(true);
            getGroups_async(environment.selectedColor);
        }
        setOpenBackdropLoader(false);

        //todo: update subgroups with new background
        //todo: update groups with new background
    }, [environment.selectedBackground]);

    async function getArticles_wait(_colorCode) {
        let obj = { ...environment, selectedColor: _colorCode };
        var articles = await fetchArticles(environment.Group.Artikel_Groep_ID, environment.Group.subGroups[0].Artikel_Subgroep_ID, _colorCode);
        setLoadingArticles(false);
        obj = { ...obj, Articles: articles };
        let objWithNumberOf = await buildPreOrder(obj);
        setEnvironment(objWithNumberOf);
    }

    async function getSpecials_wait(_colorCode) {
        let obj = { ...environment, selectedColor: _colorCode };

        var spacialCode = getSpecialCode(view);

        var articles = await fetchSpecials(spacialCode, _colorCode);
        setLoadingArticles(false);
        obj = { ...obj, Articles: articles };
        setEnvironment(obj);
    }

    function getSpecialCode(view) {
        if (view === "most_ordered") {
            return "Popular";
        } else if (view === "promotions") {
            return "Promo";
        }
    }

    async function getGroups_async(_colorCode) {
        fetchProducts(_colorCode);
        let obj = { ...environment, selectedColor: _colorCode };
        setEnvironment(obj);
    }

    async function open_info_dialog(_article) {
        setDialogTitle(dict["WS_Messages"]["Dialog_Title_Article_details"][language]);

        // <Typography variant="caption" color="text.secondary">{article.Artikel_naam_lang}</Typography>
        // <Typography variant="caption" color="text.secondary">{article.Foto_file}</Typography>
        // <Typography variant="caption" color="text.secondary">{environment.Picture.School}</Typography>
        // <Typography variant="caption" color="text.secondary">{environment.Picture.Postnr} {environment.Picture.Lokal}</Typography>
        // <Typography variant="caption" color="text.secondary">{
        //     environment.Picture.Datum_opname ? environment.Picture.Datum_opname.split("T")[0].split("-").reverse().join("/") : ""
        // }</Typography>
        // <Typography variant="caption" color="text.secondary">{environment.Picture.Foto_Info}</Typography>

        setDialogMessage1(_article.Artikel_naam_lang);
        setDialogMessage2("");
        setDialogMessage3("");
        setDialogMessage4("");
        // setDialogMessage2(environment.Picture.School );
        // setDialogMessage3(environment.Picture.Postnr + " " + environment.Picture.Lokal+" "+ environment.Picture.Foto_Info);
        // setDialogMessage4( environment.Picture.Datum_opname ? environment.Picture.Datum_opname.split("T")[0].split("-").reverse().join("/") : ""+" ");

        setOpenInfo(true);
    }

    const renderArticles = (article, index) => {
        return (
            <Grid item xs={12} sm={6} md={6} lg={4} xl={3} key={index} sx={{ cursor: "default" }}>
                <Box sx={{ position: "relative", width: 300, p: 0, overflow: "hidden" }}>
                    {article.Prijs_Promo !== 0 && (
                        <Box
                            sx={{
                                position: "absolute",
                                top: 100,
                                left: 0,
                                backgroundColor: "secondary.main",
                                color: "white",
                                transform: "rotate(-45deg)",
                                transformOrigin: "left bottom",
                                width: "200px",
                                textAlign: "center",
                                lineHeight: "30px",
                                letterSpacing: "1px",
                                zIndex: 1000,
                            }}
                        >
                            {`MIN ${Math.round(((article.Prijs - article.Prijs_Promo) / article.Prijs) * 100)}%`}
                        </Box>
                    )}

                    {/* if today is less the 1 sep 2024 */}
                    {/* {new Date() < new Date("2024-09-01") && (
                        <>
                            <Box
                                sx={{
                                    position: "absolute",
                                    top: 80,
                                    left: -27,
                                    backgroundColor: "primary.main",
                                    color: "white",
                                    transform: "rotate(-45deg)",
                                    transformOrigin: "left bottom",
                                    width: "200px",
                                    textAlign: "center",
                                    lineHeight: "30px",
                                    letterSpacing: "1px",
                                    zIndex: 1000,
                                    fontSize: ".7em",
                                }}
                            >
                                {`40% korting`}
                            </Box>
                        </>
                    )} */}

                    <Card sx={{ width: 280, p: 1, pb: 0, border: "solid 2px lightgray", borderRadius: 5, backgroundColor: "#ededed" }}>
                        <CardMedia
                            key={index}
                            className="object_fit_contain"
                            component="img"
                            height="250"
                            // id={
                            //     group?.Foto_file === environment?.Picture?.Foto_file ? "scrollRef" : null
                            // }
                            src={
                                article.ArrImage
                                    ? "data:image/jpeg;base64," + article.ArrImage.toString()
                                    : require("../images/no_image_available.jpg")
                            }
                            // image={`${item.img}?w=200&fit=crop&auto=format`}
                            alt="event photo"
                        />

                        <CardContent>
                            <Stack direction={"column"} spacing={0}>
                                <Stack direction={"row"} spacing={1} sx={{ width: "100%" }}>
                                    <Typography
                                        variant="caption"
                                        fontWeight="bolder"
                                        fontSize={14}
                                        color="text.secondary"
                                        sx={{
                                            flexGrow: 0,
                                            flexBasis: "auto",
                                            whiteSpace: "normal",
                                        }}
                                    >
                                        {article.Artikel_naam}
                                    </Typography>
                                    {/* <Stack direction={"row"} spacing={1} sx={{ flexGrow: 1, justifyContent: 'right' }}> */}

                                    {/* box align right */}
                                    <Box sx={{ flexGrow: 1, textAlign: "right" }}>
                                        {article.Prijs_Promo !== 0 ? (
                                            <>
                                                <Typography
                                                    variant="caption"
                                                    fontWeight="bolder"
                                                    fontSize={14}
                                                    color="text.secondary"
                                                    sx={{ width: "30%", color: "red", textDecoration: "line-through", pr: "5px" }}
                                                >
                                                    {`€ ${parseFloat(article.Prijs).toFixed(2)}`}
                                                </Typography>
                                                <Typography variant="caption" fontWeight={900} color="text.secondary" sx={{ width: "30%" }}>
                                                    {`€ ${parseFloat(article.Prijs_Promo).toFixed(2)}`}
                                                </Typography>
                                            </>
                                        ) : (
                                            <>
                                                <Typography
                                                    variant="caption"
                                                    fontWeight="bolder"
                                                    fontSize={14}
                                                    color="text.secondary"
                                                    sx={{ width: "30%" }}
                                                >
                                                    {`€ ${parseFloat(article.Prijs).toFixed(2)}`}
                                                </Typography>
                                            </>
                                        )}
                                    </Box>
                                    {/* </Stack> */}
                                </Stack>

                                {/* one button for minus(1) a textfield for numberOfPieces and one button for plus(1) */}
                                <Grid container spacing={0} justifyContent="space-between">
                                    <Grid item>
                                        <IconButton
                                            color={
                                                // if in account.Favorites there is object with article_nr as this article.article_nr, then color is primary, else secondary
                                                account?.Favorites.find(
                                                    (x) =>
                                                        x.Picture_Article.Webshop_Artikel_ID === article.Webshop_Artikel_ID &&
                                                        x.Picture_Article.Foto_file === article.Foto_file
                                                )
                                                    ? "primary"
                                                    : "secondary"
                                            }
                                            onClick={() => {
                                                add_remove_favorite(article);
                                            }}
                                            sx={{ height: "45px", width: "45px" }}
                                        >
                                            <FavoriteIcon />
                                        </IconButton>
                                    </Grid>

                                    <Grid item>
                                        <IconButton
                                            color="secondary"
                                            onClick={() => {
                                                open_info_dialog(article);
                                            }}
                                            sx={{ height: "45px", width: "45px" }}
                                        >
                                            <InfoIcon />
                                        </IconButton>
                                    </Grid>

                                    <Grid item>
                                        <IconButton
                                            color="secondary"
                                            onClick={() => {
                                                removeOnePieceClicked(article);
                                            }}
                                            sx={{ height: "45px", width: "45px" }}
                                        >
                                            <RemoveIcon />
                                        </IconButton>
                                        <Box
                                            id="outlined-number"
                                            sx={{
                                                width: "70px",
                                                display: "inline",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                border: 1,
                                                borderColor: "primary.main",
                                                borderRadius: "4px",
                                                padding: "5px",
                                                border: "0px solid #ced4da",
                                            }}
                                            variant="caption"
                                        >
                                            {article.numberOfPieces}
                                        </Box>
                                        <IconButton
                                            color="secondary"
                                            onClick={() => {
                                                addOnePieceClicked(article);
                                            }}
                                            sx={{ height: "45px", width: "45px" }}
                                        >
                                            <AddIcon />
                                        </IconButton>
                                    </Grid>
                                    <Grid item>
                                        <IconButton
                                            color="secondary"
                                            onClick={() => {
                                                add_article_to_cart(article);
                                            }}
                                            sx={{ height: "45px", width: "45px" }}
                                        >
                                            <ShoppingBasketOutlinedIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Stack>
                        </CardContent>
                    </Card>
                </Box>
            </Grid>
        );
    };

    function handleGotoHome() {
        //remove from environment propery "Picture" and "selectedBackground"
        var env = environment;
        delete env.Picture;
        delete env.selectedBackground;
        setEnvironment(env);

        navigate("/webshop/home");
    }

    async function handleSelectKlasFoto(event) {
        console.log(event.target.value);
        setSelectedKlasFoto(event.target.value);
    }

    return (
        <>
            <Stack spacing={2} m={1}>
                <Grid container spacing={0} sx={{ cursor: "default" }}>
                    {/* buttons box */}
                    <Grid item xs={12} sm={12} md={12} lg={6} mt={0} pb={0} minHeight={80}>
                        <Stack direction={"column"} spacing={3}>
                            <Box height={55} display={"flex"}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        padding: 1,
                                        backgroundColor: "#D0E5EF",
                                        borderRadius: 3,
                                        width: "340px",
                                        ml: "auto",
                                        mr: "auto",
                                        mt: "auto",
                                        mb: "auto",
                                    }}
                                >
                                    <Stack direction={"row"} spacing={1}>
                                        {/* <IconButton onClick={() => goBack()}>
                                        <ArrowBackIcon />
                                    </IconButton> */}
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => buttonGeneralGroupsClicked()}
                                            style={{ width: "105px" }}
                                            //if view is groups, then disable button
                                            disabled={view === "groups"}
                                        >
                                            {dict["WS_Labels"]["Button_General_groups"][language]}
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => buttonMostOrderedClicked()}
                                            style={{ width: "105px" }}
                                            disabled={view === "most_ordered"}
                                        >
                                            {dict["WS_Labels"]["Button_Most_ordered"][language]}
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => buttonPromotionsClicked()}
                                            style={{ width: "105px" }}
                                            disabled={view === "promotions"}
                                        >
                                            {dict["WS_Labels"]["Button_Promotions"][language]}
                                        </Button>

                                        {/* <IconButton onClick={() => goForward()}>
                                        <ArrowForwardIcon />
                                    </IconButton> */}
                                    </Stack>
                                </Box>
                            </Box>

                            {new Date() < new Date("2024-09-01") && (
                                <Box height={50} sx={{ pb: 1, display: { xs: "none", sm: "none", md: "flex", lg: "flex", xl: "flex" } }}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            padding: 1,
                                            backgroundColor: "#FB8500",
                                            borderRadius: 3,
                                            width: "340px",
                                            ml: "auto",
                                            mr: "auto",
                                            mt: "auto",
                                            mb: "auto",
                                            pt: 1,
                                            pb: 1,
                                        }}
                                    >
                                        <Typography variant="body2" color={"#fff"} fontWeight={600} sx={{ ml: 2 }}>
                                            {dict["WS_Messages"]["Extra korting message"][language]}
                                        </Typography>
                                    </Box>
                                </Box>
                            )}
                        </Stack>
                    </Grid>

                    {/* photo box */}
                    <Grid item xs={12} sm={12} md={12} lg={6} sx={{ cursor: "default" }}>
                        <Box
                            id="photoBox"
                            height={130}
                            p={1}
                            display="flex"
                            sx={{
                                // width: environment?.Picture?.Srt_Foto === 1 || environment?.Picture?.Srt_Foto === 3 ? 300 : 410,
                                width: environment?.Picture?.Srt_Foto === 1 || environment?.Picture?.Srt_Foto === 3 ? 320 : 320,
                                border: "solid 2px #D0E5EF",
                                borderRadius: 5,
                                backgroundColor: "#D0E5EF",
                                ml: "auto",
                                mr: "auto",
                            }}
                        >
                            {/* Left Box with Image */}
                            <Box width={210} height={130} sx={{ position: "relative", pr: 1, overflow: "hidden" }}>
                                <Box
                                    // className="object_fit_contain"
                                    component="img"
                                    height={environment?.Picture?.Srt_Foto === 1 || environment?.Picture?.Srt_Foto === 3 ? "130px" : "70px"}
                                    mt={environment?.Picture?.Srt_Foto === 1 || environment?.Picture?.Srt_Foto === 3 ? 0 : 4}
                                    // height="130px"
                                    src={
                                        environment?.Picture?.ArrImage
                                            ? "data:image/jpeg;base64," + environment.Picture.ArrImage.toString()
                                            : require("../images/no_image_available.jpg")
                                    }
                                    alt="event photo"
                                    sx={{ borderRadius: 3 }}
                                />
                            </Box>

                            {/* Right Box with Buttons */}
                            <Box width={215} height={140} display="flex" flexDirection="column" justifyContent="space-between">
                                <Box height="33%">
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => {
                                            handleGotoHome();
                                        }}
                                        sx={{ height: "40px", width: "207px" }}
                                    >
                                        {dict["WS_Labels"]["Button_change_photo"][language]}
                                    </Button>
                                </Box>

                                {/* <Box height="33%" display="flex" flexDirection="row" justifyContent="space-between"> */}
                                <Box height="33%" display="flex" flexDirection="row">
                                    <Stack direction={"row"} spacing={1}>
                                        <Button
                                            variant="contained"
                                            color={environment.selectedColor === "KL" ? "primary" : "secondary"}
                                            onClick={() => {
                                                colorButtonClicked("color");
                                            }}
                                            sx={{ height: "40px", width: "50px" }}
                                            disabled={environment.selectedColor === "KL" ? true : false}
                                        >
                                            {dict["WS_Labels"]["Button_Color"][language]}
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color={environment.selectedColor === "SE" ? "primary" : "secondary"}
                                            onClick={() => {
                                                colorButtonClicked("sephia");
                                            }}
                                            sx={{ height: "40px", width: "50px" }}
                                            disabled={environment.selectedColor === "SE" ? true : false}
                                        >
                                            {dict["WS_Labels"]["Button_Sephia"][language]}
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color={environment.selectedColor === "ZW" ? "primary" : "secondary"}
                                            onClick={() => {
                                                colorButtonClicked("black_white");
                                            }}
                                            sx={{ height: "40px", width: "50px" }}
                                            disabled={environment.selectedColor === "ZW" ? true : false}
                                        >
                                            {dict["WS_Labels"]["Button_Black_white"][language]}
                                        </Button>
                                    </Stack>
                                </Box>
                                <Box height="33%">
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => openBackgroundDialog()}
                                        style={{ width: "207px" }}
                                        disabled={environment?.Picture?.Fotomap_Kenmerk === "K" ? false : true}
                                    >
                                        {dict["WS_Labels"]["Button_Change_background"][language]}
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>

                {/* Skeleton */}
                <Grid container spacing={2}>
                    {loadingProducts ? (
                        <>
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: 1, flexWrap: "wrap" }}>
                                {[...Array(6)].map((_, index) => (
                                    <Box
                                        sx={{
                                            width: { xs: "100%", sm: "33.330%", md: "33.33%%", lg: "25%", xl: "25%" },
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <Skeleton variant="rectangular" width={280} height={200} />
                                    </Box>
                                ))}
                            </Box>
                        </>
                    ) : null}

                    {loadingArticles ? (
                        <>
                            {/* <Typography align="left" color={"#023047"} fontWeight={600} variant="h6">{dict["WS_Messages"]["Loading..."][language]}</Typography> */}

                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: 1, flexWrap: "wrap" }}>
                                {[...Array(6)].map((_, index) => (
                                    <Box
                                        sx={{
                                            width: { xs: "100%", sm: "33.330%", md: "33.33%%", lg: "25%", xl: "25%" },
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <Skeleton variant="rectangular" width={280} height={360} />
                                    </Box>
                                ))}
                            </Box>
                        </>
                    ) : null}
                </Grid>

                {/* <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: 1, flexWrap: "wrap" }}>
                    <Box sx={{ width: { xs: "100%", sm: "50%", md: "50%", lg: "33.33%", xl: "25%" }, display: "flex", justifyContent: "center" }}>
                        <Card sx={{ width: 280, p: 1 }}>
                            <CardMedia
                                className="object_fit_contain"
                                component="img"
                                height="200"
                                src={require("../images/no_image_available.jpg")}
                                alt="event photo"
                                onClick={() => groupSelected()}
                                onMouseEnter={() => {
                                    document.body.style.cursor = "pointer";
                                }}
                                onMouseLeave={() => {
                                    document.body.style.cursor = "default";
                                }}
                            />
                            <CardContent>
                                <Typography variant="body1" color="text.secondary">
                                    {dict["WS_Labels"]["Button_General_groups"][language]}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Box>
                    <Box sx={{ width: { xs: "100%", sm: "50%", md: "50%", lg: "33.33%", xl: "25%" }, display: "flex", justifyContent: "center" }}>
                        <Card sx={{ width: 280, p: 1 }}>
                            <CardMedia
                                className="object_fit_contain"
                                component="img"
                                height="200"
                                src={require("../images/no_image_available.jpg")}
                                alt="event photo"
                                onClick={() => groupSelected()}
                                onMouseEnter={() => {
                                    document.body.style.cursor = "pointer";
                                }}
                                onMouseLeave={() => {
                                    document.body.style.cursor = "default";
                                }}
                            />
                            <CardContent>
                                <Typography variant="body1" color="text.secondary">
                                    {dict["WS_Labels"]["Button_General_groups"][language]}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Box>
                </Box> */}

                {/* dynamic material ui cards for every object in array picture.Pictures based on property "Schooljaar" that should be the same as 'year' inside this map function */}
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: 1, flexWrap: "wrap" }}>
                    {view === "groups" &&
                        products?.groups?.map((group, index) => (
                            <Box
                                sx={{
                                    width: { xs: "100%", sm: "33.330%", md: "33.33%%", lg: "25%", xl: "25%" },
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <Card sx={{ width: 280, p: 1 }}>
                                    <CardMedia
                                        key={index}
                                        className="object_fit_contain"
                                        component="img"
                                        height="200"
                                        // id={
                                        //     group?.Foto_file === environment?.Picture?.Foto_file ? "scrollRef" : null
                                        // }
                                        src={
                                            group.subGroups[0].ArrImage
                                                ? "data:image/jpeg;base64," + group.subGroups[0].ArrImage.toString()
                                                : require("../images/no_image_available.jpg")
                                        }
                                        // image={`${item.img}?w=200&fit=crop&auto=format`}
                                        alt="event photo"
                                        onClick={() => groupSelected(group, environment.selectedColor)}
                                        onMouseEnter={() => {
                                            // mouse from pointer to hand
                                            document.body.style.cursor = "pointer";
                                        }}
                                        onMouseLeave={() => {
                                            // mouse from hand to pointer
                                            document.body.style.cursor = "default";
                                        }}
                                    />

                                    <CardContent>
                                        <Stack direction={"column"} spacing={0}>
                                            <Typography variant="body1" color="text.secondary">
                                                {group.Artikel_Groep_Naam}
                                            </Typography>
                                            {/* <Typography variant="caption" color="text.secondary">{group.Postnr} {group.Lokal}</Typography>
                                                        <Typography variant="caption" color="text.secondary">{
                                                            group.Datum_opname ? group.Datum_opname.split("T")[0].split("-").reverse().join("/") : ""
                                                        }</Typography>
                                                        <Typography variant="caption" color="text.secondary">{group.Foto_Info}</Typography>
                                                        <Typography variant="caption" color="text.secondary">{group.Foto_file}</Typography> */}
                                        </Stack>

                                        {/* <Button
                                                        variant="contained"
                                                        endIcon={<ArrowOutwardIcon />}
                                                        //on click call function zoomPhoto(photo)
                                                        onClick={() => zoomPhoto(group)}


                                                        disabled={!(group.Srt_Foto === 3 || group.Srt_Foto === 4)}
                                                    >
                                                        {dict["WS_Messages"]["Zoom"][language]}

                                                    </Button> */}
                                    </CardContent>
                                </Card>
                            </Box>
                        ))}

                    {view === "subgroups" && (
                        <Grid container>
                            <Typography align="left" color={"#023047"} fontWeight={600} variant="h6" pl={5} pb={1}>
                                {environment?.Group?.Artikel_Groep_Naam}
                            </Typography>
                        </Grid>
                    )}
                    {view === "subgroups" &&
                        environment?.Group?.subGroups?.map((subGroup, index) => (
                            <Grid item xs={12} sm={6} md={6} lg={4} xl={3} key={index}>
                                <Card sx={{ width: 280, p: 1 }}>
                                    <CardMedia
                                        key={index}
                                        className="object_fit_contain"
                                        component="img"
                                        height="200"
                                        // id={
                                        //     group?.Foto_file === environment?.Picture?.Foto_file ? "scrollRef" : null
                                        // }
                                        src={
                                            subGroup.ArrImage
                                                ? "data:image/jpeg;base64," + subGroup.ArrImage.toString()
                                                : require("../images/no_image_available.jpg")
                                        }
                                        // image={`${item.img}?w=200&fit=crop&auto=format`}
                                        alt="event photo"
                                        onClick={() => subgroupSelected(subGroup)}
                                        onMouseEnter={() => {
                                            // mouse from pointer to hand
                                            document.body.style.cursor = "pointer";
                                        }}
                                        onMouseLeave={() => {
                                            // mouse from hand to pointer
                                            document.body.style.cursor = "default";
                                        }}
                                    />

                                    <CardContent>
                                        <Stack direction={"column"} spacing={0}>
                                            <Typography variant="body1" color="text.secondary">
                                                {subGroup.Artikel_Subgroep_Naam}
                                            </Typography>
                                        </Stack>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}

                    {view === "products" && !loadingArticles && (
                        <Grid container sx={{ cursor: "default" }}>
                            {/* if exist environment.subgroups then show environment?.Group?.Artikel_Groep_Naam/environment?.Group?.Artikel_subGroep_Naam */}

                            {environment?.SubGroup ? (
                                <Typography align="left" color={"#023047"} fontWeight={600} variant="h6" pl={5} pb={1}>
                                    {environment?.Group?.Artikel_Groep_Naam}/{environment?.SubGroup?.Artikel_Subgroep_Naam}
                                </Typography>
                            ) : (
                                <Typography align="left" color={"#023047"} fontWeight={600} variant="h6" pl={5} pb={1}>
                                    {environment?.Group?.Artikel_Groep_Naam}
                                </Typography>
                            )}
                            {/* <Typography align="left" color={"#023047"} variant="body2" pl={5} pt={1}>
                                {dict["WS_Messages"]["Description how to order artikle"][language]}
                            </Typography> */}
                        </Grid>
                    )}

                    {view === "products" && environment?.Articles?.map((article, index) => <>{renderArticles(article, index)}</>)}

                    {(view === "most_ordered" || view === "promotions") &&
                        !updatingArticle &&
                        environment?.Articles?.map((article, index) => <>{renderArticles(article, index)}</>)}
                </Box>

                <Box sx={{ height: 90 }}></Box>

                {openZoomModal ? (
                    <ZoomModal
                        open={openZoomModal}
                        onClose={() => {
                            setOpenZoomModal(false);
                        }}
                        imageUrl={""}
                    />
                ) : null}

                <Snackbar
                    open={openMessage}
                    onClose={() => {
                        setOpenMessage(false);
                    }}
                    TransitionComponent={SlideTransition}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    key="Snackbar"
                >
                    <Alert
                        severity={severity}
                        sx={{ marginTop: 2 }}
                        onClose={() => {
                            setOpenMessage(false);
                        }}
                    >
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </Stack>

            <Dialog open={openYesNo} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Stack direction={"column"} spacing={2}>
                            <Typography variant="body1" color="#FB8500">
                                {dialogMessage1}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {dialogMessage2}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {dialogMessage3}
                            </Typography>
                            <Typography variant="body1" color="text.secondary">
                                {dialogMessage4}
                            </Typography>
                            {showSelectKlasFoto && (
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">{dict["WS_Messages"]["Select_class_photo"][language]}</FormLabel>
                                    <RadioGroup
                                        aria-label="selectKlasFoto"
                                        name="selectKlasFoto"
                                        value={selectedKlasFoto}
                                        onChange={handleSelectKlasFoto}
                                    >
                                        {/* for every foto in klasFotos create a FormControlLabel with label the foto_file */}
                                        {/* {klassFotos.map((foto, index) => (
                                            <FormControlLabel key={index} value={foto.Foto_file} control={<Radio />} label={foto.Foto_file} />
                                        ))} */}
                                        {klassFotos.map((foto, index) => (
                                            <FormControlLabel
                                                key={index}
                                                value={foto.Foto_file}
                                                control={<Radio />}
                                                label={
                                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                                        <img
                                                            src={
                                                                foto.ArrImage
                                                                    ? "data:image/jpeg;base64," + foto.ArrImage.toString()
                                                                    : require("../images/no_image_available.jpg")
                                                            }
                                                            alt="klass foto"
                                                            style={{ height: 50, marginRight: 8 }}
                                                        />
                                                        {foto.Foto_file}
                                                    </Box>
                                                }
                                            />
                                        ))}
                                    </RadioGroup>
                                </FormControl>
                            )}
                        </Stack>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>{dict["WS_Labels"]["Disagree"][language]}</Button>
                    <Button onClick={handleValidateAddOrderLine}>{dict["WS_Labels"]["Agree"][language]}</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openInfo} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Stack direction={"column"} spacing={2}>
                            <Typography variant="body1" color="text.secondary">
                                {dialogMessage1}
                            </Typography>
                            <Typography variant="body1" color="text.secondary">
                                {dialogMessage2}
                            </Typography>
                            <Typography variant="body1" color="text.secondary">
                                {dialogMessage3}
                            </Typography>
                            <Typography variant="body1" color="text.secondary">
                                {dialogMessage4}
                            </Typography>
                        </Stack>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>{dict["WS_Messages"]["Close"][language]}</Button>
                </DialogActions>
            </Dialog>

            {/* Dialog fullScreen Background */}
            <Dialog fullScreen open={openDialogBackground} onClose={handleClose} TransitionComponent={SlideTransition}>
                <AppBar sx={{ position: "relative", backgroundColor: "#177e97" }}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleCloseDialogBackground} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            {dict["WS_Labels"]["Backgrounds"][language]}
                        </Typography>
                        <Button autoFocus color="inherit" onClick={handleSaveSelectionDialogBackground}>
                            {dict["WS_Labels"]["Save"][language]}
                        </Button>
                    </Toolbar>
                </AppBar>
                {/* in a flex grid boxes with background images with background name at the bottom (array of objects is 'backgrounds') and is environment.selectedBackground is guid of zeros none of the boxes is selected otherwise the box with the same "Achtergrond_ID" is selected and the user can select other */}
                <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: 2, p: 2 }}>
                    {preselectedBG &&
                        backgrounds?.map((background, index) => (
                            <Box
                                key={index}
                                sx={{
                                    position: "relative",
                                    width: 150,
                                    height: 150,
                                    overflow: "hidden",
                                    border: "1px solid #ced4da",
                                    borderRadius: "4px",
                                    cursor: "pointer",
                                    boxShadow: preselectedBG.Achtergrond_ID === background.Achtergrond_ID ? "0px 0px 10px 5px #fb8500" : "none",
                                }}
                                onClick={() => {
                                    setPreselectedBG(background);
                                }}
                                onDoubleClick={() => {
                                    setPreselectedBG(background);
                                    handleSaveSelectionDialogBackground();
                                }}
                            >
                                <CardMedia
                                    key={index}
                                    // className="object_fit_contain"
                                    className="object_fit_cover" // Assuming this class sets `object-fit: cover;`
                                    component="img"
                                    maxWidth="100%"
                                    height="100%"
                                    style={{ borderRadius: "4px" }}
                                    src={
                                        // if environment.Picture.Srt_Foto is 1 or 3 then show the image in portrait mode, else in landscape mode
                                        environment?.Picture?.Srt_Foto === 1 || environment?.Picture?.Srt_Foto === 3
                                            ? background.Image_Portrait
                                                ? "data:image/jpeg;base64," + background.Image_Portrait.toString()
                                                : require("../images/no_image_available.jpg")
                                            : environment?.Picture?.Srt_Foto === 2 || background.Image_Landscape
                                                ? "data:image/jpeg;base64," + background.Image_Landscape.toString()
                                                : require("../images/no_image_available.jpg")

                                        // background.Image_Portrait
                                        //     ? "data:image/jpeg;base64," + background.Image_Portrait.toString()
                                        //     : require("../images/no_image_available.jpg")
                                    }
                                    alt="event photo"
                                />
                                <CardContent>
                                    {/* <Typography variant="body2" color="text.secondary">{background.Achtergrond_Label}</Typography> */}
                                </CardContent>
                            </Box>
                        ))}
                </Box>
            </Dialog>

            <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={openBackdropLoader}>
                <Typography variant="h6" sx={{ marginRight: 2 }}>
                    {dict["WS_Messages"]["Loading..."][language]}
                </Typography>
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
};

function SlideTransition(props: SlideProps) {
    return <Slide {...props} direction="up" />;
}

export default WS_Order;