import axios from "axios";

export default axios.create({


  baseURL: "https://dmz.photobizz.be/Webshop_API/api",
  // baseURL: "https://dmz.photobizz.be/Webshop_API_Test/api",



  // baseURL: "https://dmz.photobizz.be/Smool_API",  

  // baseURL: "https://dmz.photobizz.be/Smool_API_Test",

  //baseURL: 'http://localhost:3500'
});
