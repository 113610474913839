import { createContext, useState, useEffect } from "react";

const AccountContext = createContext({});

export const AccountProvider = ({ children }) => {

    const [account, setAccount] = useState(sessionStorage.getItem("session_account") === null ? {} : JSON.parse(sessionStorage.getItem("session_account")));

    useEffect(() => {
        sessionStorage.setItem("session_account", JSON.stringify(account));
    }, [account]);

    return (
        <AccountContext.Provider value={{ account, setAccount }}>
            {children}
        </AccountContext.Provider>
    )
}

export default AccountContext;