
import useLanguageContext from "../hooks/useLanguageContext";

import axios from "../api/axios";
import React, { useState, useEffect } from "react";
import { NavLink, } from "react-router-dom";
import {
    Box, Typography, Stack, Grid, Button, Card,
    CardMedia, CardContent, TextField, InputLabel,
    FormControl, FormControlLabel, RadioGroup, Radio
} from "@mui/material";


import { DatePicker } from '@mui/x-date-pickers/DatePicker';
//import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!

import Select from '@mui/material/Select';
import MenuItem from "@mui/material/MenuItem";

import WebsiteDict from "../data/WebsiteDict";

import dayjs, { Dayjs } from 'dayjs';

import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Slide, { SlideProps } from "@mui/material/Slide";

const Photoshoot = () => {

    const { language, setLanguage } = useLanguageContext();

    const [dict, setDict] = useState(WebsiteDict);
    const [events, setEvents] = useState([]);

    useEffect(() => {
        setDict(WebsiteDict);
    }, [WebsiteDict]);



    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [email, setEmail] = useState("");
    const [street, setStreet] = useState("");
    const [houseNumber, setHouseNumber] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [city, setCity] = useState("");
    const [phone, setPhone] = useState("");
    const [numberOfEmployees, setNumberOfEmployees] = useState("");
    const [VAT, setVAT] = useState("");
    const [photoshootDate, setPhotoshootDate] = useState(dayjs());
    const [period, setPeriod] = useState("");
    const [message, setMessage] = useState("");
    const [default_language, setDefaultLanguage] = useState("nl");

    const [openMessage, setOpenMessage] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [severity, setSeverity] = useState("success");




    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    const handleChangeFirstName = (event) => {
        setFirstName(event.target.value);
    };

    const handleChangeLastName = (event) => {
        setLastName(event.target.value);
    };

    const handleChangeEmail = (event) => {
        setEmail(event.target.value);
    };

    const handleChangePhone = (event) => {
        setPhone(event.target.value);
    };

    const handleChangeCompanyName = (event) => {
        setCompanyName(event.target.value);
    };


    const handleChangeStreet = (event) => {
        setStreet(event.target.value);
    };

    const handleChangeHouseNumber = (event) => {
        setHouseNumber(event.target.value);
    };

    const handleChangePostalCode = (event) => {
        setPostalCode(event.target.value);
    };

    const handleChangeCity = (event) => {
        setCity(event.target.value);
    };

    const handleChangeNumberOfEmployees = (event) => {

        //regex only allows positive integers

        const regex = /^[0-9\b]+$/;
        if (event.target.value === "" || regex.test(event.target.value)) {
            setNumberOfEmployees(event.target.value);
        } else {
            setNumberOfEmployees("");
        }

    };

    const handleChangeVAT = (event) => {
        setVAT(event.target.value);
    };

    const handleChangePhotoshootDate = (event) => {
        setPhotoshootDate(event.target.value);
    };

    const handleChangePeriod = event => {
        console.log(event.target.value);
        setPeriod(event.target.value);
    };

    const handleLanguageChange = (event) => {
        setDefaultLanguage(event.target.value);
    };


    const handleSendPhotoshootRequest = () => {

        console.log("handleSendContactMessage");
        let msg = "";
        if (firstName === "") {
            msg = msg + dict["Messages"]["First name is required."][language];
        }
        if (lastName === "") {
            msg = msg + dict["Messages"]["Last name is required."][language];
        }
        if (companyName === "") {
            msg = msg + dict["Messages"]["Company name is required."][language];
        }

        if (email === "") {
            msg = msg + dict["Messages"]["Email is required."][language];
        }

        if (street === "") {
            msg = msg + dict["Messages"]["Street is required."][language];
        }

        if (houseNumber === "") {
            msg = msg + dict["Messages"]["House number is required."][language];
        }

        if (postalCode === "") {
            msg = msg + dict["Messages"]["Postal code is required."][language];
        }

        if (city === "") {
            msg = msg + dict["Messages"]["City is required."][language];
        }

        if (VAT === "") {
            msg = msg + dict["Messages"]["VAT is required."][language];
        }

        if (phone === "") {
            msg = msg + dict["Messages"]["Phone is required."][language];
        }

        if (numberOfEmployees === "") {
            msg = msg + dict["Messages"]["Number of employees is required."][language];
        }

        if (period === "") {
            msg = msg + dict["Messages"]["Period is required."][language];
        }

        if (photoshootDate === "") {
            msg = msg + dict["Messages"]["Photoshoot date is required."][language];
        }

        // if (message === "") {
        //     msg = msg + dict["Messages"]["Message is required."][language];
        // }

        if (postalCode !== "") {
            const re = /^[0-9\b]+$/;
            if (!re.test(postalCode)) {
                msg = msg + dict["Messages"]["Postal code is not valid."][language];
            }
        }

        if (phone !== "") {
            const re = /^[0-9\b]+$/;
            if (!re.test(phone)) {
                msg = msg + dict["Messages"]["Phone is not valid."][language];
            }
        }

        if (numberOfEmployees !== "") {
            const re = /^[0-9\b]+$/;
            if (!re.test(numberOfEmployees)) {
                msg = msg + dict["Messages"]["Number of employees is not valid."][language];
            }
        }

        //validate email
        const re = /\S+@\S+\.\S+/;
        if (!re.test(email)) {
            setSnackbarMessage("Email is not valid.");
            setSeverity("error");
            setOpenMessage(true);
            return;
        }




        if (msg !== "") {
            setSnackbarMessage(msg);
            setSeverity("error");
            setOpenMessage(true);
            return;
        }



        //validate message length
        // if (message.length > 1000) {
        //     setSnackbarMessage(dict["Messages"]["Message is too long."][language]);
        //     setSeverity("error");
        //     setOpenMessage(true);
        //     return;
        // }

        let languageCapitals = language.toUpperCase();
        let periodCapitals = period.toUpperCase();
        let formedPhotoshootDate = dayjs(photoshootDate).format("YYYY-MM-DD");



        let IntegerNumberOfEmployees = 0
        //check if number of employees is a number
        if (numberOfEmployees !== "") {
            IntegerNumberOfEmployees = parseInt(numberOfEmployees);
        }


        const fetchMessage = async () => {

            try {

                let body = {
                    "ID": null,
                    "Name": lastName,
                    "FirstName": firstName,
                    "Email": email,
                    "Phone": phone,
                    "CompanyName": companyName,
                    "Language_Code": languageCapitals,
                    "Message": message,
                    "Timestamp": null
                };

                const response = await axios.post("/api/Company/PostContactRequest", body);

                if (response.status === 200) {
                    setSnackbarMessage(dict["Messages"]["Message sent successfully."][language]);
                    setSeverity("success");
                    setOpenMessage(true);
                    setFirstName("");
                    setLastName("");
                    setEmail("");
                    setPhone("");
                    setCompanyName("");
                    setMessage("");
                }
                else {
                    setSnackbarMessage(dict["Messages"]["Message could not be sent."][language]);
                    setSeverity("error");
                    setOpenMessage(true);
                }
            } catch (error) {
                console.log(error);
                setSnackbarMessage(dict["Messages"]["Message could not be sent."][language]);
                setSeverity("error");
                setOpenMessage(true);

            }

        };


        //fetchMessage();

        const getSession = async () => {
            try {
                const response = await axios.get("/api/Account/GetSession");
                if (response.status === 200) {
                    return response.data;
                }
                else {
                    setSnackbarMessage(dict["Messages"]["Session could not be retrieved."][language]);
                    setSeverity("error");
                    setOpenMessage(true);
                    return null;
                }
            } catch (error) {
                console.log(error);
                setSnackbarMessage(dict["Messages"]["Session could not be retrieved."][language]);
                setSeverity("error");
                setOpenMessage(true);
                return null;
            }
        };

        const getCompany = async (session_ID) => {
            try {
                let body = {
                    "Session_ID": session_ID,
                    "Name": companyName,
                    "Street": street,
                    "HouseNr": houseNumber,
                    "Box": null,
                    "Street2": null,
                    "PostalCode": postalCode,
                    "City": city,
                    "Country": "BE",
                    "Phone": phone,
                    "Email": email,
                    "VAT_Number": VAT,
                    "Employee_Number": IntegerNumberOfEmployees,
                    "Contact_Name": lastName,
                    "Contact_FirstName": firstName,
                    "Language_Code": languageCapitals
                }
                const response = await axios.post("/api/Company/PostCompany", body);

                if (response.status === 200) {
                    return response.data;
                }
                else {
                    setSnackbarMessage(dict["Messages"]["Company could not be retrieved."][language]);
                    setSeverity("error");
                    setOpenMessage(true);
                    return null;
                }
            } catch (error) {
                console.log(error);
                setSnackbarMessage(dict["Messages"]["Company could not be retrieved."][language]);
                setSeverity("error");
                setOpenMessage(true);
                return null;
            }
        };

        const requestPhotoshoot = async (company) => {
            try {

                let body = {
                    "Session": company.Session,
                    "Smool_Photoshoot": {
                        "Company_PK": company.Smool_Company.Company_PK,
                        "Contract_Number": null,
                        "Photoshoot_Seq": null,
                        "Ref_Shoot": null,
                        "Create_Date": null,
                        "Account_ID": company.Session.Account_ID,
                        "Account_Name": null,
                        "Account_FirstName": null,
                        "Account_Email": null,
                        "Update_Date": null,
                        "Site_PK": null,
                        "Site_Name": null,
                        "Department_ID": null,
                        "Department_Name": null,
                        "Subdepartment_ID": null,
                        "Subdepartment_Name": null,
                        "Tag_ID": null,
                        "TagName": null,
                        "Customfield_Attribute_Key": null,
                        "Customfield_AttributeValue": null,
                        "Tag_Exclude_ID": null,
                        "TagExcludeName": null,
                        "Photoshoot_Date": formedPhotoshootDate,
                        "Photoshoot_Duration": periodCapitals,
                        "Photoshoot_Location": company.Smool_Company.Company_PK,
                        "Employees_Quantity": IntegerNumberOfEmployees,
                        "Remark": null,
                        "Validated": false
                    },
                    "Language_Code": languageCapitals
                }

                const response = await axios.post("/api/Company/PostPhotoshoot", body);

                if (response.status === 200) {
                    return true;
                }
                else {
                    setSnackbarMessage(dict["Messages"]["Photoshoot request could not be sent."][language]);
                    setSeverity("error");
                    setOpenMessage(true);
                    return false;
                }
            } catch (error) {
                console.log(error);
                setSnackbarMessage(dict["Messages"]["Photoshoot request could not be sent."][language]);
                setSeverity("error");
                setOpenMessage(true);
                return false;
            }
        };


        const exec_all = async () => {

            var session_ID = null;
            var company = null;
            var requestSuccess = false;
            try {
                session_ID = await getSession();
                if (session_ID === null) {
                    return;
                }

                company = await getCompany(session_ID);
                if (company === null) {
                    return;
                }

                requestSuccess = await requestPhotoshoot(company);
                if (requestSuccess === false) {
                    return;
                } else {
                    setSnackbarMessage(dict["Messages"]["Photoshoot request sent successfully."][language]);
                    setSeverity("success");
                    setOpenMessage(true);

                    setFirstName("");
                    setLastName("");
                    setEmail("");
                    setPhone("");
                    setCompanyName("");
                    setMessage("");
                    setCity("");
                    setStreet("");
                    setHouseNumber("");
                    setPostalCode("");
                    setVAT("");
                    setNumberOfEmployees("");
                    setPeriod("");
                    setPhotoshootDate("");
                }
            } catch (error) {
                console.log(error);
                setSnackbarMessage(dict["Messages"]["Photoshoot request could not be sent."][language]);
                setSeverity("error");
                setOpenMessage(true);

            }
        }

        exec_all();







    };




    const handleCalendarChange = async (fetchInfo) => {

        //console.log("handleCalendarChange");

        try {

            let date_from = new Date();
            let date_to = new Date();
            var list = [];

            const options = { day: '2-digit', month: '2-digit', year: 'numeric' };

            if (fetchInfo) {
                date_from = new Date(fetchInfo.start).toLocaleDateString('fr-BE', options);
                date_to = new Date(fetchInfo.end).toLocaleDateString('fr-BE', options);
            }

            const parts_from = date_from.split("/");
            const formattedDate_from = `${parts_from[2]}-${parts_from[1].padStart(2, "0")}-${parts_from[0].padStart(2, "0")}`;

            const parts_to = date_to.split("/");
            const formattedDate_to = `${parts_to[2]}-${parts_to[1].padStart(2, "0")}-${parts_to[0].padStart(2, "0")}`;

            const req = "/" + formattedDate_from + "/" + formattedDate_to;
            let res = await axios.get("/api/Company/GetPhotoshootCalendar_Anonymous" + req);

            if (res.data.length > 0) {

                var targetList = [];

                let init_list = await res.data;

                for (let i = 0; i < init_list.length; i++) {
                    if (new Date(init_list[i].datum.substring(0, 10)) >= new Date()) {

                        let item = {};
                        // if (init_list[i].is_available === false) {

                        //     item = { title: 'Not available', date: init_list[i].datum.substring(0, 10), color: "#f94e4e", className: "fullDayEvent" };
                        //     targetList.push(item);
                        //     continue;
                        // }

                        if (!init_list[i].AM_available) {
                            item = { title: 'AM booked', date: init_list[i].datum.substring(0, 10), color: "#f94e4e", className: "" };
                            targetList.push(item);
                        } else {
                            item = { title: 'AM available', date: init_list[i].datum.substring(0, 10), color: "#1fbf3e", className: "" };
                            targetList.push(item);
                        }

                        if (!init_list[i].PM_available) {
                            item = { title: 'PM booked', date: init_list[i].datum.substring(0, 10), color: "#f94e4e", className: "" };
                            targetList.push(item);
                        } else {
                            item = { title: 'PM available', date: init_list[i].datum.substring(0, 10), color: "#1fbf3e", className: "" };
                            targetList.push(item);
                        }
                    }


                }
                setEvents(targetList);
                //successCallback(targetList);
            } else {
                //failureCallback("list is empty");
                setEvents([]);
            }

            console.log(res);

        } catch (error) {
            console.log(error);
        }
    }




    //=========================================================================================================
    //=========================================================================================================
    //=========================================================================================================
    //=========================================================================================================
    //=========================================================================================================
    //=========================================================================================================
    //=========================================================================================================


    return (
        <>
            <Stack spacing={8} m={3}>

                <Grid container spacing={2}>

                    <Grid item xs={12} md={5}>
                        <Box pb={2} pl={3}>
                            <Typography align="left" fontWeight={600} mt={1} variant="h5">
                                {dict["Texts"]["photoshoot_block1_title1"][language]}
                            </Typography>
                        </Box>

                        <Box pb={2} pl={3}>
                            <Typography variant="body1" align="left">
                                {dict["Texts"]["photoshoot_block1_text1"][language]}
                            </Typography>
                        </Box>

                    </Grid>
                    <Grid item xs={12} md={12} sx={{ display: { xs: "block", sm: 'block', md: 'none' } }}>
                        <Box
                            component="img"
                            sx={{ width: 350 }}
                            alt="Photobizz image"
                            src={require('../images/photobook_transparent_1000_670.png')}
                            m={1}
                        />

                    </Grid>
                    <Grid item xs={12} md={7} sx={{ display: { xs: "none", sm: 'none', md: 'block' } }}>
                        <Box
                            component="img"
                            sx={{ width: 450 }}
                            alt="Photobizz image"
                            src={require('../images/photobook_transparent_1000_670.png')}
                            m={1}
                        />

                    </Grid>
                </Grid>




                <Grid container spacing={2}>

                    <Grid item xs={12} md={12}  >

                        <Box maxWidth={1000} margin={"auto"} borderRadius={3} >
                            <Card>
                                <Grid container spacing={4} p={3}   >

                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            value={firstName}
                                            label={dict["Labels"]["first_name"][language]}
                                            onChange={handleChangeFirstName}
                                            size="small"
                                            id="outlined-name"
                                            fullWidth={true}
                                            variant="standard"
                                            margin="normal"
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            value={lastName}
                                            label={dict["Labels"]["last_name"][language]}
                                            onChange={handleChangeLastName}
                                            size="small"
                                            id="outlined-name"
                                            fullWidth={true}
                                            variant="standard"
                                            margin="normal"
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            value={companyName}
                                            label={dict["Labels"]["company_name"][language]}
                                            onChange={handleChangeCompanyName}
                                            size="small"
                                            id="outlined-name"
                                            fullWidth={true}
                                            variant="standard"
                                            margin="normal"
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            value={email}
                                            label={dict["Labels"]["email"][language]}
                                            onChange={handleChangeEmail}
                                            size="small"
                                            id="outlined-name"
                                            fullWidth={true}
                                            variant="standard"
                                            margin="normal"
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            value={street}
                                            label={dict["Labels"]["street"][language]}
                                            onChange={handleChangeStreet}
                                            size="small"
                                            id="outlined-name"
                                            fullWidth={true}
                                            variant="standard"
                                            margin="normal"
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            value={houseNumber}
                                            label={dict["Labels"]["house_number"][language]}
                                            onChange={handleChangeHouseNumber}
                                            size="small"
                                            id="outlined-name"
                                            fullWidth={true}
                                            variant="standard"
                                            margin="normal"
                                        />
                                    </Grid>


                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            value={postalCode}
                                            label={dict["Labels"]["postal_code"][language]}
                                            onChange={handleChangePostalCode}
                                            size="small"
                                            id="outlined-name"
                                            fullWidth={true}
                                            variant="standard"
                                            margin="normal"
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            value={city}
                                            label={dict["Labels"]["city"][language]}
                                            onChange={handleChangeCity}
                                            size="small"
                                            id="outlined-name"
                                            fullWidth={true}
                                            variant="standard"
                                            margin="normal"
                                        />
                                    </Grid>


                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            value={VAT}
                                            label={dict["Labels"]["btw"][language]}
                                            onChange={handleChangeVAT}
                                            size="small"
                                            id="outlined-name"
                                            fullWidth={true}
                                            variant="standard"
                                            margin="normal"
                                        />
                                    </Grid>


                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            value={phone}
                                            label={dict["Labels"]["phone"][language]}
                                            onChange={handleChangePhone}
                                            size="small"
                                            id="outlined-name"
                                            fullWidth={true}
                                            variant="standard"
                                            margin="normal"
                                        />
                                    </Grid>

                                    {/* <Grid item xs={12} md={6}>

                                    </Grid> */}

                                    <Grid item xs={12} md={5}>

                                        <Stack spacing={5} >

                                            <FormControl sx={{ marginTop: 3, width: "100%" }} size="small" >
                                                <InputLabel id="demo-simple-select-label">{dict["Labels"]["default_language"][language]}</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={default_language}
                                                    label={dict["Labels"]["default_language"][language]}
                                                    onChange={handleLanguageChange}
                                                    size="small"
                                                    variant="standard"
                                                    align="left"
                                                    fullWidth={true}
                                                    sx={{ paddingTop: '4px', width: "100%", fontSize: '13px' }}
                                                >
                                                    <MenuItem value={"en"}>{dict["Labels"]["english_translation"][language]}</MenuItem>
                                                    <MenuItem value={"nl"}>{dict["Labels"]["dutch_translation"][language]}</MenuItem>
                                                    <MenuItem value={"fr"}>{dict["Labels"]["franch_translation"][language]}</MenuItem>

                                                </Select>
                                            </FormControl>



                                            <TextField
                                                value={numberOfEmployees}
                                                label={dict["Labels"]["number_employees"][language]}
                                                onChange={handleChangeNumberOfEmployees}
                                                size="small"
                                                id="outlined-name"
                                                fullWidth={true}
                                                variant="standard"
                                                margin="normal"
                                                InputProps={{
                                                    inputProps: { min: 0 }
                                                }}
                                                type="text"
                                            />




                                            <FormControl style={{ minWidth: 300 }}>

                                                {/* <FormLabel id="demo-controlled-radio-buttons-group">Period</FormLabel> */}
                                                <RadioGroup
                                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                                    name="controlled-radio-buttons-group"
                                                    value={period}
                                                    onChange={handleChangePeriod}
                                                >

                                                    {
                                                        numberOfEmployees > 25
                                                            ? (
                                                                <FormControlLabel value="full" control={<Radio />} label={<Typography variant="body2">{dict["Labels"]["full_day"][language]}</Typography>} />
                                                            )
                                                            : (
                                                                <>
                                                                    <FormControlLabel value="am" control={<Radio />} label={<Typography variant="body2">{dict["Labels"]["am_max50"][language]}</Typography>} />
                                                                    <FormControlLabel value="pm" control={<Radio />} label={<Typography variant="body2">{dict["Labels"]["pm_max50"][language]}</Typography>} />
                                                                </>
                                                            )
                                                    }
                                                </RadioGroup>
                                            </FormControl>

                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    // views={['day', 'month', 'year']}
                                                    label={dict["Labels"]["date"][language]}
                                                    value={photoshootDate}
                                                    onChange={(newValue) => {
                                                        setPhotoshootDate(newValue);
                                                    }}
                                                    disablePast={true}
                                                    inputFormat="DD/MM/YYYY"
                                                    // shouldDisableDate={???}
                                                    renderInput={(params) =>
                                                        <TextField
                                                            fullWidth={true}
                                                            {...params}
                                                            sx={{ minWidth: '300px' }}
                                                            size="small"
                                                            helperText={null}
                                                            variant="standard"
                                                        />}
                                                />
                                            </LocalizationProvider>

                                        </Stack>
                                    </Grid>

                                    <Grid item xs={12} md={7}>
                                        <FullCalendar
                                            height='auto'
                                            // width="auto"
                                            plugins={[dayGridPlugin]}
                                            initialView="dayGridMonth"
                                            firstDay="1"
                                            weekends={true}
                                            eventDisplay="block"
                                            displayEventTime="false"
                                            timeFormat='H(:mm)'
                                            locale={language}
                                            headerToolbar={{
                                                left: 'title',
                                                center: '',
                                                right: 'prev,next'
                                            }}
                                            events={events}
                                            datesSet={(fetchInfo) => handleCalendarChange(fetchInfo)}
                                            showNonCurrentDates={false}
                                        />
                                    </Grid>



                                </Grid>
                            </Card>
                        </Box>
                        <Box pt={3}>
                            <Button variant="contained" color="secondary" onClick={handleSendPhotoshootRequest} sx={{ mt: 2 }}>
                                {dict["Labels"]["send"][language]}
                            </Button>
                        </Box>
                    </Grid>

                </Grid>

                <Snackbar
                    open={openMessage}
                    onClose={() => { setOpenMessage(false); }}
                    TransitionComponent={SlideTransition}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center", }}
                    key="Snackbar"
                >
                    <Alert
                        severity={severity}
                        sx={{ marginTop: 2 }}
                        onClose={() => {
                            setOpenMessage(false);
                        }}
                    >
                        {snackbarMessage}
                    </Alert>
                </Snackbar>



            </Stack >
        </>
    );
};

function SlideTransition(props: SlideProps) {
    return <Slide {...props} direction="up" />;
}

export default Photoshoot;
