

import LayoutWebsite from "./pages/LayoutWebsite";
import LayoutWebshop from "./components/Layout";
import PublicHome from "./pages/Home";
import PublicAbout from "./pages/About";
import PublicVacatures from "./pages/Vacatures";
import PublicFaq from "./pages/FAQ";
import PublicContact from "./pages/Contact";
import PublicDemo from "./pages/Demo";
import PublicPhotoshoot from "./pages/Photoshoot";
import PublicLogin from "./pages/Login";
import CreateAccount from "./pages/CreateAccount";
import ForgotPassword from "./pages/ForgotPassword";
import WebshopHome from "./pages/WS_Home";
import WebshopOrder from "./pages/WS_Order";
import WebshopEvents from "./pages/WS_Events";
import WebshopFavorites from "./pages/WS_Favorites";
import WebshopShare from "./pages/WS_Share";
import WebshopCart from "./pages/WS_Cart";
import WebshopHistory from "./pages/WS_History";
import WebshopMyDetails from "./pages/WS_MyDetails";
import WebshopContact from "./pages/WS_Contact";
import WebshopHelp from "./pages/WS_Help";
import Unsubscribe from "./pages/Unsubscribe";
import NoPage from "./pages/NoPage";

import { Routes, Route, Link, Router } from "react-router-dom";


function App() {

  return (
    <Routes>


      {/* <Route path="/website" element={<LayoutWebsite />}> */}
      <Route path="/" element={<LayoutWebsite />}>

        <Route path="" element={<PublicHome />} />
        <Route path="home" element={<PublicHome />} />
        <Route path="home/nl/schoolfotografie" element={<PublicHome />} />
        <Route path="home/nl/schoolfotografie/68/klasfotos" element={<PublicHome />} />

        <Route path="about" element={<PublicAbout />} />
        <Route path="vacatures" element={<PublicVacatures />} />
        <Route path="faq" element={<PublicFaq />} />
        <Route path="contact" element={<PublicContact />} />
        <Route path="demo" element={<PublicDemo />} />
        <Route path="photoshoot" element={<PublicPhotoshoot />} />

        <Route path="home/shop" element={<PublicLogin />} />
        <Route path="/shop" element={<PublicLogin />} />

        <Route path="login" element={<PublicLogin />} />
        <Route path="createaccount" element={<CreateAccount />} />
        <Route path="forgotpassword" element={<ForgotPassword />} />

        <Route path="unsubscribe" element={<Unsubscribe />} />

        <Route path="*" element={<NoPage />} />

      </Route>

      <Route path="/webshop" element={<LayoutWebshop />}>

        <Route path="home" element={<WebshopHome />} />
        <Route path="order" element={<WebshopOrder />} />
        <Route path="events" element={<WebshopEvents />} />
        <Route path="favorites" element={<WebshopFavorites />} />
        <Route path="share" element={<WebshopShare />} />
        <Route path="cart" element={<WebshopCart />} />
        <Route path="history" element={<WebshopHistory />} />
        <Route path="mydetails" element={<WebshopMyDetails />} />
        <Route path="contact" element={<WebshopContact />} />
        <Route path="help" element={<WebshopHelp />} />

        <Route path="*" element={<NoPage />} />

      </Route>

    </Routes>

  );
}


export default App;
