import axios from "../api/axiosPhotobizz";
import axiosRoot from "../api/axiosWithoutBaseURL.js";

import useLanguageContext from "../hooks/useLanguageContext";
import useAccount from "../hooks/useAccount";

import React, { useState, useEffect } from "react";
// import { NavLink, } from "react-router-dom";
import { Box, Typography, Stack, Grid, Button, Card, TextField } from "@mui/material";

import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Slide, { SlideProps } from "@mui/material/Slide";

import WebsiteDict from "../data/WebsiteDict";
import { NavLink, useNavigate } from "react-router-dom";

import CircularProgress from "@mui/material/CircularProgress";
import { InputAdornment, IconButton } from "@mui/material/";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import Modal from "@mui/material/Modal";

const Login = () => {
    const { language, setLanguage } = useLanguageContext();
    const { account, setAccount } = useAccount();

    const [dict, setDict] = useState(WebsiteDict);

    const navigate = useNavigate();

    useEffect(() => {
        setDict(WebsiteDict);
    }, [WebsiteDict]);

    const [password, setPassword] = useState("");
    const [showPass, setShowPass] = useState(false);
    const [email, setEmail] = useState("");

    const [sessionID, setSessionID] = useState("");
    const [bizzSession, setBizzSession] = useState({ Session: { Session_ID: "", Account_ID: "" }, Webshop_Account: {} });

    // const [firstName, setFirstName] = useState("");
    // const [lastName, setLastName] = useState("");
    // const [phone, setPhone] = useState("");
    // const [companyName, setCompanyName] = useState("");
    // const [message, setMessage] = useState("");

    const [openMessage, setOpenMessage] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [severity, setSeverity] = useState("success");
    const [loading, setLoading] = useState(false);

    const [termsConditions, setTermsConditions] = useState(false);
    const openTermsConditions = () => setTermsConditions(true);
    const closeTermsConditions = () => setTermsConditions(false);

    const [privacyPolicy, setPrivacyPolicy] = useState(false);
    const openPrivacyPolicy = () => setPrivacyPolicy(true);
    const closePrivacyPolicy = () => setPrivacyPolicy(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);

        const loginAccount = urlParams.get("Login");
        const loginCode = urlParams.get("Code");
        const order = urlParams.get("order");
        const dachCode = urlParams.get("DashboardCode");
        console.log(loginAccount, loginCode, order);

        if (loginAccount !== null && loginCode !== null) {
            loginFromEmail(loginAccount, loginCode, order);
        }
        if (loginAccount !== null && dachCode !== null) {
            loginFromDashboard(loginAccount, dachCode, order);
        }
    }, []);

    async function loginFromEmail(_loginAccount, _loginCode, _order) {
        // var Client_IP = "::1";
        // try {
        //     Client_IP = await fetchIP();
        //     console.log(Client_IP);
        // } catch (error) {
        //     console.log(error);
        // }

        // var t_setBizzSessionID = "";
        // try {
        //     t_setBizzSessionID = await fetchSession();
        //     setSessionID(t_setBizzSessionID);
        // } catch (error) {
        //     console.log(error);
        //     setSnackbarMessage("Error: " + error);
        //     setSeverity("error");
        //     setOpenMessage(true);
        //     setLoading(false);
        //     return;
        // }

        async function fetchLoginFromEmail(_loginAccount, _loginCode) {
            try {
                // Account/GetAccount_from_Email/{Login}/{Code}

                const response = await axios.get("/Account/GetAccount_from_Email/" + _loginAccount + "/" + _loginCode);
                if (response.status === 200) {
                    // setBizzSession(response.data);

                    var response_with_password = response.data;
                    response_with_password.Webshop_Account.pw = "";

                    return response_with_password;
                    //sessionStorage.setItem("session_account", JSON.stringify(response_with_password));

                    // setSnackbarMessage(dict["WS_Messages"]["Succesfully logged in."][language]);
                    // setSeverity("success");
                    // setOpenMessage(true);
                    navigate("/webshop/home");
                } else {
                    return {};
                }
            } catch (error) {
                return {};
            }
        }

        var acc = await fetchLoginFromEmail(_loginAccount, _loginCode);
        if (acc !== null && acc !== undefined && acc.Webshop_Account !== null && acc.Webshop_Account !== undefined) {
            setAccount(acc);
            if (_order !== null && _order !== undefined) {
                navigate("/webshop/history?orderNr=" + _order);
            } else {
                navigate("/webshop/home");
            }
        }
    }

    async function loginFromDashboard(_loginAccount, _loginCode, _order) {
        async function fetchLoginFromDashboard(_loginAccount, _loginCode) {
            try {
                // Account/GetAccount_from_Email/{Login}/{Code}

                const response = await axios.get("/Account/GetAccount_from_Dashboard/" + _loginAccount + "/" + _loginCode);
                if (response.status === 200) {
                    // setBizzSession(response.data);

                    var response_with_password = response.data;
                    response_with_password.Webshop_Account.pw = "";

                    return response_with_password;
                    //sessionStorage.setItem("session_account", JSON.stringify(response_with_password));

                    // setSnackbarMessage(dict["WS_Messages"]["Succesfully logged in."][language]);
                    // setSeverity("success");
                    // setOpenMessage(true);
                    navigate("/webshop/home");
                } else {
                    setSnackbarMessage(dict["WS_Messages"]["Login from dashboard failed."][language]);
                    setSeverity("error");
                    setOpenMessage(true);
                    return {};
                }
            } catch (error) {
                console.log(error);
                setSnackbarMessage(dict["WS_Messages"]["Login from dashboard failed."][language]);
                setSeverity("error");
                setOpenMessage(true);
                return {};
            }
        }

        var acc = await fetchLoginFromDashboard(_loginAccount, _loginCode);
        if (acc !== null && acc !== undefined && acc.Webshop_Account !== null && acc.Webshop_Account !== undefined) {
            setAccount(acc);
            if (_order !== null && _order !== undefined) {
                navigate("/webshop/history?orderNr=" + _order);
            } else {
                navigate("/webshop/home");
            }
        }
    }

    // const handleChangeFirstName = (event) => {
    //     setFirstName(event.target.value);
    // };

    // const handleChangeLastName = (event) => {
    //     setLastName(event.target.value);
    // };

    const handleChangeEmail = (event) => {
        setEmail(event.target.value);
    };

    // const handleChangePhone = (event) => {
    //     setPhone(event.target.value);
    // };

    // const handleChangeCompanyName = (event) => {
    //     setCompanyName(event.target.value);
    // };

    // const handleChangeMessage = (event) => {
    //     setMessage(event.target.value);
    // };

    const handleChangePassword = (event) => {
        setPassword(event.target.value);
    };

    // =================== Fetch Session ===================
    async function fetchSession() {
        try {
            // await axios.get("/Account/GetSession").then((response) => {
            //     setSessionID(response.data);
            //     return response.data;
            // });
            const res = await axios.get("/Account/GetSession");
            setSessionID(res.data);
            return res.data;
        } catch (error) {
            throw error;
        }
    }

    // =================== Fetch IP ===================
    async function fetchIP() {
        try {
            const res = await axiosRoot.get("api.ipify.org?format=json");
            return res.data.ip;
        } catch (error) {
            throw error;
        }
    }

    const sendLoginRequest = async (e) => {
        e.preventDefault();

        let msg = "";

        if (email === "") {
            msg = msg + dict["WS_Messages"]["Email is required."][language];
        }

        if (password === "") {
            msg = msg + dict["WS_Messages"]["Password is required."][language];
        }

        if (msg !== "") {
            setSnackbarMessage(msg);
            setSeverity("error");
            setOpenMessage(true);
            setLoading(false);
            return;
        }

        //validate email
        const re = /\S+@\S+\.\S+/;
        if (!re.test(email)) {
            setSnackbarMessage(dict["WS_Messages"]["Email is not valid."][language]);
            setSeverity("error");
            setOpenMessage(true);
            setLoading(false);
            return;
        }

        setLoading(true);

        var Client_IP = "::1";
        try {
            Client_IP = await fetchIP();
            console.log(Client_IP);
        } catch (error) {
            console.log(error);
        }

        var t_setBizzSessionID = "";
        try {
            t_setBizzSessionID = await fetchSession();
            setSessionID(t_setBizzSessionID);
        } catch (error) {
            console.log(error);
            setSnackbarMessage("Error: " + error);
            setSeverity("error");
            setOpenMessage(true);
            setLoading(false);
            return;
        }

        // const fetchIP = async () => {
        //     try {
        //         const res = await axios.get("https://api.ipify.org?format=json");
        //         Client_IP = res.data.ip;
        //     } catch (error) {
        //         console.log(error);
        //     }
        // }

        // const fetchSession = async () => {
        //     try {
        //         await axios.get("/Account/GetSession").then((response) => {
        //             setSessionID(response.data);
        //             t_setSmlSessionID = response.data;
        //         });
        //     } catch (error) {
        //         console.log(error);
        //         setSnackbarMessage("Error: " + error);
        //         setSeverity("error");
        //         setOpenMessage(true);
        //         setLoading(false);
        //         return
        //     }
        // }

        const fetchLogin = async () => {
            try {
                // {
                //     "Session_ID": "{{Session_ID}}",
                //     "Email": "{{Username}}",
                //     "Pw": "{{Password}}",
                //     "Client_IP": "192.168.1.200"
                // }
                let body = {
                    Session_ID: t_setBizzSessionID,
                    Email: email,
                    Pw: password,
                    Client_IP: Client_IP,
                };

                const response = await axios.post("/Account/PostLogin", body);
                if (response.status === 200) {
                    setBizzSession(response.data);

                    var response_with_password = response.data;
                    response_with_password.Webshop_Account.pw = "";

                    setAccount(response_with_password);
                    //sessionStorage.setItem("session_account", JSON.stringify(response_with_password));

                    // setSnackbarMessage(dict["WS_Messages"]["Succesfully logged in."][language]);
                    // setSeverity("success");
                    // setOpenMessage(true);
                    navigate("/webshop/home");
                } else {
                    setSnackbarMessage(dict["WS_Messages"]["Login failed."][language]);
                    setSeverity("error");
                    setOpenMessage(true);
                }
            } catch (error) {
                console.log(error);
                setSnackbarMessage(dict["WS_Messages"]["Login failed."][language]);
                setSeverity("error");
                setOpenMessage(true);
            }
        };

        fetchLogin();

        setLoading(false);

        // console.log("handleSendContactMessage");
        // let msg = "";
        // if (firstName === "") {
        //     msg = msg + dict["Messages"]["First name is required."][language];
        // }
        // if (lastName === "") {
        //     msg = msg + dict["Messages"]["Last name is required."][language];
        // }
        // if (email === "") {
        //     msg = msg + dict["WS_Messages"]["Email is required."][language];
        // }

        // if (password === "") {
        //     msg = msg + dict["WS_Messages"]["Password is required."][language];
        // }

        // if (companyName === "") {
        //     msg = msg + dict["Messages"]["School name is required."][language];
        // }

        // if (message === "") {
        //     msg = msg + dict["Messages"]["Message is required."][language];
        // }

        //validate phone
        // if (phone !== "") {
        //     const re = /^[0-9\b]+$/;
        //     if (!re.test(phone)) {
        //         setSnackbarMessage(dict["Messages"]["Phone is not valid. Only numbers are allowed."][language]);
        //         setSeverity("error");
        //         setOpenMessage(true);
        //         return;
        //     }
        // }

        //validate message length
        // if (message.length > 1000) {
        //     setSnackbarMessage(dict["Messages"]["Message is too long."][language]);
        //     setSeverity("error");
        //     setOpenMessage(true);
        //     return;
        // }

        // let languageCapitals = language.toUpperCase();

        // const fetchMessage = async () => {

        //     try {

        //         let body = {
        //             "ID": null,
        //             // "Name": lastName,
        //             // "FirstName": firstName,
        //             "Email": email,
        //             // "Phone": phone,
        //             // "SchoolName": companyName,
        //             "Language_Code": languageCapitals,
        //             // "Message": message,
        //             "Timestamp": null
        //         };

        //         const response = await axios.post("/api/Company/PostSchoolContactRequest", body);

        //         if (response.status === 200) {
        //             setSnackbarMessage(dict["Messages"]["Message sent successfully."][language]);
        //             setSeverity("success");
        //             setOpenMessage(true);
        //             // setFirstName("");
        //             // setLastName("");
        //             setEmail("");
        //             setPassword("");
        //             // setPhone("");
        //             // setCompanyName("");
        //             // setMessage("");
        //         }
        //         else {
        //             setSnackbarMessage(dict["Messages"]["Message could not be sent."][language]);
        //             setSeverity("error");
        //             setOpenMessage(true);
        //         }
        //     } catch (error) {
        //         console.log(error);
        //         setSnackbarMessage(dict["Messages"]["Message could not be sent."][language]);
        //         setSeverity("error");
        //         setOpenMessage(true);

        //     }

        // };

        // fetchMessage();
        // setLoading(false);
    };

    const handlePassVisibilty = () => {
        setShowPass(!showPass);
    };

    const handleCreateAccountClick = () => {
        navigate("/createaccount");
    };

    return (
        <>
            <Stack spacing={8} m={3}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Stack spacing={4}>
                            <Grid item>
                                <Box pb={2}>
                                    <Typography align="left" color={"#023047"} fontWeight={600} variant="h4">
                                        {dict["WS_Labels"]["Singin"][language]}
                                    </Typography>
                                </Box>
                            </Grid>

                            {/* <Grid item > */}
                            <Box display="flex" alignItems="center">
                                <Typography variant="body1" align="left">
                                    {dict["WS_Messages"]["login_welcome"][language]}
                                </Typography>
                            </Box>

                            {/* grid item username */}
                            <Grid item xs={12} md={12}>
                                <TextField
                                    color="secondary"
                                    value={email}
                                    label={dict["WS_Labels"]["email"][language]}
                                    placeholder={dict["WS_Labels"]["enter_your_email"][language]}
                                    onChange={handleChangeEmail}
                                    size="small"
                                    id="outlined-name"
                                    fullWidth={true}
                                    variant="standard"
                                    margin="normal"
                                    required
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <MailOutlineIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>

                            {/* grid item password */}
                            <Grid item xs={12} md={12}>
                                <TextField
                                    // value={password}
                                    // label={dict["WS_Labels"]["enter_your_password"][language]}
                                    // onChange={handleChangePassword}
                                    // size="small"
                                    // id="outlined-name"
                                    // fullWidth={true}
                                    // variant="standard"
                                    // margin="normal"
                                    // type="password"
                                    color="secondary"
                                    type={showPass ? "text" : "password"}
                                    fullWidth
                                    label={dict["WS_Labels"]["password"][language]}
                                    placeholder={dict["WS_Labels"]["enter_your_password"][language]}
                                    variant="standard"
                                    required
                                    defaultValue={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <LockOutlinedIcon />
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={handlePassVisibilty} aria-label="toggle password" edge="end">
                                                    {showPass ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>

                            {/* grid item forgot password */}
                            <Grid item xs={12} md={12}>
                                <NavLink to="/forgotpassword" style={{ textDecoration: "none" }}>
                                    <Typography variant="body1" align="left" color="secondary">
                                        {dict["WS_Labels"]["forgot_password"][language]}
                                    </Typography>
                                </NavLink>
                            </Grid>

                            <Box pt={0}>
                                <Button
                                    variant="contained"
                                    onClick={sendLoginRequest}
                                    sx={{
                                        mt: 0,
                                        width: "100%",
                                        backgroundColor: "white",
                                        border: "2px solid #219EBC",
                                        color: "#219EBC", // Ensure the text color is visible on a white background
                                        "&:hover": {
                                            backgroundColor: "#219EBC", // Maintain white background on hover
                                            border: "2px solid #219EBC", // Optional: Darken the border on hover
                                            color: "white", // Optional: Darken the text color on hover
                                        },
                                    }}
                                >
                                    {dict["WS_Labels"]["Button_login"][language]}
                                    {loading && (
                                        <CircularProgress
                                            size={20}
                                            sx={{
                                                color: "secondary",
                                                position: "relative",
                                                top: "6px",
                                                left: "60px",
                                                marginTop: "-12px",
                                                marginLeft: "-12px",
                                            }}
                                        />
                                    )}
                                </Button>
                            </Box>

                            <Box>
                                <Typography variant="body1" align="left">
                                    {dict["WS_Labels"]["text_under_signin_line1"][language]}
                                </Typography>
                                <Typography variant="body1" align="left">
                                    {dict["WS_Labels"]["text_under_signin_line2"][language]}
                                </Typography>
                                {/* <NavLink to="/singup" style={{ textDecoration: "none" }}>
                                    <Typography variant="body1" align="left" color="secondary" style={{ marginLeft: 8 }}>
                                        {dict["WS_Labels"]["sign_up_here"][language]}
                                    </Typography>
                                </NavLink> */}
                            </Box>

                            <Box pt={0}>
                                <Button variant="contained" color="secondary" onClick={handleCreateAccountClick} sx={{ mt: 0, width: "100%" }}>
                                    {dict["WS_Labels"]["Button_createaccount"][language]}
                                </Button>
                            </Box>

                            {/* </Grid> */}
                        </Stack>

                        <Stack mt={3} ml={0} direction="column" spacing={0}>
                            {/* create a link with onclick functionality laber 'click me' */}
                            <Box sx={{ textAlign: 'left' }}>
                                <NavLink onClick={openTermsConditions} style={{ textDecoration: 'none' }}>
                                    <Typography variant="body1" sx={{ color: "primary.main" }}>
                                        {dict["WS_Labels"]["Terms_&_Conditions"][language]}
                                    </Typography>
                                </NavLink>
                            </Box>
                            <Box sx={{ textAlign: 'left' }}>
                                <NavLink onClick={openPrivacyPolicy} style={{ textDecoration: 'none' }}>
                                    <Typography variant="body1" sx={{ color: "primary.main" }}>
                                        {dict["WS_Labels"]["Privacy_policy"][language]}
                                    </Typography>
                                </NavLink>
                            </Box>
                        </Stack>

                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Grid item xs={12} md={12} sx={{ display: { xs: "none", sm: "none", md: "none", lg: "block" } }}>
                            <Box component="img" sx={{ maxWidth: 500 }} alt="Photobizz image" src={require("../images/WelcomeBack_full.png")} m={1} />
                        </Grid>
                        <Grid item xs={12} md={12} sx={{ display: { xs: "none", sm: "none", md: "block", lg: "none" } }}>
                            <Box component="img" sx={{ maxWidth: 400 }} alt="Photobizz image" src={require("../images/WelcomeBack_full.png")} m={1} />
                        </Grid>
                    </Grid>
                </Grid>

                <Snackbar
                    open={openMessage}
                    onClose={() => {
                        setOpenMessage(false);
                    }}
                    TransitionComponent={SlideTransition}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    key="Snackbar"
                >
                    <Alert
                        severity={severity}
                        sx={{ marginTop: 2 }}
                        onClose={() => {
                            setOpenMessage(false);
                        }}
                    >
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </Stack>

            <Modal
                open={termsConditions}
                onClose={closeTermsConditions}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className={"modal-style"}>
                    {language === "en" ? (
                        <Typography variant="caption" display={"block"} sx={{ mt: 2 }}>
                            General terms and conditions
                            <br />
                            General sales and delivery terms
                            <br />
                            <br />
                            PHOTOBIZZ BV (August 2024)
                            <br />
                            <br />
                            These general terms and conditions regulate the use of the website https://www.photobizz.be/ and the webshop
                            https://www.photobizz.eu/webshop/home, held by PHOTOBIZZ BV, with registered office at 8780 OOSTROZEBEKE,
                            Stationsstraat 75, registered in the KBO/BCE and VAT register under the number BE0474962379, tel. +32.(0)56.66.33.88,
                            e-mail info@photobizz.be and (hereinafter 'PHOTOBIZZ').
                            <br />
                            In addition, any legal relationship between PHOTOBIZZ and the Customer (collectively referred to as the "Parties"),
                            shall be governed by these general terms and conditions, possibly supplemented by specific terms and conditions,
                            unless expressly derogated from them in writing. These terms and conditions annul and replace all written or oral
                            contracts, proposals and undertakings relating to the same object that would precede the date of the agreement between
                            the Parties.
                            <br />
                            <br />
                            Article 1 - Application
                            <br />
                            1.1. The terms and conditions apply when an order of photographs or gadgets (hereinafter referred to as 'the
                            products') is placed via PHOTOBIZZ's webshop https://www.photobizz.eu/webshop/home by a visitor to this site
                            (hereinafter referred to as 'the Client').
                            <br />
                            1.2. When placing an order through the PHOTOBIZZ webshop, the Customer must expressly accept these Terms and
                            Conditions, thereby agreeing to their applicability to the exclusion of all other terms and conditions. Acceptance of
                            the Terms and Conditions is also derived from the normal performance of the services by PHOTOBIZZ.
                            <br />
                            1.3. PHOTOBIZZ reserves the right, at any time, to amend or delete the General Terms and Conditions and this without
                            any prior notice being necessary. Each order shall be subject to the general terms and conditions included on the
                            website and order confirmation at that time, to the exclusion of any older or newer provision.
                            <br />
                            1.4. If any of these conditions should not be legally valid for whatever reason, the remaining conditions shall
                            continue to apply in full.
                            <br />
                            1.5. The Customer is solely and exclusively responsible for the accuracy of all data he or she provides. By placing an
                            order on the website, the Client confirms to be a natural person, legally capable and at least 18 years old, acting as
                            a consumer and for non-professional purposes. PHOTOBIZZ bears no responsibility for orders placed by persons or
                            entities that do not meet these criteria or for incorrect data provided by the Customer.
                            <br />
                            <br />
                            Article 2 - Formation of the agreement and ordering process
                            <br />
                            2.1. Commercial documents and offers do not create any obligations on the part of PHOTOBIZZ.
                            <br />
                            2.2. The Client receives a user code or QR code from the school to access and order specific photos through the
                            webshop. This code is unique, confidential and strictly personal and only valid for a fixed duration. The duration of
                            the user code is indicated on the flyer distributed by the school. On the basis of the code provided, the Customer can
                            create a free personal user account that will allow him to manage orders more easily from anywhere in the world. To
                            access the user account, registration is required using the Customer's e-mail address, as well as a personal password,
                            which the Customer creates himself. The Client is responsible for keeping his personal password confidential. He is
                            obliged to inform PHOTOBIZZ immediately of any unauthorised use of his password and to change the password if it is no
                            longer confidential. During ordering, the Customer is guided step by step through the ordering process, and can change
                            the order or correct his data by going back to previous steps. In principle, the user account is accessible 7 days a
                            week and 24 hours a day.
                            <br />
                            2.3. An order and accompanying information are stored by PHOTOBIZZ until the production of the order or for a fixed
                            term. This is in case problems arise in the execution or delivery of the order. This period is extended proportionally
                            if PHOTOBIZZ needs to reprint the photos. A summary of the order and its contents will be sent by e-mail as an order
                            confirmation.
                            <br />
                            2.4. If the user account is terminated, PHOTOBIZZ deletes the user account and all photos and projects stored therein.
                            In addition, PHOTOBIZZ may, without prior court order or any form of compensation, terminate and/or block a user
                            account in whole or in part or delete some or all digital photos contained in the user account in case these General
                            Terms and Conditions were violated.
                            <br />
                            2.5. The agreement between PHOTOBIZZ and the Customer is only concluded when the online order of products is validated
                            by the Customer with an order confirmation sent by PHOTOBIZZ by e-mail within 24 hours. The confirmation is sent to
                            the email address provided by the Customer. It is the responsibility of the Client to ensure that messages do not end
                            up in the spam folder. Confirmation is only possible after acceptance of the General Terms and Conditions and full
                            payment of the ordered products by the Client. PHOTOBIZZ remains owner of all products until full payment.
                            <br />
                            2.6. When confirming the order, a summary of the order is displayed summarising all details of the order. The Client
                            must check all details and correct them where necessary. Once the ordering process has been completed, changes are not
                            possible.
                            <br />
                            Article 3 - Prices of products
                            <br />
                            3.1. The sales prices indicated on the webshop for products on the date of the order are the applicable prices. The
                            prices are also stated in the order summary. These prices are expressed in euros and include VAT and other government
                            levies.
                            <br />
                            3.2. Depending on the payment method chosen, additional bank charges may be charged, which shall be borne by the
                            customer.
                            <br />
                            <br />
                            Article 4 - Payment
                            <br />
                            4.1. Payment is made immediately after the order is placed. PHOTOBIZZ offers various ways to securely pay for online
                            orders electronically through the payment system and payment terms of payment provider Timestone, Ogone
                            Ingenico,Fortis Banque (SixPayment) and PaySquare. By placing an order and payment through the PHOTOBIZZ webshop, the
                            Customer declares to have read and accepted the general terms and conditions of the payment provider. The Customer is
                            also responsible for any cost associated with the payment transaction.
                            <br />
                            4.2. No order shall be validated without prior receipt of the corresponding payment.
                            <br />
                            4.3. PHOTOBIZZ only accepts payments from accounts within the European Union.Orders are not binding on PHOTOBIZZ until
                            confirmed by e-mail or any other appropriate means of communication.
                            <br />
                            <br />
                            Article 5 - Delivery and transfer of risk
                            <br />
                            5.1. Products ordered through the webshop are delivered only after receipt of payment in accordance with article 4 of
                            the present general terms and conditions. Products are delivered directly to the school.
                            <br />
                            5.2. Delivery is made by postal delivery or by PHOTOBIZZ's own transport or by an external transport company of
                            PHOTOBIZZ's choice.
                            <br />
                            5.3. The transfer of risk of the goods takes place at the moment of conclusion of the agreement in case of standard
                            shipping. The Customer shall bear the risk from that time onwards.
                            <br />
                            5.4. PHOTOBIZZ can under no circumstances be held responsible for a delivery that arrives later than expected or a
                            package that is lost due to the actions of third parties, unforeseen circumstances or force majeure. PHOTOBIZZ is also
                            not liable for delays or failure to deliver due to the fault of the carrier. If an order is not delivered within the
                            expected timeframe, an investigation will be opened with the carrier, which may take several days. During this period,
                            no refund or reshipment can be made.
                            <br />
                            5.5. If despite all precautions certain products would be damaged during transport, the Customer can contact the
                            school. In this case, PHOTOBIZZ will provide a new product and deliver it to the school free of charge.
                            <br />
                            <br />
                            Article 6 - Right of withdrawal
                            <br />
                            6.1. All products are custom-made to the wishes and specifications of the Client. Once the order has been confirmed
                            and payment has reached PHOTOBIZZ, the order cannot be revoked or cancelled.
                            <br />
                            <br />
                            Article 7 - Liability, indemnities, force majeure and exclusions
                            <br />
                            7.1. Except for the explicit commitments made by PHOTOBIZZ by virtue of these General Terms and Conditions, the
                            liability of PHOTOBIZZ is limited to the liability mandatorily imposed by law.
                            <br />
                            7.2. PHOTOBIZZ makes every effort to ensure that the website and the webshop are accessible and available 7 days a
                            week, 24 hours a day. However, it is possible that due to maintenance, website or network updates or other causes or
                            interruptions beyond the fault of PHOTOBIZZ, access to the site or user account may be interrupted. PHOTOBIZZ cannot
                            be held liable for losses or damages resulting from such interruptions. Nor is it liable for damages caused directly
                            or indirectly by the use of the website or the web shop, the possibility of placing an online order or by any other
                            act of the Client or a third party, whether caused by fault or negligence, for example (non-exhaustive) due to a
                            design, material or manufacturing fault of the product. PHOTOBIZZ also has no personal information obligation for
                            online orders.
                            <br />
                            7.3. PHOTOBIZZ is not liable for any material or physical damage, indirect damage or consequential damage suffered by
                            the Customer and/or his appointees for any reason whatsoever, including (not limitative) a shortcoming, negligence,
                            error or serious fault of its or its appointees or suppliers.
                            <br />
                            7.4. Finally, PHOTOBIZZ cannot be held liable for delays or deficiencies in the execution of the agreement if these
                            delays or deficiencies are the result of facts or circumstances that are independent of its will, that cannot be
                            foreseen and that cannot be avoided (force majeure), such as e.g. (non-exhaustive): illness or unavailability of the
                            executing persons, blockage of distribution or the non-availability of certain raw materials or products. If PHOTOBIZZ
                            is affected by a force majeure situation, it shall immediately inform the Customer in writing.
                            <br />
                            7.5. Insofar as any liability would nevertheless be retained, it shall be limited to reimbursement of the price of the
                            product.
                            <br />
                            7.6. The website is only intended to make general information available to the Customer regarding the products and
                            activities of PHOTOBIZZ. PHOTOBIZZ has with regard to the access, ordering process, delivery or other services only a
                            best-efforts obligation.
                            <br />
                            7.7. PHOTOBIZZ may include links to other internet sites on the pages of its website. However, PHOTOBIZZ is not
                            responsible for the content of these sites, the advertised products, services or other materials from external sources
                            or for any assumed or actual damages or losses that may result from the use or connection to such sites or external
                            sources.
                            <br />
                            <br />
                            Article 8 - Intellectual property
                            <br />
                            8.1. Designs, concepts, creations, materials, illustrations, logos, drawings, photographs, images, processing and
                            manufacturing methods, (product) descriptions, data, product or company names, texts, etc. that are developed and/or
                            used by PHOTOBIZZ, in any form whatsoever, are and remain the property of PHOTOBIZZ, as well as any documentation that
                            would be made available to the Client or placed on the webshop https://www.dms.photobizz.be/. Publication or other
                            forms of publication of these documents is only possible after written consent has been obtained from PHOTOBIZZ.
                            <br />
                            <br />
                            Article 9 - Processing of personal data
                            <br />
                            9.1. By placing an order or booking a service, the Client gives his express consent to PHOTOBIZZ to process his
                            personal data for the purpose of processing and shipping his ordered products. In addition, the data provided may also
                            be used for customer management, the newsletter, advertising or marketing purposes and statistical information
                            collection regarding the customers of PHOTOBIZZ. PHOTOBIZZ also refers in this context to its privacy statement.
                            <br />
                            9.2. The controller, PHOTOBIZZ, respects the General Data Protection Regulation of 24 May 2016 regarding the
                            protection of privacy in the processing of personal data. In accordance with this Regulation, the Customer has a legal
                            right to access and correct his personal data, as well as the right to have his data completely deleted. To this end,
                            the Client, with proof of identity (copy of identity card), must address a written, dated and signed request to
                            PHOTOBIZZ, with registered office at 8780 Oostrozebeke, Stationsstraat 75. 9.3. All personal data obtained and
                            processed through https://www.photobizz.be/ or https://www.dms.photobizz.be/, will be treated confidentially and under
                            no circumstances passed on to third parties.
                            <br />
                            9.4. As stated in article 2.4, PHOTOBIZZ deletes the user account and all photos and projects stored therein, if the
                            user account is terminated.
                            <br />
                            <br />
                            Article 10 - Complaints
                            <br />
                            10.1. Any complaints should be addressed directly to the school. PHOTOBIZZ only handles complaints communicated
                            through the school or through helpdesk@fjvandereycken.com.
                            <br />
                            <br />
                            Article 11 - Applicable law and competent authorities
                            <br />
                            11.1. These general terms and conditions and the overall legal relationship between PHOTOBIZZ and the Client are
                            governed by Belgian law, even if an order is placed from abroad. The application of the Vienna Convention on Contracts
                            for the International Sale of Goods (1980) is expressly excluded.
                            <br />
                            11.2. The Customer accepts electronic proof.
                            <br />
                            11.3. All possible disputes arising from or related to the legal relationship between PHOTOBIZZ and the Customer shall
                            be settled exclusively by the courts of the judicial district of Brussels.
                            <br />
                        </Typography>
                    ) : (
                        ""
                    )}

                    {language === "nl" ? (
                        <Typography variant="caption" display={"block"} sx={{ mt: 2 }}>
                            Algemene Voorwaarden
                            <br />
                            Algemene verkoops- en leveringsvoorwaarden
                            <br />
                            <br />
                            PHOTOBIZZ BV (augustus 2024)
                            <br />
                            <br />
                            Deze algemene voorwaarden regelen het gebruik van de website https://www.photobizz.be/ en de webshop
                            https://www.photobizz.eu/webshop/home, aangehouden door PHOTOBIZZ BV, met maatschappelijke zetel te 8780 OOSTROZEBEKE,
                            Stationsstraat 75, ingeschreven in het KBO en BTW register onder het nummer BE0474962379, tel. +32.(0)56.66.33.88,
                            e-mail info@photobizz.be en (hierna ‘PHOTOBIZZ’).
                            <br />
                            <br />
                            Daarnaast wordt elke rechtsverhouding tussen PHOTOBIZZ en de Klant (samen aangeduid als de ‘Partijen’), beheerst door
                            onderhavige algemene voorwaarden, eventueel aangevuld met specifieke voorwaarden, behoudens uitdrukkelijke
                            schriftelijke afwijking hieraan. Deze voorwaarden vernietigen en vervangen alle schriftelijke of mondelinge
                            contracten, voorstellen en verbintenissen die betrekking hebben op hetzelfde voorwerp en die aan de datum van de
                            overeenkomst tussen partijen zouden voorafgaan.
                            <br />
                            <br />
                            Artikel 1 – Toepassing
                            <br />
                            1.1. De voorwaarden zijn van toepassing wanneer een bestelling van foto’s of gadgets (hierna ‘de producten’) via de
                            webshop https://www.photobizz.eu/webshop/home van PHOTOBIZZ geplaatst wordt door een bezoeker van deze site (hierna
                            genoemd ‘de Klant’).
                            <br />
                            1.2. Bij het plaatsen van een bestelling via de webshop van PHOTOBIZZ moet de Klant deze algemene voorwaarden
                            uitdrukkelijk aanvaarden, waardoor hij instemt met de toepasselijkheid van deze voorwaarden met uitsluiting van alle
                            andere voorwaarden. De aanvaarding van de Voorwaarden wordt tevens afgeleid uit de normale uitvoering van de diensten
                            door PHOTOBIZZ.
                            <br />
                            1.3. PHOTOBIZZ behoudt zich het recht voor om, op eender welk tijdstip, de algemene voorwaarden te wijzigen of te
                            schrappen en dit zonder dat enige voorafgaande kennisgeving noodzakelijk is. Bij elke bestelling zijn de algemene
                            voorwaarden van toepassing die op dat ogenblik op de website en orderbevestiging werden opgenomen, met uitsluiting van
                            alle eventuele oudere of nieuwere bepalingen.
                            <br />
                            1.4. Indien één van deze voorwaarden om welke reden dan ook niet rechtsgeldig zou zijn, blijven de overige voorwaarden
                            onverkort van toepassing.
                            <br />
                            1.5. De klant is zelf en uitsluitend verantwoordelijk voor de juistheid van alle gegevens die hij of zij doorgeeft.
                            Door het plaatsen van een bestelling op de website bevestigt de Klant een natuurlijk persoon te zijn,
                            handelingsbekwaam en minstens 18 jaar oud, handelend als consument en voor niet professionele doeleinden. PHOTOBIZZ
                            draagt geen enkele verantwoordelijkheid voor bestellingen door personen of entiteiten die niet aan deze criteria
                            voldoen of voor foutieve gegevensvermelding door de Klant.
                            <br />
                            <br />
                            Artikel 2 – Totstandkoming van de overeenkomst en bestelproces
                            <br />
                            2.1. Commerciële documenten en aanbiedingen scheppen geen verbintenissen in hoofde van PHOTOBIZZ.
                            <br />
                            2.2. De Klant ontvangt vanwege de school een gebruikerscode of een QR-code om toegang te krijgen tot welbepaalde
                            foto’s en deze vervolgens te kunnen bestellen via de webshop. Deze code is uniek, vertrouwelijk en strikt persoonlijk
                            en slechts gedurende een vaste looptijd geldig. De duur van de gebruikerscode wordt vermeld op de door de school
                            verdeelde flyer. Aan de hand van de verstrekte code, kan de Klant een gratis persoonlijk gebruikersaccount aanmaken
                            waarmee hij vanuit elke plaats ter wereld makkelijker de bestellingen kan beheren. Om toegang te krijgen tot het
                            gebruikersaccount is registratie verplicht aan de hand van het e-mailadres van de klant, alsook een persoonlijk
                            paswoord, dat de klant zelf creëert. De Klant is verantwoordelijk voor het vertrouwelijk houden van zijn persoonlijk
                            paswoord. Hij is verplicht om PHOTOBIZZ onmiddellijk op de hoogte te brengen van ongeoorloofd gebruik van zijn
                            wachtwoord en om het wachtwoord te veranderen als het niet langer vertrouwelijk is. Tijdens het bestellen wordt de
                            Klant stap voor stap door het bestelproces geleidt, en kan hij de bestelling wijzigen of zijn gegevens corrigeren door
                            terug te gaan naar eerdere stappen. Het gebruikersaccount is in principe 7 dagen per week en 24 uur per dag
                            toegankelijk.
                            <br />
                            2.3. Een bestelling en de bijhorende informatie worden door PHOTOBIZZ opgeslagen tot aan de productie van de
                            bestelling of gedurende een vaste looptijd. Dit voor het geval er problemen ontstaan bij de uitvoering of aflevering
                            van de bestelling. Deze periode wordt evenredig verlengd als PHOTOBIZZ tot herdruk van de foto’s dient over te gaan.
                            Een overzicht van de bestelling en de inhoud wordt per e-mail verstuurd als orderbevestiging.
                            <br />
                            2.4. Als het gebruikersaccount wordt beëindigd, verwijdert PHOTOBIZZ het gebruikersaccount en alle foto’s en projecten
                            die daarin zijn opgeslagen. Bovendien kan PHOTOBIZZ zonder voorafgaande rechtelijke uitspraak of enige vorm van
                            compensatie een gebruikersaccount opheffen en/of geheel of gedeeltelijk blokkeren of sommige of alle digitale foto’s
                            die zich in het gebruikersaccount bevinden verwijderen in het geval deze Algemene Voorwaarden werden geschonden.
                            <br />
                            2.5. De overeenkomst tussen PHOTOBIZZ en de Klant komt slechts tot stand op het ogenblik dat de online bestelling van
                            producten door de Klant gevalideerd wordt met een orderbevestiging die per e-mail verstuurd wordt door PHOTOBIZZ
                            binnen 24 uur. De bevestiging wordt naar het door de Klant opgegeven e-mailadres verstuurd. Het is de
                            verantwoordelijkheid van de Klant om erop toe te zien dat de berichten niet in de spamfolder terechtkomen. Een
                            bevestiging is pas mogelijk na aanvaarding van de Algemene Voorwaarden en volledige betaling van de bestelde producten
                            door de Klant. PHOTOBIZZ blijft eigenaar van alle producten tot op het ogenblik van volledige betaling.
                            <br />
                            2.6. Bij het bevestigen van de bestelling wordt een overzicht van de bestelling getoond met een samenvatting van alle
                            details van de bestelling. De Klant dient alle details te controleren en desgevallend te corrigeren waar nodig. Is het
                            bestelproces eenmaal voltooid, dan zijn wijzigingen niet meer mogelijk. Bestellingen zijn niet bindend voor PHOTOBIZZ
                            zolang deze niet per e-mail of enige ander passend communicatiemiddel zijn bevestigd.
                            <br />
                            <br />
                            Artikel 3 – Prijzen producten
                            <br />
                            3.1. De op de webshop aangegeven verkoopprijzen voor producten op datum van de bestelling, zijn de van toepassing
                            zijnde prijzen. De prijzen worden tevens vermeld in het overzicht van de bestelling. Deze prijzen zijn uitgedrukt in
                            euro en zijn inclusief BTW en andere heffingen die van overheidswege worden opgelegd.
                            <br />
                            3.2. Afhankelijk van de gekozen betalingswijze, kunnen er bijkomende bankkosten aangerekend worden, die ten laste zijn
                            van de klant.
                            <br />
                            <br />
                            Artikel 4 – Betaling
                            <br />
                            4.1. De betaling vindt plaats direct na de bestelling. PHOTOBIZZ biedt verschillende manieren aan om op een veilige
                            wijze de online bestellingen elektronisch te betalen via het betalingssysteem en de betalingsvoorwaarden van
                            betalingsprovider Timestone, Ogone Ingenico,Fortis Banque (SixPayment) en PaySquare. Middels het plaatsen van een
                            bestelling en betaling via de webshop van PHOTOBIZZ verklaart de Klant tevens kennis te hebben genomen van de algemene
                            voorwaarden van de betalingsprovider en aanvaardt hij erdoor gebonden te zijn. De Klant is tevens verantwoordelijk
                            voor elke kost die gepaard gaat met de betaaltransactie.
                            <br />
                            4.2. Geen enkele bestelling wordt gevalideerd zonder voorafgaand de overeenstemmende betaling te ontvangen.
                            <br />
                            4.3. PHOTOBIZZ aanvaardt enkel betalingen van rekeningen binnen de Europese Unie.
                            <br />
                            <br />
                            Artikel 5 – Levering en risico-overdracht
                            <br />
                            5.1. Producten besteld via de webshop worden pas geleverd na ontvangst van de betaling overeenkomstig artikel 4 van de
                            huidige algemene voorwaarden. De producten worden rechtstreeks geleverd aan de school.
                            <br />
                            5.2. De levering gebeurt via postbedeling of via het eigen vervoer van PHOTOBIZZ of door een externe transportfirma
                            naar keuze van PHOTOBIZZ.
                            <br />
                            5.3. De risico-overdracht van de goederen vindt plaats op het moment van de sluiting van de overeenkomst in geval van
                            standaard verzending. De Klant draagt vanaf dat ogenblik het risico.
                            <br />
                            5.4. PHOTOBIZZ kan in geen geval verantwoordelijk gehouden worden voor een levering die later dan verwacht aankomt of
                            een pakket dat verloren raakt door toedoen van derden, door onvoorziene omstandigheden of door overmacht. PHOTOBIZZ is
                            evenmin aansprakelijk voor vertraging of uitblijven van de levering door toedoen van de vervoerder. Als een bestelling
                            niet geleverd wordt binnen de verwachte tijdspanne, wordt een onderzoek ingesteld bij de vervoerder, wat meerdere
                            dagen in beslag kan nemen. Gedurende deze periode kan niet worden overgegaan tot terugbetaling of een andere
                            verzending.
                            <br />
                            5.5. Wanneer ondanks alle voorzorgsmaatregelen bepaalde producten tijdens het transport zouden beschadigd raken, kan
                            de Klant contact opnemen met de school. PHOTOBIZZ zal in dat geval een nieuw product voorzien en gratis aan de school
                            bezorgen.
                            <br />
                            <br />
                            Artikel 6 – Herroepingsrecht
                            <br />
                            6.1. Alle producten worden op maat vervaardigd naar de wensen en specificaties van de Klant. Eens de bestelling is
                            bevestigd en de betaling bij PHOTOBIZZ is toegekomen, kan de bestelling niet meer herroepen of geannuleerd worden.
                            <br />
                            <br />
                            Artikel 7 – Aansprakelijkheid, vrijwaringen, overmacht en uitsluitingen
                            <br />
                            7.1. Behoudens de expliciete verbintenissen aangegaan door PHOTOBIZZ krachtens deze algemene voorwaarden, is de
                            aansprakelijkheid van PHOTOBIZZ beperkt tot de aansprakelijkheid die dwingend door de wet is opgelegd.
                            <br />
                            7.2. PHOTOBIZZ doet een uiterste inspanning om ervoor te zorgen dat de website en de webshop 7 dagen per week, 24 uur
                            per dag toegankelijk en beschikbaar zijn. Het is echter mogelijk dat door onderhoud, website of netwerk updates of
                            door andere oorzaken of onderbrekingen buiten de schuld van PHOTOBIZZ, toegang tot de site of tot het
                            gebruikersaccount onderbroken wordt. PHOTOBIZZ kan niet aansprakelijk worden gesteld voor verliezen of schade als
                            gevolg van dergelijke onderbrekingen. Evenmin is zij aansprakelijk voor schade die rechtstreeks of onrechtstreeks
                            veroorzaakt wordt door het gebruik van de website of de webshop, de mogelijkheid om een online bestelling te plaatsen,
                            noch door een andere daad van de Klant of van een derde, ongeacht of deze worden veroorzaakt door een fout of
                            nalatigheid, bijvoorbeeld (niet limitatief) ingevolge een ontwerp-, materiaal- of fabricagefout aan het product.
                            PHOTOBIZZ heeft tevens geen enkele persoonlijke informatieplicht voor online bestellingen.
                            <br />
                            7.3. PHOTOBIZZ is niet aansprakelijk voor enige materiële of lichamelijke schade, onrechtstreekse schade of
                            gevolgschade, die de Klant en/of zijn aangesteldenlijden om welke reden ook, hieronder begrepen (niet limitatief) een
                            tekortkoming, nalatigheid, fout of zware fout van haar of haar aangestelden of leveranciers.
                            <br />
                            7.4. Tenslotte kan PHOTOBIZZ niet aansprakelijk worden gesteld voor vertragingen of gebreken in de uitvoering van de
                            overeenkomst als deze vertragingen of gebreken het gevolg zijn van feiten of omstandigheden die onafhankelijk zijn van
                            haar wil, die niet te voorzien zijn en die niet vermeden kunnen worden (overmacht), zoals bv. (niet-limitatief):
                            ziekte of onbeschikbaarheid van de uitvoerende personen, blokkering van distributie of het niet voorradig zijn van
                            bepaalde grondstoffen of producten. Indien PHOTOBIZZ wordt getroffen door een situatie van overmacht zal ze de Klant
                            onmiddellijk schriftelijk van op de hoogte brengen.
                            <br />
                            7.5. Voor zover enige aansprakelijkheid toch weerhouden zou worden, dan is deze beperkt tot terugbetaling van de prijs
                            van het product.
                            <br />
                            7.6. De website is enkel bestemd om algemene informatie ter beschikking te stellen van de Klant over de producten en
                            activiteiten van PHOTOBIZZ. PHOTOBIZZ heeft met betrekking tot de toegang, het bestelproces, de levering of de andere
                            diensten slechts een inspanningsverbintenis.
                            <br />
                            7.7. PHOTOBIZZ kan op de pagina’s van haar website links naar andere internetsites plaatsen. PHOTOBIZZ is echter niet
                            verantwoordelijk voor de inhoud van deze sites, de geadverteerde producten, diensten of andere materialen van externe
                            bronnen of voor veronderstelde of werkelijke schade of verliezen die het gevolg kunnen zijn van het gebruik of de
                            verbinding met dergelijke sites of externe bronnen.
                            <br />
                            <br />
                            Artikel 8 – Intellectuele eigendom
                            <br />
                            8.1. Ontwerpen, concepten, creaties, materiaalkeuze, illustraties, logo’s, tekeningen, foto’s, afbeeldingen,
                            verwaardiging en fabricagemethodieken, (product-)omschrijvingen, data, product- of bedrijfsnamen, teksten, e.d.m. die
                            ontwikkeld en/of gebruikt worden door PHOTOBIZZ, in welke vorm dan ook, zijn en blijven eigendom van PHOTOBIZZ,
                            evenals enige documentatie die desaangaande ter beschikking gesteld zou worden van de Klant of op de webshop
                            https://www.dms.photobizz.be/ zou worden geplaatst. Publicatie of andere vormen van openbaarmaking hiervan kan alleen
                            na verkregen schriftelijke toestemming van PHOTOBIZZ.
                            <br />
                            <br />
                            Artikel 9 – Verwerking persoonsgegevens
                            <br />
                            9.1. Door een bestelling te plaatsen of een dienst te boeken, geeft de Klant zijn uitdrukkelijke toestemming aan
                            PHOTOBIZZ om zijn persoonsgegevens te verwerken met het oog op de verwaardiging en verzending van de door hem bestelde
                            producten. Daarnaast kunnen de verstrekte gegevens eveneens worden gebruikt voor klantenbeheer, de nieuwsbrief,
                            reclame- of marketingdoeleinden en statistische informatieverzameling omtrent de klanten van PHOTOBIZZ. PHOTOBIZZ
                            verwijst in dit kader ook naar zijn privacyverklaring.
                            <br />
                            9.2. De verantwoordelijke voor de verwerking, PHOTOBIZZ, respecteert de Algemene Verordening Gegevensbescherming van
                            24 mei 2016 met betrekking tot de bescherming van het privéleven in de verwerking van persoonlijke gegevens. Conform
                            deze Verordening, beschikt de Klant over een wettelijk recht op inzage en correctie van zijn persoonsgegevens, alsmede
                            het recht om zijn gegevens volledig te laten verwijderen. Ten dien einde dient de Klant, met bewijs van identiteit
                            (kopie identiteitskaart), een schriftelijke, gedateerde en ondertekende aanvraag aan PHOTOBIZZ, met maatschappelijke
                            zetel te 8780 Oostrozebeke, Stationsstraat 75, richten.
                            <br />
                            9.3. Alle persoonsgegevens die via https://www.photobizz.be/ of https://www.dms.photobizz.be/, worden verkregen en
                            verwerkt, zullen vertrouwelijk worden behandeld en in geen geval aan derden worden doorgegeven.
                            <br />
                            9.4. Zoals in artikel 2.4. wordt aangegeven verwijdert PHOTOBIZZ het gebruikersaccount en alle foto’s en projecten die
                            daarin zijn opgeslagen, als het gebruikersaccount wordt beëindigd.
                            <br />
                            <br />
                            Artikel 10 – Klachten
                            <br />
                            10.1. Eventuele klachten dienen rechtstreeks gericht te worden aan de school. PHOTOBIZZ behandelt enkel klachten die
                            via de school of via helpdesk@fjvandereycken.com worden doorgegeven.
                            <br />
                            <br />
                            Artikel 11 – Toepasselijk recht en bevoegde instanties
                            <br />
                            11.1. Deze algemene voorwaarden en de globale rechtsverhouding tussen PHOTOBIZZ en de Klant worden beheerst door het
                            Belgische recht, zelfs indien een bestelling vanuit het buitenland zou worden geplaatst. De toepassing van het Weens
                            Koopverdrag inzake internationale koopovereenkomsten (1980) wordt uitdrukkelijk uitgesloten.
                            <br />
                            11.2. De klant aanvaardt elektronische bewijsvoering.
                            <br />
                            11.3. Alle eventuele geschillen voortvloeiend uit of verband houdend met de rechtsverhouding tussen PHOTOBIZZ en de
                            Klant zullen uitsluitend worden beslecht door de rechtbanken van het gerechtelijk arrondissement Brussel.
                            <br />
                            <br />
                            Oostrozebeke, Augustus 2024
                            <br />
                        </Typography>
                    ) : (
                        ""
                    )}

                    {language === "fr" ? (
                        <Typography variant="caption" display={"block"} sx={{ mt: 2 }}>
                            Conditions générales de vente
                            <br />
                            Conditions générales de vente et de livraison
                            <br />
                            <br />
                            PHOTOBIZZ BV (août 2024)
                            <br />
                            <br />
                            Les présentes conditions générales régissent l'utilisation du site web https://www.photobizz.be/ et de la boutique en
                            ligne https://www.photobizz.eu/webshop/home, détenus par PHOTOBIZZ BV, dont le siège social est établi à 8780
                            OOSTROZEBEKE, Stationsstraat 75, inscrite au registre BCE et TVA sous le numéro BE0474962379, tél. +32.(0)56.66.33.88,
                            e-mail info@photobizz.be et (ci-après "PHOTOBIZZ").
                            <br />
                            En outre, toute relation juridique entre PHOTOBIZZ et le client (collectivement dénommés les " parties "), est régie
                            par les présentes conditions générales, éventuellement complétées par des conditions spécifiques, sauf dérogation
                            expresse et écrite. Les présentes conditions générales annulent et remplacent tous les contrats, propositions et
                            engagements écrits ou oraux relatifs au même objet qui précéderaient la date de l'accord entre les Parties.
                            <br />
                            <br />
                            Article 1 - Application
                            <br />
                            1.1. Les présentes conditions s'appliquent lorsqu'une commande de photographies ou de gadgets (ci-après dénommés "les
                            produits") est passée via la boutique en ligne de PHOTOBIZZ https://www.photobizz.eu/webshop/home par un visiteur de
                            ce site (ci-après dénommé "le client").
                            <br />
                            1.2. Lors de la passation d'une commande sur le webshop de PHOTOBIZZ, le client doit accepter expressément les
                            présentes conditions générales, ce qui implique qu'il en accepte l'applicabilité à l'exclusion de toute autre
                            condition. L'acceptation des conditions générales découle également de l'exécution normale des prestations de
                            PHOTOBIZZ.
                            <br />
                            1.3. PHOTOBIZZ se réserve le droit de modifier ou de supprimer à tout moment les conditions générales, et ce sans
                            préavis. Chaque commande est soumise aux conditions générales figurant sur le site Internet et la confirmation de
                            commande à ce moment-là, à l'exclusion de toute disposition plus ancienne ou plus récente.
                            <br />
                            1.4. Si l'une de ces conditions n'était pas juridiquement valable pour quelque raison que ce soit, les autres
                            conditions continueraient à s'appliquer intégralement.
                            <br />
                            1.5. Le Client est seul et unique responsable de l'exactitude de toutes les données qu'il fournit. En passant commande
                            sur le site, le Client confirme être une personne physique, capable juridiquement et âgée d'au moins 18 ans, agissant
                            en tant que consommateur et à des fins non professionnelles. PHOTOBIZZ n'est pas responsable des commandes passées par
                            des personnes ou entités ne répondant pas à ces critères ou des données erronées fournies par le Client.
                            <br />
                            <br />
                            Article 2 – Conclusion du contrat et processus de commande
                            <br />
                            2.1. Les documents commerciaux et les offres ne créent aucune obligation pour PHOTOBIZZ.
                            <br />
                            2.2. Le client reçoit de l'école un code d'utilisateur ou QR code lui permettant d'accéder et de commander des photos
                            spécifiques via le webshop. Ce code est unique, confidentiel et strictement personnel et n'est valable que pour une
                            durée déterminée. La durée du code d'utilisateur est indiquée sur le flyer distribué par l'école. Sur la base du code
                            fourni, le Client peut créer gratuitement un compte utilisateur personnel qui lui permettra de gérer plus facilement
                            ses commandes depuis n'importe quel endroit du monde. Pour accéder au compte utilisateur, une inscription est
                            nécessaire en utilisant l'adresse e-mail du Client, ainsi qu'un mot de passe personnel, que le Client crée lui-même.
                            Le client est responsable de la confidentialité de son mot de passe personnel. Il est tenu d'informer immédiatement
                            PHOTOBIZZ de toute utilisation non autorisée de son mot de passe et de modifier le mot de passe s'il n'est plus
                            confidentiel. Lors de la commande, le client est guidé pas à pas dans le processus de commande et peut modifier sa
                            commande ou corriger ses données en revenant aux étapes précédentes. En principe, le compte utilisateur est accessible
                            7 jours sur 7 et 24 heures sur 24.
                            <br />
                            2.3. Une commande et les informations qui l'accompagnent sont conservées par PHOTOBIZZ jusqu'à la production de la
                            commande ou pour une durée déterminée. Ceci pour le cas où des problèmes surviendraient lors de l'exécution ou de la
                            livraison de la commande. Ce délai est prolongé proportionnellement si PHOTOBIZZ doit réimprimer les photos. Un
                            récapitulatif de la commande et de son contenu est envoyé par e-mail en guise de confirmation de commande.
                            <br />
                            2.4. En cas de résiliation du compte utilisateur, PHOTOBIZZ supprime le compte utilisateur ainsi que toutes les photos
                            et tous les projets qui y sont enregistrés. En outre, PHOTOBIZZ peut, sans décision judiciaire préalable ni
                            dédommagement d'aucune sorte, résilier et/ou bloquer un compte d'utilisateur en tout ou en partie ou supprimer une
                            partie ou la totalité des photos numériques contenues dans le compte d'utilisateur en cas de violation des présentes
                            conditions générales d'utilisation.
                            <br />
                            2.5. Le contrat entre PHOTOBIZZ et le client n'est conclu que lorsque la commande de produits en ligne est validée par
                            le client par une confirmation de commande envoyée par PHOTOBIZZ par e-mail dans les 24 heures. La confirmation est
                            envoyée à l'adresse e-mail indiquée par le client. Il est de la responsabilité du Client de s'assurer que les messages
                            n'atterrissent pas dans le dossier spam. La confirmation n'est possible qu'après acceptation des Conditions Générales
                            et paiement intégral des produits commandés par le Client. PHOTOBIZZ reste propriétaire de tous les produits jusqu'à
                            leur paiement intégral.
                            <br />
                            2.6. Lors de la confirmation de la commande, un récapitulatif de la commande s'affiche résumant tous les détails de la
                            commande. Le client est tenu de vérifier tous les détails et de les corriger le cas échéant. Une fois le processus de
                            commande terminé, aucune modification n'est possible. Les commandes n'engagent PHOTOBIZZ que lorsqu'elles ont été
                            confirmées par e-mail ou par tout autre moyen de communication approprié.
                            <br />
                            <br />
                            Article 3 - Prix des produits
                            <br />
                            3.1. Les prix de vente indiqués sur le webshop pour les produits à la date de la commande sont les prix applicables.
                            Les prix sont également indiqués dans le récapitulatif de la commande. Ces prix sont exprimés en euros et incluent la
                            TVA et les autres prélèvements publics.
                            <br />
                            3.2. En fonction du mode de paiement choisi, des frais bancaires supplémentaires peuvent être facturés, qui sont à
                            charge du client.
                            <br />
                            <br />
                            Article 4 - Paiement
                            <br />
                            4.1. Le paiement est effectué immédiatement après la commande. PHOTOBIZZ propose différents moyens de paiement
                            sécurisés pour les commandes en ligne par voie électronique grâce au système de paiement et aux modalités de paiement
                            du prestataire de paiement Timestone, Ogone Ingenico, Fortis Banque (SixPayment) et PaySquare. En passant une commande
                            et en effectuant un paiement via la boutique en ligne de PHOTOBIZZ, le client déclare avoir pris connaissance et
                            accepté les conditions générales du prestataire de paiement. Le client est également responsable de tous les frais
                            liés à la transaction de paiement.
                            <br />
                            4.2. Aucune commande ne sera validée sans réception préalable du paiement correspondant.
                            <br />
                            4.3. PHOTOBIZZ n'accepte que les paiements provenant de comptes situés dans l'Union européenne.
                            <br />
                            <br />
                            Article 5 - Livraison et transfert des risques
                            <br />
                            5.1. Les produits commandés via le webshop ne sont livrés qu'après réception du paiement conformément à l'article 4
                            des présentes conditions générales. Les produits sont livrés directement à l'école.
                            <br />
                            5.2. La livraison est effectuée par voie postale ou par le propre transport de PHOTOBIZZ ou par une entreprise de
                            transport externe au choix de PHOTOBIZZ.
                            <br />
                            5.3. Le transfert des risques de la marchandise a lieu au moment de la conclusion du contrat en cas d'expédition
                            standard. Le client supporte les risques à partir de ce moment.
                            <br />
                            5.4. PHOTOBIZZ ne peut en aucun cas être tenue responsable d'une livraison qui arrive plus tard que prévu ou d'un
                            colis qui est perdu en raison de l'action de tiers, de circonstances imprévues ou de cas de force majeure. PHOTOBIZZ
                            n'est pas non plus responsable des retards ou défauts de livraison dus à la faute du transporteur. Si une commande
                            n'est pas livrée dans les délais prévus, une enquête sera ouverte auprès du transporteur, ce qui peut prendre
                            plusieurs jours. Pendant cette période, aucun remboursement ou réexpédition ne pourra être effectué.
                            <br />
                            5.5. Si malgré toutes les précautions prises certains produits venaient à être endommagés lors du transport, le Client
                            peut contacter l'école. Dans ce cas, PHOTOBIZZ fournira un nouveau produit et le livrera gratuitement à l'école.
                            <br />
                            <br />
                            Article 6 - Droit de rétractation
                            <br />
                            6.1. Tous les produits sont fabriqués sur mesure selon les souhaits et les spécifications du client. Une fois la
                            commande confirmée et le paiement parvenu à PHOTOBIZZ, la commande ne peut être révoquée ou annulée.
                            <br />
                            <br />
                            Article 7 - Responsabilité, indemnités, force majeure et exclusions
                            <br />
                            7.1. A l'exception des engagements explicites pris par PHOTOBIZZ en vertu des présentes conditions générales, la
                            responsabilité de PHOTOBIZZ est limitée à la responsabilité imposée par la loi.
                            <br />
                            7.2. PHOTOBIZZ met tout en œuvre pour que le site web et le webshop soient accessibles et disponibles 7 jours sur 7 et
                            24 heures sur 24. Il est toutefois possible que l'accès au site ou au compte utilisateur soit interrompu en raison
                            d'une maintenance, d'une mise à jour du site ou du réseau ou d'autres causes ou interruptions indépendantes de la
                            volonté de PHOTOBIZZ. PHOTOBIZZ ne peut être tenue responsable des pertes ou dommages résultant de telles
                            interruptions. Elle n'est pas non plus responsable des dommages causés directement ou indirectement par l'utilisation
                            du site ou de la boutique en ligne, par la possibilité de passer une commande en ligne ou par tout autre acte du
                            client ou d'un tiers, qu'il soit causé par une faute ou une négligence, par exemple (de manière non exhaustive) en
                            raison d'un défaut de conception, de matériel ou de fabrication du produit. PHOTOBIZZ n'a pas non plus d'obligation
                            d'information personnelle pour les commandes en ligne.
                            <br />
                            7.3. PHOTOBIZZ n'est pas responsable des dommages matériels ou corporels, des dommages indirects ou des dommages
                            consécutifs subis par le client et/ou ses mandataires pour quelque raison que ce soit, y compris (de manière non
                            limitative) un manquement, une négligence, une erreur ou une faute grave de sa part ou de celle de ses mandataires ou
                            fournisseurs.
                            <br />
                            7.4. Enfin, PHOTOBIZZ ne peut être tenue responsable de retards ou de manquements dans l'exécution du contrat si ces
                            retards ou manquements résultent de faits ou de circonstances indépendants de sa volonté, imprévisibles et inévitables
                            (force majeure), comme par exemple (non exhaustif) : la maladie ou l'indisponibilité des exécutants, le blocage de la
                            distribution ou l'indisponibilité de certaines matières premières ou de certains produits. Si PHOTOBIZZ est touchée
                            par une situation de force majeure, elle en informera immédiatement le client par écrit.
                            <br />
                            7.5. Dans la mesure où une responsabilité serait néanmoins retenue, elle est limitée au remboursement du prix du
                            produit.
                            <br />
                            7.6. Le site web est uniquement destiné à mettre à la disposition du client des informations générales sur les
                            produits et les activités de PHOTOBIZZ. PHOTOBIZZ n'a, en ce qui concerne l'accès, le processus de commande, la
                            livraison ou d'autres services, qu'une obligation de moyens.
                            <br />
                            7.7. PHOTOBIZZ peut inclure des liens vers d'autres sites Internet sur les pages de son site. Toutefois, PHOTOBIZZ
                            n'est pas responsable du contenu de ces sites, ni des produits, services ou autres éléments annoncés par ces sources
                            externes, ni des dommages ou pertes, réels ou supposés, consécutifs à l'utilisation ou à la connexion à ces sites ou
                            sources externes.
                            <br />
                            <br />
                            Article 8 - Propriété intellectuelle
                            <br />
                            8.1. Les designs, concepts, créations, matériaux, illustrations, logos, dessins, photographies, images, méthodes de
                            traitement et de fabrication, descriptions (de produits), données, noms de produits ou d'entreprises, textes, etc.
                            développés et/ou utilisés par PHOTOBIZZ, sous quelque forme que ce soit, sont et restent la propriété de PHOTOBIZZ, de
                            même que toute documentation qui serait mise à la disposition du client ou placée sur la boutique en ligne
                            https://www.dms.photobizz.be/. La publication ou toute autre forme de publication de ces documents n'est possible
                            qu'après accord écrit de PHOTOBIZZ.
                            <br />
                            <br />
                            Article 9 - Traitement des données à caractère personnel
                            <br />
                            9.1. En passant une commande ou en réservant un service, le client donne son accord exprès à PHOTOBIZZ pour le
                            traitement de ses données personnelles dans le but de traiter et d'expédier les produits commandés. En outre, les
                            données fournies peuvent également être utilisées pour la gestion de la clientèle, la newsletter, à des fins
                            publicitaires ou de marketing et pour la collecte d'informations statistiques concernant les clients de PHOTOBIZZ.
                            PHOTOBIZZ renvoie également dans ce contexte à sa déclaration de confidentialité.
                            <br />
                            9.2. Le responsable du traitement, PHOTOBIZZ, respecte le règlement général sur la protection des données du 24 mai
                            2016 relatif à la protection de la vie privée dans le cadre du traitement des données à caractère personnel.
                            Conformément à ce règlement, le Client dispose d'un droit légal d'accès et de rectification de ses données
                            personnelles, ainsi que du droit à l'effacement complet de ses données. A cette fin, le Client, muni d'une preuve
                            d'identité (copie de la carte d'identité), doit adresser une demande écrite, datée et signée à PHOTOBIZZ, dont le
                            siège social est établi à 8780 Oostrozebeke, Stationsstraat 75.
                            <br />
                            9.3. Toutes les données à caractère personnel obtenues et traitées par l'intermédiaire de https://www.photobizz.be/ ou
                            https://www.dms.photobizz.be/ seront traitées de manière confidentielle et ne seront en aucun cas transmises à des
                            tiers.
                            <br />
                            9.4. Comme indiqué à l'article 2.4, PHOTOBIZZ supprime le compte utilisateur et toutes les photos et projets qui y
                            sont stockés, si le compte utilisateur est résilié.
                            <br />
                            <br />
                            Article 10 - Réclamations
                            <br />
                            10.1. Toute plainte doit être adressée directement à l'école. PHOTOBIZZ ne traite que les plaintes communiquées par
                            l'intermédiaire de l'école ou de helpdesk@fjvandereycken.com.
                            <br />
                            <br />
                            Article 11 - Droit applicable et autorités compétentes
                            <br />
                            11.1. Les présentes conditions générales et l'ensemble des relations juridiques entre PHOTOBIZZ et le client sont
                            régies par le droit belge, même si la commande est passée depuis l'étranger. L'application de la Convention de Vienne
                            sur les contrats de vente internationale de marchandises (1980) est expressément exclue.
                            <br />
                            11.2. Le Client accepte la preuve électronique.
                            <br />
                            11.3. Tous les litiges éventuels découlant de ou liés à la relation juridique entre PHOTOBIZZ et le client sont
                            exclusivement réglés par les tribunaux de l'arrondissement judiciaire de Bruxelles.
                            <br />
                            Oostrozebeke, Août 2024
                            <br />
                        </Typography>
                    ) : (
                        ""
                    )}

                    {/* <Typography id="modal-modal-title" variant="h6" component="h2">
                            Terms & Conditions
                        </Typography> */}
                </Box>
            </Modal>

            <Modal
                open={privacyPolicy}
                onClose={closePrivacyPolicy}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className={"modal-style"}>
                    {language === "en" ? (
                        <Typography variant="caption" display={"block"} sx={{ mt: 2 }}>
                            Privacy Policy
                            <br />
                            <br />
                            This Privacy Policy relates to all personal data collected and processed by Photobizz BVBA (hereinafter "Photobizz"),
                            a private limited liability company, with registered office at 8780 Oostrozebeke, Stationsstraat 75, with company
                            number VAT BE0474.962.379, and whose contact person is Mr Bernard Derom (info@photobizz.be).
                            <br />
                            "Photobizz" makes every effort to respect your privacy. If you use this website or otherwise provide us with personal
                            information, you agree to this Privacy Policy.
                            <br />
                            <br />
                            1. What is personal data?
                            <br />
                            Personal data is any data that allows a natural person to be identified or identifiable. This includes contact details
                            such as your name and email address, but also usage data, your IP address, your preferences, financial data, sensitive
                            data,...
                            <br />
                            <br />
                            2. From whom do we collect personal data?
                            <br />
                            "Photobizz" takes class photos and portrait photos on behalf of schools. In doing so, it only holds as personal data
                            the photos themselves, linked to the school and class. Using your personal code, you can create an account on this
                            website. The following data are requested and linked to the photos concerned: <br />
                            - Name
                            <br />
                            - Address
                            <br />
                            - E-mail address
                            <br />
                            - Phone number
                            <br />
                            - Name oldest child
                            <br />
                            - Payment details <br />
                            - Password
                            <br />
                            <br />
                            <br />
                            3. Why do we collect personal data?
                            <br />
                            "Photobizz" collects personal data for the following purposes:
                            <br />
                            In the context of our offered services
                            <br />
                            In the context of our offered services of class photos and selling related products, we process your personal data. By
                            creating a login on our platform, you are considered a customer of "Photobizz". You can modify or delete your data via
                            the settings of your login.
                            <br />
                            "Photobizz" makes every effort to ensure the security of login data and connection and cooperates with a specialised
                            IT company for this purpose. The user must also ensure the safe storage of his/her login data. This processing of
                            personal data is necessary for our agreement with the client.
                            <br />
                            Newsletter A newsletter or commercial promotions can be sent to existing clients under the justified interest, and
                            thus without prior consent of the client, regarding the products offered by "Photobizz". On each newsletter,
                            "Photobizz" provides an option to unsubscribe.
                            <br />
                            Contact form Through the contact form on our website, the personal data are collected, which we consider necessary to
                            meet your request. This processing of personal data finds its legal basis in the consent of the person in question.
                            <br />
                            <br />
                            4. Processors and transfer of data
                            <br />
                            It is possible that your personal data may be processed by a limited number of processors, such as our IT partner,
                            accountant or the photographers who will be taking photos at the schools. They will not process more data than
                            necessary and clear agreements have been made with the processors in accordance with the GDPR regarding the processing
                            of that data. Your data will not be traded, sold, rented or passed on to third parties. In rare cases, "Photobizz" may
                            have to disclose your Personal Data pursuant to a court order or to comply with other mandatory laws or regulations.
                            "Photobizz" will make reasonable efforts to inform you in advance in this regard, unless this is subject to legal
                            restrictions.
                            <br />
                            <br />
                            5. How long will your data be kept?
                            <br />
                            "Photobizz" will never store and process your personal data for longer than necessary in the context of the purposes
                            of the processing
                            <br />
                            <br />
                            6. How are your data protected?
                            <br />
                            "Photobizz" has developed security measures adapted at technical and organisational level to avoid the destruction,
                            loss, falsification, modification, unauthorised access or erroneous notification to third parties of personal data
                            collected as well as any other unauthorised processing of these data. Under no circumstances can "Photobizz" be held
                            liable for any direct or indirect damages resulting from an erroneous or unlawful use by a third party of your
                            personal data. You must at all times comply with the security regulations, including by preventing any unauthorised
                            access to your login and code. You are solely responsible for the use made from the Website of your computer, IP
                            address and of your identification data, as well as for their confidentiality.
                            <br />
                            <br />
                            7. What rights do you have?
                            <br />
                            You have the right to access your stored personal data, as well as our use of this data, free of charge at any time.
                            <br />
                            7.1 Right of rectification, deletion and restriction
                            <br />
                            You are free to communicate or not your personal data to "Photobizz". In addition, you always have the right to
                            request "Photobizz" to correct, supplement or delete your personal data. You acknowledge that in case of refusal of
                            communication or request for deletion of personal data, certain services may not be available. You may also request to
                            restrict the processing of your personal data.
                            <br />
                            7.2 Right of objection
                            <br />
                            You have a right to object to the processing of your personal data for serious and legitimate reasons. In addition,
                            you always have the right to oppose the use of personal data for direct marketing purposes; in such case, you do not
                            even need to provide reasons.
                            <br />
                            7.3 Right to free data transfer
                            <br />
                            You have the right to obtain your personal data that you have provided to “Photobizz” in a structured, common and
                            machine-readable form and/or to have it transferred to other controllers.
                            <br />
                            7.4 Right to withdraw consent
                            <br />
                            To the extent that the processing is based on your prior consent, you have the right to withdraw that consent.
                            <br />
                            7.5 Exercising your rights
                            <br />
                            You can exercise your rights by contacting “Photobizz” for this purpose, either by e-mail to info@photobizz.be, by
                            post to 8780 Oostrozebeke, Stationsstraat 75, provided that you attach a copy of the front of your identity card.
                            <br />
                            7.6 Automated decisions and profiling The processing of your personal data does not include profiling and will not be
                            subject to automated decisions by “Photobizz” either.
                            <br />
                            7.7 Right to lodge a complaint
                            <br />
                            You have the right to lodge a complaint with the Belgian Data Protection Authority (GBA), Drukpersstraat 35, 1000
                            Brussels, Tel +32 (0)2 274 48 00, Fax +32 (0)2 274 48 35, e-mail: contact@apd-gba.be.
                            <br />
                            This is without prejudice to a remedy before a civil court.
                            <br />
                            <br />
                            <br />
                            8. Changes
                            <br />
                            This Privacy Policy may be changed. Any changes will be reported on this page.
                            <br />
                            We advise you to consult this page regularly.
                            <br />
                            Last updated: August 2024
                            <br />
                            <br />
                            COOKIE POLICY
                            <br />
                            WHO?
                            <br />
                            “PHOTOBIZZ”
                            <br />
                            Private Company with Limited Liability
                            <br />
                            Stationsstraat 75
                            <br />
                            8780 Oostrozebeke
                            <br />
                            <br />
                            https://www.photobizz.be
                            <br />
                            e-mail: bd@photobizz.be
                            <br />
                            <br />
                            BTW‐BE0474.962.379
                            <br />
                            <br />
                            WHAT ARE COOKIES?
                            <br />
                            <br />
                            Cookies are small data or text files that are placed on your local computer by websites. Such cookies can have
                            different purposes: for example, there are technical cookies (for example, for language settings), session cookies
                            (temporary cookies) and tracking cookies (cookies that follow and track your behaviour on the website, in order to
                            offer you a more optimal user experience).
                            <br />
                            A cookie contains a unique code that allows your browser to be recognised during a visit to the website or to improve
                            your user experience during repeated visits to the platform.
                            <br />
                            Cookies can be placed by the server of the website you are visiting or by a partner with whom this website
                            collaborates. The server of the website can only read the cookies that it has placed itself. It has no access to other
                            information that is on your computer or mobile device.
                            <br />
                            The content of a cookie usually consists of the name of the server that placed the cookie, an expiration date and a
                            unique numeric code. Cookies generally make the interaction between visitors and the website easier and faster and
                            help visitors navigate between the different parts of the website. Cookies can also be used to personalize the content
                            of the website to the user.
                            <br />
                            More information about the types of cookies can be found at www.allaboutcookies.org.
                            <br />
                            “PHOTOBIZZ” USES THE FOLLOWING COOKIES:
                            <br />
                            <br />
                            1. Necessary cookies
                            <br />
                            These cookies are essential to be able to visit our websites and to be able to use certain parts of them. These
                            cookies allow you, for example, to navigate between the different parts of the website or, for example, to fill in
                            forms and participate in discussions on our platform. If you refuse these cookies, certain parts of the website may
                            not work or may not work optimally.
                            <br />
                            <br />
                            2. Functional cookies
                            <br />
                            The functional cookies are the cookies that facilitate the functioning of our websites and make it more pleasant for
                            the visitor and ensure that you get a more personalized surfing experience. For example, the website uses a
                            registration or a newsletter. These cookies can be used to recognize you if you visit the website again and to save
                            your registration data or to remember your preferences that you have indicated on the website.
                            <br />
                            <br />
                            3. Third party cookies
                            <br />
                            This website does not use third-party cookies:
                            <br />
                            <br />
                            MANAGEMENT OF COOKIES
                            <br />
                            On your first visit to our Website, you will be asked to accept our different types of cookies. You can allow or
                            refuse cookies category by category. You can change the cookie settings for our Website at any time via the hyperlink
                            at the bottom of our website and thus withdraw your consent.
                            <br />
                            You can refuse the installation of these cookies by choosing “Refuse cookies” in the pop-up screen offered for this
                            purpose when you first use the Website.
                            <br />
                            You can refuse the installation of cookies via your browser settings. Disabling cookies may mean that you cannot use
                            certain functionalities of the Website. On the following website you will find the procedure for refusing cookies for
                            the most commonly used browsers: http://www.aboutcookies.org/
                            <br />
                            You can also delete the already installed cookies from your computer or mobile device at any time.
                            <br />
                            If you want to fully experience the website without restrictions, make sure that cookies are enabled in your browser.
                            To enable cookies, you must perform the following actions:
                            <br />
                            <br />
                            For browser – Microsoft Internet Explorer
                            <br />
                            • In Internet Explorer, click on ‘Internet Options’ in the ‘Extra’ menu.
                            <br />
                            • On the ‘Privacy’ tab, move the settings slider to ‘low’ or ‘accept all cookies’ (setting above ‘medium’ disables
                            cookies).
                            <br />
                            • Click on ‘OK’.
                            <br />
                            <br />
                            <br />
                            For browser – Mozilla Firefox
                            <br />
                            • Click on ‘Firefox’ in the top left corner of your browser and then click on ‘Options’.
                            <br />
                            •On the ‘Privacy’ tab, make sure that the ‘Tell websites I do not want to be tracked’ box is not checked.
                            <br />
                            • Click on ‘OK’.
                            <br />
                            <br />
                            <br />
                            For browser – Google Chrome
                            <br />
                            • Click on ‘Tools’ at the top of your browser window and select ‘Options’.
                            <br />
                            • Click on the ‘Under the Hood’ tab, find the ‘Privacy’ section and select the ‘Content settings’ button.
                            <br />
                            • Now select ‘Allow local data to be set’.
                            <br />
                            <br />
                            <br />
                            For browser – Safari
                            <br />
                            • Click on the ‘Cog’ icon at the top of your browser window and select the
                            <br />
                            ‘Preferences’ option.
                            <br />
                            • Click on ‘Security’, then check the option that says: ‘Block third-party and advertising cookies’.
                            <br />
                            Click ‘Save’.
                            <br />
                            <br />
                            <br />
                            If you have any questions or comments about cookies after reading this Cookie Statement, you can always contact us via
                            bd@photobizz.be.
                            <br />
                            <br />
                            Last updated version: August 2024
                            <br />
                        </Typography>
                    ) : (
                        ""
                    )}

                    {language === "nl" ? (
                        <Typography variant="caption" display={"block"} sx={{ mt: 2 }}>
                            Privacy Policy
                            <br />
                            <br />
                            Deze Privacy Policy heeft betrekking op alle persoonsgegevens die worden verzameld en verwerkt door Photobizz BVBA
                            (hierna: “Photobizz”), besloten vennootschap met beperkte aansprakelijkheid, met maatschappelijke zetel te 8780
                            Oostrozebeke, Stationsstraat 75, met ondernemingsnummer BTW BE0474.962.379, en als contactpersoon de heer Bernard
                            Derom (info@photobizz.be).
                            <br />
                            “Photobizz” doet het mogelijke om uw privacy te respecteren. Indien u gebruik maakt van deze website of ons anderszins
                            persoonlijke informatie verstrekt, stemt u in met deze Privacy Policy.
                            <br />
                            <br />
                            1. Wat zijn persoonsgegevens?
                            <br />
                            Persoonsgegevens zijn alle gegevens waardoor een natuurlijk persoon geïdentificeerd kan worden of identificeerbaar is.
                            Hieronder vallen contactgegevens zoals uw naam en e-mailadres, maar ook gebruiksgegevens, uw IP adres, uw voorkeuren,
                            financiële gegevens, gevoelige gegevens,…
                            <br />
                            <br />
                            2. Van wie verzamelen wij persoonsgegevens?
                            <br />
                            “Photobizz” neemt in opdracht van scholen klasfoto’s en portretfoto’s. Hierbij heeft zij enkel als persoonsgegevens de
                            foto’s zelf, gekoppeld aan de school en klas in haar bezit. Aan de hand van uw persoonlijke code kan u een account
                            aanmaken op deze website. Hierbij worden volgende gegevens opgevraagd, en gekoppeld aan de betreffende foto’s: <br />
                            - Naam
                            <br />
                            - Adres
                            <br />
                            - E-mailadres
                            <br />
                            - Telefoonnummer
                            <br />
                            - Naam oudste kind
                            <br />
                            - Betalingsgegevens <br />
                            - Wachtwoord
                            <br />
                            <br />
                            3. Waarom verzamelen wij persoonsgegevens? <br />
                            <br />
                            “Photobizz” verzamelt persoonsgegevens voor volgende doeleinden:
                            <br />
                            <br />
                            In het kader van onze aangeboden diensten
                            <br />
                            In het kader van onze aangeboden diensten van klasfoto’s en het verkopen van aanverwante producten verwerken wij uw
                            persoonsgegevens. Door een login aan te maken op ons platform wordt u als een klant aanzien van “Photobizz”. U kan uw
                            gegevens aanpassen of wissen via de instellingen van uw login. “Photobizz” doet het mogelijke om de beveiliging te
                            garanderen van de login gegevens en verbinding en werkt hiervoor samen met een gespecialiseerde IT-onderneming. De
                            gebruiker dient eveneens in te staan voor de veilige bewaring van zijn/haar login gegevens. Deze verwerking van
                            persoonsgegevens is noodzakelijk voor onze overeenkomst met de cliënt.
                            <br />
                            Nieuwsbrief
                            <br />
                            Naar bestaande klanten kan onder het gerechtvaardigd belang, en dus zonder voorafgaandelijke toestemming van de klant
                            een nieuwsbrief of commerciële promoties worden verstuurd met betrekking tot de aangeboden producten van “Photobizz”.
                            Op iedere nieuwsbrief voorziet “Photobizz” een optie om uit te schrijven.
                            <br />
                            <br />
                            Contactformulier
                            <br />
                            Via het contactformulier op onze website worden de persoonsgegevens verzameld, die wij nodig achten om aan uw vraag
                            tegemoet te komen. Deze verwerking van persoonsgegevens vindt haar rechtsgrond in de toestemming van de persoon in
                            kwestie.
                            <br />
                            <br />
                            4. Verwerkers en doorgifte gegevens
                            <br />
                            <br />
                            Het is mogelijk dat uw persoonsgegevens worden verwerkt door een beperkt aantal verwerkers, zoals onze IT-partner,
                            boekhouder of de fotografen die de foto’s gaan nemen op de scholen. Zij zullen niet meer gegevens verwerken dan nodig
                            en er werd met de verwerkers overeenkomstig de GDPR duidelijke afspraken gemaakt omtrent de verwerking van die
                            gegevens. Uw gegevens worden niet verhandeld, verkocht, verhuurd of doorgegeven aan derden.
                            <br />
                            In zeldzame gevallen kan het voorkomen dat “Photobizz” uw Persoonsgegevens ingevolge een gerechtelijk bevel of om te
                            voldoen aan andere dwingende wet- of regelgeving moet onthullen. “Photobizz” zal in redelijkheid pogen u van tevoren
                            dienaangaande te informeren, tenzij een en ander aan wettelijke beperkingen onderhevig is.
                            <br />
                            <br />
                            5. Hoe lang worden uw gegevens bewaard?
                            <br />
                            “Photobizz” bewaart en verwerkt uw persoonsgegevens nooit langer dan noodzakelijk in het kader van de doeleinden van
                            de verwerking.
                            <br />
                            <br />
                            6. Hoe worden uw gegevens beschermd?
                            <br />
                            “Photobizz” heeft veiligheidsmaatregelen ontwikkeld die aangepast zijn op technisch en organisatorisch vlak, om de
                            vernietiging, het verlies, de vervalsing, de wijziging, de niet-toegestane toegang of de kennisgeving per vergissing
                            aan derden van persoonsgegevens verzameld te vermijden alsook elke andere niet toegestane verwerking van deze
                            gegevens. In geen geval kan “Photobizz” aansprakelijk worden geacht voor enige directe of indirecte schade die
                            voortvloeit uit een foutief of onrechtmatig gebruik door een derde van uw persoonsgegevens. U dient te allen tijde de
                            veiligheidsvoorschriften na te leven, onder andere door elke niet toegestane toegang tot uw login en code te
                            voorkomen. U bent als enige verantwoordelijk voor het gebruik dat wordt gemaakt vanaf de Website van uw computer,
                            IP-adres en van uw identificatiegegevens, alsook voor de vertrouwelijkheid ervan.
                            <br />
                            <br />
                            7. Welke rechten heeft u?
                            <br />
                            U hebt het recht om op elk moment kosteloos kennis te nemen van uw opgeslagen persoonsgegevens, alsook ons gebruik van
                            deze gegevens.
                            <br />
                            7.1 Recht van verbetering, verwijdering en beperking
                            <br />
                            U bent vrij om uw persoonsgegevens al dan niet mee te delen aan “Photobizz”. Daarnaast heeft u steeds het recht om
                            “Photobizz” te verzoeken uw persoonsgegevens te verbeteren, aan te vullen of te verwijderen. U erkent dat bij
                            weigering van mededeling of verzoek tot verwijdering van persoonsgegevens, bepaalde diensten mogelijks niet leverbaar
                            zijn. U mag eveneens vragen om de verwerking van uw persoonsgegevens te beperken.
                            <br />
                            7.2 Recht van verzet
                            <br />
                            U beschikt over een recht van verzet tegen de verwerking van uw persoonsgegevens om ernstige en legitieme redenen.
                            Daarnaast hebt u steeds het recht om u te verzetten tegen het gebruik van persoonsgegevens voor doeleinden van direct
                            marketing; in dergelijk geval hoeft u zelfs geen redenen op te geven.
                            <br />
                            7.3 Recht van vrije gegevensoverdracht
                            <br />
                            U beschikt over het recht om uw persoonsgegevens die door u verstrekt zijn aan “Photobizz” in een gestructureerde,
                            gangbare en machineleesbare vorm te verkrijgen en/of aan andere verantwoordelijken over te laten dragen.
                            <br />
                            7.4 Recht van intrekking van de toestemming
                            <br />
                            Voor zover de verwerking gebaseerd is op uw voorafgaande toestemming, beschikt u over het recht om die toestemming in
                            te trekken.
                            <br />
                            7.5 Uitoefening van uw rechten
                            <br />
                            U kan uw rechten uitoefenen door “Photobizz” daartoe te contacteren, hetzij per e-mail naar info@photobizz.be, per
                            post naar 8780 Oostrozebeke, Stationsstraat 75, mits bijvoeging van een kopie van de voorzijde van uw
                            identiteitskaart.
                            <br />
                            7.6 Automatische beslissingen en profiling De verwerking van uw persoonsgegevens omvat geen profiling en zal door
                            “Photobizz” evenmin aan geautomatiseerde beslissingen worden onderworpen.
                            <br />
                            7.7 Recht om klacht in te dienen
                            <br />
                            U beschikt over het recht om een klacht in te dienen bij de Belgische <br />
                            Gegevensbeschermingsautoriteit (GBA), Drukpersstraat 35, 1000 Brussel, Tel +32 (0)2 274 48 <br />
                            00, Fax +32 (0)2 274 48 35, e-mail: contact@apd-gba.be. <br />
                            Dit laat een voorziening voor een burgerlijke rechtbank onverlet.
                            <br />
                            <br />
                            <br />
                            8. Wijzigingen
                            <br />
                            Deze Privacy Policy kan worden gewijzigd. Elke wijziging zal worden gemeld op deze pagina. <br />
                            Wij adviseren u deze pagina regelmatig raad te plegen. <br />
                            Laats bijgewerkte versie: Augustus 2024
                            <br />
                            <br />
                            <br />
                            COOKIE POLICY
                            <br />
                            <br />
                            WIE?
                            <br />
                            <br />
                            “PHOTOBIZZ”
                            <br />
                            Besloten Vennootschap met Beperkte Aansprakelijkheid
                            <br />
                            Stationsstraat 75
                            <br />
                            8780 Oostrozebeke
                            <br />
                            <br />
                            https://www.photobizz.be
                            <br />
                            e-mail: bd@photobizz.be
                            <br />
                            <br />
                            BTW‐BE0474.962.379
                            <br />
                            <br />
                            WAT ZIJN COOKIES?
                            <br />
                            <br />
                            Cookies zijn kleine data- of tekstbestanden die door websites op je lokale computer worden geplaatst. Dergelijke
                            cookies kunnen verschillende doeleinden hebben: zo heb je technische cookies (bijvoorbeeld bij taalinstellingen),
                            sessiecookies (tijdelijke cookies) en tracking cookies (cookies die je gedrag op de website gaan volgen en bijhouden,
                            om je op die manier een meer optimale gebruikservaring te kunnen aanbieden).
                            <br />
                            Een cookie bevat een unieke code die toelaat om uw browser te herkennen gedurende een bezoek aan de website of uw
                            gebruikerservaring te verbeteren bij herhaalde bezoeken aan het platform.
                            <br />
                            Cookies kunnen geplaatst worden door de server van de website die u bezoekt of door een partner, waarmee deze website
                            samenwerkt. De server van de website kan alleen de cookies lezen die het zelf geplaatst heeft. Hij heeft geen toegang
                            tot andere informatie die zich op uw computer of mobiel apparaat bevindt.
                            <br />
                            De inhoud van een cookie bestaat meestal uit de naam van de server die de cookie geplaatst heeft, een vervaldatum en
                            een unieke cijfercode. Cookies maken de interactie tussen de bezoekers en de website over het algemeen gemakkelijker
                            en sneller en helpen de bezoekers te navigeren tussen de verschillende onderdelen van de website. Cookies kunnen ook
                            gebruikt worden om de inhoud van de website te personaliseren aan de gebruiker.
                            <br />
                            Meer informatie over de soorten cookies kan u vinden op www.allaboutcookies.org.
                            <br />
                            <br />
                            “PHOTOBIZZ” MAAKT GEBRUIK VAN VOLGENDE COOKIES:
                            <br />
                            <br />
                            1. Noodzakelijke cookies
                            <br />
                            Deze cookies zijn onmisbaar om onze websites te kunnen bezoeken en om bepaalde onderdelen ervan te kunnen gebruiken.
                            Deze cookies laten u bijvoorbeeld toe om te navigeren tussen de verschillende onderdelen van de website of
                            bijvoorbeeld om formulieren in te vullen en mee te discussiëren op ons platform. Indien u deze cookies weigert zullen
                            bepaalde onderdelen van de website mogelijk niet of niet optimaal werken.
                            <br />
                            <br />
                            2. Functionele cookies
                            <br />
                            De functionele cookies zijn de cookies die het functioneren van onze websites vergemakkelijken en aangenamer maken
                            voor de bezoeker en er voor zorgen dat u een meer gepersonaliseerde surfervaring krijgt. Zo maakt de website gebruik
                            van een registratie of een nieuwsbrief. Deze cookies kunnen gebruikt worden om u te herkennen indien u de website
                            opnieuw bezoekt en uw registratiegegevens op te slaan of uw voorkeuren die u hebt aangegeven op de website te
                            onthouden.
                            <br />
                            <br />
                            3. Third party cookies
                            <br />
                            Deze website maakt geen gebruik van Third-party cookies:
                            <br />
                            <br />
                            BEHEER VAN COOKIES
                            <br />
                            Bij uw eerste bezoek aan onze Website wordt U gevraagd om onze verschillende soorten cookies te aanvaarden. U kunt
                            categorie per categorie cookies toestaan of weigeren. U kunt te allen tijde de cookie instellingen voor onze Website
                            wijzigen via de hyperlink onderaan onze website en aldus uw toestemming intrekken.
                            <br />
                            U kunt de installatie van deze cookies weigeren door bij het eerste gebruik van de Website in het daartoe aangeboden
                            pop-up scherm te kiezen voor “Cookies weigeren”.
                            <br />
                            Via uw browserinstellingen kunt u de installatie van cookies weigeren. Het uitschakelen van cookies kan betekenen dat
                            U van bepaalde functionaliteiten van de Website geen gebruik kunt maken. Op de volgende website vindt u voor de meest
                            gebruikte browsers de werkwijze om cookies te weigeren: http://www.aboutcookies.org/
                            <br />
                            U kunt ook op elk moment de reeds geïnstalleerde cookies verwijderen van uw computer of mobiel apparaat.
                            <br />
                            Als u de website ten volle wil ervaren zonder beperkingen, zorg ervoor dat cookies zijn ingeschakeld in je browser. Om
                            cookies in te schakelen moet je de volgende handelingen uitvoeren:
                            <br />
                            <br />
                            Bij browser – Microsoft Internet Explorer <br />
                            • In Internet Explorer, klik op ‘Internetopties’ in het menu ‘Extra’.
                            <br />
                            •Op het tabblad ‘Privacy’, verplaats de instellingen- schuifknop naar ‘laag’ of ‘accepteer alle cookies’ (instelling
                            boven ‘medium’ schakelt cookies uit).
                            <br />
                            • Klik op ‘OK’.
                            <br />
                            <br />
                            <br />
                            Bij browser – Mozilla Firefox
                            <br />
                            • Klik op ‘Firefox’ in de linkerbovenhoek van je browser en klik vervolgens op ‘Opties’.
                            <br />
                            •Op het tabblad ‘Privacy’ , zorg ervoor dat de ‘Websites laten weten dat ik niet gevolgd wil worden’ niet is
                            aangevinkt.
                            <br />
                            • Klik op ‘OK’.
                            <br />
                            <br />
                            Bij browser – Google Chrome
                            <br />
                            • Klik op ‘Extra’ bovenaan in uw browservenster en kies ‘Opties’.
                            <br />
                            • Klik op de ‘Under the Hood’ tab, zoek het gedeelte ‘Privacy’ en selecteer de knop ‘Content settings’.
                            <br />
                            • Selecteer nu ‘Allow local data to be set’.
                            <br />
                            <br />
                            Bij browser – Safari
                            <br />
                            • Klik op het pictogram ‘Cog’ bovenaan in uw browservenster en selecteer de ‘Voorkeur’ optie.
                            <br />
                            • Klik op ‘Beveiliging’, vink dan de optie die zegt: ‘Blokkeer cookies van derden en reclame cookies’ aan. Click
                            ‘Save’. <br />
                            <br />
                            Mocht je na het lezen van deze Cookieverklaring toch nog vragen of opmerkingen rond cookies hebben, kan je steeds
                            contact opnemen via bd@photobizz.be.
                            <br />
                            <br />
                            Laatst bijgewerkte versie: Augustus 2024
                            <br />
                        </Typography>
                    ) : (
                        ""
                    )}

                    {language === "fr" ? (
                        <Typography variant="caption" display={"block"} sx={{ mt: 2 }}>
                            Politique de confidentialité
                            <br />
                            <br />
                            La présente politique de protection de la vie privée concerne toutes les données à caractère personnel collectées et
                            traitées par Photobizz BVBA (ci-après "Photobizz"), société privée à responsabilité limitée, dont le siège social est
                            établi à 8780 Oostrozebeke, Stationsstraat 75, avec le numéro d'entreprise TVA BE0474.962.379, et dont la personne de
                            contact est M. Bernard Derom (info@photobizz.be).
                            <br />
                            <br />
                            "Photobizz" s'efforce de respecter votre vie privée. Si vous utilisez ce site web ou si vous nous fournissez des
                            informations personnelles, vous acceptez la présente politique de confidentialité.
                            <br />
                            <br />
                            1. Qu'est-ce qu'une donnée à caractère personnel ?
                            <br />
                            Les données à caractère personnel sont toutes les données qui permettent d'identifier ou de reconnaître une personne
                            physique. Cela comprend les coordonnées telles que votre nom et votre adresse e-mail, mais aussi les données
                            d'utilisation, votre adresse IP, vos préférences, les données financières, les données sensibles,....
                            <br />
                            <br />
                            2. De qui collectons-nous les données à caractère personnel ?
                            <br />
                            "Photobizz" prend des photos de classe et des photos de portrait pour le compte des écoles. Ce faisant, elle ne
                            conserve comme données personnelles que les photos elles-mêmes, liées à l'école et à la classe. A l'aide de votre code
                            personnel, vous pouvez créer un compte sur ce site web. Les données suivantes sont demandées et liées aux photos
                            concernées : <br />
                            - Nom
                            <br />
                            - l'adresse
                            <br />
                            - Adresse électronique
                            <br />
                            - Numéro de téléphone
                            <br />
                            - Nom de l'enfant le plus âgé
                            <br />
                            - Données de paiement <br />
                            - Mot de passe
                            <br />
                            <br />
                            3. Pourquoi collectons-nous des données personnelles ?
                            <br />
                            <br />
                            "Photobizz" collecte des données personnelles pour les raisons suivantes :
                            <br />
                            <br />
                            Dans le cadre de notre offre de services
                            <br />
                            Dans le cadre de notre offre de services de classement de photos et de vente de produits dérivés, nous traitons vos
                            données personnelles. En créant un login sur notre plateforme, vous êtes considéré comme un client de "Photobizz".
                            Vous pouvez modifier ou supprimer vos données via les paramètres de votre login.
                            <br />
                            "Photobizz" met tout en œuvre pour assurer la sécurité des données d'accès et de la connexion et collabore à cet effet
                            avec une société informatique spécialisée. L'utilisateur doit également veiller à ce que ses données de connexion
                            soient conservées en toute sécurité. Ce traitement des données personnelles est nécessaire pour notre accord avec le
                            client.
                            <br />
                            Bulletin d'information
                            <br />
                            Une lettre d'information ou des promotions commerciales peuvent être envoyées aux clients existants dans le cadre de
                            l'intérêt justifié, et donc sans consentement préalable du client, concernant les produits offerts par "Photobizz".
                            Sur chaque newsletter, "Photobizz" fournit une option de désinscription.
                            <br />
                            Formulaire de contact
                            <br />
                            Par le biais du formulaire de contact sur notre site web, nous collectons les données personnelles que nous estimons
                            nécessaires pour répondre à votre demande. Ce traitement de données personnelles trouve sa base juridique dans le
                            consentement de la personne concernée.
                            <br />
                            4. Sous-traitants et transfert de données
                            <br />
                            <br />
                            Il est possible que vos données personnelles soient traitées par un nombre limité de sous-traitants, tels que notre
                            partenaire informatique, notre comptable ou les photographes qui prendront des photos dans les écoles. Ils ne
                            traiteront pas plus de données que nécessaire et des accords clairs ont été conclus avec les sous-traitants
                            conformément au GDPR concernant le traitement de ces données. Vos données ne seront pas échangées, vendues, louées ou
                            transmises à des tiers. Dans de rares cas, "Photobizz" peut être amené à divulguer vos données personnelles en vertu
                            d'une décision de justice ou pour se conformer à d'autres lois ou réglementations obligatoires. "Photobizz" fera des
                            efforts raisonnables pour vous informer à l'avance à cet égard, à moins que cela ne soit soumis à des restrictions
                            légales.
                            <br />
                            <br />
                            5. Combien de temps vos données seront-elles conservées ?
                            <br />
                            "Photobizz" ne conservera et ne traitera jamais vos données personnelles plus longtemps que nécessaire dans le cadre
                            des finalités du traitement.
                            <br />
                            <br />
                            6. Comment vos données sont-elles protégées ?
                            <br />
                            "Photobizz" a développé des mesures de sécurité adaptées au niveau technique et organisationnel pour éviter la
                            destruction, la perte, la falsification, la modification, l'accès non autorisé ou la notification erronée à des tiers
                            des données personnelles collectées ainsi que tout autre traitement non autorisé de ces données. En aucun cas
                            "Photobizz" ne pourra être tenu responsable des dommages directs ou indirects résultant d'une utilisation erronée ou
                            illicite par un tiers de vos données personnelles. Vous devez à tout moment respecter les règles de sécurité,
                            notamment en empêchant tout accès non autorisé à votre login et à votre code. Vous êtes seul responsable de
                            l'utilisation faite à partir du site web de votre ordinateur, de votre adresse IP et de vos données d'identification,
                            ainsi que de leur confidentialité.
                            <br />
                            <br />
                            7. Quels sont vos droits ?
                            <br />
                            Vous avez le droit d'accéder gratuitement et à tout moment à vos données personnelles enregistrées ainsi qu'à
                            l'utilisation que nous en faisons.
                            <br />
                            7.1 Droit de rectification, d'effacement et de limitation
                            <br />
                            Vous êtes libre de communiquer ou non vos données personnelles à "Photobizz". En outre, vous avez toujours le droit de
                            demander à "Photobizz" de corriger, compléter ou supprimer vos données personnelles. Vous reconnaissez qu'en cas de
                            refus de communication ou de demande de suppression des données personnelles, certains services peuvent ne pas être
                            disponibles. Vous pouvez également demander la limitation du traitement de vos données personnelles.
                            <br />
                            7.2 Droit d'opposition
                            <br />
                            Vous avez le droit de vous opposer au traitement de vos données personnelles pour des raisons sérieuses et légitimes.
                            En outre, vous avez toujours le droit de vous opposer à l'utilisation de données à caractère personnel à des fins de
                            marketing direct ; dans ce cas, vous n'avez même pas besoin de fournir de raisons.
                            <br />
                            7.3 Droit au transfert gratuit des données
                            <br />
                            Vous avez le droit d'obtenir les données personnelles que vous avez fournies à "Photobizz" dans un format structuré,
                            commun et lisible par machine et/ou de les faire transférer à d'autres parties responsables.
                            <br />
                            7.4 Droit de retirer son consentement
                            <br />
                            Dans la mesure où le traitement est fondé sur votre consentement préalable, vous avez le droit de retirer ce
                            consentement.
                            <br />
                            7.5 Exercice de vos droits
                            <br />
                            Vous pouvez exercer vos droits en contactant "Photobizz" à cet effet, soit par e-mail à info@photobizz.be, soit par
                            courrier à 8780 Oostrozebeke, Stationsstraat 75, à condition de joindre une copie du recto de votre carte d'identité.
                            <br />
                            7.6 Décisions automatisées et profilage Le traitement de vos données personnelles n'inclut pas le profilage et
                            "Photobizz" ne vous soumettra pas à des décisions automatisées.
                            <br />
                            <br />
                            8. Modifications
                            <br />
                            Cette politique de confidentialité est susceptible de changer. Tout changement sera signalé sur cette page. Nous vous
                            conseillons de consulter cette page régulièrement. Dernière version mise à jour : Août 2024
                            <br />
                            <br />
                            POLITIQUE EN MATIÈRE DE COOKIES
                            <br />
                            <br />
                            QUI ?
                            <br />
                            <br />
                            « PHOTOBIZZ »<br />
                            Société Privée à Responsabilité Limitée
                            <br />
                            Rue des Stations 75
                            <br />
                            8780 Oostrozebeke
                            <br />
                            <br />
                            https://www.photobizz.be
                            <br />
                            email: bd@photobizz.be
                            <br />
                            <br />
                            TVA‐BE0474.962.379
                            <br />
                            <br />
                            QUE SONT LES COOKIES ?<br />
                            <br />
                            Les cookies sont de petits fichiers de données ou de texte que les sites Web placent sur votre ordinateur local. Ces
                            cookies peuvent avoir différentes finalités : par exemple, vous disposez de cookies techniques (par exemple pour les
                            paramètres de langue), de cookies de session (cookies temporaires) et de cookies de suivi (cookies qui suivent et
                            tracent votre comportement sur le site Internet, afin de vous fournir une expérience utilisateur plus optimale).
                            <br />
                            Un cookie contient un code unique qui permet de reconnaître votre navigateur lors d'une visite sur le site Internet ou
                            d'améliorer votre expérience utilisateur lors de visites répétées sur la plateforme.
                            <br />
                            Des cookies peuvent être déposés par le serveur du site Internet que vous visitez ou par un partenaire avec lequel ce
                            site Internet collabore. Le serveur du site Internet ne peut lire que les cookies qu'il a lui-même déposés. Il n’a
                            accès à aucune autre information située sur votre ordinateur ou appareil mobile.
                            <br />
                            1. Cookies nécessaires
                            <br />
                            Ces cookies sont indispensables pour visiter nos sites Internet et en utiliser certaines parties. Ces cookies vous
                            permettent par exemple de naviguer entre les différentes parties du site internet ou par exemple de remplir des
                            formulaires et de participer aux discussions sur notre plateforme. Si vous refusez ces cookies, certaines parties du
                            site Internet pourraient ne pas fonctionner ou ne pas fonctionner de manière optimale.
                            <br />
                            2. Cookies fonctionnels
                            <br />
                            Les cookies fonctionnels sont les cookies qui facilitent le fonctionnement de nos sites Web, les rendent plus
                            agréables pour le visiteur et garantissent une expérience de navigation plus personnalisée. Par exemple, le site Web
                            utilise une inscription ou une newsletter. Ces cookies peuvent être utilisés pour vous reconnaître si vous visitez à
                            nouveau le site Web et stocker vos données d'enregistrement ou mémoriser vos préférences que vous avez indiquées sur
                            le site Web.
                            <br />
                            3. Cookies tiers
                            <br />
                            Ce site Web n'utilise pas de cookies tiers :
                            <br />
                            <br />
                            GESTION DES COOKIES
                            <br />
                            Lors de votre première visite sur notre Site Internet, il vous sera demandé d'accepter nos différents types de
                            cookies. Vous pouvez autoriser ou refuser les cookies catégorie par catégorie. Vous pouvez à tout moment modifier les
                            paramètres des cookies de notre site Internet via le lien hypertexte situé en bas de notre site Internet et ainsi
                            retirer votre consentement.
                            <br />
                            Vous pouvez refuser l'installation de ces cookies en choisissant « Refuser les cookies » dans la fenêtre pop-up lors
                            de votre première utilisation du Site.
                            <br />
                            Vous pouvez refuser l'installation de cookies via les paramètres de votre navigateur. La désactivation des cookies
                            peut signifier que vous ne pouvez pas utiliser certaines fonctionnalités du site Web. Sur le site suivant vous
                            trouverez la méthode pour refuser les cookies pour les navigateurs les plus couramment utilisés :
                            http://www.aboutcookies.org/
                            <br />
                            Vous pouvez également à tout moment supprimer les cookies déjà installés de votre ordinateur ou appareil mobile.
                            <br />
                            Si vous souhaitez profiter pleinement du site Web sans limitations, assurez-vous que les cookies sont activés dans
                            votre navigateur. Pour activer les cookies, vous devez effectuer les actions suivantes :
                            <br />
                            Par navigateur – Microsoft Internet Explorer
                            <br />
                            • Dans Internet Explorer, cliquez sur « Options Internet » dans le menu « Outils ».
                            <br />
                            • Dans l'onglet « Confidentialité », déplacez le curseur des paramètres sur « faible » ou « accepter tous les cookies
                            » (le paramètre au-dessus de « moyen » désactive les cookies).
                            <br />
                            • Cliquez sur 'OK'.
                            <br />
                            <br />
                            Par navigateur – Mozilla Firefox
                            <br />
                            • Cliquez sur « Firefox » dans le coin supérieur gauche de votre navigateur puis cliquez sur « Options ».
                            <br />
                            •Dans l'onglet « Confidentialité », assurez-vous que « Dire aux sites Web que je ne veux pas être suivi » n'est pas
                            coché.
                            <br />
                            • Cliquez sur 'OK'.
                            <br />
                            <br />
                            Par navigateur – Google Chrome
                            <br />
                            • Cliquez sur « Outils » en haut de la fenêtre de votre navigateur et choisissez « Options ».
                            <br />
                            • Cliquez sur l'onglet « Sous le capot », recherchez la section « Confidentialité » et sélectionnez le bouton «
                            Paramètres de contenu ».
                            <br />
                            • Sélectionnez maintenant « Autoriser la définition des données locales ».
                            <br />
                            <br />
                            Dans le navigateur – Safari
                            <br />
                            • Cliquez sur l'icône « Cog » en haut de la fenêtre de votre navigateur et sélectionnez le
                            <br />
                            Option « préférée ».
                            <br />
                            • Cliquez sur « Sécurité », puis cochez l'option qui dit : « Bloquer les cookies tiers et publicitaires ».
                            <br />
                            Cliquez sur « Enregistrer ».
                            <br />
                            <br />
                            Si vous avez encore des questions ou des commentaires sur les cookies après avoir lu cette déclaration relative aux
                            cookies, vous pouvez toujours nous contacter via bd@photobizz.be.
                            <br />
                            <br />
                            Dernière version mise à jour : Août 2024
                            <br />
                        </Typography>
                    ) : (
                        ""
                    )}
                </Box>
            </Modal>
        </>
    );
};

function SlideTransition(props: SlideProps) {
    return <Slide {...props} direction="up" />;
}

export default Login;
