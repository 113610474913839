import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
// import { AuthProvider } from "./context/AuthProvider";
// import { CompanyProvider } from "./context/CompanyProvider";
// import { DictionaryProvider } from "./context/DictionaryProvider";
import { LanguageProvider } from './context/LanguageProvider';
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import "@fontsource/montserrat"
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import { AccountProvider } from "./context/AccountProvider";
import { PictureProvider } from "./context/PictureProvider";
import { OrderProvider } from "./context/OrderProvider";
import { EnvironmentProvider } from "./context/EnvironmentProvider";
import { ProductsProvider } from "./context/ProductsProvider";
//import { useNavigate } from "react-router-dom";useNavigate
// Pedro https://www.youtube.com/watch?v=UjHT_NKR_gU

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <LanguageProvider>
          <AccountProvider>
            <PictureProvider>
              <ProductsProvider>
                <OrderProvider>
                  <EnvironmentProvider>
                    <Routes>
                      <Route path="/*" element={<App />} />
                    </Routes>
                  </EnvironmentProvider>
                </OrderProvider>
              </ProductsProvider>
            </PictureProvider>
          </AccountProvider>
        </LanguageProvider>
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);


