import { createContext, useState, useEffect } from "react";

const PictureContext = createContext({});

export const PictureProvider = ({ children }) => {

    const [picture, setPicture] = useState(sessionStorage.getItem("session_picture") === null ? {} : JSON.parse(sessionStorage.getItem("session_picture")));

    useEffect(() => {
        sessionStorage.setItem("session_picture", JSON.stringify(picture));
    }, [picture]);


    return (
        <PictureContext.Provider value={{ picture, setPicture }}>
            {children}
        </PictureContext.Provider>
    )
}

export default PictureContext;