

import axios from "../api/axiosPhotobizz";

import useLanguageContext from "../hooks/useLanguageContext";
import useAccount from "../hooks/useAccount";
import usePicture from "../hooks/usePicture";
import useOrder from "../hooks/useOrder";
import useEnvironment from "../hooks/useEnvironment";
import useProducts from "../hooks/useProducts";

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import React, { useState, useEffect, useRef } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import { Box, Typography, Stack, Grid, Button, Card, CardMedia, CardContent, TextField } from "@mui/material";
import { Paper, IconButton } from '@mui/material'

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Carousel from 'react-material-ui-carousel';

import Skeleton from '@mui/material/Skeleton';

import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import ZoomInIcon from '@mui/icons-material/ZoomIn';

import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Slide, { SlideProps } from "@mui/material/Slide";

import WebsiteDict from "../data/WebsiteDict";
import ZoomModal from "../components/ZoomModal";

const WS_Events = () => {


    //   events = [
    //   {
    //     ID: "62c1d48d-6c26-4420-a2f5-5bbfb7ed36bf",
    //     datum_opname: "2021-09-05T00:00:00",
    //     naam: "VBS De Vliegeraar",
    //     postnr: "8710",
    //     lokal: "Sint-Baafs-Vijve",
    //     display: " 05/09/2021",
    //     eventSessions: [
    //       {
    //         Event_PictureFilter: "COMMUNIE",
    //       },
    //       {
    //         Event_PictureFilter: "DIENST",
    //       },
    //       {
    //         Event_PictureFilter: "FAMILIE",
    //       },
    //       {
    //         Event_PictureFilter: "FAMILIES",
    //       },
    //       {
    //         Event_PictureFilter: "GROEP",
    //       },
    //       {
    //         Event_PictureFilter: "PORTRET",
    //       },
    //       {
    //         Event_PictureFilter: "SFEER",
    //       },
    //     ],
    //   },
    // ] 




    const { language, setLanguage } = useLanguageContext();
    const { account, setAccount } = useAccount();
    const { picture, setPicture } = usePicture();
    const { order, setOrder } = useOrder();
    const { environment, setEnvironment } = useEnvironment();
    const { products, setProducts } = useProducts();

    const [events, setEvents] = useState([]);
    const [selectedEvent_ID, setSelectedEvent_ID] = useState("");
    const [selectedEventPictureFilter, setSelectedEventPictureFilter] = useState("");
    const [eventPictures, setEventPictures] = useState([]);
    const [selectedPhotos, setSelectedPhotos] = useState([]);


    const navigate = useNavigate();
    const [dict, setDict] = useState(WebsiteDict);


    const [openMessage, setOpenMessage] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [severity, setSeverity] = useState("success");

    const [loading, setLoading] = useState(false);
    const [loadingPictures, setLoadingPictures] = useState(false);
    const [openZoomModal, setOpenZoomModal] = useState(false);
    const [zoomedPhoto, setZoomedPhoto] = useState(null);


    useEffect(() => {
        setDict(WebsiteDict);
    }, [WebsiteDict]);

    useEffect(() => {

        if (events === null || events === undefined || Object.keys(events).length === 0) {
            getEvents();
        }
        setEnvironment({ ...environment, selectedView: "events" });

    }, []);

    async function getEvents() {

        fetchEvents();
    }

    async function fetchEvents() {
        //Account/GetEvents/{{Session_ID}}/{{Account_ID}}
        var _events = [];

        try {
            var sessAcc = account.Session.Session_ID + "/" + account.Session.Account_ID;

            const response = await axios.get("/Account/GetEvents/" + sessAcc);
            if (response.status === 200) {
                _events = response.data.Events;

                //for each event in events, fetch the eventSessions

                for (let i = 0; i < _events.length; i++) {
                    let event = _events[i];
                    let _sess = await fetchEventSessions(event.ID);
                    event.eventSessions = _sess;
                };

                setEvents(_events);

            }
            else {
                setSnackbarMessage(dict["WS_Messages"]["Loading events failed."][language]);
                setSeverity("error");
                setOpenMessage(true);
            }
        } catch (error) {
            console.log(error);
            setSnackbarMessage(dict["WS_Messages"]["Loading events failed."][language]);
            setSeverity("error");
            setOpenMessage(true);
        }
    }

    async function fetchEventSessions(eventID) {

        //Picture/GetPicturesEvent/{Session_ID}/{Account_ID}/{eventID}/get_all_filters/1/1

        try {
            var sessAcc = account.Session.Session_ID + "/" + account.Session.Account_ID;

            const response = await axios.get("/Picture/GetPicturesEvent/" + sessAcc + "/" + eventID + "/get_all_filters/1/1");
            if (response.status === 200) {
                return response.data.Event_PictureFilter;
            }
            else {
                setSnackbarMessage(dict["WS_Messages"]["Loading event sessions failed."][language]);
                setSeverity("error");
                setOpenMessage(true);
            }
        } catch (error) {
            console.log(error);
            setSnackbarMessage(dict["WS_Messages"]["Loading event sessions failed."][language]);
            setSeverity("error");
            setOpenMessage(true);
        }
    }





    async function fetchPhotos(_PictureFilter) {

        try {

            //Picture/GetPicturesEvent/{{Session_ID}}/{{Account_ID}}/selectedEvent.ID/selectedEventPictureFilter/1/1

            var sessAcc = account.Session.Session_ID + "/" + account.Session.Account_ID;

            const response_temp = await axios.get("/Picture/GetPicturesEvent/" + sessAcc + "/" + selectedEvent_ID + "/" + _PictureFilter + "/0/1");

            const response = await axios.get("/Picture/GetPicturesEvent/" + sessAcc + "/" + selectedEvent_ID + "/" + _PictureFilter + "/" + response_temp.data.Aantal_Fotos + "/1");

            if (response.status === 200) {

                //if one of the pictures has a property 'ArrImage' and it is bigger that 50000 then reformat it to become less than 50000
                // response.data.Pictures.forEach(photo => {
                //     if (photo.ArrImage && photo.ArrImage.length > 50000) {
                //         let arr = photo.ArrImage;
                //         let newarr = arr.slice(0, 50000);
                //         photo.ArrImage = newarr;
                //     }
                // });

                //if one of the pictures has a property 'ArrImage' empty then remove the picture from the array
                let _cleanPictures = response.data.Pictures.filter(photo => photo.ArrImage !== null);

                //if a picture belongs in the picture.Pictures array (same Fotomap_nr and Foto_file) then add it to the selectedPhotos array
                let _selectedPhotos = [];
                _cleanPictures.forEach(photo => {
                    //for each picture in the picture.Pictures array
                    picture.Pictures.forEach(p => {
                        //if the picture belongs in the picture.Pictures array then add it to the selectedPhotos array
                        if (photo.Fotomap_nr === p.Fotomap_nr && photo.Foto_file === p.Foto_file) {
                            _selectedPhotos.push(p.Foto);
                        }

                    });
                    setSelectedPhotos(_selectedPhotos);
                }
                );

                setEventPictures(_cleanPictures);
                //sessionStorage.setItem("session_picture", JSON.stringify(response.data));

            }
            else {
                setSnackbarMessage(dict["WS_Messages"]["Login failed."][language]);
                setSeverity("error");
                setOpenMessage(true);
            }
        } catch (error) {
            console.log(error);
            setSnackbarMessage(dict["WS_Messages"]["Login failed."][language]);
            setSeverity("error");
            setOpenMessage(true);
        }
        setLoadingPictures(false);

    }


    useEffect(() => {

    }, []);







    function CarouselItemLandscapeWide(props) {
        return (
            <Box
                component="img"
                sx={{ width: 450 }}
                alt="Photobizz image"
                src={props.item.name}
                m={1}
            />
        )
    }
    // =================== Accordion ===================


    async function zoomPhoto(photo) {
        console.log(photo);

        //fetch zoomed image
        async function fetchZoomPhoto() {

            try {

                var sessAcc = account.Session.Session_ID + "/" + account.Session.Account_ID;

                let filenameWithoutExtension = photo.Foto_file.split(".")[0];
                filenameWithoutExtension = photo.Foto_file;

                // let str = "/Picture/GetPictureZoom/" + sessAcc + "/" + filenameWithoutExtension + "/" + photo.Fotomap_nr

                // var sessAcc = account.Session.Session_ID + "/" + account.Session.Account_ID;

                const response = await axios.get("/Picture/GetPictureZoom/" + sessAcc + "/" + filenameWithoutExtension + "/" + photo.Fotomap_nr);

                // const response = await axios.get(str);
                if (response.status === 200) {
                    // setPicture(response.data);
                    // setSnackbarMessage(dict["WS_Messages"]["Succesfully logged in."][language]);
                    // setSeverity("success");
                    // setOpenMessage(true);
                    // setArrayYears(findYears(response.data.Pictures));
                    setOpenZoomModal(true);
                    console.log(response.data);
                }
                else {

                    setSnackbarMessage(dict["WS_Messages"]["Unable to get zoomed photo."][language]);
                    setSeverity("error");
                    setOpenMessage(true);
                }
            } catch (error) {

                console.log(error);
                setSnackbarMessage(dict["WS_Messages"]["Unable to get zoomed photo."][language]);
                setSeverity("error");
                setOpenMessage(true);

            }
            setLoadingPictures(false);

        }

        await fetchZoomPhoto();

    }



    const handle_event_change = (event) => {
        console.log(event.target.value);
        setSelectedEvent_ID(event.target.value);
    }

    const handle_event_session_change = (event) => {
        setLoadingPictures(true);
        console.log(event.target.value);
        setSelectedEventPictureFilter(event.target.value);
        fetchPhotos(event.target.value);

    }










    function formatDate(dateString) {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // January is 0!
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    }

    const handleSelectPhoto = async (event, photo) => {
        console.log(event.target.checked);
        console.log(photo);

        //add or remove photo from selectedPhotos (selectedPhotos is an array like [ photos1.Foto, photo2.Foto, photo3.Foto, ...])
        //if photo is not in selectedPhotos then add it
        //if photo is in selectedPhotos then remove it
        if (event.target.checked) {
            let add_response = await addEventPhotoToMyPictures(photo);
            if (add_response) {
                setSelectedPhotos([...selectedPhotos, photo.Foto]);
                setEnvironment({ ...environment, triggerReloadMyPictures: true });

                //if the photo belongs in the picture.Pictures array (same Fotomap_nr and Foto_file) then add triggerReloadMyPyctures in environment and set it to true
                setEnvironment({ ...environment, triggerReloadMyPictures: true });

                //add the photo to picture.Pictures array
                setPicture({ ...picture, Pictures: [...picture.Pictures, photo] });

            }
        }
        else {
            //remove photo from selectedPhotos
            let remove_response = await removeEventPhotoFromMyPictures(photo);
            if (remove_response) {
                setSelectedPhotos(selectedPhotos.filter(item => item !== photo.Foto));
                //if the photo belongs in the picture.Pictures array (same Fotomap_nr and Foto_file) then remove it from picture.Pictures array
                setPicture({ ...picture, Pictures: picture.Pictures.filter(item => item.Foto !== photo.Foto) });

            }
        }



    }

    async function addEventPhotoToMyPictures(photo) {
        // visual basic body example is
        // _event_picture.Fotomap_nr = p.Fotomap_nr
        // _event_picture.Foto_file = p.Foto_file
        // _event_picture.Flyer_ID = New Guid(mySelectedEvent.ID)
        // _event_picture.Session = myResponse_Account.Session

        var body = {
            Fotomap_nr: photo.Fotomap_nr,
            Foto_file: photo.Foto_file,
            Flyer_ID: selectedEvent_ID,
            Session: account.Session
        }

        try {
            const response = await axios.post("/Picture/PostAddEventPicture", body);
            if (response.status === 200) {
                setSnackbarMessage(dict["WS_Messages"]["Photo added to My Pictures."][language]);
                setSeverity("success");
                setOpenMessage(true);
                return true;
            }
            else {
                setSnackbarMessage(dict["WS_Messages"]["Adding photo to My Pictures failed."][language]);
                setSeverity("error");
                setOpenMessage(true);
                return false;
            }
        } catch (error) {
            console.log(error);
            setSnackbarMessage(dict["WS_Messages"]["Adding photo to My Pictures failed."][language]);
            setSeverity("error");
            setOpenMessage(true);
            return false;
        }
    }

    async function removeEventPhotoFromMyPictures(photo) {
        // visual basic body example is
        // _event_picture.Fotomap_nr = p.Fotomap_nr
        // _event_picture.Foto_file = p.Foto_file
        // _event_picture.Flyer_ID = New Guid(mySelectedEvent.ID)
        // _event_picture.Session = myResponse_Account.Session

        var body = {
            Fotomap_nr: photo.Fotomap_nr,
            Foto_file: photo.Foto_file,
            Flyer_ID: selectedEvent_ID,
            Session: account.Session
        }

        try {
            const response = await axios.delete("/Picture/DeleteEventPicture", {
                data: body, // Axios expects the request body in the `data` property for DELETE requests
                headers: { 'Content-Type': 'application/json' } // Explicitly set the Content-Type header
            });
            if (response.status === 200) {
                setSnackbarMessage(dict["WS_Messages"]["Photo removed from My Pictures."][language]);
                setSeverity("success");
                setOpenMessage(true);
                return true;
            }
            else {
                setSnackbarMessage(dict["WS_Messages"]["Removing photo from My Pictures failed."][language]);
                setSeverity("error");
                setOpenMessage(true);
                return false;
            }
        } catch (error) {
            console.log(error);
            setSnackbarMessage(dict["WS_Messages"]["Removing photo from My Pictures failed."][language]);
            setSeverity("error");
            setOpenMessage(true);
            return false;
        }
    }




    const onCloseZoomModal = () => {
        setOpenZoomModal(false);
        setZoomedPhoto(null);
    }

    async function zoomPhoto(photo) {
        console.log(photo);

        //fetch zoomed image
        async function fetchZoomPhoto() {

            try {

                var sessAcc = account.Session.Session_ID + "/" + account.Session.Account_ID;

                let filenameWithoutExtension = photo.Foto_file.split(".")[0];
                filenameWithoutExtension = photo.Foto_file;

                // let str = "/Picture/GetPictureZoom/" + sessAcc + "/" + filenameWithoutExtension + "/" + photo.Fotomap_nr

                // var sessAcc = account.Session.Session_ID + "/" + account.Session.Account_ID;

                const response = await axios.get("/Picture/GetPictureZoom/" + sessAcc + "/" + photo.Foto_file + "/" + photo.Fotomap_nr);

                // const response = await axios.get(str);
                if (response.status === 200) {
                    setZoomedPhoto(response.data);

                    setOpenZoomModal(true);
                    console.log(response.data);
                }
                else {

                    setSnackbarMessage(dict["WS_Messages"]["Unable to get zoomed photo."][language]);
                    setSeverity("error");
                    setOpenMessage(true);
                }
            } catch (error) {

                console.log(error);
                setSnackbarMessage(dict["WS_Messages"]["Unable to get zoomed photo."][language]);
                setSeverity("error");
                setOpenMessage(true);

            }
            setLoadingPictures(false);

        }

        await fetchZoomPhoto();

    }





























    return (
        <>
            <Stack spacing={3} m={3}>

                {/* grid with two dropdown boxes 'Event' and 'Event_PictureFilter' . When i change event then the second dropdown should show Event_PictureFilter of that event  */}
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6} mt={2} >
                        <FormControl fullWidth>
                            <InputLabel id="event-label">{dict["WS_Labels"]["School_name_and_Year_of_Event"][language]}</InputLabel>
                            <Select
                                labelId="event-label"
                                id="event"
                                value={selectedEvent_ID}
                                onChange={handle_event_change}
                                label={dict["WS_Labels"]["School_name_and_Year_of_Event"][language]}
                            >
                                {events.map((event) => (
                                    <MenuItem key={event.ID} value={event.ID}>
                                        {/* convert event.datum_opname from '2021-09-05T00:00:00' format to 'dd/mm/yyyy' */}
                                        {event.naam} {formatDate(event.datum_opname)}

                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} mt={2} >
                        <FormControl fullWidth>
                            <InputLabel id="event-picture-filter-label">{dict["WS_Labels"]["Foto_Session_of_Event"][language]}</InputLabel>
                            {/* select that shows Event_PictureFilter of the selected event */}
                            <Select
                                labelId="event-picture-filter-label"
                                id="event-picture-filter"
                                value={selectedEventPictureFilter}
                                onChange={handle_event_session_change}
                                label={dict["WS_Labels"]["Foto_Session_of_Event"][language]}
                            >
                                {events.map((event) => (
                                    event.eventSessions.map((session) => (
                                        <MenuItem key={session.Event_PictureFilter} value={session.Event_PictureFilter}>
                                            {session.Event_PictureFilter}
                                        </MenuItem>
                                    ))
                                ))}
                            </Select>
                        </FormControl>

                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    {(loadingPictures ?

                        <>
                            <Grid item xs={12} md={12} mt={2} >

                                <Typography align="left" color={"#023047"} fontWeight={600} variant="h6">{dict["WS_Messages"]["Loading..."][language]}</Typography>

                                <Box sx={{ pt: 0.5 }}>
                                    <Skeleton variant="rectangular" width={"100%"} height={60} />
                                </Box>

                                <Box sx={{ display: 'flex', gap: 2 }}>
                                    <Box sx={{ pt: 0.5 }}>
                                        <Skeleton variant="rectangular" width={210} height={118} />
                                        <Skeleton width="60%" />
                                        <Skeleton width="100%" />
                                    </Box>
                                    <Box sx={{ pt: 0.5 }}>
                                        <Skeleton variant="rectangular" width={210} height={118} />
                                        <Skeleton width="40%" />
                                        <Skeleton width="80%" />
                                    </Box>
                                    <Box sx={{ pt: 0.5 }}>
                                        <Skeleton variant="rectangular" width={210} height={118} />
                                        <Skeleton width="80%" />
                                        <Skeleton width="50%" />
                                    </Box>
                                </Box>

                                <Box sx={{ pt: 0.5 }}>
                                    <Skeleton variant="rectangular" width={"100%"} height={60} />
                                </Box>

                                <Box sx={{ pt: 0.5 }}>
                                    <Skeleton variant="rectangular" width={"100%"} height={60} />
                                </Box>

                            </Grid>
                        </> : null)
                    }

                </Grid>

                <Grid container spacing={2}>
                    {!loadingPictures && eventPictures.length > 0 ? eventPictures.map((photo, index) => (
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3} mt={2} key={photo.Foto} >
                            <Card sx={{ padding: "10px", width: 280, border: "none", boxShadow: "none", backgroundColor: "transparent" }}>
                                {/* <CardMedia
                                    key={index}
                                    className="object_fit_contain"
                                    component="img"
                                    height="200"

                                    id={//if photo.Foto_file is the same as environment.Picture.Foto_file then scroll to that position
                                        photo?.Foto_file === environment?.Picture?.Foto_file ? "scrollRef" : null
                                    }
                                    src={
                                        photo.ArrImage
                                            ? "data:image/jpeg;base64," + photo.ArrImage.toString()
                                            : require("../images/no_image_available.jpg")
                                    }
                                    // image={`${item.img}?w=200&fit=crop&auto=format`}
                                    alt="event photo"
                                    // onClick={() => photoSelected(photo)}
                                    onMouseEnter={() => {
                                        // mouse from pointer to hand
                                        document.body.style.cursor = "pointer";
                                    }
                                    }
                                    onMouseLeave={() => {
                                        // mouse from hand to pointer
                                        document.body.style.cursor = "default";
                                    }
                                    }
                                /> */}

                                <Box
                                    component="img"
                                    sx={{
                                        height: 190,
                                        borderRadius: 3,

                                    }}
                                    alt="The house from the offer."
                                    src={
                                        photo.ArrImage
                                            ? "data:image/jpeg;base64," + photo.ArrImage.toString()
                                            : require("../images/no_image_available.jpg")
                                    }
                                />
                                <CardContent sx={{ height: "1px" }} >

                                    {/* insert a check box */}
                                    <Box sx={{
                                        position: 'relative', right: '-85px', top: '-65px',
                                        backgroundColor: 'rgba(128, 128, 128, .5)', width: 90, borderRadius: 5
                                    }}>
                                        <Checkbox
                                            checked={selectedPhotos.includes(photo.Foto)}

                                            sx={{
                                                color: 'secondary', // Color of the border (unchecked state)
                                                backgroundColor: 'rgba(0, 128, 0, 0.04)', // Gray background when unchecked
                                                '&.Mui-checked': {
                                                    color: '#219EBC', // Color when checked
                                                    '&:hover': {
                                                        backgroundColor: 'rgba(0, 128, 0, 0.04)', // Light green background on hover when checked
                                                    }
                                                },
                                                '&:hover': {
                                                    backgroundColor: 'rgba(128, 128, 128, 0.04)', // Light gray background on hover when unchecked
                                                }
                                            }}
                                            onChange={(e) => handleSelectPhoto(e, photo)}
                                            name="selectedPhoto"
                                            color="primary"
                                        />

                                        <IconButton color="secondary"
                                            onClick={() => zoomPhoto(photo)}
                                            aria-label="zoom"
                                        // disabled={!(photo.Srt_Foto === 3 || photo.Srt_Foto === 4)}
                                        >
                                            <ZoomInIcon />
                                        </IconButton>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    )) : null}
                </Grid>












                {openZoomModal ? <ZoomModal open={openZoomModal} onClose={() => { onCloseZoomModal() }} imageUrl={zoomPhoto && zoomedPhoto.ArrImage} /> : null}


                < Snackbar
                    open={openMessage}
                    onClose={() => { setOpenMessage(false); }}
                    TransitionComponent={SlideTransition}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center", }}
                    key="Snackbar"
                >
                    <Alert
                        severity={severity}
                        sx={{ marginTop: 2 }}
                        onClose={() => {
                            setOpenMessage(false);
                        }}
                    >
                        {snackbarMessage}
                    </Alert>
                </Snackbar>


            </Stack >
        </>
    );
};

function SlideTransition(props: SlideProps) {
    return <Slide {...props} direction="up" />;
}

export default WS_Events;
