import axios from "../api/axiosPhotobizz";

import React, { useState, useEffect, useRef } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import { Box, Typography, Stack, Grid, Button, Card, CardMedia, CardContent, TextField } from "@mui/material";
import { Paper, IconButton } from "@mui/material";

import useLanguageContext from "../hooks/useLanguageContext";
import useAccount from "../hooks/useAccount";
import usePicture from "../hooks/usePicture";
import useOrder from "../hooks/useOrder";
import useEnvironment from "../hooks/useEnvironment";
import useProducts from "../hooks/useProducts";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Carousel from "react-material-ui-carousel";

import Skeleton from "@mui/material/Skeleton";
import Tooltip from "@mui/material/Tooltip";

// import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
// import ZoomInIcon from '@mui/icons-material/ZoomIn';
// import RemoveIcon from '@mui/icons-material/Remove';
// import AddIcon from '@mui/icons-material/Add';
// import FavoriteIcon from '@mui/icons-material/Favorite';

import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Slide, { SlideProps } from "@mui/material/Slide";

import WebsiteDict from "../data/WebsiteDict";
import ZoomModal from "../components/ZoomModal";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Label } from "@mui/icons-material";

const WS_Contact = () => {
    const theme = useTheme();
    const navigate = useNavigate();

    const { language, setLanguage } = useLanguageContext();
    const [dict, setDict] = useState(WebsiteDict);

    const { account, setAccount } = useAccount();
    const { picture, setPicture } = usePicture();
    const { order, setOrder } = useOrder();
    const { environment, setEnvironment } = useEnvironment();
    const { products, setProducts } = useProducts();

    const [favorites, setFavorites] = useState([]);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [telephone, setTelephone] = useState("");
    const [topic, setTopic] = useState("");
    const [message, setMessage] = useState("");

    const [openMessage, setOpenMessage] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [severity, setSeverity] = useState("success");

    const [loading, setLoading] = useState(false);
    const [loadingPictures, setLoadingPictures] = useState(false);

    useEffect(() => {
        setDict(WebsiteDict);
    }, [WebsiteDict]);

    useEffect(() => {
        //example Sebshop_Account
        // "Webshop_Account": {
        //     "ID": "f3497de1-9b6b-48d4-9437-8b8b3825b7e6",
        //     "email": "bderom@gmail.com",
        //     "pw": "bderom",
        //     "geldig_van": "0001-01-01T00:00:00",
        //     "geldig_tot": "0001-01-01T00:00:00",
        //     "naam": "Derom",
        //     "voornaam": "Bernard",
        //     "adres": "Hubert Maelfaitlaan",
        //     "huisnr": "7",
        //     "adres_2": "",
        //     "postcode": "9830",
        //     "gemeente": "Sint-Martens-Latem",
        //     "land": "BE",
        //     "lev_naam": "Derom",
        //     "lev_voornaam": "Bernard",
        //     "lev_adres": "Hubert Maelfaitlaan",
        //     "lev_huisnr": "7",
        //     "lev_adres_2": "",
        //     "lev_postcode": "9830",
        //     "lev_gemeente": "Sint-Martens-Latem",
        //     "lev_land": "BE",
        //     "tel": "+32497251322",
        //     "gsm": "",
        //     "creditcard_type": "",
        //     "creditcard_number": "",
        //     "creditcard_expmonth": 0,
        //     "creditcard_expyear": 0,
        //     "creditcard_name": "",
        //     "creditcard_firstname": "",
        //     "naam_oudste": "TT",
        //     "voornaam_oudste": "SS",
        //     "kent_achtergronden": true,
        //     "uiterste_besteldatum": "0001-01-01T00:00:00",
        //     "uiterste_besteldatum_korting_proc": 0,
        //     "webshop_account_nr": 4020,
        //     "nieuwsbrief_uitschrijven": "0001-01-01T00:00:00",
        //     "opt_in": true
        // }

        if (account !== null && account !== undefined && Object.keys(account).length > 0) {
            setName(account.Webshop_Account.voornaam + " " + account.Webshop_Account.naam);
            setEmail(account.Webshop_Account.email);
            setTelephone(account.Webshop_Account.tel);
        }

        setEnvironment({ ...environment, selectedView: "contact" });

    }, []);

    async function send_message() {
        if (name === "") {
            setSnackbarMessage(dict["WS_Messages"]["contact_saveChanges_error_name"][language]);
            setSeverity("error");
            setOpenMessage(true);
            return;
        }

        if (email === "") {
            setSnackbarMessage(dict["WS_Messages"]["contact_saveChanges_error_email"][language]);
            setSeverity("error");
            setOpenMessage(true);
            return;
        }

        //check email
        let emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        if (!emailPattern.test(email)) {
            setSnackbarMessage(dict["WS_Messages"]["contact_saveChanges_error_email_format"][language]);
            setSeverity("error");
            setOpenMessage(true);
            return;
        }

        if (telephone === "") {
            setSnackbarMessage(dict["WS_Messages"]["contact_saveChanges_error_phone"][language]);
            setSeverity("error");
            setOpenMessage(true);
            return;
        }

        if (topic === "") {
            setSnackbarMessage(dict["WS_Messages"]["contact_saveChanges_error_topic"][language]);
            setSeverity("error");
            setOpenMessage(true);
            return;
        }

        if (message === "") {
            setSnackbarMessage(dict["WS_Messages"]["contact_saveChanges_error_message"][language]);
            setSeverity("error");
            setOpenMessage(true);
            return;
        }

        //message bigger than 1000 characters
        if (message.length > 1000) {
            setSnackbarMessage(dict["WS_Messages"]["contact_saveChanges_error_message_length"][language]);
            setSeverity("error");
            setOpenMessage(true);
            return;
        }

        //body example
        // {
        //     "Session": {
        //         "Session_ID": "3A0E76FE-9316-4209-82F0-8FF2D9779D5D",
        //         "Account_ID": "F3497DE1-9B6B-48D4-9437-8B8B3825B7E6"
        //    },
        //    "Email": "luc@delusoft.be",
        //    "Naam": "Familienaam",
        //    "Tel": "056/66 77 88",
        //    "GSM": "0477/11 22 33",
        //    "TextEmail": "ljjljljl",
        //    "Topic": "Onderwerp"
        // }

        let body = {
            Session: {
                Session_ID: account.Session.Session_ID,
                Account_ID: account.Session.Account_ID
            },
            Email: email,
            Naam: name,
            Tel: telephone,
            GSM: "",
            TextEmail: message,
            Topic: topic
        };

        try {
            const response = await axios.put("Account/PutAccount_Contact/" + account.Session.Account_ID, body);
            console.log("response", response);
            if (response.status === 200) {
                setSnackbarMessage(dict["WS_Messages"]["contact_send_message_success"][language]);
                setSeverity("success");
                setOpenMessage(true);

            } else {
                setSnackbarMessage(dict["WS_Messages"]["contact_send_message_error"][language]);
                setSeverity("error");
                setOpenMessage(true);
            }
        } catch (error) {
            console.error("error", error);
            setSnackbarMessage(dict["WS_Messages"]["contact_send_message_error"][language]);
            setSeverity("error");
            setOpenMessage(true);
        }
    }

    return (
        <>
            <Box sx={{ width: "100%" }}>
                <Box
                    sx={{
                        margin: 3,
                        marginLeft: 6,
                        // maxWidth: { xs: 200, sm: 300, md: 500, lg: "100%" }
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={8} xl={9} order={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 1 }}>
                                <Box pr={2}>
                                    <Paper sx={{ overflow: "hidden", marginTop: 1, p: 2 }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={12}>
                                                <Typography>{dict["WS_Labels"]["myDetailsTitle"][language]}</Typography>
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    id="outlined-basic"
                                                    label={dict["WS_Labels"]["label_name"][language]}
                                                    variant="outlined"
                                                    size="small"
                                                    value={name}
                                                    fullWidth
                                                    required
                                                    onChange={(e) => {
                                                        setName(e.target.value);
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    id="outlined-basic"
                                                    label={dict["WS_Labels"]["email"][language]}
                                                    variant="outlined"
                                                    size="small"
                                                    value={email}
                                                    fullWidth
                                                    required
                                                    onChange={(e) => {
                                                        setEmail(e.target.value);
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    id="outlined-basic"
                                                    label={dict["WS_Labels"]["telephone"][language]}
                                                    variant="outlined"
                                                    size="small"
                                                    value={telephone}
                                                    fullWidth
                                                    required
                                                    onChange={(e) => {
                                                        setTelephone(e.target.value);
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    id="outlined-basic"
                                                    label={dict["WS_Labels"]["topic"][language]}
                                                    variant="outlined"
                                                    size="small"
                                                    value={topic}
                                                    fullWidth
                                                    required
                                                    sx={{ marginTop: 4 }}
                                                    onChange={(e) => {
                                                        setTopic(e.target.value);
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={12}>
                                                <TextField
                                                    id="outlined-basic"
                                                    label={dict["WS_Labels"]["message"][language]}
                                                    variant="outlined"
                                                    size="small"
                                                    value={message}
                                                    fullWidth
                                                    required
                                                    multiline={true}
                                                    rows={6}
                                                    onChange={(e) => {
                                                        setMessage(e.target.value);
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <Button
                                                    sx={{ marginTop: 2, width: "100%" }}
                                                    color="secondary"
                                                    variant="contained"
                                                    onClick={() => {
                                                        send_message();
                                                    }}
                                                >
                                                    {dict["WS_Labels"]["contact_send_message"][language]}
                                                </Button>

                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Box sx={{ height: 90 }}></Box>

            <Snackbar
                open={openMessage}
                onClose={() => {
                    setOpenMessage(false);
                }}
                TransitionComponent={SlideTransition}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                key="Snackbar"
            >
                <Alert
                    severity={severity}
                    sx={{ marginTop: 2 }}
                    onClose={() => {
                        setOpenMessage(false);
                    }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    );
};

function SlideTransition(props: SlideProps) {
    return <Slide {...props} direction="up" />;
}

function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // January is 0!
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
}

export default WS_Contact;
