import axios from "../api/axiosPhotobizz";
import axiosRoot from "../api/axiosWithoutBaseURL.js";

import useLanguageContext from "../hooks/useLanguageContext";
import useAccount from "../hooks/useAccount";

import React, { useState, useEffect } from "react";
// import { NavLink, } from "react-router-dom";
import { Box, Typography, Stack, Grid, Button, Card, TextField } from "@mui/material";

import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Slide, { SlideProps } from "@mui/material/Slide";

import WebsiteDict from "../data/WebsiteDict";
import { NavLink, useNavigate } from "react-router-dom";

import CircularProgress from "@mui/material/CircularProgress";
import { InputAdornment, IconButton } from "@mui/material/";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import ContactEmergencyOutlinedIcon from "@mui/icons-material/ContactEmergencyOutlined";

import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

const CreateAccount = () => {
    const { language, setLanguage } = useLanguageContext();
    const { account, setAccount } = useAccount();

    const [dict, setDict] = useState(WebsiteDict);

    const navigate = useNavigate();

    useEffect(() => {
        setDict(WebsiteDict);
    }, [WebsiteDict]);

    const [password, setPassword] = useState("");
    const [showPass, setShowPass] = useState(false);
    const [email, setEmail] = useState("");
    const [emailRepeat, setEmailRepeat] = useState("");
    const [photoCode, setPhotoCode] = useState("");
    const [display_not_same_emails_warning, setDisplay_not_same_emails_warning] = useState("none");

    const [wishPromotions, setWishPromotions] = useState(false);
    const [agreeConditions, setAgreeConditions] = useState(false);

    const [sessionID, setSessionID] = useState("");
    const [bizzSession, setBizzSession] = useState({ Session: { Session_ID: "", Account_ID: "" }, Webshop_Account: {} });

    // const [firstName, setFirstName] = useState("");
    // const [lastName, setLastName] = useState("");
    // const [phone, setPhone] = useState("");
    // const [companyName, setCompanyName] = useState("");
    // const [message, setMessage] = useState("");

    const [openMessage, setOpenMessage] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [severity, setSeverity] = useState("success");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // const handleChangeFirstName = (event) => {
    //     setFirstName(event.target.value);
    // };

    // const handleChangeLastName = (event) => {
    //     setLastName(event.target.value);
    // };

    const handleChangeEmail = (event) => {
        setEmail(event.target.value);
    };

    // const handleChangePhone = (event) => {
    //     setPhone(event.target.value);
    // };

    // const handleChangeCompanyName = (event) => {
    //     setCompanyName(event.target.value);
    // };

    // const handleChangeMessage = (event) => {
    //     setMessage(event.target.value);
    // };

    const handleChangePassword = (event) => {
        setPassword(event.target.value);
    };

    // =================== Fetch Session ===================
    async function fetchSession() {
        try {
            // await axios.get("/Account/GetSession").then((response) => {
            //     setSessionID(response.data);
            //     return response.data;
            // });
            const res = await axios.get("/Account/GetSession");
            setSessionID(res.data);
            return res.data;
        } catch (error) {
            throw error;
        }
    }

    // =================== Fetch IP ===================
    async function fetchIP() {
        try {
            const res = await axiosRoot.get("api.ipify.org?format=json");
            return res.data.ip;
        } catch (error) {
            throw error;
        }
    }

    const sendLoginRequest = async (e) => {
        e.preventDefault();

        let msg = "";

        if (email === "") {
            msg = msg + dict["WS_Messages"]["Email is required."][language];
        }

        if (emailRepeat === "") {
            msg = msg + dict["WS_Messages"]["Confirm Email is required."][language];
        }

        if (email !== emailRepeat) {
            setDisplay_not_same_emails_warning("block");
            msg = msg + dict["WS_Messages"]["email_not_same"][language];
        } else {
            setDisplay_not_same_emails_warning("none");
        }

        if (password === "") {
            msg = msg + dict["WS_Messages"]["Password is required."][language];
        }

        if (photoCode === "") {
            msg = msg + dict["WS_Messages"]["Photo code is required."][language];
        }

        if (!agreeConditions) {
            msg = msg + dict["WS_Messages"]["You must agree to the terms and conditions."][language];
        }

        if (msg !== "") {
            setSnackbarMessage(msg);
            setSeverity("error");
            setOpenMessage(true);
            setLoading(false);
            return;
        }

        //validate email
        const re = /\S+@\S+\.\S+/;
        if (!re.test(email)) {
            setSnackbarMessage(dict["WS_Messages"]["Email is not valid."][language]);
            setSeverity("error");
            setOpenMessage(true);
            setLoading(false);
            return;
        }

        setLoading(true);

        var Client_IP = "::1";
        try {
            Client_IP = await fetchIP();
            console.log(Client_IP);
        } catch (error) {
            console.log(error);
        }

        var t_setBizzSessionID = "";
        try {
            t_setBizzSessionID = await fetchSession();
            setSessionID(t_setBizzSessionID);
        } catch (error) {
            console.log(error);
            setSnackbarMessage("Error: " + error);
            setSeverity("error");
            setOpenMessage(true);
            setLoading(false);
            return;
        }

        // const fetchIP = async () => {
        //     try {
        //         const res = await axios.get("https://api.ipify.org?format=json");
        //         Client_IP = res.data.ip;
        //     } catch (error) {
        //         console.log(error);
        //     }
        // }

        // const fetchSession = async () => {
        //     try {
        //         await axios.get("/Account/GetSession").then((response) => {
        //             setSessionID(response.data);
        //             t_setSmlSessionID = response.data;
        //         });
        //     } catch (error) {
        //         console.log(error);
        //         setSnackbarMessage("Error: " + error);
        //         setSeverity("error");
        //         setOpenMessage(true);
        //         setLoading(false);
        //         return
        //     }
        // }

        const fetchCreateAccount = async () => {
            try {
                //body example :
                // {
                //     "Session_ID": "adf9ecae-668f-4a58-92b4-bbd4cbb6addf",
                //     "Code": "hy8fecpv",
                //     "Email": "luc441@delusoft.be",
                //     "Confirm_Email": "luc441@delusoft.be",
                //     "Pw": "zwartje1",
                //     "Client_IP": "192.168.1.100",
                //     "Opt_In": true,
                //     "Accept_Privacy_Policy": true
                // }

                let body = {
                    Session_ID: t_setBizzSessionID,
                    Code: photoCode,
                    Email: email,
                    Confirm_Email: emailRepeat,
                    Pw: password,
                    Client_IP: Client_IP,
                    Opt_In: wishPromotions,
                    Accept_Privacy_Policy: agreeConditions,
                };

                const response = await axios.post("/Account/PostNewAccount", body);
                if (response.status === 200) {
                    setBizzSession(response.data);

                    var response_with_password = response.data;
                    response_with_password.Webshop_Account.pw = "";

                    setAccount(response_with_password);
                    navigate("/webshop/home");
                } else {
                    setSnackbarMessage(dict["WS_Messages"]["Creating account failed."][language]);
                    setSeverity("error");
                    setOpenMessage(true);
                }
            } catch (error) {
                console.log(error);
                if (error.response.status === 404 && error.response.data.Message === "Code is niet correct") {
                    setSnackbarMessage(dict["WS_Messages"]["Code is niet correct"][language]);

                } else {
                    setSnackbarMessage(dict["WS_Messages"]["Creating account failed."][language] + " " + error.response.data.Message);
                }
                setSeverity("error");
                setOpenMessage(true);
            }
        };

        fetchCreateAccount();

        setLoading(false);

        // console.log("handleSendContactMessage");
        // let msg = "";
        // if (firstName === "") {
        //     msg = msg + dict["Messages"]["First name is required."][language];
        // }
        // if (lastName === "") {
        //     msg = msg + dict["Messages"]["Last name is required."][language];
        // }
        // if (email === "") {
        //     msg = msg + dict["WS_Messages"]["Email is required."][language];
        // }

        // if (password === "") {
        //     msg = msg + dict["WS_Messages"]["Password is required."][language];
        // }

        // if (companyName === "") {
        //     msg = msg + dict["Messages"]["School name is required."][language];
        // }

        // if (message === "") {
        //     msg = msg + dict["Messages"]["Message is required."][language];
        // }

        //validate phone
        // if (phone !== "") {
        //     const re = /^[0-9\b]+$/;
        //     if (!re.test(phone)) {
        //         setSnackbarMessage(dict["Messages"]["Phone is not valid. Only numbers are allowed."][language]);
        //         setSeverity("error");
        //         setOpenMessage(true);
        //         return;
        //     }
        // }

        //validate message length
        // if (message.length > 1000) {
        //     setSnackbarMessage(dict["Messages"]["Message is too long."][language]);
        //     setSeverity("error");
        //     setOpenMessage(true);
        //     return;
        // }

        // let languageCapitals = language.toUpperCase();

        // const fetchMessage = async () => {

        //     try {

        //         let body = {
        //             "ID": null,
        //             // "Name": lastName,
        //             // "FirstName": firstName,
        //             "Email": email,
        //             // "Phone": phone,
        //             // "SchoolName": companyName,
        //             "Language_Code": languageCapitals,
        //             // "Message": message,
        //             "Timestamp": null
        //         };

        //         const response = await axios.post("/api/Company/PostSchoolContactRequest", body);

        //         if (response.status === 200) {
        //             setSnackbarMessage(dict["Messages"]["Message sent successfully."][language]);
        //             setSeverity("success");
        //             setOpenMessage(true);
        //             // setFirstName("");
        //             // setLastName("");
        //             setEmail("");
        //             setPassword("");
        //             // setPhone("");
        //             // setCompanyName("");
        //             // setMessage("");
        //         }
        //         else {
        //             setSnackbarMessage(dict["Messages"]["Message could not be sent."][language]);
        //             setSeverity("error");
        //             setOpenMessage(true);
        //         }
        //     } catch (error) {
        //         console.log(error);
        //         setSnackbarMessage(dict["Messages"]["Message could not be sent."][language]);
        //         setSeverity("error");
        //         setOpenMessage(true);

        //     }

        // };

        // fetchMessage();
        // setLoading(false);
    };

    const handlePassVisibilty = () => {
        setShowPass(!showPass);
    };

    const handleCreateAccountClick = () => {
        navigate("/singup");
    };

    return (
        <>
            <Stack spacing={8} m={3}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Stack spacing={1}>
                            <Grid item>
                                <Box pb={2}>
                                    <Typography align="left" color={"#023047"} fontWeight={600} variant="h4">
                                        {dict["WS_Labels"]["Title_createaccount"][language]}
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item>
                                <Box display="flex" alignItems="center">
                                    <Typography variant="body1" align="left">
                                        {dict["WS_Messages"]["if_you_have_already_account"][language]}
                                    </Typography>
                                    <NavLink to="/login" style={{ textDecoration: "none" }}>
                                        <Typography variant="body1" align="left" color="secondary" style={{ marginLeft: 8 }}>
                                            {dict["WS_Messages"]["login_here"][language]}
                                        </Typography>
                                    </NavLink>
                                </Box>
                            </Grid>

                            {/* grid item email */}
                            <Grid item xs={12} md={12}>
                                <TextField
                                    color="secondary"
                                    value={email}
                                    label={dict["WS_Labels"]["email"][language]}
                                    placeholder={dict["WS_Labels"]["enter_your_email"][language]}
                                    onChange={handleChangeEmail}
                                    size="small"
                                    id="outlined-name"
                                    fullWidth={true}
                                    variant="standard"
                                    margin="normal"
                                    required
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <MailOutlineIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>

                            {/* grid item email repeat */}
                            <Grid item xs={12} md={12}>
                                <TextField
                                    color="secondary"
                                    value={emailRepeat}
                                    label={dict["WS_Labels"]["confirm_email"][language]}
                                    placeholder={dict["WS_Labels"]["email_repeat"][language]}
                                    onChange={(e) => setEmailRepeat(e.target.value)}
                                    size="small"
                                    id="outlined-name"
                                    fullWidth={true}
                                    variant="standard"
                                    margin="normal"
                                    required
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <MailOutlineIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>

                            {/* warning : the two emails are not the same */}
                            <Grid item xs={12} md={12}>
                                <Typography variant="body1" align="left" color="red" display={display_not_same_emails_warning}>
                                    {dict["WS_Messages"]["email_not_same"][language]}
                                </Typography>
                            </Grid>

                            {/* grid item password */}
                            <Grid item xs={12} md={12}>
                                <TextField
                                    color="secondary"
                                    type={showPass ? "text" : "password"}
                                    fullWidth
                                    label={dict["WS_Labels"]["password"][language]}
                                    placeholder={dict["WS_Labels"]["create_password"][language]}
                                    variant="standard"
                                    required
                                    defaultValue={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <LockOutlinedIcon />
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={handlePassVisibilty} aria-label="toggle password" edge="end">
                                                    {showPass ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>

                            {/* grid item 'photo code' */}
                            <Grid item xs={12} md={12}>
                                <TextField
                                    color="secondary"
                                    label={dict["WS_Labels"]["photo_code"][language]}
                                    placeholder={dict["WS_Messages"]["photo code instructions"][language]}
                                    size="small"
                                    id="outlined-name"
                                    fullWidth={true}
                                    variant="standard"
                                    margin="normal"
                                    required
                                    value={photoCode}
                                    onChange={(e) => setPhotoCode(e.target.value)}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <ContactEmergencyOutlinedIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>

                            {/* checkbox 'wish to receive promotions' */}
                            <Grid item xs={12} md={12} sx={{ display: "flex", justifyContent: "flex-start" }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={wishPromotions}
                                            onChange={() => {
                                                setWishPromotions(!wishPromotions);
                                            }}
                                        />
                                    }
                                    label={
                                        <Typography variant="body1" component="div" sx={{ flexGrow: 0 }}>
                                            {dict["WS_Messages"]["I wish promotions"][language]}
                                        </Typography>
                                    }
                                />
                            </Grid>

                            <Grid item xs={12} md={12} sx={{ display: "flex", justifyContent: "flex-start" }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={agreeConditions}
                                            onChange={() => {
                                                setAgreeConditions(!agreeConditions);
                                            }}
                                        />
                                    }
                                    label={
                                        <Typography variant="body1" component="div" sx={{ flexGrow: 0 }}>
                                            {dict["WS_Messages"]["I agree to the terms and conditions"][language]}
                                        </Typography>
                                    }
                                />
                            </Grid>

                            {/* grid item forgot password */}
                            {/* <Grid item xs={12} md={12}>
                                <NavLink to="/forgotpassword" style={{ textDecoration: "none" }}>
                                    <Typography variant="body1" align="left" color="secondary">
                                        {dict["WS_Labels"]["forgot_password"][language]}
                                    </Typography>
                                </NavLink>
                            </Grid> */}

                            <Box pt={0}>
                                <Button
                                    variant="contained"
                                    onClick={sendLoginRequest}
                                    sx={{
                                        mt: 0,
                                        width: "100%",
                                        backgroundColor: "white",
                                        border: "2px solid #219EBC",
                                        color: "#219EBC", // Ensure the text color is visible on a white background
                                        "&:hover": {
                                            backgroundColor: "#219EBC", // Maintain white background on hover
                                            border: "2px solid #219EBC", // Optional: Darken the border on hover
                                            color: "white", // Optional: Darken the text color on hover
                                        },
                                    }}
                                >
                                    {dict["WS_Labels"]["Button_createaccount"][language]}
                                    {loading && (
                                        <CircularProgress
                                            size={20}
                                            sx={{
                                                color: "secondary",
                                                position: "relative",
                                                top: "6px",
                                                left: "60px",
                                                marginTop: "-12px",
                                                marginLeft: "-12px",
                                            }}
                                        />
                                    )}
                                </Button>
                            </Box>

                            {/* </Grid> */}
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Grid item xs={12} md={12} sx={{ display: { xs: "none", sm: "none", md: "none", lg: "block" } }}>
                            <Box component="img" sx={{ maxWidth: 500 }} alt="Photobizz image" src={require("../images/WelcomeBack_full.png")} m={1} />
                        </Grid>
                        <Grid item xs={12} md={12} sx={{ display: { xs: "none", sm: "none", md: "block", lg: "none" } }}>
                            <Box component="img" sx={{ maxWidth: 400 }} alt="Photobizz image" src={require("../images/WelcomeBack_full.png")} m={1} />
                        </Grid>
                    </Grid>
                </Grid>

                <Snackbar
                    open={openMessage}
                    onClose={() => {
                        setOpenMessage(false);
                    }}
                    TransitionComponent={SlideTransition}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    key="Snackbar"
                >
                    <Alert
                        severity={severity}
                        sx={{ marginTop: 2 }}
                        onClose={() => {
                            setOpenMessage(false);
                        }}
                    >
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </Stack>
        </>
    );
};

function SlideTransition(props: SlideProps) {
    return <Slide {...props} direction="up" />;
}

export default CreateAccount;
