import axios from "../api/axiosPhotobizz";

import useLanguageContext from "../hooks/useLanguageContext";
import useAccount from "../hooks/useAccount";
import usePicture from "../hooks/usePicture";
import useOrder from "../hooks/useOrder";
import useEnvironment from "../hooks/useEnvironment";
import useProducts from "../hooks/useProducts";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

import React, { useState, useEffect, useRef } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";

import { Box, Typography, Stack, Grid, Button, Card, CardMedia, CardContent, TextField } from "@mui/material";
import { Paper, IconButton } from "@mui/material";

import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Carousel from "react-material-ui-carousel";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";

import Skeleton from "@mui/material/Skeleton";
import Tooltip from "@mui/material/Tooltip";

import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShoppingBasketOutlinedIcon from "@mui/icons-material/ShoppingBasketOutlined";

import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Slide, { SlideProps } from "@mui/material/Slide";

import WebsiteDict from "../data/WebsiteDict";
import ZoomModal from "../components/ZoomModal";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { DeleteForever, Label } from "@mui/icons-material";

import Avatar from "@mui/material/Avatar";

// ============= Table =============
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
// table =================================

// ============= Tabs =============
import "react-tabs/style/react-tabs.css";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
// ============= Tabs =============

// ============= Tabs =============

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel_Horizontal(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

// ============= Tabs =============

const WS_Cart = () => {
    const theme = useTheme();
    const navigate = useNavigate();

    const matchesMdUp = useMediaQuery(theme.breakpoints.up("md"));
    const matchesSM = useMediaQuery((theme) => theme.breakpoints.down("lg"));

    const [dict, setDict] = useState(WebsiteDict);
    const { language, setLanguage } = useLanguageContext();

    const { account, setAccount } = useAccount();
    const { picture, setPicture } = usePicture();
    const { order, setOrder } = useOrder();
    const { environment, setEnvironment } = useEnvironment();
    const { products, setProducts } = useProducts();

    const [shopObject, setShopObject] = useState({});

    const [areTabsDisabled, setAreTabsDisabled] = useState(false);
    const [isGotoDeliveryDetailsDisabled, setIsGotoDeliveryDetailsDisabled] = useState(false);

    const [subtotal, setSubtotal] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [deliveryCosts, setDeliveryCosts] = useState(0);
    const [discountCoupon, setDiscountCoupon] = useState(0);
    const [balanceCoupon, setBalanceCoupon] = useState(0);
    const [total, setTotal] = useState(0);
    const [isHomeDelivery, setIsHomeDelivery] = useState(false);
    const [isHomeDeliveryDisabled, setIsHomeDeliveryDisabled] = useState(true);
    const [discountCode, setDiscountCode] = useState("");
    const [areGotoButtonsDisabled, setAreGotoButtonsDisabled] = useState(false);
    const [Uiterste_Besteldatum, setUiterste_Besteldatum] = useState("");

    const [mollieResponse, setMollieResponse] = useState("");
    const [mollieOrderCombo, setMollieOrderCombo] = useState({});

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [street, setStreet] = useState("");
    const [houseNr, setHouseNr] = useState("");
    const [bus, setBus] = useState("");
    const [postcode, setPostcode] = useState("");
    const [city, setCity] = useState("");
    const [email, setEmail] = useState("");
    const [telephone, setTelephone] = useState("");
    const [gsm, setGsm] = useState("");
    const [childLastName, setChildLastName] = useState("");
    const [childFirstName, setChildFirstName] = useState("");

    const [showDiscountDetails, setShowDiscountDetails] = useState(true);

    const [showGratisButton, setShowGratisButton] = useState(false);
    const [pincode, setPincode] = useState("");

    const [openMessage, setOpenMessage] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [severity, setSeverity] = useState("success");

    // ================== Tabs ==================
    const [value_horizontal, setValue_horizontal] = React.useState(0);
    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    }

    const handleChange_horizontal = (event: React.SyntheticEvent, newValue: number) => {
        setValue_horizontal(newValue);
    };

    interface Column {
        id: "ArrImage" | "Artikle_Naam" | "KL_SE_ZW" | "Aantal" | "Eenheidsprijs" | "priceTotal" | "delete";
        label: string;
        minWidth?: number;
        align?: "left";
        // format?: (value: number) => string;
    }

    const getLabel = (key) => {
        const labels = {
            nl: {
                Artikle_Naam: "Artikel naam",
                KL_SE_ZW: "Kleur",
                Aantal: "Aantal",
                Eenheidsprijs: "Eenheidsprijs",
                priceTotal: "Totale prijs",
                delete: "",
            },
            en: {
                Artikle_Naam: "Article name",
                KL_SE_ZW: "Color",
                Aantal: "Amount",
                Eenheidsprijs: "Unit price",
                priceTotal: "Total price",
                delete: "",
            },
            fr: {
                Artikle_Naam: "Nom de l'article",
                KL_SE_ZW: "Couleur",
                Aantal: "Quantité",
                Eenheidsprijs: "Prix unitaire",
                priceTotal: "Prix total",
                delete: "",
            },
            // Add more languages as needed
        };
        return labels[language][key];
    };

    const tableColumns: Column[] = [
        {
            id: "ArrImage",
            label: "",
            renderCell: (params) => {
                return params.value !== null ? (
                    <Avatar sx={{ width: 30, height: 30 }} src={"data:image/jpeg;base64," + params.value.toString()} />
                ) : (
                    <></>
                );
            },
            hideOnSM: false,
            align: "right",
            minWidth: 50,
        },

        { id: "Artikle_Naam", label: getLabel("Artikle_Naam"), hideOnSM: false, align: "left", minWidth: 100 },
        { id: "KL_SE_ZW", label: getLabel("KL_SE_ZW"), hideOnSM: true, align: "left" },
        { id: "Aantal", label: getLabel("Aantal"), hideOnSM: false, align: "center" },
        { id: "Eenheidsprijs", label: getLabel("Eenheidsprijs"), hideOnSM: false, align: "right", minWidth: 100 },
        { id: "priceTotal", label: getLabel("priceTotal"), hideOnSM: false, align: "right", minWidth: 100 },
        { id: "delete", label: "", hideOnSM: false, align: "right", minWidth: 50 },
    ];

    const { tab } = useParams();

    const filteredTableColumns = tableColumns.filter((column) => !(matchesSM && column.hideOnSM));

    useEffect(() => {
        if (tab !== undefined) {
            setValue_horizontal(Number(tab));
        }
    }, [tab]);

    useEffect(() => {
        setDict(WebsiteDict);
    }, [WebsiteDict]);

    useEffect(() => {
        if (account === null || account === undefined || Object.keys(account).length === 0) {
            if (
                JSON.parse(sessionStorage.getItem("session_account")) === null ||
                JSON.parse(sessionStorage.getItem("session_account")) === undefined
            ) {
                navigate("/login");
            } else {
                setAccount(JSON.parse(sessionStorage.getItem("session_account")));
            }
        }

        fetchDataSequentially();

        setupEventListeners();
        setEnvironment({ ...environment, selectedView: "cart" });
    }, []);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === "F2") {
                // if textfield with id "telephone_textbox" is focused then do console.log
                if (document.activeElement.id === "telephone_textbox") {
                    setShowGratisButton((prevState) => !prevState);
                    console.log("F2 pressed and telephone_textbox is focused");
                }
            }
        };

        window.addEventListener("keydown", handleKeyDown);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    useEffect(() => {
        if (shopObject.Order_Lijnen !== undefined) {
            calculateOverview(shopObject);
        }
    }, [shopObject]);

    async function fetchDataSequentially() {
        try {
            var _account = account;
            var _shopObject = undefined;
            var _order = undefined;
            var _cart = undefined;

            _shopObject = await fetchWinkelmandje(account.Order.order_nr);

            if (_shopObject === undefined || _shopObject === null || Object.keys(_shopObject).length === 0) {
                setAreTabsDisabled(true);
                setAreGotoButtonsDisabled(true);

                setSnackbarMessage(dict["WS_Messages"]["There is no open order"][language]);
                setSeverity("error");
                setOpenMessage(true);
            } else {
                setFirstName(
                    _shopObject.Webshop_Account.lev_voornaam !== "" ? _shopObject.Webshop_Account.lev_voornaam : _shopObject.Webshop_Account.voornaam
                );
                setLastName(_shopObject.Webshop_Account.lev_naam !== "" ? _shopObject.Webshop_Account.lev_naam : _shopObject.Webshop_Account.naam);
                setStreet(_shopObject.Webshop_Account.lev_straat !== "" ? _shopObject.Webshop_Account.lev_adres : _shopObject.Webshop_Account.adres);
                setHouseNr(
                    _shopObject.Webshop_Account.lev_huisnr !== "" ? _shopObject.Webshop_Account.lev_huisnr : _shopObject.Webshop_Account.huisnr
                );
                setBus(_shopObject.Webshop_Account.lev_bus !== "" ? _shopObject.Webshop_Account.lev_adres_2 : _shopObject.Webshop_Account.adres_2);
                setPostcode(
                    _shopObject.Webshop_Account.lev_postcode !== "" ? _shopObject.Webshop_Account.lev_postcode : _shopObject.Webshop_Account.postcode
                );
                setCity(
                    _shopObject.Webshop_Account.lev_gemeente !== "" ? _shopObject.Webshop_Account.lev_gemeente : _shopObject.Webshop_Account.gemeente
                );
                setEmail(_shopObject.Webshop_Account.email);
                setTelephone(_shopObject.Webshop_Account.tel);
                setGsm(_shopObject.Webshop_Account.gsm);
                setChildFirstName(_shopObject.Webshop_Account.voornaam_oudste);
                setChildLastName(_shopObject.Webshop_Account.naam_oudste);

                //setAccount({ ..._account, Favorites: _favorites, Order: _order, Cart: _cart });
                setShopObject(_shopObject);
            }
        } catch (error) {
            console.error("Failed to fetch data:", error);
        }
    }

    async function fetchWinkelmandje(_order_nr) {
        var sessAcc = "";
        sessAcc = account.Session.Session_ID + "/" + account.Session.Account_ID;

        try {
            const response = await axios.get("Order/GetWinkelmandje/" + sessAcc + "/" + _order_nr + "/" + language.toUpperCase());
            if (response.status === 200) {
                console.log(response.data);
                return response.data;
            } else {
                return {};
            }
        } catch (error) {
            console.error("Failed to fetch data:", error);
            return {};
        }
    }

    async function fetchWinkelmandje_with_delivery_info(_order_nr, _isHomeDelivery) {
        var sessAcc = "";
        sessAcc = account.Session.Session_ID + "/" + account.Session.Account_ID;

        try {
            const response = await axios.get("Order/GetWinkelmandje_WenstLeveringThuis/" + sessAcc + "/" + _order_nr + "/" + isHomeDelivery + "/" + language.toUpperCase());
            if (response.status === 200) {
                console.log(response.data);
                return response.data;
            } else {
                return {};
            }
        } catch (error) {
            console.error("Failed to fetch data:", error);
            return {};
        }
    }




    async function fetchOrder(_order_nr) {
        var sessAcc = "";
        var _orderCombo = {};

        sessAcc = account.Session.Session_ID + "/" + account.Session.Account_ID;

        try {
            const response = await axios.get("Order/GetOrder/" + sessAcc + "/" + _order_nr + "/" + language.toUpperCase());
            if (response.status === 200) {
                console.log(response.data);
                return response.data;
            } else {
                return {};
            }
        } catch (error) {
            console.error("Failed to fetch data:", error);
            return {};
        }
    }

    function calculateOverview(_shopObject) {
        if (_shopObject === undefined || _shopObject === null || Object.keys(_shopObject).length === 0) {
            setSubtotal(0);
            setDiscount(0);
            setDeliveryCosts(0);
            setDiscountCoupon(0);
            setBalanceCoupon(0);
            setTotal(0);
            setIsHomeDelivery(false);
            setIsHomeDeliveryDisabled(true);
            return;
        }

        var _subtotal = _shopObject.Order_Lijnen.reduce((acc, item) => acc + item.Aantal * item.Eenheidsprijs, 0);
        console.log("subtotal: " + _subtotal);
        setSubtotal(_subtotal);

        // for every item in the cart, check if Prijs_Promo is not zero and if not then add Eenheidsprijs - Prijs_Promo to the discount
        // var _discount = _shopObject.Order_Lijnen.reduce((acc, item) => acc + (item.Prijs_Promo !== 0 ? item.Eenheidsprijs - item.Prijs_Promo : 0), 0);
        var _discount = _shopObject.Korting;
        console.log("discount: " + _discount);
        setDiscount(_discount);

        var _deliveryCosts = _shopObject.Verzendkosten;
        console.log("deliveryCosts: " + _deliveryCosts);
        setDeliveryCosts(_shopObject.Verzendkosten);

        setUiterste_Besteldatum(_shopObject.Uiterste_Besteldatum);
        // setUiterste_Besteldatum('2024-10-15T00:00:00');

        setIsHomeDeliveryDisabled(!_shopObject.Show_Keuze_LeveringAanHuis);
        setIsHomeDelivery(_shopObject.Levering_Thuis);

        // if shopObject.Uiterste_Besteldatum(to date) is older than today
        // if (new Date(_shopObject.Uiterste_Besteldatum) < new Date()) {
        //     _deliveryCosts = _shopObject.Verzendkosten;

        //     setDeliveryCosts(_deliveryCosts);
        //     setIsHomeDelivery(true);
        //     setIsHomeDeliveryDisabled(true);
        // } else {
        //     if (isHomeDelivery) {
        //         setDeliveryCosts(_shopObject.Verzendkosten);
        //     } else {
        //         setDeliveryCosts(0);
        //     }
        // }

        var _discountCoupon = 0;
        var _balanceCoupon = 0;
        var _total = 0;

        if (_shopObject.Beschikbaar_Bedrag_Waardebonnen !== 0) {
            if (_shopObject.Beschikbaar_Bedrag_Waardebonnen > _subtotal + _deliveryCosts - _discount) {
                _discountCoupon = _subtotal + _deliveryCosts - _discount;
                _balanceCoupon = _shopObject.Beschikbaar_Bedrag_Waardebonnen - _discountCoupon;
                _total = _subtotal + _deliveryCosts - _discount - _discountCoupon;
            } else {
                _discountCoupon = _shopObject.Beschikbaar_Bedrag_Waardebonnen;
                _balanceCoupon = 0;
                _total = _subtotal + _deliveryCosts - _discount - _discountCoupon;
            }
        } else {
            _total = _subtotal + _deliveryCosts - _discount;
        }

        if (_shopObject.Beschikbaar_Bedrag_Waardebonnen === 0) {
            setShowDiscountDetails(false);
        } else {
            setShowDiscountDetails(true);
        }

        setDiscountCoupon(_discountCoupon);
        setBalanceCoupon(_balanceCoupon);
        setTotal(_total);

        // if (_shopObject.Beschikbaar_Bedrag_Waardebonnen === 0) {
        //     setShowDiscountDetails(false);
        //     _discountCoupon = 0;
        //     _balanceCoupon = 0;
        // } else {
        //     setShowDiscountDetails(true);
        //     if (shopObject.Beschikbaar_Bedrag_Waardebonnen > _subtotal + _deliveryCosts - _discount) {
        //         _discountCoupon = _subtotal + _deliveryCosts - _discount;
        //         _balanceCoupon = shopObject.Beschikbaar_Bedrag_Waardebonnen - _discountCoupon;
        //     } else {
        //         _discountCoupon = shopObject.Beschikbaar_Bedrag_Waardebonnen;
        //         _balanceCoupon = 0;
        //     }
        // }
    }

    async function handleDeleteClicked(_orderLine_ID) {
        console.log("Delete clicked: " + _orderLine_ID);

        //body example:
        // {
        //     "Session": {
        //           "Session_ID": "C5E0DEA6-1537-41A2-84FA-1590FF0260D7",
        //           "Account_ID": "BB7DD27A-C3E8-4FA6-AC65-E49132D19BB2"
        //     },
        //     "Order_Nr": 5194,
        //     "OrderLijn_ID": "c60a706a-fcd6-41be-b75b-e2fa4d880823"
        //   }

        var body = {
            Session: account.Session,
            Order_Nr: account.Order.order_nr,
            OrderLijn_ID: _orderLine_ID,
        };

        try {
            var response = await axios.post("Order/PostDeleteOrderLijn", body);
            if (response.status === 200) {
                console.log("Order line deleted successfully");
                setSnackbarMessage(dict["WS_Messages"]["Order line deleted successfully"][language]);
                setSeverity("success");
                setOpenMessage(true);

                //remove the deleted item from the shopObject.Order_Lijnen
                var newCart = shopObject.Order_Lijnen.filter((item) => item.OrderLijn_ID !== _orderLine_ID);
                setAccount({ ...account, Cart: newCart });

                //var _mandje = await fetchWinkelmandje(account.Order.order_nr);
                var _mandje_with_delivery_info = await fetchWinkelmandje_with_delivery_info(account.Order.order_nr, isHomeDelivery);
                setShopObject(_mandje_with_delivery_info);
            } else {
                console.log("Failed to delete Order line");
                setSnackbarMessage(dict["WS_Messages"]["Failed to delete order line"][language]);
                setSeverity("error");
                setOpenMessage(true);
            }
        } catch (error) {
            console.error("Failed to fetch data:", error);
            setSnackbarMessage(dict["WS_Messages"]["Failed to delete order line"][language]);
            setSeverity("error");
            setOpenMessage(true);
        }
    }

    async function removeOnePieceClicked(_orderLine_ID) {
        console.log("remove one piece clicked: " + _orderLine_ID);

        //body example:
        // {
        //     "Session": {
        //           "Session_ID": "C5E0DEA6-1537-41A2-84FA-1590FF0260D7",
        //           "Account_ID": "BB7DD27A-C3E8-4FA6-AC65-E49132D19BB2"
        //     },
        //     "Order_Nr": 5194,
        //     "OrderLijn_ID": "206e29a7-ecf3-4127-af7c-3390771f6df8",
        //     "Aantal": 3
        //   }

        var newNumberOfProducts = shopObject.Order_Lijnen.filter((item) => item.OrderLijn_ID === _orderLine_ID)[0].Aantal - 1;
        if (newNumberOfProducts === 0) {
            // setSnackbarMessage(dict["WS_Messages"]["You can't have less than 1 product in your cart"][language]);
            // setSeverity("error");
            // setOpenMessage(true);

            handleDeleteClicked(_orderLine_ID);

            return;
        }

        var body = {
            Session: account.Session,
            Order_Nr: account.Order.order_nr,
            OrderLijn_ID: _orderLine_ID,
            Aantal: newNumberOfProducts,
        };

        try {
            var response = await axios.post("Order/PostUpdateAantalOrderLijn", body);
            if (response.status === 200) {
                console.log("Order line updated successfully");
                setSnackbarMessage(dict["WS_Messages"]["Order line updated successfully"][language]);
                setSeverity("success");
                setOpenMessage(true);

                //update the amount of the item in the shopObject.Order_Lijnen
                var newCart = shopObject.Order_Lijnen.map((item) => {
                    if (item.OrderLijn_ID === _orderLine_ID) {
                        return { ...item, Aantal: newNumberOfProducts };
                    } else {
                        return item;
                    }
                });
                setAccount({ ...account, Cart: newCart });

                // var _mandje = await fetchWinkelmandje(account.Order.order_nr);
                var _mandje = await fetchWinkelmandje_with_delivery_info(account.Order.order_nr, isHomeDelivery);
                setShopObject(_mandje);
            } else {
                console.log("Failed to update Order line");
                setSnackbarMessage(dict["WS_Messages"]["Failed to update order line"][language]);
                setSeverity("error");
                setOpenMessage(true);
            }
        } catch (error) {
            console.error("Failed to fetch data:", error);
            setSnackbarMessage(dict["WS_Messages"]["Failed to update order line"][language]);
            setSeverity("error");
            setOpenMessage(true);
        }
    }

    async function addOnePieceClicked(_orderLine_ID) {
        console.log("add one piece clicked: " + _orderLine_ID);

        //body example:
        // {
        //     "Session": {
        //           "Session_ID": "C5E0DEA6-1537-41A2-84FA-1590FF0260D7",
        //           "Account_ID": "BB7DD27A-C3E8-4FA6-AC65-E49132D19BB2"
        //     },
        //     "Order_Nr": 5194,
        //     "OrderLijn_ID": "206e29a7-ecf3-4127-af7c-3390771f6df8",
        //     "Aantal": 3
        //   }

        var newNumberOfProducts = shopObject.Order_Lijnen.filter((item) => item.OrderLijn_ID === _orderLine_ID)[0].Aantal + 1;

        var body = {
            Session: account.Session,
            Order_Nr: account.Order.order_nr,
            OrderLijn_ID: _orderLine_ID,
            Aantal: newNumberOfProducts,
        };

        try {
            var response = await axios.post("Order/PostUpdateAantalOrderLijn", body);
            if (response.status === 200) {
                console.log(dict["WS_Messages"]["Order line updated successfully"][language]);
                setSnackbarMessage("Order line updated successfully");
                setSeverity("success");
                setOpenMessage(true);

                //update the amount of the item in the shopObject.Order_Lijnen
                var newCart = shopObject.Order_Lijnen.map((item) => {
                    if (item.OrderLijn_ID === _orderLine_ID) {
                        return { ...item, Aantal: newNumberOfProducts };
                    } else {
                        return item;
                    }
                });
                setAccount({ ...account, Cart: newCart });

                // var _mandje = await fetchWinkelmandje(account.Order.order_nr);
                var _mandje = await fetchWinkelmandje_with_delivery_info(account.Order.order_nr, isHomeDelivery);
                setShopObject(_mandje);
            } else {
                console.log("Failed to update order line");
                setSnackbarMessage(dict["WS_Messages"]["Failed to update order line"][language]);
                setSeverity("error");
                setOpenMessage(true);
            }
        } catch (error) {
            console.error("Failed to fetch data:", error);
            setSnackbarMessage(dict["WS_Messages"]["Failed to update order line"][language]);
            setSeverity("error");
            setOpenMessage(true);
        }
    }

    //todo: calculate_subtotals

    async function handleHomeDeliveryChanged() {
        console.log("Home delivery changed", !isHomeDelivery);
        // setIsHomeDelivery(!isHomeDelivery);

        // Order/GetWinkelmandje_WenstLeveringThuis/{Session_ID}/{Account_ID}/{Order_Nr}/{Wenst_LeveringThuis}
        try {
            var response = await axios.get(
                "Order/GetWinkelmandje_WenstLeveringThuis/" +
                account.Session.Session_ID +
                "/" +
                account.Session.Account_ID +
                "/" +
                account.Order.order_nr +
                "/" +
                !isHomeDelivery +
                "/" +
                language.toUpperCase()
            );
            if (response.status === 200) {
                console.log("Home delivery changed successfully");

                setSnackbarMessage(dict["WS_Messages"]["Home delivery changed successfully"][language]);
                setSeverity("success");
                setOpenMessage(true);

                //var _mandje = await fetchWinkelmandje(account.Order.order_nr);
                setShopObject(response.data);
                //calculateOverview(response.data);
            } else {
                console.log("Failed to change home delivery");
                setSnackbarMessage(dict["WS_Messages"]["Failed to change home delivery"][language]);
                setSeverity("error");
                setOpenMessage(true);
            }
        } catch (error) {
            console.error("Failed to fetch data:", error);
            setSnackbarMessage(dict["WS_Messages"]["Failed to change home delivery"][language]);
            setSeverity("error");
            setOpenMessage(true);
        }

        //todo: update backend about this change an get mandje again ...

    }

    async function handleActivateCoupon() {
        console.log("Activate coupon clicked");

        if (discountCode === "") {
            setSnackbarMessage(dict["WS_Messages"]["Please enter a discount code"][language]);
            setSeverity("error");
            setOpenMessage(true);
            return;
        }

        // Wk925hra (X)
        // Wuuob3sx
        // Wxbxgjjc
        // Wm74deb4
        // W5em4jn9
        // Wvswyfk8
        // Wtchhamb
        // Wf2peszg
        // Wa3zqbyc
        // Wahw5fbj
        // Wfmrzoo9
        // Wvvogbsk
        // Wj6g34sd
        // Wyqqg8f9
        // Wrcprhu6

        //body example:
        //     {
        //         "Session_ID": "dc879b2d-5831-4cd8-ad1c-4c4dae070d82",
        //         "Account_ID": "f3497de1-9b6b-48d4-9437-8b8b3825b7e6"
        // }

        var body = {
            Session_ID: account.Session.Session_ID,
            Account_ID: account.Session.Account_ID,
        };

        try {
            var response = await axios.put("Account/PutWaardebon_Activate/" + discountCode, body);
            if (response.status === 200) {
                console.log("Coupon activated successfully");
                setSnackbarMessage(dict["WS_Messages"]["Coupon activated successfully"][language]);
                setSeverity("success");
                setOpenMessage(true);

                var _mandje = await fetchWinkelmandje_with_delivery_info(account.Order.order_nr, isHomeDelivery);
                setShopObject(_mandje);
            } else {
                console.log("Failed to activate coupon");
                setSnackbarMessage(dict["WS_Messages"]["Failed to activate coupon"][language]);
                setSeverity("error");
                setOpenMessage(true);
            }
        } catch (error) {
            if (error.response.status === 400) {
                setSnackbarMessage(dict["WS_Messages"][error?.response?.data?.Message][language]);
                setSeverity("error");
                setOpenMessage(true);
            } else {
                console.error("Failed to fetch data:", error);
                setSnackbarMessage(dict["WS_Messages"]["Failed to activate coupon"][language]);
                setSeverity("error");
                setOpenMessage(true);
            }
        }
    }

    function gotoDeliveryDetails() {
        console.log("Go to delivery details clicked");
        setValue_horizontal(1);
    }

    async function gotoOrderOverview() {
        console.log("Go to order overview clicked");

        //body example:
        // {
        //     "Session": {
        //         "Session_ID": "c5e0dea6-1537-41a2-84fa-1590ff0260d7",
        //         "Account_ID": "bb7dd27a-c3e8-4fa6-ac65-e49132d19bb2"
        //    },
        //    "Lev_Naam": "Familienaam",
        //    "Lev_Voornaam": "Voornaam",
        //    "Lev_Adres": "Stationsstraat",
        //    "Lev_Huisnr": "76",
        //    "Lev_Adres_2": "",
        //    "Lev_Postcode": "8780",
        //    "Lev_Gemeente": "Oostrozebeke",
        //    "Lev_Land": "BE",
        //    "Naam_Oudste": "Naam Oudste",
        //    "Voornaam_Oudste": "Voornaam Oudste",
        //    "Tel": "05gdfg53",
        //    "GSM": "04gfhfh075"
        // }

        //if firstName, lastName, street, houseNr, postcode, city, telephone are empty then show error message
        if (firstName === "") {
            setSnackbarMessage(dict["WS_Messages"]["First name is required."][language]);
            setSeverity("error");
            setOpenMessage(true);
            return;
        }

        if (lastName === "") {
            setSnackbarMessage(dict["WS_Messages"]["Last name is required."][language]);
            setSeverity("error");
            setOpenMessage(true);
            return;
        }

        if (street === "") {
            setSnackbarMessage(dict["WS_Messages"]["Street is required."][language]);
            setSeverity("error");
            setOpenMessage(true);
            return;
        }

        if (houseNr === "") {
            setSnackbarMessage(dict["WS_Messages"]["House number is required."][language]);
            setSeverity("error");
            setOpenMessage(true);
            return;
        }

        if (postcode === "") {
            setSnackbarMessage(dict["WS_Messages"]["Postcode is required."][language]);
            setSeverity("error");
            setOpenMessage(true);
            return;
        }

        if (city === "") {
            setSnackbarMessage(dict["WS_Messages"]["City is required."][language]);
            setSeverity("error");
            setOpenMessage(true);
            return;
        }

        if (telephone === "") {
            setSnackbarMessage(dict["WS_Messages"]["Telephone is required."][language]);
            setSeverity("error");
            setOpenMessage(true);
            return;
        }

        var body = {
            Session: account.Session,
            Lev_Naam: lastName,
            Lev_Voornaam: firstName,
            Lev_Adres: street,
            Lev_Huisnr: houseNr,
            Lev_Adres_2: bus,
            Lev_Postcode: postcode,
            Lev_Gemeente: city,
            Lev_Land: "BE",
            Naam_Oudste: childLastName,
            Voornaam_Oudste: childFirstName,
            Tel: telephone,
            GSM: gsm,
        };

        try {
            var response = await axios.put("Account/PutAccount_Levering/" + account.Session.Account_ID, body);
            if (response.status === 200) {
                // console.log("Order updated successfully");
                // setSnackbarMessage("Delivery details updated successfully");
                // setSeverity("success");
                // setOpenMessage(true);
                setValue_horizontal(2);
            } else {
                console.log("Failed to update Order");
                setSnackbarMessage(dict["WS_Messages"]["Failed to update delivery details"][language]);
                setSeverity("error");
                setOpenMessage(true);
                return;
                //todo: if failed then disable the next button
            }
        } catch (error) {
            console.error("Failed to fetch data:", error);
            setSnackbarMessage(dict["WS_Messages"]["Failed to update delivery details"][language]);
            setSeverity("error");
            setOpenMessage(true);
            return;
            //todo: if failed then disable the next button
        }

    }

    async function gotoPayment() {
        console.log("Go to payment clicked");

        var response_init_Mollie = await init_mollie();

        console.log(response_init_Mollie);

        if (response_init_Mollie !== undefined) {
            if (response_init_Mollie.status === "open") {
                const formName = "form1";
                const method = "POST";
                const url = response_init_Mollie.payment_URL;

                const body = `
        <body onload="document.${formName}.submit()">
            <form name="${formName}" method="${method}" action="${url}">
            </form>
        </body>
    `;

                const myWindow = window.open("", "_self");
                myWindow.document.write(body);
                myWindow.document.close();
            }
            if (response_init_Mollie.status === "gratis") {
                window.location.href = "cart?result=gratis&order_nr=" + account.Order.order_nr;
            }
        }

        // setValue_horizontal(3);
    }

    // Example function to handle the callback URL
    function setupEventListeners() {
        window.addEventListener("load", () => {
            const urlParams = new URLSearchParams(window.location.search);
            const paymentStatus = urlParams.get("result"); // Example parameter
            //order_nr
            const paymentOrder_nr = urlParams.get("order_nr"); // Example parameter

            //if there are parameters in the URL and paymentStatus is not null and paymentOrder_nr is not null
            if (urlParams && paymentStatus !== null && paymentOrder_nr !== null) {
                //call the function to update the order status
                updateOrderStatus(paymentOrder_nr, paymentStatus);
            }
        });

        document.addEventListener("keydown", function (event) {
            if (event.key === 121) {
                // Your code to handle F10 key press
            }
        });
    }

    async function updateOrderStatus(_order_nr, _paymentStatus) {
        console.log("updateOrderStatus: " + _order_nr + " " + _paymentStatus);

        //for testing purposes
        //"open" || _paymentStatus === "canceled" || _paymentStatus === "pending" || _
        //paymentStatus === "authorized" || _paymentStatus === "expired" || _paymentStatus === "failed"
        //_paymentStatus = "gratis";
        // setMollieResponse(_paymentStatus);
        // setValue_horizontal(3);
        // return;

        if (_paymentStatus === "unknown") {
            var mollieStatus = await getMollieStatus(_order_nr);
            console.log("Mollie status: " + mollieStatus);
            _paymentStatus = mollieStatus.status;
        }

        if (_paymentStatus === "paid" || _paymentStatus === "gratis") {
            setValue_horizontal(3);

            // var _orderCombo = await fetchOrder(_order_nr);
            var _orderCombo = await fetchWinkelmandje(_order_nr);
            console.log("Order combo: " + _orderCombo);

            setMollieOrderCombo(_orderCombo);
            setMollieResponse(_paymentStatus);
        }

        //open ,canceled ,pending,authorized,expired,failed

        if (
            _paymentStatus === "open" ||
            _paymentStatus === "canceled" ||
            _paymentStatus === "pending" ||
            _paymentStatus === "authorized" ||
            _paymentStatus === "expired" ||
            _paymentStatus === "failed"
        ) {
            setValue_horizontal(3);
            setMollieResponse(_paymentStatus);
        }

        // millieStatus = {
        //     order_nr: 394403,
        //     status: "paid",
        //     mollie_ID: "tr_u2rZ6bxASU",
        //     amount: {
        //         Currency: "EUR",
        //         Value: "9.00",
        //     },
        //     payment_URL: "",
        // }

        calculateOverview(_orderCombo);
    }

    async function getMollieStatus(_order_nr) {
        //Order/GetMollieStatus/{{Session_ID}}/{{Account_ID}}/292266

        let sessAcc = account.Session.Session_ID + "/" + account.Session.Account_ID;

        try {
            var response = await axios.get("Order/GetMollieStatus/" + sessAcc + "/" + _order_nr);
            if (response.status === 200) {
                console.log(response.data);
                return response.data.Mollie_Response;
            } else {
                return {};
            }
        } catch (error) {
            console.error("Failed to fetch data:", error);
            return {};
        }
    }

    async function init_mollie() {
        let sessAcc = account.Session.Session_ID + "/" + account.Session.Account_ID;

        try {
            var response = await axios.get(
                "Order/GetInitieerMollie/" + sessAcc + "/" + account.Order.order_nr + "/" + isHomeDelivery.toString() + "/" + language.toUpperCase()
            );
            if (response.status === 200) {
                console.log(response.data.Mollie_Response);
                return response.data.Mollie_Response;
            } else {
                return {};
            }
        } catch (error) {
            console.error("Failed to fetch data:", error);
            return {};
        }
    }

    const renderOverviewGrid = (_isActive, onNextClick, buttonLabel) => {
        return (
            <Grid container spacing={0} id="overviewGrid">
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: 1 }}>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                            {dict["WS_Labels"]["Overview_title"][language]}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: 1 }}>
                        <Typography variant="body1" component="div" sx={{ flexGrow: 0 }}>
                            {dict["WS_Labels"]["Subtotal"][language]}
                        </Typography>
                        <Typography variant="body1" component="div" sx={{ flexGrow: 1, textAlign: "right", minWidth: 100 }}>
                            € {subtotal.toFixed(2)}
                        </Typography>
                    </Box>
                </Grid>
                {discount > 0 && (
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: 1 }}>
                            <Typography variant="body1" component="div" sx={{ flexGrow: 0 }}>
                                {/* if account.Webshop_Account.uiterste_besteldatum_korting_proc !== 0 replace 'XX' with account.Webshop_Account.uiterste_besteldatum_korting_proc  */}
                                {account.Webshop_Account.uiterste_besteldatum_korting_proc !== 0
                                    ? dict["WS_Labels"]["Discount_with_percentage"][language].replace(
                                        "XX",
                                        account.Webshop_Account.uiterste_besteldatum_korting_proc
                                    )
                                    : dict["WS_Labels"]["Discount"][language]}
                            </Typography>
                            <Typography variant="body1" component="div" sx={{ flexGrow: 1, textAlign: "right", minWidth: 100 }}>
                                € {discount > 0 ? (-discount).toFixed(2) : "0.00"}
                            </Typography>
                        </Box>
                    </Grid>
                )}
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: 1 }}>
                        <Typography variant="body1" component="div" sx={{ flexGrow: 0 }}>
                            {dict["WS_Labels"]["Delivery_costs"][language]}
                        </Typography>
                        <Typography variant="body1" component="div" sx={{ flexGrow: 1, textAlign: "right", minWidth: 100 }}>
                            € {deliveryCosts.toFixed(2)}
                        </Typography>
                    </Box>
                </Grid>
                {showDiscountDetails && (
                    <>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: 1 }}>
                                <Typography variant="body1" component="div" sx={{ flexGrow: 0 }}>
                                    {dict["WS_Labels"]["Discount_coupon"][language]}
                                </Typography>
                                <Typography variant="body1" component="div" sx={{ flexGrow: 1, textAlign: "right", minWidth: 100 }}>
                                    € {discountCoupon.toFixed(2)}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: 1 }}>
                                <Typography variant="body1" component="div" sx={{ flexGrow: 0 }}>
                                    {dict["WS_Labels"]["Balance_coupon"][language]}
                                </Typography>
                                <Typography variant="body1" component="div" sx={{ flexGrow: 1, textAlign: "right", minWidth: 100 }}>
                                    € {balanceCoupon.toFixed(2)}
                                </Typography>
                            </Box>
                        </Grid>
                    </>
                )}

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: 1 }}>
                        <Typography variant="body1" component="div" sx={{ flexGrow: 0 }}>
                            {dict["WS_Labels"]["Home_delivery"][language]}
                        </Typography>
                        <Box sx={{ flexGrow: 1, textAlign: "right" }}>
                            <FormControlLabel
                                sx={{ width: "15px" }}
                                control={<Checkbox />}
                                disabled={isHomeDeliveryDisabled || !_isActive}
                                onChange={() => {
                                    handleHomeDeliveryChanged();
                                }}
                                checked={isHomeDelivery}
                            />
                        </Box>
                    </Box>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: 1 }}>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 0 }}>
                            {dict["WS_Labels"]["Total"][language]}
                        </Typography>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1, textAlign: "right", minWidth: 100 }}>
                            € {total.toFixed(2)}
                        </Typography>
                        {/* if today is before 1 sep 2024 */}
                    </Box>
                </Grid>
                {/* {((new Date() < new Date("2024-09-01")) && (value_horizontal !== 3)) && ( */}
                {/* ((deliveryCosts > 0) && (value_horizontal !== 3)) && ( */}
                {((deliveryCosts > 0 && new Date() >= new Date(Uiterste_Besteldatum)) && (value_horizontal !== 3)) && (
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display={"flex"}>
                        <LocalShippingOutlinedIcon sx={{ pl: 1, pr: 1, color: "#219EBC" }} />
                        <Typography variant="contained" component="div" sx={{ flexGrow: 0, pb: 1, color: "#219EBC" }}>
                            {dict["WS_Messages"]["Free shipping"][language]}
                        </Typography>
                    </Grid>
                )}

                {onNextClick !== null && (
                    <>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Box mb={1} mr={1}>
                                <Button
                                    sx={{ width: "100%" }}
                                    variant="contained"
                                    color="primary"
                                    startIcon={<ShoppingBasketOutlinedIcon />}
                                    onClick={onNextClick}
                                    disabled={areGotoButtonsDisabled}
                                >
                                    {buttonLabel}
                                </Button>
                            </Box>
                        </Grid>

                        {showGratisButton ? (
                            <>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <Box mb={1} mr={1}>
                                        <Button
                                            sx={{ width: "100%" }}
                                            variant="contained"
                                            color="error"
                                            backgroundColor="#177e97"
                                            onClick={() => {
                                                handleGratisOrder();
                                            }}
                                        >
                                            Gratis
                                        </Button>
                                    </Box>
                                </Grid>
                                <Grid item xs={6} md={6}>
                                    <Box mb={1} mr={1}>
                                        <TextField
                                            id="outlined-basic"
                                            label="pin code"
                                            variant="outlined"
                                            size="small"
                                            value={pincode}
                                            fullWidth
                                            type="password"
                                            onChange={(e) => setPincode(e.target.value)}
                                        />
                                    </Box>
                                </Grid>
                            </>
                        ) : null}
                        <Grid item xs={12}>
                            <Box sx={{ width: "95%", borderBottom: 1, borderColor: "divider" }}></Box>
                        </Grid>
                    </>
                )}

                {_isActive && (
                    <>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: 1 }}>
                                <Typography variant="body1" component="div" sx={{ flexGrow: 0 }}>
                                    {dict["WS_Labels"]["Discount_code"][language]}
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: 1 }}>
                                <TextField
                                    sx={{ flexGrow: 0, width: "140px" }}
                                    id="outlined-basic"
                                    label={dict["WS_Labels"]["Code"][language]}
                                    variant="outlined"
                                    size="small"
                                    value={discountCode}
                                    onChange={(e) => setDiscountCode(e.target.value)}
                                />
                                <Button
                                    sx={{ width: "100%", marginLeft: 1, flexGrow: 1, textAlign: "right", backgroundColor: "#177e97" }}
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleActivateCoupon()}
                                >
                                    {dict["WS_Messages"]["Activate coupon"][language]}
                                </Button>
                            </Box>
                        </Grid>
                    </>
                )}
            </Grid>
        );
    };

    function renderTableContainer(_order, _isActive) {
        return (
            <TableContainer>
                <Table sx={{ minWidth: 350 }} stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow
                            sx={{
                                "& th": {
                                    color: "#fdd835",
                                    backgroundColor: "#023047",
                                },
                            }}
                        >
                            {filteredTableColumns.map((column) => (
                                <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                                    {column.label}
                                </TableCell>
                            ))}

                            <TableCell key={"delete"} align={"center"}>
                                <Button></Button>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {_order?.Order_Lijnen?.map((row) => {
                            return (
                                <TableRow hover tabIndex={-1} key={row.OrderLijn_Seq}>
                                    {/* {!matchesSM && ( */}
                                    {true && (
                                        <TableCell align={"center"}>
                                            <Box
                                                component="img"
                                                sx={{
                                                    height: 50,
                                                    borderRadius: 1,
                                                }}
                                                alt="The house from the offer."
                                                src={
                                                    row.ArrImage
                                                        ? "data:image/jpeg;base64," + row.ArrImage.toString()
                                                        : require("../images/no_image_available.jpg")
                                                }
                                            />
                                        </TableCell>
                                    )}

                                    <TableCell align={"left"}>{row.Artikel_Naam}</TableCell>

                                    {!matchesSM && <TableCell align={"left"}>{dict["WS_Labels"][row.KL_SE_ZW][language]}</TableCell>}

                                    <TableCell align={"left"}>
                                        {/* box with centerd items */}
                                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "left", pl: 3 }}>
                                            {_isActive && (
                                                <IconButton
                                                    color="secondary"
                                                    onClick={() => {
                                                        removeOnePieceClicked(row.OrderLijn_ID);
                                                    }}
                                                    sx={{ height: "50px", width: "40px" }}
                                                >
                                                    <RemoveIcon />
                                                </IconButton>
                                            )}
                                            <Box
                                                id="outlined-number"
                                                sx={{
                                                    // width: '20px',
                                                    display: "inline",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    border: 1,
                                                    borderColor: "primary.main",
                                                    borderRadius: "4px",
                                                    padding: "5px",
                                                    border: "0px solid #ced4da",
                                                }}
                                            >
                                                {row.Aantal}
                                            </Box>
                                            {_isActive && (
                                                <IconButton
                                                    color="secondary"
                                                    onClick={() => {
                                                        addOnePieceClicked(row.OrderLijn_ID);
                                                    }}
                                                    sx={{ height: "50px", width: "40px" }}
                                                >
                                                    <AddIcon />
                                                </IconButton>
                                            )}
                                        </Box>
                                    </TableCell>

                                    <TableCell align={"right"}>€ {row.Eenheidsprijs.toFixed(2)}</TableCell>
                                    <TableCell align={"right"}>€ {(row.Aantal * row.Eenheidsprijs).toFixed(2)}</TableCell>

                                    {_isActive && (
                                        <TableCell key={"delete" + row.ID} align={"center"}>
                                            <IconButton
                                                onClick={() => {
                                                    handleDeleteClicked(row.OrderLijn_ID);
                                                }}
                                            >
                                                <DeleteForeverIcon />
                                            </IconButton>
                                        </TableCell>
                                    )}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }

    async function handleGratisOrder() {
        if (pincode === "timoleon") {
            console.log("Gratis order clicked");

            var body = {
                Session_ID: account.Session.Session_ID,
                Account_ID: account.Session.Account_ID,
            };

            // Order/PutOrderGratis/{Order_Nr}/{Levering_Thuis}/{Taalcode}
            var _order_nr = account.Order.order_nr;
            var _levering_thuis = isHomeDelivery;
            var _taalcode = language.toUpperCase();

            try {
                var response = await axios.put("Order/PutOrderGratis/" + _order_nr + "/" + _levering_thuis + "/" + _taalcode, body);
                if (response.status === 200) {
                    window.location.href = "cart?result=gratis&order_nr=" + account.Order.order_nr;
                } else {
                    console.log("Failed to send free order");
                    setSnackbarMessage("Failed to send free order");
                    setSeverity("error");
                    setOpenMessage(true);
                }
            } catch (error) {
                console.error("Failed to send free order", error);
                setSnackbarMessage("Failed to send free order");
                setSeverity("error");
                setOpenMessage(true);
            }

        } else {
            setSnackbarMessage("Pincode is not correct");
            setSeverity("error");
            setOpenMessage(true);
        }
    }

    return (
        <>
            <Box sx={{ width: "100%" }}>
                <Box
                    sx={{
                        borderBottom: 1,
                        borderColor: "divider",
                        // maxWidth: { xs: 200, sm: 300, md: 500, lg: "100%" }
                    }}
                >
                    <Tabs
                        value={value_horizontal}
                        onChange={handleChange_horizontal}
                        aria-label="basic tabs example"
                        variant="scrollable"
                        scrollButtons
                        allowScrollButtonsMobile
                        disabled={areTabsDisabled}
                    >
                        <Tab
                            label={dict["WS_Messages"]["tab_Winkelwagen"][language]}
                            {...a11yProps(0)}
                            sx={{ fontSize: 14 }}
                            disabled={areTabsDisabled}
                        />
                        <Tab
                            label={dict["WS_Messages"]["tab_Bestelling plaatsen"][language]}
                            {...a11yProps(1)}
                            sx={{ fontSize: 14 }}
                            disabled={areTabsDisabled}
                        />
                        <Tab
                            label={dict["WS_Messages"]["tab_Bestelling betalen"][language]}
                            {...a11yProps(2)}
                            sx={{ fontSize: 14 }}
                            disabled={areTabsDisabled}
                        />
                        <Tab
                            label={dict["WS_Messages"]["tab_Bestelling bevestigd"][language]}
                            {...a11yProps(3)}
                            sx={{ fontSize: 14 }}
                            disabled={areTabsDisabled}
                        />
                    </Tabs>
                </Box>

                <TabPanel_Horizontal value={value_horizontal} index={0}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={8} xl={9} order={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 1 }}>
                            <Box pr={2}>
                                {/* Table active */}
                                <Paper sx={{ overflow: "hidden", marginTop: 1 }}>{renderTableContainer(shopObject, true)}</Paper>
                            </Box>
                        </Grid>

                        {/* Right Grid Item */}
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={4}
                            xl={3}
                            order={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 1 }}
                            sx={{ backgroundColor: "white" }}
                        >
                            {renderOverviewGrid(true, gotoDeliveryDetails, dict["WS_Labels"]["gotoDeliveryDetails"][language])}
                        </Grid>
                    </Grid>
                </TabPanel_Horizontal>

                <TabPanel_Horizontal value={value_horizontal} index={1}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={8} xl={9} order={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 1 }}>
                            <Box pr={2}>
                                <Paper sx={{ overflow: "hidden", marginTop: 1, p: 2 }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={12}>
                                            <Typography>{dict["WS_Labels"]["deliveryDetailsTitle"][language]}</Typography>
                                        </Grid>

                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                id="outlined-basic"
                                                label={dict["WS_Labels"]["firstName"][language]}
                                                variant="outlined"
                                                size="small"
                                                value={firstName}
                                                fullWidth
                                                required
                                                onChange={(e) => setFirstName(e.target.value)}
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                id="outlined-basic"
                                                label={dict["WS_Labels"]["lastName"][language]}
                                                variant="outlined"
                                                size="small"
                                                value={lastName}
                                                fullWidth
                                                required
                                                onChange={(e) => setLastName(e.target.value)}
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                id="outlined-basic"
                                                label={dict["WS_Labels"]["street"][language]}
                                                variant="outlined"
                                                size="small"
                                                value={street}
                                                fullWidth
                                                required
                                                onChange={(e) => setStreet(e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} md={6}>
                                                    <TextField
                                                        id="outlined-basic"
                                                        label={dict["WS_Labels"]["houseNr"][language]}
                                                        variant="outlined"
                                                        size="small"
                                                        value={houseNr}
                                                        fullWidth
                                                        required
                                                        onChange={(e) => setHouseNr(e.target.value)}
                                                    />
                                                </Grid>

                                                <Grid item xs={12} md={6}>
                                                    <TextField
                                                        id="outlined-basic"
                                                        label={dict["WS_Labels"]["bus"][language]}
                                                        variant="outlined"
                                                        size="small"
                                                        value={bus}
                                                        fullWidth
                                                        onChange={(e) => setBus(e.target.value)}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                id="outlined-basic"
                                                label={dict["WS_Labels"]["postcode"][language]}
                                                variant="outlined"
                                                size="small"
                                                value={postcode}
                                                fullWidth
                                                required
                                                onChange={(e) => setPostcode(e.target.value)}
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                id="outlined-basic"
                                                label={dict["WS_Labels"]["city"][language]}
                                                variant="outlined"
                                                size="small"
                                                value={city}
                                                fullWidth
                                                required
                                                onChange={(e) => setCity(e.target.value)}
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                id="outlined-basic"
                                                label={dict["WS_Labels"]["email"][language]}
                                                variant="outlined"
                                                size="small"
                                                value={email}
                                                fullWidth
                                                disabled={true}
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                id="telephone_textbox"
                                                label={dict["WS_Labels"]["telephone"][language]}
                                                variant="outlined"
                                                size="small"
                                                value={telephone}
                                                fullWidth
                                                required
                                                onChange={(e) => setTelephone(e.target.value)}
                                            />
                                        </Grid>

                                        {/* <Grid item xs={12} md={6} >
                                            <TextField
                                                id="outlined-basic"
                                                label={dict["WS_Labels"]["gsm"][language]}
                                                variant="outlined"
                                                size="small"
                                                value={gsm}
                                                fullWidth
                                            />
                                        </Grid> */}
                                    </Grid>
                                </Paper>
                            </Box>
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={4}
                            xl={3}
                            order={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 1 }}
                            sx={{ backgroundColor: "white" }}
                        >
                            {renderOverviewGrid(false, gotoOrderOverview, dict["WS_Labels"]["gotoOrderOverview"][language])}
                        </Grid>
                    </Grid>
                </TabPanel_Horizontal>

                <TabPanel_Horizontal value={value_horizontal} index={2}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={8} xl={9} order={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 1 }}>
                            <Box pr={2}>
                                {/* Table active */}
                                <Paper sx={{ overflow: "hidden", marginTop: 1 }}>{renderTableContainer(shopObject, false)}</Paper>
                            </Box>
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={4}
                            xl={3}
                            order={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 1 }}
                            sx={{ backgroundColor: "white" }}
                        >
                            {renderOverviewGrid(false, gotoPayment, dict["WS_Labels"]["gotoPayment"][language])}
                        </Grid>
                    </Grid>
                </TabPanel_Horizontal>

                <TabPanel_Horizontal value={value_horizontal} index={3}>
                    {(mollieResponse === "paid" || mollieResponse === "gratis") && (
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={8} xl={9} order={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 1 }}>
                                <Box pr={2}>
                                    <Grid container spacing={0}>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                    padding: 1,
                                                    paddingBottom: 2,
                                                }}
                                            >
                                                <Typography variant="h6" component="div" sx={{ flexGrow: 1, textAlign: "left" }}>
                                                    {dict["WS_Messages"]["mollie_" + mollieResponse + "_Title"][language]}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: 1 }}>
                                                <Typography variant="body1" component="div" sx={{ flexGrow: 0 }}>
                                                    {dict["WS_Labels"]["OrderNumber"][language]}
                                                </Typography>
                                                <Typography variant="body1" component="div" sx={{ flexGrow: 1, textAlign: "right" }}>
                                                    {mollieOrderCombo.Order.order_nr}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: 1 }}>
                                                <Typography variant="body1" component="div" sx={{ flexGrow: 0 }}>
                                                    {dict["WS_Labels"]["DateOrdered"][language]}
                                                </Typography>
                                                <Typography variant="body1" component="div" sx={{ flexGrow: 1, textAlign: "right" }}>
                                                    {formatDate(mollieOrderCombo.Order.creatie_datum)}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: 1 }}>
                                                <Typography variant="body1" component="div" sx={{ flexGrow: 0 }}>
                                                    {dict["WS_Labels"]["Delivery"][language]}
                                                </Typography>
                                                <Typography variant="body1" component="div" sx={{ flexGrow: 1, textAlign: "right" }}>
                                                    {mollieOrderCombo.Order.ws_levering_thuis
                                                        ? dict["WS_Labels"]["deliveryHome"][language]
                                                        : dict["WS_Labels"]["deliverySchool"][language]}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: 1 }}>
                                                <Typography variant="body1" component="div" sx={{ flexGrow: 0 }}>
                                                    {dict["WS_Labels"]["PaymentMethod"][language]}
                                                </Typography>
                                                <Typography variant="body1" component="div" sx={{ flexGrow: 1, textAlign: "right" }}>
                                                    {mollieOrderCombo.Order.ws_betaalwijze + "/" + mollieOrderCombo.Order.ws_betaalwijze_brand}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    {/* Table active */}
                                    <Paper sx={{ overflow: "hidden", marginTop: 1 }}>{renderTableContainer(mollieOrderCombo, false)}</Paper>
                                </Box>
                            </Grid>

                            {/* Right Grid Item */}
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={4}
                                xl={3}
                                order={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 1 }}
                                sx={{ backgroundColor: "white" }}
                            >
                                {renderOverviewGrid(false, null, "")}
                            </Grid>
                        </Grid>
                    )}

                    {(mollieResponse === "open" ||
                        mollieResponse === "canceled" ||
                        mollieResponse === "pending" ||
                        mollieResponse === "authorized" ||
                        mollieResponse === "expired" ||
                        mollieResponse === "failed") && (
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={12} lg={8} xl={9} order={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 1 }}>
                                    <Box pr={2}>
                                        <Grid container spacing={0}>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                        alignItems: "center",
                                                        padding: 1,
                                                        paddingBottom: 2,
                                                    }}
                                                >
                                                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, textAlign: "left" }}>
                                                        {dict["WS_Messages"]["mollie_" + mollieResponse + "_Title"][language]}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: 1 }}>
                                                    <Typography variant="body1" component="div" sx={{ flexGrow: 1, textAlign: "left" }}>
                                                        {dict["WS_Messages"]["mollie_" + mollieResponse + "_Message"][language]}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        )}
                </TabPanel_Horizontal>
            </Box>
            <Box sx={{ height: 90 }}></Box>

            <Snackbar
                open={openMessage}
                onClose={() => {
                    setOpenMessage(false);
                }}
                TransitionComponent={SlideTransition}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                key="Snackbar"
            >
                <Alert
                    severity={severity}
                    sx={{ marginTop: 2 }}
                    onClose={() => {
                        setOpenMessage(false);
                    }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    );
};

function SlideTransition(props: SlideProps) {
    return <Slide {...props} direction="up" />;
}

function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // January is 0!
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
}

export default WS_Cart;

// shopObject example:
// {
//     Session: {
//       Session_ID: "5d1d7550-522d-471f-b06c-ab1c8e2d5f3e",
//       Account_ID: "f3497de1-9b6b-48d4-9437-8b8b3825b7e6",
//     },
//     Webshop_Account: {
//       ID: "f3497de1-9b6b-48d4-9437-8b8b3825b7e6",
//       email: "bderom@gmail.com",
//       pw: "bderom",
//       geldig_van: "0001-01-01T00:00:00",
//       geldig_tot: "0001-01-01T00:00:00",
//       naam: "Derom",
//       voornaam: "Bernard",
//       adres: "Hubert Maelfaitlaan",
//       huisnr: "7",
//       adres_2: "",
//       postcode: "9830",
//       gemeente: "Sint-Martens-Latem",
//       land: "BE",
//       lev_naam: "Derom",
//       lev_voornaam: "Bernard",
//       lev_adres: "Hubert Maelfaitlaan",
//       lev_huisnr: "7",
//       lev_adres_2: "",
//       lev_postcode: "9830",
//       lev_gemeente: "Sint-Martens-Latem",
//       lev_land: "BE",
//       tel: "+32497251322",
//       gsm: "",
//       creditcard_type: "",
//       creditcard_number: "",
//       creditcard_expmonth: 0,
//       creditcard_expyear: 0,
//       creditcard_name: "",
//       creditcard_firstname: "",
//       naam_oudste: "TT",
//       voornaam_oudste: "SS",
//       kent_achtergronden: true,
//       uiterste_besteldatum: "0001-01-01T00:00:00",
//       uiterste_besteldatum_korting_proc: 0,
//       webshop_account_nr: 4020,
//       nieuwsbrief_uitschrijven: "0001-01-01T00:00:00",
//       opt_in: true,
//     },
//     Order: {
//       order_nr: 386336,
//       skontr_nr: 16463,
//       creatie_datum: "2024-05-30T12:01:56.43",
//       labo_datum: "0001-01-01T00:00:00",
//       prodbon_bonnr: 0,
//       opmerking: "",
//       prijs_school: 22.5,
//       prijs_lln: 22.5,
//       verzend_datum: "0001-01-01T00:00:00",
//       productie_datum: "0001-01-01T00:00:00",
//       aangemaakt_door: "",
//       medium: "WS",
//       medium_label: "Webshop",
//       webshop_account_ID: "f3497de1-9b6b-48d4-9437-8b8b3825b7e6",
//       ws_bedrag_betaald: 0,
//       ws_betaalwijze: "",
//       ws_betaalwijze_brand: "",
//       ws_status: 0,
//       ws_payID: "0",
//       ws_verzendkosten: 0,
//       ws_waardebon_ID: "00000000-0000-0000-0000-000000000000",
//       ws_waardebon_bedrag: 0,
//       environment: "S",
//       ws_levering_thuis: false,
//       ws_korting: 0,
//       verwachte_leveringsdatum: "2023-06-08T00:00:00",
//     },
//     Order_Lijnen: [
//       {
//         Order_Nr: 386336,
//         OrderLijn_Seq: 1,
//         Skontr_Nr: 16463,
//         Skontrd_Seq: 1,
//         Artikel_Nr: 8,
//         Artikel_Naam: "13 x 18",
//         Artikel_Naam_Lang: "Fotoprint 13x18",
//         Gratis: false,
//         Fotomap_Nr: 36868,
//         Foto_File: "0029.png",
//         Achtergrond_ID: "6843eeae-f8ff-4d73-a42e-5a7fdf4f9b46",
//         KL_SE_ZW: "KL",
//         Aantal: 2,
//         Klasfoto: "",
//         Eenheidsprijs: 7.5,
//         Verzendkosten: true,
//         Artikel_Groep_ID: "FOTO",
//         Artikel_Subgroep_ID: "STFOTO",
//         Download_File: "",
//         Download_Reg: "",
//         OrderLijn_ID: "9221a3b3-f95f-4eda-ab72-0abd13439c92",
//         ArrImage: "/9j/4AAQSkZJRgABAQEBLAEsAAD/2wBDAAgGBgcGBQgHBwcJCQgKDBQNDAsLDBkSEw8UHRofHh
//         Width: 200,
//         Height: 300,
//         Prijs: 7.5,
//         Prijs_Promo: 0,
//       },
//       {
//         Order_Nr: 386336,
//         OrderLijn_Seq: 7,
//         Skontr_Nr: 15998,
//         Skontrd_Seq: 3,
//         Artikel_Nr: 131,
//         Artikel_Naam: "13 x 18",
//         Artikel_Naam_Lang: "Foto 13x18 kleur ",
//         Gratis: false,
//         Fotomap_Nr: 35399,
//         Foto_File: "2059.jpg",
//         Achtergrond_ID: "00000000-0000-0000-0000-000000000000",
//         KL_SE_ZW: "KL",
//         Aantal: 1,
//         Klasfoto: "",
//         Eenheidsprijs: 7.5,
//         Verzendkosten: true,
//         Artikel_Groep_ID: "FOTO",
//         Artikel_Subgroep_ID: "STFOTO",
//         Download_File: "",
//         Download_Reg: "",
//         OrderLijn_ID: "7df9b74c-94f9-4244-8edc-58d14529ee4b",
//         ArrImage: "/9j/4AAQSkZJRgABAQEBLAEsAAD/2wBDAAgGBgcGBQgHBwcJCQgKD
//         Width: 300,
//         Height: 200,
//         Prijs: 7.5,
//         Prijs_Promo: 0,
//       },
//     ],
//     Uiterste_Besteldatum: "2023-05-30T00:00:00",
//     Verzendkosten: 5,
//     Beschikbaar_Bedrag_Waardebonnen: 199,
//     Show_Keuze_LeveringAanHuis: false,
//   }