import useLanguageContext from "../hooks/useLanguageContext";

import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Box, Typography, Stack, Grid, Button, Card, CardMedia, CardContent } from "@mui/material";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useEnvironment from "../hooks/useEnvironment";

import WebsiteDict from "../data/WebsiteDict";

const WS_Help = () => {
    const { language, setLanguage } = useLanguageContext();
    const { environment, setEnvironment } = useEnvironment();

    const [dict, setDict] = useState(WebsiteDict);

    useEffect(() => {
        setDict(WebsiteDict);
    }, [WebsiteDict]);

    useEffect(() => {
        window.scrollTo(0, 0);

        setEnvironment({ ...environment, selectedView: "help" });
    }, []);

    const [expanded, setExpanded] = useState("panel1");

    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <>
            <Stack spacing={8} m={3}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12} mt={2}>
                        <Accordion sx={{ minHeight: "70px" }} expanded={expanded === "panel1"} onChange={handleChange("panel1")}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                <Typography color={"secondary"} fontWeight={600} variant="h6">
                                    {dict["WS_Messages"]["faq_block1_title1"][language]}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box maxWidth={900}>
                                    <Typography align="left">{dict["WS_Messages"]["faq_block1_text1"][language]}</Typography>
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>

                    <Grid item xs={12} md={12} mt={3}>
                        <Accordion sx={{ minHeight: "70px" }} expanded={expanded === "panel2"} onChange={handleChange("panel2")}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                <Typography color={"secondary"} fontWeight={600} variant="h6">
                                    {dict["WS_Messages"]["faq_block2_title1"][language]}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box maxWidth={900}>
                                    <Typography align="left">{dict["WS_Messages"]["faq_block2_text1"][language]}</Typography>
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>

                    <Grid item xs={12} md={12} mt={3}>
                        <Accordion sx={{ minHeight: "70px" }} expanded={expanded === "panel3"} onChange={handleChange("panel3")}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                <Typography color={"secondary"} fontWeight={600} variant="h6">
                                    {dict["WS_Messages"]["faq_block3_title1"][language]}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box maxWidth={900}>
                                    <Typography align="left">{dict["WS_Messages"]["faq_block3_text1"][language]}</Typography>
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>

                    <Grid item xs={12} md={12} mt={3}>
                        <Accordion sx={{ minHeight: "70px" }} expanded={expanded === "panel4"} onChange={handleChange("panel4")}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                <Typography color={"secondary"} fontWeight={600} variant="h6">
                                    {dict["WS_Messages"]["faq_block4_title1"][language]}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box maxWidth={900}>
                                    <Typography align="left">{dict["WS_Messages"]["faq_block4_text1"][language]}</Typography>
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>

                    <Grid item xs={12} md={12} mt={3}>
                        <Accordion sx={{ minHeight: "70px" }} expanded={expanded === "panel5"} onChange={handleChange("panel5")}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                <Typography color={"secondary"} fontWeight={600} variant="h6">
                                    {dict["WS_Messages"]["faq_block5_title1"][language]}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box maxWidth={900}>
                                    <Typography align="left">{dict["WS_Messages"]["faq_block5_text1"][language]}</Typography>
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>

                    <Grid item xs={12} md={12} mt={3}>
                        <Accordion sx={{ minHeight: "70px" }} expanded={expanded === "panel6"} onChange={handleChange("panel6")}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                <Typography color={"secondary"} fontWeight={600} variant="h6">
                                    {dict["WS_Messages"]["faq_block6_title1"][language]}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box maxWidth={900}>
                                    <Typography align="left">{dict["WS_Messages"]["faq_block6_text1"][language]}</Typography>
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>

                    <Grid item xs={12} md={12} mt={3}>
                        <Accordion sx={{ minHeight: "70px" }} expanded={expanded === "panel7"} onChange={handleChange("panel7")}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                <Typography color={"secondary"} fontWeight={600} variant="h6">
                                    {dict["WS_Messages"]["faq_block7_title1"][language]}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box maxWidth={900}>
                                    <Typography align="left">{dict["WS_Messages"]["faq_block7_text1"][language]}</Typography>
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>

                    <Grid item xs={12} md={12} mt={3}>
                        <Accordion sx={{ minHeight: "70px" }} expanded={expanded === "panel8"} onChange={handleChange("panel8")}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                <Typography color={"secondary"} fontWeight={600} variant="h6">
                                    {dict["WS_Messages"]["faq_block8_title1"][language]}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box maxWidth={900}>
                                    <Typography align="left">{dict["WS_Messages"]["faq_block8_text1"][language]}</Typography>
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>
            </Stack>
            <Box sx={{ height: 90 }}></Box>
        </>
    );
};

export default WS_Help;
