import axios from "../api/axiosPhotobizz";
import axiosRoot from "../api/axiosWithoutBaseURL.js";

import useLanguageContext from "../hooks/useLanguageContext";
import useAccount from "../hooks/useAccount";
import usePicture from "../hooks/usePicture";
import useOrder from "../hooks/useOrder";
import useEnvironment from "../hooks/useEnvironment";
import useProducts from "../hooks/useProducts";

import React, { useState, useEffect, useRef } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import { Box, Typography, Stack, Grid, Button, Card, CardMedia, CardContent, TextField } from "@mui/material";
import { Paper, IconButton } from "@mui/material";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Carousel from "react-material-ui-carousel";

import Skeleton from "@mui/material/Skeleton";

import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import ZoomInIcon from "@mui/icons-material/ZoomIn";

import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Slide, { SlideProps } from "@mui/material/Slide";

import WebsiteDict from "../data/WebsiteDict";
import ZoomModal from "../components/ZoomModal";

import Tooltip from "@mui/material/Tooltip";

//Dialog background
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

const WS_Home = () => {
    const { language, setLanguage } = useLanguageContext();
    const { account, setAccount } = useAccount();
    const { picture, setPicture } = usePicture();
    const { order, setOrder } = useOrder();
    const { environment, setEnvironment } = useEnvironment();
    const { products, setProducts } = useProducts();

    const navigate = useNavigate();
    const scrollRef = useRef(null);

    const [dict, setDict] = useState(WebsiteDict);

    const [openMessage, setOpenMessage] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [severity, setSeverity] = useState("success");

    const [loading, setLoading] = useState(false);
    const [loadingPictures, setLoadingPictures] = useState(false);
    const [openZoomModal, setOpenZoomModal] = useState(false);
    const [zoomedPhoto, setZoomedPhoto] = useState(null);

    const [arrayYears, setArrayYears] = useState([]);
    const [currentSchoolYear, setCurrentSchoolYear] = useState("");

    const [photoCode, setPhotoCode] = useState("");

    const [openDialogBackground, setOpenDialogBackground] = useState(false);
    const [backgrounds, setBackgrounds] = useState();
    const [preselectedBG, setPreselectedBG] = useState();
    const [preselectedPhoto, setPreselectedPhoto] = useState();

    const [openBackdropLoader, setOpenBackdropLoader] = useState(false);

    const theme = useTheme();
    const isMediumOrSmallerScreen = useMediaQuery(theme.breakpoints.down("md"));
    const isSmallOrSmallerScreen = useMediaQuery(theme.breakpoints.down("sm"));

    useEffect(() => {
        setDict(WebsiteDict);
    }, [WebsiteDict]);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);

        const warning = urlParams.get("warning");
        if (warning !== null && warning === "NoPictureSelected") {
            setSnackbarMessage(dict["WS_Messages"]["NoPictureSelected"][language]);
            setSeverity("error");
            setOpenMessage(true);
        }

        setEnvironment({ ...environment, selectedView: "home" });

        // var _account = undefined;
        // if (account !== null && account !== undefined && Object.keys(account).length !== 0) {
        //     _account = account;
        // }
        // const loginAccount = urlParams.get("Login");
        // const loginCode = urlParams.get("Code");
        // if (loginAccount !== null && loginCode !== null) {
        //     _account = loginRequest(loginAccount, loginCode);
        // }

        // if (loginAccount === null && loginCode === null && _account === undefined) {
        //     sessionStorage.setItem("session_account", null);
        //     setAccount({});
        //     setPicture({});
        //     setOrder({});
        //     setEnvironment({});

        //     navigate("/login");
        // }
    }, []);

    // =================== Fetch Session ===================
    async function fetchSession() {
        try {
            // await axios.get("/Account/GetSession").then((response) => {
            //     setSessionID(response.data);
            //     return response.data;
            // });
            const res = await axios.get("/Account/GetSession");
            // setSessionID(res.data);
            return res.data;
        } catch (error) {
            throw error;
        }
    }

    // =================== Fetch IP ===================
    async function fetchIP() {
        try {
            const res = await axiosRoot.get("api.ipify.org?format=json");
            return res.data.ip;
        } catch (error) {
            throw error;
        }
    }

    async function loginRequest(_loginAccount, _loginCode) {
        // var Client_IP = "::1";
        // try {
        //     Client_IP = await fetchIP();
        //     console.log(Client_IP);
        // } catch (error) {
        //     console.log(error);
        // }

        // var t_setBizzSessionID = "";
        // try {
        //     t_setBizzSessionID = await fetchSession();
        //     setSessionID(t_setBizzSessionID);
        // } catch (error) {
        //     console.log(error);
        //     setSnackbarMessage("Error: " + error);
        //     setSeverity("error");
        //     setOpenMessage(true);
        //     setLoading(false);
        //     return;
        // }

        const fetchLoginFromEmail = async (_loginAccount, _loginCode) => {
            try {
                // Account/GetAccount_from_Email/{Login}/{Code}

                const response = await axios.get("/Account/GetAccount_from_Email/" + _loginAccount + "/" + _loginCode);
                if (response.status === 200) {
                    // setBizzSession(response.data);

                    var response_with_password = response.data;
                    response_with_password.Webshop_Account.pw = "";

                    setAccount(response_with_password);
                    //sessionStorage.setItem("session_account", JSON.stringify(response_with_password));

                    // setSnackbarMessage(dict["WS_Messages"]["Succesfully logged in."][language]);
                    // setSeverity("success");
                    // setOpenMessage(true);
                    navigate("/webshop/home");
                } else {
                    setSnackbarMessage(dict["WS_Messages"]["Login failed."][language]);
                    setSeverity("error");
                    setOpenMessage(true);
                }
            } catch (error) {
                navigate("/login"); //todo: redirect to login page with warning message
                console.log(error);
                setSnackbarMessage(dict["WS_Messages"]["Login failed."][language]);
                setSeverity("error");
                setOpenMessage(true);
            }
        };

        fetchLoginFromEmail(_loginAccount, _loginCode);
    }

    async function fetchPhotos() {
        try {
            var sessAcc = account.Session.Session_ID + "/" + account.Session.Account_ID;

            const response = await axios.get("/Picture/GetPictures/" + sessAcc);
            if (response.status === 200) {
                //if one of the pictures has a property 'ArrImage' and it is bigger that 50000 then reformat it to become less than 50000
                response.data.Pictures.forEach((photo) => {
                    if (photo.ArrImage && photo.ArrImage.length > 50000) {
                        let arr = photo.ArrImage;
                        let newarr = arr.slice(0, 50000);
                        photo.ArrImage = newarr;
                    }
                });

                setPicture(response.data);
                //sessionStorage.setItem("session_picture", JSON.stringify(response.data));
                setArrayYears(findYears(response.data.Pictures));
            } else {
                setSnackbarMessage(dict["WS_Messages"]["Login failed."][language]);
                setSeverity("error");
                setOpenMessage(true);
            }
        } catch (error) {
            console.log(error);
            setSnackbarMessage(dict["WS_Messages"]["Login failed."][language]);
            setSeverity("error");
            setOpenMessage(true);
        }
        setLoadingPictures(false);
    }

    const findYears = (pictures) => {
        let years = [];
        pictures.forEach((photo) => {
            let year = photo.Schooljaar;
            if (!years.includes(year)) {
                years.push(year);
            }
        });

        //if current month is bigger than 9 (september) then build 'schoolyear' as thisyear+" "+nextyear and add it to array 'years' if it doesn't exist
        let d = new Date();
        let n = d.getMonth();
        if (n > 7) {
            let thisyear = d.getFullYear();
            let nextyear = thisyear + 1;
            let schoolyear = thisyear + "-" + nextyear;
            setCurrentSchoolYear(schoolyear);
            if (!years.includes(schoolyear)) {
                years.push(schoolyear);
            }
        } else {
            let thisyear = d.getFullYear();
            let lastyear = thisyear - 1;
            let schoolyear = lastyear + "-" + thisyear;
            setCurrentSchoolYear(schoolyear);
            if (!years.includes(schoolyear)) {
                years.push(schoolyear);
            }
        }

        //sort array 'years' in descending order
        years.sort((a, b) => {
            return b.localeCompare(a);
        });

        return years;
    };

    useEffect(() => {
        // =================== Fetching photos ===================

        if (account !== null && account !== undefined && Object.keys(account).length !== 0) {
            if (picture === null || picture === undefined || Object.keys(picture).length === 0) {
                setLoadingPictures(true);
                fetchPhotos();
            } else {
                setArrayYears(findYears(picture.Pictures));
            }
        }
    }, [account]);

    useEffect(() => {
        // if (document.getElementById("scrollRef")) {
        //     document.getElementById("scrollRef").scrollIntoView({ behavior: "smooth" });
        // }

        // if environment is not empty, set setExpanded to environment.selectedHomePanel
        if (environment !== null && environment !== undefined && Object.keys(environment).length !== 0) {
            setExpanded(environment.selectedHomePanel);

            if (
                environment.selectedHomePanelArray !== null &&
                environment.selectedHomePanelArray !== undefined &&
                environment.selectedHomePanelArray.length > 0
            ) {
                setExpandedArray(environment.selectedHomePanelArray);
            } else {
                setExpandedArray(["panel0", "panel1"]);
            }
        }

        setProducts();

        //sessionStorage.setItem("session_products", JSON.stringify({}));
    }, [arrayYears]);

    // =================== Accordion ===================
    const [expanded, setExpanded] = useState("");
    const [expandedArray, setExpandedArray] = useState(["panel0", "panel1"]);

    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);

        var arr = [];

        //if isExpanded is true then set add pannel to expandedArray if don't exist
        if (isExpanded) {
            if (!expandedArray.includes(panel)) {
                arr = [...expandedArray, panel];
            }
        } else {
            //if isExpanded is false then remove pannel from expandedArray
            arr = expandedArray.filter((item) => item !== panel);
        }
        setExpandedArray(arr);

        let obj = { ...environment, selectedHomePanel: panel, selectedHomePanelArray: arr };

        setEnvironment(obj);
    };

    function CarouselItemLandscapeWide(props) {
        return <Box component="img" sx={{ width: 450 }} alt="Photobizz image" src={props.item.name} m={1} />;
    }
    // =================== Accordion ===================

    function handlePhotoCode() {
        if (photoCode === "") {
            setSnackbarMessage(dict["WS_Messages"]["Please enter a photo code."][language]);
            setSeverity("error");
            setOpenMessage(true);
        } else {
            //fetch photocode
            async function fetchPhotoCode() {
                try {
                    var sessAcc = account.Session.Session_ID + "/" + account.Session.Account_ID;

                    const response = await axios.put("/Account/PutAddPicture/" + photoCode, account.Session);
                    if (response.status === 200) {
                        setArrayYears([]);
                        setLoadingPictures(true);
                        fetchPhotos();
                    } else {
                        setSnackbarMessage(dict["WS_Messages"]["Photo code not found."][language]);
                        setSeverity("error");
                        setOpenMessage(true);
                    }
                } catch (error) {
                    console.log(error);
                    let errorMessage = error.response.data.Message;
                    if (dict["WS_Messages"][error.response.data.Message]) {
                        setSnackbarMessage(dict["WS_Messages"][error.response.data.Message][language]);
                    } else {
                        setSnackbarMessage(errorMessage);
                    }
                    setSeverity("error");
                    setOpenMessage(true);
                }
            }

            fetchPhotoCode();
        }
    }

    const emptyData = [];

    async function zoomPhoto(photo) {
        console.log(photo);

        //fetch zoomed image
        async function fetchZoomPhoto() {
            try {
                var sessAcc = account.Session.Session_ID + "/" + account.Session.Account_ID;

                let filenameWithoutExtension = photo.Foto_file.split(".")[0];
                filenameWithoutExtension = photo.Foto_file;

                const response = await axios.get("/Picture/GetPictureZoom/" + sessAcc + "/" + photo.Foto_file + "/" + photo.Fotomap_nr);

                // const response = await axios.get(str);
                if (response.status === 200) {
                    setZoomedPhoto(response.data);
                    setOpenZoomModal(true);
                    console.log(response.data);
                } else {
                    setSnackbarMessage(dict["WS_Messages"]["Unable to get zoomed photo."][language]);
                    setSeverity("error");
                    setOpenMessage(true);
                }
            } catch (error) {
                console.log(error);
                setSnackbarMessage(dict["WS_Messages"]["Unable to get zoomed photo."][language]);
                setSeverity("error");
                setOpenMessage(true);
            }
            setLoadingPictures(false);
        }

        await fetchZoomPhoto();
    }

    function photoSelected(photo) {
        console.log(photo);

        var obj = {};

        if (environment === null || environment === undefined || Object.keys(environment).length === 0) {
            obj = { Picture: photo };
            //setEnvironment(obj);
        } else {
            //keep the rest of environment but change Picture
            obj = { ...environment, Picture: photo };
            //setEnvironment(obj);
        }

        if (photo.selectedBackground !== undefined && photo.selectedBackground !== null) {
            obj = {
                ...obj,
                homeWindowScroll: { x: window.scrollX, y: window.scrollY },
                selectedBackground: photo.selectedBackground,
                selectedColor: "KL",
            };
        } else {
            if (photo.Srt_Foto === 1 || photo.Srt_Foto === 2 || photo.Srt_Foto === 3) {
                obj = {
                    ...obj,
                    homeWindowScroll: { x: window.scrollX, y: window.scrollY },
                    selectedBackground: photo.Default_Achtergrond,
                    selectedColor: "KL",
                };
            } else {
                obj = {
                    ...obj,
                    homeWindowScroll: { x: window.scrollX, y: window.scrollY },
                    selectedBackground: "00000000-0000-0000-0000-000000000000",
                    selectedColor: "KL",
                };
            }
        }

        //set environment.homeWindowScroll to current scroll position
        setEnvironment(obj);

        navigate("/webshop/order");
    }

    const onCloseZoomModal = () => {
        setOpenZoomModal(false);
        setZoomedPhoto(null);
    };

    async function openBackgroundDialog(_photo) {
        setPreselectedPhoto(_photo);
        var bg = [];

        if (backgrounds === null || backgrounds === undefined || Object.keys(backgrounds).length === 0) {
            setOpenBackdropLoader(true);
            bg = await fetchBackgrounds(_photo);

            //if _photo.Srt_Foto === 1 then keep only backgrounds from bg where there is property 'Image_Portrait'
            if (_photo.Srt_Foto === 1 || _photo.Srt_Foto === 3) {
                bg = bg.filter((bg) => bg.Image_Portrait !== undefined);
            }

            //if _photo.Srt_Foto === 2 then remove all backgrounds from bg where there is property 'Image_Landscape'
            if (_photo.Srt_Foto === 2) {
                bg = bg.filter((bg) => bg.Image_Landscape !== undefined);
            }

            await setBackgrounds(bg);
        } else {
            bg = backgrounds;
        }

        if (_photo.selectedBackground !== undefined && _photo.selectedBackground !== null) {
            setPreselectedBG(bg.find((bg) => bg.Achtergrond_ID === _photo.selectedBackground));
        } else {
            setPreselectedBG(bg[0]);
        }
        setOpenDialogBackground(true);
    }

    async function handleCloseDialogBackground() {
        setOpenDialogBackground(false);
        // setOpenBackdropLoader(false);
    }

    async function handleSaveSelectionDialogBackground() {
        console.log(preselectedBG);
        setOpenDialogBackground(false);
        setOpenBackdropLoader(true);
        //find photo in picture.Pictures and set selectedBackground to preselectedBG.Achtergrond_ID
        let newPictures = await Promise.all(
            picture.Pictures.map(async (photo) => {
                if (photo.Foto === preselectedPhoto.Foto) {
                    var newPhoto = await fetchPhoto(photo, preselectedBG);
                    if (newPhoto !== undefined) {
                        return { ...newPhoto, selectedBackground: preselectedBG.Achtergrond_ID };
                    } else {
                        return { ...photo, selectedBackground: preselectedBG.Achtergrond_ID };
                    }
                } else {
                    return photo;
                }
            })
        );
        setPicture({ ...picture, Pictures: newPictures });

        // setOpenDialogBackground(false);
        setPreselectedBG(undefined);
        setOpenBackdropLoader(false);
    }

    async function fetchPhoto(photo, preselectedBG) {
        //Picture/GetPicture/{Session_ID}/{Account_ID}/{Fotomap_nr}/{Foto_file}/{KL_SE_ZW}/{Achtergrond_ID}

        try {
            var sessAcc = account.Session.Session_ID + "/" + account.Session.Account_ID;
            const response = await axios.get(
                "Picture/GetPicture/" + sessAcc + "/" + photo.Fotomap_nr + "/" + photo.Foto_file + "/" + "KL" + "/" + preselectedBG.Achtergrond_ID
            );
            if (response.status === 200) {
                console.log(response.data);
                return response.data.Pictures[0];
            } else {
                setSnackbarMessage(dict["WS_Messages"]["Loading backgrounds failed."][language]);
                setSeverity("error");
                setOpenMessage(true);
            }
        } catch (error) {
            console.error("Failed to fetch data:", error);
            setSnackbarMessage(dict["WS_Messages"]["Loading backgrounds failed."][language]);
            setSeverity("error");
            setOpenMessage(true);
        }
    }

    async function fetchBackgrounds(_photo) {
        var sessAcc = "";

        sessAcc = account.Session.Session_ID + "/" + account.Session.Account_ID;

        try {
            const response = await axios.get("Picture/GetAchtergronden/" + sessAcc + "/" + _photo.Fotomap_nr);
            if (response.status === 200) {
                console.log(response.data.Picture_Achtergronden);
                setOpenBackdropLoader(false);
                return response.data.Picture_Achtergronden;
            } else {
                setSnackbarMessage(dict["WS_Messages"]["Loading backgrounds failed."][language]);
                setSeverity("error");
                setOpenMessage(true);
            }
        } catch (error) {
            console.error("Failed to fetch data:", error);
            setSnackbarMessage(dict["WS_Messages"]["Loading backgrounds failed."][language]);
            setSeverity("error");
            setOpenMessage(true);
        }
        setOpenBackdropLoader(false);
    }

    return (
        <>
            <Stack spacing={3} m={3}>
                <Grid container spacing={2}>
                    {loadingPictures ? (
                        <>
                            <Grid item xs={12} md={12} mt={2}>
                                <Typography align="left" color={"#023047"} fontWeight={600} variant="h6">
                                    {dict["WS_Messages"]["Loading..."][language]}
                                </Typography>

                                <Box sx={{ pt: 0.5 }}>
                                    <Skeleton variant="rectangular" width={"100%"} height={60} />
                                </Box>

                                <Box sx={{ display: "flex", gap: 2 }}>
                                    <Box sx={{ pt: 0.5 }}>
                                        <Skeleton variant="rectangular" width={210} height={118} />
                                        <Skeleton width="60%" />
                                        <Skeleton width="100%" />
                                    </Box>
                                    <Box sx={{ pt: 0.5 }}>
                                        <Skeleton variant="rectangular" width={210} height={118} />
                                        <Skeleton width="40%" />
                                        <Skeleton width="80%" />
                                    </Box>
                                    <Box sx={{ pt: 0.5 }}>
                                        <Skeleton variant="rectangular" width={210} height={118} />
                                        <Skeleton width="80%" />
                                        <Skeleton width="50%" />
                                    </Box>
                                </Box>

                                <Box sx={{ pt: 0.5 }}>
                                    <Skeleton variant="rectangular" width={"100%"} height={60} />
                                </Box>

                                <Box sx={{ pt: 0.5 }}>
                                    <Skeleton variant="rectangular" width={"100%"} height={60} />
                                </Box>
                            </Grid>
                        </>
                    ) : null}
                </Grid>

                {/* if for the first year in arrayYear the are photos in picture?.Pictures the show "there are image" else null */}
                {picture?.Pictures?.filter((photo) => photo.Schooljaar === arrayYears[0]).length !== 0 ? (
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={12} mt={0}>
                            {/* <Typography align="left" color={"#023047"} fontWeight={600} variant="body1">
                                {dict["WS_Messages"]["You have not yet added the photos from this school year."][language]}
                            </Typography> */}
                            <Grid container spacing={0}>
                                <Grid item xs={6} md={4}>
                                    <TextField
                                        id="photoCode"
                                        label={dict["WS_Messages"]["Photo code"][language]}
                                        variant="outlined"
                                        fullWidth
                                        margin="none"
                                        // InputLabelProps={{
                                        //     shrink: true,
                                        // }}
                                        value={photoCode}
                                        onChange={(e) => setPhotoCode(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={6} md={4}>
                                    <Button variant="contained" color="secondary" onClick={handlePhotoCode} sx={{ marginTop: 1 }}>
                                        {dict["WS_Messages"]["Add photos"][language]}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                ) : null}

                <Grid container spacing={1} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    {/* dynamis Accordion based on array arrYears  */}
                    {arrayYears.map((year, index) => (
                        <Grid item xs={12} md={12} mt={2} key={"years" + index}>
                            <Accordion
                                className="accordion_round_corners"
                                // sx={{ minHeight: "70px", border: '2px solid #219EBC', boxShadow: 0 }}
                                sx={{ minHeight: "70px", boxShadow: 0 }}
                                key={index}
                                // expanded={expanded === 'panel' + index}
                                expanded={expandedArray && expandedArray.includes("panel" + index)}
                                onChange={handleChange("panel" + index)}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    sx={{ borderBottom: "2px solid #219EBC" }}
                                >
                                    <Typography align="left" pt={1} pr={1} color={"#023047"} fontWeight={600} variant="h6">
                                        {dict["WS_Messages"]["School year"][language]}
                                    </Typography>
                                    <Typography
                                        align="left"
                                        pt={1}
                                        color={"#023047"}
                                        fontWeight={600}
                                        variant={isMediumOrSmallerScreen ? "h6" : "h5"}
                                    >
                                        {year}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {/* dynamic material ui cards for every object in array picture.Pictures based on property "Schooljaar" that should be the same as 'year' inside this map function */}
                                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: 1, flexWrap: "wrap" }}>
                                        {
                                            // if year is equal to currentSchoolYear and there are no photos for this year, show message to add photo code ,
                                            year === currentSchoolYear &&
                                                picture?.Pictures?.filter((photo) => photo.Schooljaar === year).length === 0 ? (
                                                <Grid item xs={12} md={12} mt={2}>
                                                    <Typography align="left" color={"#023047"} fontWeight={600} variant="body1">
                                                        {dict["WS_Messages"]["You have not yet added the photos from this school year."][language]}
                                                    </Typography>
                                                    {/* add TextField for photo code */}
                                                    {/* textfield and button get 50% width one next to other until size md and then 100% */}

                                                    <Grid container spacing={2}>
                                                        <Grid item xs={6} md={4}>
                                                            <TextField
                                                                id="photoCode"
                                                                label={dict["WS_Messages"]["Photo code"][language]}
                                                                variant="outlined"
                                                                fullWidth
                                                                margin="normal"
                                                                // InputLabelProps={{
                                                                //     shrink: true,
                                                                // }}
                                                                value={photoCode}
                                                                onChange={(e) => setPhotoCode(e.target.value)}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6} md={4}>
                                                            <Button
                                                                variant="contained"
                                                                color="secondary"
                                                                onClick={handlePhotoCode}
                                                                //margin top 16px
                                                                sx={{ marginTop: 3 }}
                                                            >
                                                                {dict["WS_Messages"]["Add photos"][language]}
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            ) : (
                                                <>
                                                    {picture?.Pictures?.map((photo, index) =>
                                                        photo.Schooljaar === year ? (
                                                            <Box
                                                                sx={{
                                                                    width: { xs: "100%", sm: "49%", md: "49%", lg: "25%", xl: "20%" },
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                }}
                                                            >
                                                                <Card sx={{ width: 260, border: "none", boxShadow: "none" }}>
                                                                    <Tooltip
                                                                        title={
                                                                            <Stack direction={"column"} spacing={1}>
                                                                                {/* <Typography variant="caption" color="text.secondary">{article.Artikel_naam_lang}</Typography>*/}
                                                                                <Typography variant="caption">{photo?.School}</Typography>
                                                                                <Typography variant="caption">
                                                                                    {photo?.Postnr} {photo?.Lokal}
                                                                                </Typography>
                                                                                <Typography variant="caption">
                                                                                    {photo?.Datum_opname
                                                                                        ? photo?.Datum_opname.split("T")[0]
                                                                                            .split("-")
                                                                                            .reverse()
                                                                                            .join("/")
                                                                                        : ""}
                                                                                </Typography>
                                                                                <Typography variant="caption">{photo?.Foto_Info}</Typography>
                                                                                <Typography variant="caption">
                                                                                    {photo?.Skontr_Nr}/{photo?.Foto_file}
                                                                                </Typography>
                                                                            </Stack>
                                                                        }
                                                                        placement="top"
                                                                        enterDelay={2000}
                                                                        leaveDelay={100}

                                                                    // PopperProps={{
                                                                    //     sx: {
                                                                    //         '.MuiTooltip-tooltip': {
                                                                    //             bgcolor: '#fb850099', // Use theme color or specify a color code
                                                                    //         },
                                                                    //     },
                                                                    // }}
                                                                    >
                                                                        <CardMedia
                                                                            key={index}
                                                                            className="object_fit_contain"
                                                                            component="img"
                                                                            height="250"
                                                                            id={
                                                                                //if photo.Foto_file is the same as environment.Picture.Foto_file then scroll to that position
                                                                                photo?.Foto_file === environment?.Picture?.Foto_file
                                                                                    ? "scrollRef"
                                                                                    : null
                                                                            }
                                                                            src={
                                                                                photo.ArrImage
                                                                                    ? "data:image/jpeg;base64," + photo.ArrImage.toString()
                                                                                    : require("../images/no_image_available.jpg")
                                                                            }
                                                                            // image={`${item.img}?w=200&fit=crop&auto=format`}
                                                                            alt="event photo"
                                                                            onClick={() => photoSelected(photo)}
                                                                            onMouseEnter={() => {
                                                                                // mouse from pointer to hand
                                                                                document.body.style.cursor = "pointer";
                                                                            }}
                                                                            onMouseLeave={() => {
                                                                                // mouse from hand to pointer
                                                                                document.body.style.cursor = "default";
                                                                            }}
                                                                        />
                                                                    </Tooltip>

                                                                    <CardContent sx={{ height: 30 }}>
                                                                        {(photo.Srt_Foto === 3 || photo.Srt_Foto === 4) && (
                                                                            <IconButton
                                                                                onClick={() => zoomPhoto(photo)}
                                                                                disabled={!(photo.Srt_Foto === 3 || photo.Srt_Foto === 4)}
                                                                                aria-label={dict["WS_Messages"]["Zoom"][language]}
                                                                                sx={{ top: -10 }}
                                                                            >
                                                                                <ZoomInIcon />
                                                                            </IconButton>
                                                                        )}

                                                                        {/* if photo.Fotomap_Kenmerk === "K" then show button 'change background' */}

                                                                        {photo.Fotomap_Kenmerk === "K" && (
                                                                            <Button
                                                                                variant="contained"
                                                                                color="secondary"
                                                                                onClick={() => {
                                                                                    openBackgroundDialog(photo);
                                                                                }}
                                                                                // sx={{ top: -10, pl: 1 ,backgroundColor: 'lightgray','&:hover': {
                                                                                //         backgroundColor: 'secondary.main'
                                                                                //     }}}
                                                                                disabled={!(photo.Fotomap_Kenmerk === "K")}
                                                                                size="small"
                                                                            >
                                                                                {dict["WS_Labels"]["Button_Change_background"][language]}
                                                                            </Button>
                                                                        )}
                                                                    </CardContent>
                                                                </Card>
                                                            </Box>
                                                        ) : null
                                                    )}
                                                </>
                                            )
                                        }
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    ))}
                </Grid>
                <Box sx={{ height: 90 }}></Box>

                {openZoomModal ? (
                    <ZoomModal
                        open={openZoomModal}
                        onClose={() => {
                            onCloseZoomModal();
                        }}
                        imageUrl={zoomPhoto && zoomedPhoto.ArrImage}
                    />
                ) : null}

                <Dialog fullScreen open={openDialogBackground} onClose={handleCloseDialogBackground} TransitionComponent={SlideTransition}>
                    <AppBar sx={{ position: "relative", backgroundColor: "#177e97" }}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={handleCloseDialogBackground} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                Backgrounds
                            </Typography>
                            <Button autoFocus color="inherit" onClick={handleSaveSelectionDialogBackground}>
                                save
                            </Button>
                        </Toolbar>
                    </AppBar>
                    {/* in a flex grid boxes with background images with background name at the bottom (array of objects is 'backgrounds') and is environment.selectedBackground is guid of zeros none of the boxes is selected otherwise the box with the same "Achtergrond_ID" is selected and the user can select other */}
                    <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: 2, p: 2 }}>
                        {preselectedBG &&
                            backgrounds?.map((background, index) => (
                                <Box
                                    key={index}
                                    sx={{
                                        position: "relative",
                                        width: 150,
                                        height: 150,

                                        // p: 1,
                                        overflow: "hidden",
                                        border: "1px solid #ced4da",
                                        borderRadius: "4px",
                                        cursor: "pointer",
                                        boxShadow: preselectedBG.Achtergrond_ID === background.Achtergrond_ID ? "0px 0px 10px 5px #fb8500" : "none",
                                    }}
                                    onClick={() => {
                                        setPreselectedBG(background);
                                    }}
                                    onDoubleClick={() => {
                                        setPreselectedBG(background);
                                        handleSaveSelectionDialogBackground();
                                    }}
                                >
                                    <CardMedia
                                        key={index}
                                        // className="object_fit_contain"
                                        className="object_fit_cover" // Assuming this class sets `object-fit: cover;`
                                        component="img"
                                        maxWidth="100%"
                                        height="100%"
                                        style={{ borderRadius: "4px" }}
                                        src={
                                            // background.Image_Portrait
                                            //     ? "data:image/jpeg;base64," + background.Image_Portrait.toString()
                                            //     : require("../images/no_image_available.jpg")

                                            // if environment.Picture.Srt_Foto is 1 or 3 then show the image in portrait mode, else in landscape mode
                                            preselectedPhoto?.Srt_Foto === 1 || preselectedPhoto?.Srt_Foto === 3
                                                ? background.Image_Portrait
                                                    ? "data:image/jpeg;base64," + background.Image_Portrait.toString()
                                                    : require("../images/no_image_available.jpg")
                                                : preselectedPhoto?.Srt_Foto === 2 || background.Image_Landscape
                                                    ? "data:image/jpeg;base64," + background.Image_Landscape.toString()
                                                    : require("../images/no_image_available.jpg")
                                        }
                                        alt="event photo"
                                    />
                                    <CardContent>
                                        {/* <Typography variant="body2" color="text.secondary">{background.Achtergrond_Label}</Typography> */}
                                    </CardContent>
                                </Box>
                            ))}
                    </Box>
                </Dialog>

                <Snackbar
                    open={openMessage}
                    onClose={() => {
                        setOpenMessage(false);
                    }}
                    TransitionComponent={SlideTransition}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    key="Snackbar"
                >
                    <Alert
                        severity={severity}
                        sx={{ marginTop: 2 }}
                        onClose={() => {
                            setOpenMessage(false);
                        }}
                    >
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </Stack>
            <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={openBackdropLoader}>
                <Typography variant="h6" sx={{ marginRight: 2 }}>
                    {dict["WS_Messages"]["Loading..."][language]}
                </Typography>
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
};

function SlideTransition(props: SlideProps) {
    return <Slide {...props} direction="up" />;
}

export default WS_Home;