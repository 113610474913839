import axios from "../api/axiosPhotobizz";
import axiosRoot from "../api/axiosWithoutBaseURL.js";

import useLanguageContext from "../hooks/useLanguageContext";
import useAccount from "../hooks/useAccount";

import React, { useState, useEffect } from "react";
// import { NavLink, } from "react-router-dom";
import { Box, Typography, Stack, Grid, Button, Card, TextField } from "@mui/material";

import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Slide, { SlideProps } from "@mui/material/Slide";

import WebsiteDict from "../data/WebsiteDict";
import { NavLink, useNavigate } from "react-router-dom";

import CircularProgress from "@mui/material/CircularProgress";
import { InputAdornment, IconButton } from "@mui/material/";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

const ForgotPassword = () => {
    const { language, setLanguage } = useLanguageContext();
    const { account, setAccount } = useAccount();

    const [dict, setDict] = useState(WebsiteDict);

    const navigate = useNavigate();

    useEffect(() => {
        setDict(WebsiteDict);
    }, [WebsiteDict]);

    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const [showPass, setShowPass] = useState(false);
    const [showPass2, setShowPass2] = useState(false);
    const [email, setEmail] = useState("");

    const [sessionID, setSessionID] = useState("");
    const [bizzSession, setBizzSession] = useState({ Session: { Session_ID: "", Account_ID: "" }, Webshop_Account: {} });

    // const [firstName, setFirstName] = useState("");
    // const [lastName, setLastName] = useState("");
    // const [phone, setPhone] = useState("");
    // const [companyName, setCompanyName] = useState("");
    // const [message, setMessage] = useState("");

    const [openMessage, setOpenMessage] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [severity, setSeverity] = useState("success");
    const [loading, setLoading] = useState(false);
    const [mode, setMode] = useState("requestPassword");
    const [changePassID, setChangePassID] = useState("");

    useEffect(() => {
        window.scrollTo(0, 0);
        setupEventListeners();
    }, []);

    // Example function to handle the callback URL
    function setupEventListeners() {
        window.addEventListener("load", () => {
            const urlParams = new URLSearchParams(window.location.search);

            const _changePassID = urlParams.get("ID");

            //if ID is a guid
            if (_changePassID && _changePassID.length === 36) {
                setMode("changePassword");
            } else {
                setMode("requestPassword");
            }
            setChangePassID(_changePassID);
        });
    }

    const handleChangeEmail = (event) => {
        setEmail(event.target.value);
    };

    // const handleChangePhone = (event) => {
    //     setPhone(event.target.value);
    // };

    // const handleChangeCompanyName = (event) => {
    //     setCompanyName(event.target.value);
    // };

    // const handleChangeMessage = (event) => {
    //     setMessage(event.target.value);
    // };

    const handleChangePassword = (event) => {
        setPassword(event.target.value);
    };

    // =================== Fetch Session ===================
    async function fetchSession() {
        try {
            // await axios.get("/Account/GetSession").then((response) => {
            //     setSessionID(response.data);
            //     return response.data;
            // });
            const res = await axios.get("/Account/GetSession");
            setSessionID(res.data);
            return res.data;
        } catch (error) {
            throw error;
        }
    }

    // =================== Fetch IP ===================
    async function fetchIP() {
        try {
            const res = await axiosRoot.get("api.ipify.org?format=json");
            return res.data.ip;
        } catch (error) {
            throw error;
        }
    }

    const sendChangePassword = async (e) => {
        e.preventDefault();

        let msg = "";

        if (password === "" || password2 === "") {
            msg = msg + dict["WS_Messages"]["Password is required."][language];
        }

        if (password !== password2) {
            msg = msg + dict["WS_Messages"]["Passwords do not match."][language];
        }

        if (msg !== "") {
            setSnackbarMessage(msg);
            setSeverity("error");
            setOpenMessage(true);
            setLoading(false);
            return;
        }

        setLoading(true);

        var Client_IP = "::1";
        try {
            Client_IP = await fetchIP();
            console.log(Client_IP);
        } catch (error) {
            console.log(error);
        }

        var t_setBizzSessionID = "";
        try {
            t_setBizzSessionID = await fetchSession();
            setSessionID(t_setBizzSessionID);
        } catch (error) {
            console.log(error);
            setSnackbarMessage("Error: " + error);
            setSeverity("error");
            setOpenMessage(true);
            setLoading(false);
            return;
        }

        const fetchChangePass = async () => {
            try {
                // {
                //     "Session_ID": "ce360976-8259-4594-9e22-6cbbd2779f27",
                //     "ID": "F38F249B-B50F-4E88-8FED-08FD5AD56DF9",
                //     "Pw": "........",
                //     "Confirm_Pw": "........",
                //     "Client_IP": "192.168.1.111"
                // }

                const body = {
                    Session_ID: t_setBizzSessionID,
                    ID: changePassID,
                    Pw: password,
                    Confirm_Pw: password2,
                    Client_IP: Client_IP,
                };

                const response = await axios.post("/Account/PostWW_Wijzigen", body);
                if (response.status === 200) {
                    setBizzSession(response.data);
                    var response_with_password = response.data;
                    response_with_password.Webshop_Account.pw = "";

                    setAccount(response_with_password);
                    navigate("/webshop/home");
                } else {
                    setSnackbarMessage(dict["WS_Messages"]["Password request failed."][language]);
                    setSeverity("error");
                    setOpenMessage(true);
                }
            } catch (error) {
                console.log(error);
                if (
                    error.response.status === 400 &&
                    error.response?.data?.Message === "Wachtwoord kon niet gewijzigd worden"
                ) {
                    setSnackbarMessage(dict["WS_Messages"]["Wachtwoord kon niet gewijzigd worden"][language]);
                } else {
                    setSnackbarMessage(dict["WS_Messages"]["Password request failed."][language]);
                }

                setSeverity("error");
                setOpenMessage(true);
            }
        };

        fetchChangePass();

        setLoading(false);
    };

    const sendPasswordRequest = async (e) => {
        e.preventDefault();

        let msg = "";

        if (email === "") {
            msg = msg + dict["WS_Messages"]["Email is required."][language];
        }

        if (msg !== "") {
            setSnackbarMessage(msg);
            setSeverity("error");
            setOpenMessage(true);
            setLoading(false);
            return;
        }

        //validate email
        const re = /\S+@\S+\.\S+/;
        if (!re.test(email)) {
            setSnackbarMessage(dict["WS_Messages"]["Email is not valid."][language]);
            setSeverity("error");
            setOpenMessage(true);
            setLoading(false);
            return;
        }

        setLoading(true);

        var Client_IP = "::1";
        try {
            Client_IP = await fetchIP();
            console.log(Client_IP);
        } catch (error) {
            console.log(error);
        }

        var t_setBizzSessionID = "";
        try {
            t_setBizzSessionID = await fetchSession();
            setSessionID(t_setBizzSessionID);
        } catch (error) {
            console.log(error);
            setSnackbarMessage("Error: " + error);
            setSeverity("error");
            setOpenMessage(true);
            setLoading(false);
            return;
        }

        const fetchPassRequest = async () => {
            try {
                // {
                //     "Session_ID": "{{Session_ID}}",
                //     "Email": "{{Username}}",
                //     "Pw": "{{Password}}",
                //     "Client_IP": "192.168.1.200"
                // }
                const body = {
                    Session_ID: t_setBizzSessionID,
                    Email: email,
                    Pw: "",
                    Client_IP: Client_IP,
                };

                const response = await axios.post("/Account/PostWW_Vergeten", body);
                if (response.status === 200) {
                    setBizzSession(response.data);

                    setSnackbarMessage(dict["WS_Messages"]["Mail has been sent"][language]);
                    setSeverity("success");
                    setOpenMessage(true);
                } else {
                    setSnackbarMessage(dict["WS_Messages"]["Password request failed."][language]);
                    setSeverity("error");
                    setOpenMessage(true);
                }
            } catch (error) {
                console.log(error);
                setSnackbarMessage(dict["WS_Messages"]["Password request failed."][language]);
                setSeverity("error");
                setOpenMessage(true);
            }
        };

        fetchPassRequest();

        setLoading(false);
    };

    const handlePassVisibilty = () => {
        setShowPass(!showPass);
    };
    const handlePassVisibilty2 = () => {
        setShowPass2(!showPass2);
    };

    const handleCreateAccountClick = () => {
        navigate("/createaccount");
    };

    return (
        <>
            <Stack spacing={8} m={3}>
                <Grid container spacing={2}>
                    {mode === "requestPassword" && (
                        <Grid item xs={12} md={6}>
                            <Stack spacing={4}>
                                <Grid item>
                                    <Box pb={2}>
                                        <Typography align="left" color={"#023047"} fontWeight={600} variant="h4">
                                            {dict["WS_Labels"]["ForgotPassword"][language]}
                                        </Typography>
                                    </Box>
                                </Grid>

                                {/* <Grid item > */}
                                <Box display="flex" alignItems="center">
                                    <Typography variant="body1" align="left">
                                        {dict["WS_Messages"]["forgot pass request text"][language]}
                                    </Typography>
                                </Box>

                                {/* grid item username */}
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        color="secondary"
                                        value={email}
                                        label={dict["WS_Labels"]["email"][language]}
                                        placeholder={dict["WS_Labels"]["enter_your_email"][language]}
                                        onChange={handleChangeEmail}
                                        size="small"
                                        id="outlined-name"
                                        fullWidth={true}
                                        variant="standard"
                                        margin="normal"
                                        required
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <MailOutlineIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>

                                <Box pt={0}>
                                    <Button
                                        variant="contained"
                                        onClick={sendPasswordRequest}
                                        sx={{
                                            mt: 0,
                                            width: "100%",
                                            backgroundColor: "white",
                                            border: "2px solid #219EBC",
                                            color: "#219EBC", // Ensure the text color is visible on a white background
                                            "&:hover": {
                                                backgroundColor: "#219EBC", // Maintain white background on hover
                                                border: "2px solid #219EBC", // Optional: Darken the border on hover
                                                color: "white", // Optional: Darken the text color on hover
                                            },
                                        }}
                                    >
                                        {dict["WS_Labels"]["Button_send"][language]}
                                        {loading && (
                                            <CircularProgress
                                                size={20}
                                                sx={{
                                                    color: "secondary",
                                                    position: "relative",
                                                    top: "6px",
                                                    left: "60px",
                                                    marginTop: "-12px",
                                                    marginLeft: "-12px",
                                                }}
                                            />
                                        )}
                                    </Button>
                                </Box>

                                <Grid item>
                                    <Box display="flex" alignItems="center">
                                        <NavLink to="/login" style={{ textDecoration: "none" }}>
                                            <Typography variant="body1" align="left" color="secondary" style={{ marginLeft: 8 }}>
                                                {dict["WS_Messages"]["go back to login"][language]}
                                            </Typography>
                                        </NavLink>
                                    </Box>
                                </Grid>

                                {/* </Grid> */}
                            </Stack>
                        </Grid>
                    )}

                    {mode === "changePassword" && (
                        <Grid item xs={12} md={6}>
                            <Stack spacing={4}>
                                <Grid item>
                                    <Box pb={2}>
                                        <Typography align="left" color={"#023047"} fontWeight={600} variant="h4">
                                            {dict["WS_Labels"]["ForgotPassword"][language]}
                                        </Typography>
                                    </Box>
                                </Grid>

                                {/* <Grid item > */}
                                {/* <Box display="flex" alignItems="center">
                                    <Typography variant="body1" align="left">
                                        {dict["WS_Messages"]["forgot pass request text"][language]}
                                    </Typography>
                                </Box> */}

                                <Grid item xs={12} md={12}>
                                    <TextField
                                        color="secondary"
                                        type={showPass ? "text" : "password"}
                                        fullWidth
                                        label={dict["WS_Labels"]["password"][language]}
                                        placeholder={dict["WS_Labels"]["create_password"][language]}
                                        variant="standard"
                                        required
                                        defaultValue={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <LockOutlinedIcon />
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={handlePassVisibilty} aria-label="toggle password" edge="end">
                                                        {showPass ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} md={12}>
                                    <TextField
                                        color="secondary"
                                        type={showPass2 ? "text" : "password"}
                                        fullWidth
                                        label={dict["WS_Labels"]["password"][language]}
                                        placeholder={dict["WS_Labels"]["confirm_password"][language]}
                                        variant="standard"
                                        required
                                        defaultValue={password2}
                                        onChange={(e) => setPassword2(e.target.value)}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <LockOutlinedIcon />
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={handlePassVisibilty2} aria-label="toggle password" edge="end">
                                                        {showPass2 ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>

                                <Box pt={0}>
                                    <Button
                                        variant="contained"
                                        onClick={sendChangePassword}
                                        sx={{
                                            mt: 0,
                                            width: "100%",
                                            backgroundColor: "white",
                                            border: "2px solid #219EBC",
                                            color: "#219EBC", // Ensure the text color is visible on a white background
                                            "&:hover": {
                                                backgroundColor: "#219EBC", // Maintain white background on hover
                                                border: "2px solid #219EBC", // Optional: Darken the border on hover
                                                color: "white", // Optional: Darken the text color on hover
                                            },
                                        }}
                                    >
                                        {dict["WS_Labels"]["Button_send"][language]}
                                        {loading && (
                                            <CircularProgress
                                                size={20}
                                                sx={{
                                                    color: "secondary",
                                                    position: "relative",
                                                    top: "6px",
                                                    left: "60px",
                                                    marginTop: "-12px",
                                                    marginLeft: "-12px",
                                                }}
                                            />
                                        )}
                                    </Button>
                                </Box>

                                <Grid item>
                                    <Box display="flex" alignItems="center">
                                        <NavLink to="/login" style={{ textDecoration: "none" }}>
                                            <Typography variant="body1" align="left" color="secondary" style={{ marginLeft: 8 }}>
                                                {dict["WS_Messages"]["go back to login"][language]}
                                            </Typography>
                                        </NavLink>
                                    </Box>
                                </Grid>

                                {/* </Grid> */}
                            </Stack>
                        </Grid>
                    )}

                    <Grid item xs={12} md={6}>
                        <Grid item xs={12} md={12} sx={{ display: { xs: "none", sm: "none", md: "none", lg: "block" } }}>
                            <Box component="img" sx={{ maxWidth: 500 }} alt="Photobizz image" src={require("../images/WelcomeBack_full.png")} m={1} />
                        </Grid>
                        <Grid item xs={12} md={12} sx={{ display: { xs: "none", sm: "none", md: "block", lg: "none" } }}>
                            <Box component="img" sx={{ maxWidth: 400 }} alt="Photobizz image" src={require("../images/WelcomeBack_full.png")} m={1} />
                        </Grid>
                    </Grid>
                </Grid>

                <Snackbar
                    open={openMessage}
                    onClose={() => {
                        setOpenMessage(false);
                    }}
                    TransitionComponent={SlideTransition}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    key="Snackbar"
                >
                    <Alert
                        severity={severity}
                        sx={{ marginTop: 2 }}
                        onClose={() => {
                            setOpenMessage(false);
                        }}
                    >
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </Stack>
        </>
    );
};

function SlideTransition(props: SlideProps) {
    return <Slide {...props} direction="up" />;
}

export default ForgotPassword;
