import axios from "../api/axiosPhotobizz";

import useLanguageContext from "../hooks/useLanguageContext";
import useAccount from "../hooks/useAccount";
import usePicture from "../hooks/usePicture";
import useOrder from "../hooks/useOrder";
import useEnvironment from "../hooks/useEnvironment";
import useProducts from "../hooks/useProducts";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

import React, { useState, useEffect, useRef } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import { Box, Typography, Stack, Grid, Button, Card, CardMedia, CardContent, TextField } from "@mui/material";
import { Paper, IconButton } from "@mui/material";

import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Carousel from "react-material-ui-carousel";

import InfoIcon from "@mui/icons-material/Info";

import Skeleton from "@mui/material/Skeleton";
import Tooltip from "@mui/material/Tooltip";

import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import ShoppingBasketOutlinedIcon from '@mui/icons-material/ShoppingBasketOutlined';
import FavoriteIcon from "@mui/icons-material/Favorite";

import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Slide, { SlideProps } from "@mui/material/Slide";

import WebsiteDict from "../data/WebsiteDict";
import ZoomModal from "../components/ZoomModal";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { FormLabel, RadioGroup, Radio } from "@mui/material";

const WS_Favorites = () => {
    const { language, setLanguage } = useLanguageContext();
    const { account, setAccount } = useAccount();
    const { picture, setPicture } = usePicture();
    const { order, setOrder } = useOrder();
    const { environment, setEnvironment } = useEnvironment();
    const { products, setProducts } = useProducts();

    const [favorites, setFavorites] = useState([]);

    const navigate = useNavigate();
    const [dict, setDict] = useState(WebsiteDict);

    const [openMessage, setOpenMessage] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [severity, setSeverity] = useState("success");

    const [loading, setLoading] = useState(false);
    const [loadingPictures, setLoadingPictures] = useState(false);
    const [openZoomModal, setOpenZoomModal] = useState(false);

    const [openYesNo, setOpenYesNo] = React.useState(false);
    const [dialogTitle, setDialogTitle] = useState("");
    const [dialogMessage1, setDialogMessage1] = useState("");
    const [dialogMessage2, setDialogMessage2] = useState("");
    const [dialogMessage3, setDialogMessage3] = useState("");
    const [dialogMessage4, setDialogMessage4] = useState("");

    const [showSelectKlasFoto, setShowSelectKlasFoto] = useState(false);
    const [selectedKlasFoto, setSelectedKlasFoto] = useState();
    const [klassFotos, setKlassFotos] = useState([]);

    const [openInfo, setOpenInfo] = React.useState(false);

    const [clickedArticle, setClickedArticle] = useState({});
    const [fakeState, setFakeState] = useState(false);

    useEffect(() => {
        setDict(WebsiteDict);
    }, [WebsiteDict]);

    useEffect(() => {
        if (favorites === null || favorites === undefined || favorites.length === 0) {
            getFavorites();
        }
        setEnvironment({ ...environment, selectedView: "favorites" });
    }, []);

    async function getFavorites() {
        fetchFavorites();
    }

    async function fetchFavorites() {
        setLoading(true);

        try {
            var sessAcc = account.Session.Session_ID + "/" + account.Session.Account_ID;

            const response = await axios.get("/Picture/GetFavorieten/" + sessAcc);
            if (response.status === 200) {
                let fav = [];
                //copy from response.data.Picture_Article_Favorite to fav only Picture_Article propery using for() loop
                for (let i = 0; i < response.data.Picture_Article_Favorite.length; i++) {
                    fav.push(response.data.Picture_Article_Favorite[i].Picture_Article);
                }
                let objWithNumberOf = await buildPreOrder(fav);
                setFavorites(objWithNumberOf);
                setLoading(false);
            } else {
                setSnackbarMessage(dict["WS_Messages"]["Loading favorites failed."][language]);
                setSeverity("error");
                setOpenMessage(true);
            }
        } catch (error) {
            console.error("Error loading favorites: ", error);
            setSnackbarMessage(dict["WS_Messages"]["Loading favorites failed."][language]);
            setSeverity("error");
            setOpenMessage(true);
        }
    }

    function buildPreOrder(_obj) {
        //for every article in environment.Articles, add a property 'numberOfPieces' with value 0
        let articles = _obj;
        if (articles === null || articles === undefined || Object.keys(articles).length === 0) {
            return _obj;
        }
        articles.forEach((article) => {
            article.numberOfPieces = 1;
        });
        return _obj;
    }

    function addOnePieceClicked(article) {
        let _favs = favorites;
        let index = _favs.findIndex((x) => x.Artikel_nr === article.Artikel_nr);
        _favs[index].numberOfPieces = _favs[index].numberOfPieces + 1;
        setFavorites(_favs);
        setFakeState(!fakeState);
    }

    function removeOnePieceClicked(article) {
        let _favs = favorites;
        let index = _favs.findIndex((x) => x.Artikel_nr === article.Artikel_nr);
        if (_favs[index].numberOfPieces > 1) {
            _favs[index].numberOfPieces = _favs[index].numberOfPieces - 1;
            setFavorites(_favs);
        }
        setFakeState(!fakeState);
    }

    async function fetchClassPhoto(_article) {
        //Order/GetGekoppeldeKlasfotos/{Session_ID}/{Account_ID}/{Fotomap_nr}/{Foto_file}
        var p = picture;

        var sessAcc = account.Session.Session_ID + "/" + account.Session.Account_ID;
        var photoDetails = "/" + _article.Fotomap_nr + "/" + _article.Foto_file;

        try {
            const response = await axios.get("Order/GetGekoppeldeKlasfotos/" + sessAcc + photoDetails + "/");
            if (response.status === 200) {
                console.log(response.data.GekoppeldeKlasfotos);

                var klasFotoArr = [];

                for (var i = 0; i < p.Pictures.length; i++) {
                    for (var j = 0; j < response.data.GekoppeldeKlasfotos.length; j++) {
                        if (
                            p.Pictures[i].Fotomap_nr === response.data.GekoppeldeKlasfotos[j].Fotomap_Nr &&
                            p.Pictures[i].Foto_file === response.data.GekoppeldeKlasfotos[j].Foto_File
                        ) {
                            klasFotoArr.push(p.Pictures[i]);
                        }
                    }
                }

                return klasFotoArr;
            } else {
                setSnackbarMessage(dict["WS_Messages"]["Loading classphotos failed."][language]);
                setSeverity("error");
                setOpenMessage(true);
            }
        } catch (error) {
            console.error("Failed to fetch data:", error);
            setSnackbarMessage(dict["WS_Messages"]["Loading classphotos failed."][language]);
            setSeverity("error");
            setOpenMessage(true);
        }
    }

    async function add_article_to_cart(_article) {
        if (_article.numberOfPieces === 0) {
            setSnackbarMessage(dict["WS_Messages"]["Please select a quantity."][language]);
            setSeverity("error");
            setOpenMessage(true);
            return;
        }

        if (_article.Pakket_Bevat_Klasfoto) {
            console.log("Pakket bevat klasfoto");
            setShowSelectKlasFoto(true);
            var classPhotoArr = await fetchClassPhoto(_article);
            setKlassFotos(classPhotoArr);
            setSelectedKlasFoto(classPhotoArr[0].Foto_file);
        } else {
            setShowSelectKlasFoto(false);
            setKlassFotos([]);
            setSelectedKlasFoto();
        }

        setDialogTitle(dict["WS_Messages"]["Dialog_Title_Add_to_cart"][language]);

        //find current school year
        let currentSchoolYear = "";
        let d = new Date();
        let n = d.getMonth();
        if (n > 7) {
            let thisyear = d.getFullYear();
            let nextyear = thisyear + 1;
            let schoolyear = thisyear + "-" + nextyear;
            currentSchoolYear = schoolyear;
        } else {
            let thisyear = d.getFullYear();
            let lastyear = thisyear - 1;
            let schoolyear = lastyear + "-" + thisyear;
            currentSchoolYear = schoolyear;
        }

        //if picture in Pictures.Pictures with properties Foto_file and Fotomap_nr is the same as _article.Foto_file and _article.Fotomap_nr then set it to currentPicture
        let currentPicture = picture.Pictures.find((photo) => photo.Foto_file === _article.Foto_file && photo.Fotomap_nr === _article.Fotomap_nr);

        var msg1 = "";
        var newLine = "";
        if (currentPicture.Schooljaar !== currentSchoolYear) {
            msg1 = dict["WS_Messages"]["Dialog Message Attention Picture is from other schoolyear"][language] + " ";
            newLine = "\r\n";
        }

        if (_article.Warning_GeenPasfoto) {
            msg1 = msg1 + newLine + dict["WS_Messages"]["Dialog Message Attention there are no passport fotos"][language];
        }

        setDialogMessage1(msg1);

        setDialogMessage2(
            dict["WS_Messages"]["Article details:"][language] + " " + _article.Artikel_naam_lang + " (" + _article.Artikel_Subgroep_Naam + ")"
        );
        setDialogMessage3(dict["WS_Messages"]["Number of articles:"][language] + " " + _article.numberOfPieces);
        setDialogMessage4(dict["WS_Messages"]["Do you want to add this article to your cart?"][language]);

        setClickedArticle(_article);

        setOpenYesNo(true);
    }

    async function add_remove_favorite(article) {
        console.log(article);

        //if article is in account.Favorites, then function removeFavorite else function addFavorite
        if (
            account.Favorites.some(
                (favorite) => favorite.Picture_Article.Artikel_nr === article.Artikel_nr && favorite.Picture_Article.Foto_file === article.Foto_file
            )
        ) {
            //find favorite in account.Favorites
            let favorite = account.Favorites.find(
                (favorite) => favorite.Picture_Article.Artikel_nr === article.Artikel_nr && favorite.Picture_Article.Foto_file === article.Foto_file
            );
            await removeFavorite(favorite);
        } else {
            //await addFavorite(article);
        }
    }

    async function removeFavorite(favorite) {
        let sessAcc = account.Session.Session_ID + "/" + account.Session.Account_ID;

        try {
            const response = await axios.delete("Picture/DeleteFavorite/" + sessAcc + "/" + favorite.Favorite_ID);
            if (response.status === 200) {
                console.log(response.data);
                let newFavorites = await fetchFavorites();
                setAccount({ ...account, Favorites: newFavorites });

                //remove favorite from favorites
                let _newFavorites = favorites.filter((fav) => fav.Artikel_nr !== favorite.Picture_Article.Artikel_nr);
                setFavorites(_newFavorites);

                setSnackbarMessage(dict["WS_Messages"]["Favorite removed."][language]);
                setSeverity("success");
                setOpenMessage(true);
            } else {
                setSnackbarMessage(dict["WS_Messages"]["Removing favorite failed."][language]);
                setSeverity("error");
                setOpenMessage(true);
            }
        } catch (error) {
            console.error("Failed to fetch data:", error);
            setSnackbarMessage(dict["WS_Messages"]["Removing favorite failed."][language]);
            setSeverity("error");
            setOpenMessage(true);
        }
    }

    const renderArticles = (article, index) => {
        return (
            <Grid item xs={12} sm={6} md={6} lg={4} xl={3} key={index} sx={{ cursor: "default" }}>
                <Box sx={{ position: "relative", width: 300, p: 0, overflow: "hidden" }}>
                    {article.Prijs_Promo !== 0 && (
                        <Box
                            sx={{
                                position: "absolute",
                                top: 100,
                                left: 0,
                                backgroundColor: "secondary.main",
                                color: "white",
                                transform: "rotate(-45deg)",
                                transformOrigin: "left bottom",
                                width: "200px",
                                textAlign: "center",
                                lineHeight: "30px",
                                letterSpacing: "1px",
                                zIndex: 1000,
                            }}
                        >
                            {`MIN ${Math.round(((article.Prijs - article.Prijs_Promo) / article.Prijs) * 100)}%`}
                        </Box>
                    )}

                    <Card sx={{ width: 280, p: 1, pb: 0, border: "solid 2px lightgray", borderRadius: 5, backgroundColor: "#ededed" }}>
                        <CardMedia
                            key={index}
                            className="object_fit_contain"
                            component="img"
                            height="250"
                            // id={
                            //     group?.Foto_file === environment?.Picture?.Foto_file ? "scrollRef" : null
                            // }
                            src={
                                article.ArrImage
                                    ? "data:image/jpeg;base64," + article.ArrImage.toString()
                                    : require("../images/no_image_available.jpg")
                            }
                            // image={`${item.img}?w=200&fit=crop&auto=format`}
                            alt="event photo"
                        />

                        <CardContent>
                            <Stack direction={"column"} spacing={0}>
                                <Stack direction={"row"} spacing={1} sx={{ width: "100%" }}>
                                    <Typography
                                        variant="caption"
                                        fontWeight="bolder"
                                        fontSize={14}
                                        color="text.secondary"
                                        sx={{
                                            flexGrow: 0,
                                            flexBasis: "auto",
                                            whiteSpace: "normal",
                                        }}
                                    >
                                        {article.Artikel_naam}
                                    </Typography>
                                    {/* <Stack direction={"row"} spacing={1} sx={{ flexGrow: 1, justifyContent: 'right' }}> */}

                                    {/* box align right */}
                                    <Box sx={{ flexGrow: 1, textAlign: "right" }}>
                                        {article.Prijs_Promo !== 0 ? (
                                            <>
                                                <Typography
                                                    variant="caption"
                                                    fontWeight="bolder"
                                                    fontSize={14}
                                                    color="text.secondary"
                                                    sx={{ width: "30%", color: "red", textDecoration: "line-through", pr: "5px" }}
                                                >
                                                    {`€ ${parseFloat(article.Prijs).toFixed(2)}`}
                                                </Typography>
                                                <Typography variant="caption" fontWeight={900} color="text.secondary" sx={{ width: "30%" }}>
                                                    {`€ ${parseFloat(article.Prijs_Promo).toFixed(2)}`}
                                                </Typography>
                                            </>
                                        ) : (
                                            <>
                                                <Typography
                                                    variant="caption"
                                                    fontWeight="bolder"
                                                    fontSize={14}
                                                    color="text.secondary"
                                                    sx={{ width: "30%" }}
                                                >
                                                    {`€ ${parseFloat(article.Prijs).toFixed(2)}`}
                                                </Typography>
                                            </>
                                        )}
                                    </Box>
                                    {/* </Stack> */}
                                </Stack>

                                {/* one button for minus(1) a textfield for numberOfPieces and one button for plus(1) */}
                                <Grid container spacing={0} justifyContent="space-between">
                                    <Grid item>
                                        <IconButton
                                            color={"primary"}
                                            onClick={() => {
                                                add_remove_favorite(article);
                                            }}
                                            sx={{ height: "45px", width: "45px" }}
                                        >
                                            <FavoriteIcon />
                                        </IconButton>
                                    </Grid>

                                    <Grid item>
                                        <IconButton
                                            color="secondary"
                                            onClick={() => {
                                                open_info_dialog(article);
                                            }}
                                            sx={{ height: "45px", width: "45px" }}
                                        >
                                            <InfoIcon />
                                        </IconButton>
                                    </Grid>

                                    <Grid item>
                                        <IconButton
                                            color="secondary"
                                            onClick={() => {
                                                removeOnePieceClicked(article);
                                            }}
                                            sx={{ height: "45px", width: "45px" }}
                                        >
                                            <RemoveIcon />
                                        </IconButton>
                                        <Box
                                            id="outlined-number"
                                            sx={{
                                                width: "70px",
                                                display: "inline",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                border: 1,
                                                borderColor: "primary.main",
                                                borderRadius: "4px",
                                                padding: "5px",
                                                border: "0px solid #ced4da",
                                            }}
                                            variant="caption"
                                        >
                                            {article.numberOfPieces}
                                        </Box>
                                        <IconButton
                                            color="secondary"
                                            onClick={() => {
                                                addOnePieceClicked(article);
                                            }}
                                            sx={{ height: "45px", width: "45px" }}
                                        >
                                            <AddIcon />
                                        </IconButton>
                                    </Grid>
                                    <Grid item>
                                        <IconButton
                                            color="secondary"
                                            onClick={() => {
                                                add_article_to_cart(article);
                                            }}
                                            sx={{ height: "45px", width: "45px" }}
                                        >
                                            <ShoppingBasketOutlinedIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Stack>
                        </CardContent>
                    </Card>
                </Box>
            </Grid>
        );
    };

    async function open_info_dialog(_article) {
        setDialogTitle(dict["WS_Messages"]["Dialog_Title_Article_details"][language]);
        setDialogMessage1(_article.Artikel_naam_lang);
        setDialogMessage2("");
        setDialogMessage3("");
        setDialogMessage4("");

        setOpenInfo(true);
    }

    //Modal Yes No ========================
    const handleClose = () => {
        setOpenYesNo(false);
        setOpenInfo(false);
    };

    async function handleSelectKlasFoto(event) {
        console.log(event.target.value);
        setSelectedKlasFoto(event.target.value);
    }

    async function handleValidateAddOrderLine() {
        var _article = clickedArticle;

        var s = selectedKlasFoto;

        setOpenYesNo(false);

        var _order = undefined;

        if (account.Order === null || account.Order === undefined || Object.keys(account.Order).length === 0) {
            _order = await fetchNewOrder(_article);
        } else {
            _order = account.Order;
        }

        var _session = await createOrderLijn(_order, _article);

        if (areObjectsEqual(_session, account.Session)) {
            var _orderCombo = await fetchOpenOrder();
            setAccount({ ...account, Order: _orderCombo.Order, Cart: _orderCombo.Order_Lijnen });

            setSnackbarMessage(dict["WS_Messages"]["Article added to cart."][language]);
            setSeverity("success");
            setOpenMessage(true);
        }
    }

    function areObjectsEqual(obj1, obj2) {
        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);

        if (keys1.length !== keys2.length) {
            return false;
        }

        for (let key of keys1) {
            if (obj1[key] !== obj2[key]) {
                return false;
            }
        }

        return true;
    }

    async function fetchNewOrder(_article) {
        //body example
        // {
        //     "Session": {
        //           "Session_ID": "C5E0DEA6-1537-41A2-84FA-1590FF0260D7",
        //           "Account_ID": "BB7DD27A-C3E8-4FA6-AC65-E49132D19BB2"
        //     },
        //     "Fotomap_nr": 27861,
        //     "Foto_file": "0641.JPG"
        //   }

        var body = {
            Session: {
                Session_ID: account.Session.Session_ID,
                Account_ID: account.Session.Account_ID,
            },
            Fotomap_nr: _article.Fotomap_nr,
            Foto_file: _article.Foto_file,
        };

        try {
            const response = await axios.post("Order/PostCreateOrder", body);
            if (response.status === 200) {
                console.log(response.data.Order);
                return response.data.Order;
            } else {
                setSnackbarMessage(dict["WS_Messages"]["Creating order failed."][language]);
                setSeverity("error");
                setOpenMessage(true);
            }
        } catch (error) {
            console.error("Failed to fetch data:", error);
            setSnackbarMessage(dict["WS_Messages"]["Creating order failed."][language]);
            setSeverity("error");
            setOpenMessage(true);
        }
    }

    async function createOrderLijn(_order, _article) {
        //body example
        // {
        //     "Session": {
        //           "Session_ID": "C5E0DEA6-1537-41A2-84FA-1590FF0260D7",
        //           "Account_ID": "BB7DD27A-C3E8-4FA6-AC65-E49132D19BB2"
        //     },
        //     "Order_Nr": 5194,
        //     "Fotomap_Nr": 27861,
        //     "Foto_File": "0641.JPG",
        //     "Artikel_Nr": 8,
        //     "Artikel_Groep_ID": "FOTO",
        //     "Artikel_Subgroep_ID": "STFOTO",
        //     "Achtergrond_ID": ""+environment.selectedBackground,
        //     "KL_SE_ZW": "KL",
        //     "Aantal": 2,
        //     "Klasfoto": "",
        //   }

        var klassfoto = {};
        var klassFotoBody = "";
        if (showSelectKlasFoto) {
            if (selectedKlasFoto !== undefined && selectedKlasFoto !== null) {
                //find klasfoto in klassFotos with property 'Foto_file' equal to selectedKlasFoto
                klassfoto = klassFotos.find((kf) => kf.Foto_file === selectedKlasFoto);
                klassFotoBody = klassfoto.Fotomap_nr + "|" + klassfoto.Foto_file;
            }
        }

        var body = {
            Session: {
                Session_ID: account.Session.Session_ID,
                Account_ID: account.Session.Account_ID,
            },
            Order_Nr: _order.order_nr,
            Fotomap_Nr: _article.Fotomap_nr,
            Foto_File: _article.Foto_file,
            Artikel_Nr: _article.Artikel_nr,
            Artikel_Groep_ID: _article.Artikel_Groep_ID,
            Artikel_Subgroep_ID: _article.Artikel_Subgroep_ID,
            Achtergrond_ID: _article.Achtergrond_ID,
            KL_SE_ZW: _article.KL_SE_ZW,
            Aantal: _article.numberOfPieces,
            Klasfoto: klassFotoBody,
        };

        try {
            const response = await axios.post("Order/PostCreateOrderLijn", body);
            if (response.status === 200) {
                console.log(response.data);
                return response.data;
            } else {
                setSnackbarMessage(dict["WS_Messages"]["Adding article to cart failed."][language]);
                setSeverity("error");
                setOpenMessage(true);
            }
        } catch (error) {
            console.error("Failed to fetch data:", error);
            setSnackbarMessage(dict["WS_Messages"]["Adding article to cart failed."][language]);
            setSeverity("error");
            setOpenMessage(true);
        }
        setSelectedKlasFoto("")
        setShowSelectKlasFoto(false);
        setKlassFotos([]);
    }

    async function fetchOpenOrder() {
        var sessAcc = "";
        var _orderCombo = {};

        sessAcc = account.Session.Session_ID + "/" + account.Session.Account_ID;

        const response = await axios.get("Order/GetOpenOrder/" + sessAcc + "/" + language.toUpperCase());
        if (response.status === 200) {
            console.log(response.data);
            _orderCombo = response.data;
        }

        return _orderCombo;
    }


    return (
        <>
            <Grid container spacing={2}>
                {loading ? (
                    <>
                        <Grid item xs={12} md={12} mt={2}>
                            <Typography align="left" color={"#023047"} fontWeight={600} variant="h6">
                                {dict["WS_Messages"]["Loading..."][language]}
                            </Typography>

                            <Box sx={{ pt: 0.5 }}>
                                <Skeleton variant="rectangular" width={"100%"} height={60} />
                            </Box>

                            <Box sx={{ display: "flex", gap: 2 }}>
                                <Box sx={{ pt: 0.5 }}>
                                    <Skeleton variant="rectangular" width={210} height={118} />
                                    <Skeleton width="60%" />
                                    <Skeleton width="100%" />
                                </Box>
                                <Box sx={{ pt: 0.5 }}>
                                    <Skeleton variant="rectangular" width={210} height={118} />
                                    <Skeleton width="40%" />
                                    <Skeleton width="80%" />
                                </Box>
                                <Box sx={{ pt: 0.5 }}>
                                    <Skeleton variant="rectangular" width={210} height={118} />
                                    <Skeleton width="80%" />
                                    <Skeleton width="50%" />
                                </Box>
                            </Box>

                            <Box sx={{ pt: 0.5 }}>
                                <Skeleton variant="rectangular" width={"100%"} height={60} />
                            </Box>

                            <Box sx={{ pt: 0.5 }}>
                                <Skeleton variant="rectangular" width={"100%"} height={60} />
                            </Box>
                        </Grid>
                    </>
                ) : null}
            </Grid>

            <Stack spacing={3} m={3}>

                <Grid container spacing={1}>
                    {!loading && favorites?.map((article, index) => <>{renderArticles(article, index)}</>)}
                </Grid>
                <Box sx={{ height: 20 }}></Box>
            </Stack>

            {fakeState ? <></> : <></>}

            {openZoomModal ? (
                <ZoomModal
                    open={openZoomModal}
                    onClose={() => {
                        setOpenZoomModal(false);
                    }}
                    imageUrl={""}
                />
            ) : null}

            <Snackbar
                open={openMessage}
                onClose={() => {
                    setOpenMessage(false);
                }}
                TransitionComponent={SlideTransition}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                key="Snackbar"
            >
                <Alert
                    severity={severity}
                    sx={{ marginTop: 2 }}
                    onClose={() => {
                        setOpenMessage(false);
                    }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>

            <Dialog open={openYesNo} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Stack direction={"column"} spacing={2}>
                            <Typography variant="body1" color="#FB8500">
                                {dialogMessage1}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {dialogMessage2}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {dialogMessage3}
                            </Typography>
                            <Typography variant="body1" color="text.secondary">
                                {dialogMessage4}
                            </Typography>
                            {showSelectKlasFoto && (
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">{dict["WS_Messages"]["Select_class_photo"][language]}</FormLabel>
                                    <RadioGroup
                                        aria-label="selectKlasFoto"
                                        name="selectKlasFoto"
                                        value={selectedKlasFoto}
                                        onChange={handleSelectKlasFoto}
                                    >
                                        {/* for every foto in klasFotos create a FormControlLabel with label the foto_file */}
                                        {/* {klassFotos.map((foto, index) => (
                                            <FormControlLabel key={index} value={foto.Foto_file} control={<Radio />} label={foto.Foto_file} />
                                        ))} */}
                                        {klassFotos.map((foto, index) => (
                                            <FormControlLabel
                                                key={index}
                                                value={foto.Foto_file}
                                                control={<Radio />}
                                                label={
                                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                                        <img
                                                            src={
                                                                foto.ArrImage
                                                                    ? "data:image/jpeg;base64," + foto.ArrImage.toString()
                                                                    : require("../images/no_image_available.jpg")
                                                            }
                                                            alt="klass foto"
                                                            style={{ height: 50, marginRight: 8 }}
                                                        />
                                                        {foto.Foto_file}
                                                    </Box>
                                                }
                                            />
                                        ))}
                                    </RadioGroup>
                                </FormControl>
                            )}
                        </Stack>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>{dict["WS_Labels"]["Disagree"][language]}</Button>
                    <Button onClick={handleValidateAddOrderLine}>{dict["WS_Labels"]["Agree"][language]}</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openInfo} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Stack direction={"column"} spacing={2}>
                            <Typography variant="body1" color="text.secondary">
                                {dialogMessage1}
                            </Typography>
                            <Typography variant="body1" color="text.secondary">
                                {dialogMessage2}
                            </Typography>
                            <Typography variant="body1" color="text.secondary">
                                {dialogMessage3}
                            </Typography>
                            <Typography variant="body1" color="text.secondary">
                                {dialogMessage4}
                            </Typography>
                        </Stack>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>{dict["WS_Messages"]["Close"][language]}</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

function SlideTransition(props: SlideProps) {
    return <Slide {...props} direction="up" />;
}

function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // January is 0!
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
}

export default WS_Favorites;
