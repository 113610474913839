import { createContext, useState, useEffect } from "react";

const OrderContext = createContext({});

export const OrderProvider = ({ children }) => {

    const [order, setOrder] = useState(sessionStorage.getItem("session_order") === null ? {} : JSON.parse(sessionStorage.getItem("session_order")));

    useEffect(() => {
        sessionStorage.setItem("session_order", JSON.stringify(order));
    }
        , [order]);

    return (
        <OrderContext.Provider value={{ order, setOrder }}>
            {children}
        </OrderContext.Provider>
    )
}

export default OrderContext;