import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    typography: {
        fontFamily: ['"Montserrat"', 'Open Sans'].join(',')
    },
    palette: {
        primary: {
            // main: "#176e83",
            main: "#FB8500",
            contrastText: "#FFFFFF",
        },
        secondary: {
            main: '#219EBC'
        }

    },
    status: {
        smoolDark: '#219EBC',
    },
    components: {
        MuiAlert: {
            styleOverrides: {
                standardSuccess: {
                    backgroundColor: '#41a2b9',
                    color: 'white'
                },
                standardError: {
                    backgroundColor: '#fba544',
                    color: 'white'
                },
                standardWarning: {
                    backgroundColor: '#fba544',
                    color: 'white'
                },
                standardInfo: {
                    backgroundColor: '#41a2b9',
                    color: 'black'
                }
            }
        },
    },
})

export default theme;