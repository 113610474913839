import axios from "../api/axios";
import useLanguageContext from "../hooks/useLanguageContext";

import React, { useState, useEffect } from "react";
// import { NavLink, } from "react-router-dom";
import { Box, Typography, Stack, Grid, Button, Card, TextField } from "@mui/material";

import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Slide, { SlideProps } from "@mui/material/Slide";

import WebsiteDict from "../data/WebsiteDict";

const Contact = () => {

    const { language, setLanguage } = useLanguageContext();

    const [dict, setDict] = useState(WebsiteDict);

    useEffect(() => {
        setDict(WebsiteDict);
    }, [WebsiteDict]);

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [message, setMessage] = useState("");


    const [openMessage, setOpenMessage] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [severity, setSeverity] = useState("success");

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);



    const handleChangeFirstName = (event) => {
        setFirstName(event.target.value);
    };

    const handleChangeLastName = (event) => {
        setLastName(event.target.value);
    };

    const handleChangeEmail = (event) => {
        setEmail(event.target.value);
    };

    const handleChangePhone = (event) => {
        setPhone(event.target.value);
    };

    const handleChangeCompanyName = (event) => {
        setCompanyName(event.target.value);
    };

    const handleChangeMessage = (event) => {
        setMessage(event.target.value);
    };

    const handleSendContactMessage = () => {
        console.log("handleSendContactMessage");
        let msg = "";
        if (firstName === "") {
            msg = msg + dict["Messages"]["First name is required."][language];
        }
        if (lastName === "") {
            msg = msg + dict["Messages"]["Last name is required."][language];
        }
        if (email === "") {
            msg = msg + dict["Messages"]["Email is required."][language];
        }

        // if (companyName === "") {
        //     msg = msg + dict["Messages"]["School name is required."][language];
        // }

        if (message === "") {
            msg = msg + dict["Messages"]["Message is required."][language];
        }

        if (msg !== "") {
            setSnackbarMessage(msg);
            setSeverity("error");
            setOpenMessage(true);
            return;
        }

        //validate email
        const re = /\S+@\S+\.\S+/;
        if (!re.test(email)) {
            setSnackbarMessage("Email is not valid.");
            setSeverity("error");
            setOpenMessage(true);
            return;
        }

        //validate phone
        // if (phone !== "") {
        //     const re = /^[0-9\b]+$/;
        //     if (!re.test(phone)) {
        //         setSnackbarMessage(dict["Messages"]["Phone is not valid. Only numbers are allowed."][language]);
        //         setSeverity("error");
        //         setOpenMessage(true);
        //         return;
        //     }
        // }

        //validate message length
        if (message.length > 1000) {
            setSnackbarMessage(dict["Messages"]["Message is too long."][language]);
            setSeverity("error");
            setOpenMessage(true);
            return;
        }

        let languageCapitals = language.toUpperCase();

        const fetchMessage = async () => {

            try {

                let body = {
                    "ID": null,
                    "Name": lastName,
                    "FirstName": firstName,
                    "Email": email,
                    "Phone": phone,
                    "SchoolName": companyName,
                    "Language_Code": languageCapitals,
                    "Message": message,
                    "Timestamp": null
                };

                const response = await axios.post("/api/Company/PostSchoolContactRequest", body);

                if (response.status === 200) {
                    setSnackbarMessage(dict["Messages"]["Message sent successfully."][language]);
                    setSeverity("success");
                    setOpenMessage(true);
                    setFirstName("");
                    setLastName("");
                    setEmail("");
                    setPhone("");
                    setCompanyName("");
                    setMessage("");
                }
                else {
                    setSnackbarMessage(dict["Messages"]["Message could not be sent."][language]);
                    setSeverity("error");
                    setOpenMessage(true);
                }
            } catch (error) {
                console.log(error);
                setSnackbarMessage(dict["Messages"]["Message could not be sent."][language]);
                setSeverity("error");
                setOpenMessage(true);

            }

        };


        fetchMessage();
    };









    return (
        <>
            <Stack spacing={8} m={3}>

                <Grid container spacing={2}>

                    <Grid item xs={12} md={12}>
                        <Box pb={2}>
                            <Typography fontWeight={600} mt={1} variant="h4">{dict["Labels"]["contact_us"][language]}</Typography>
                        </Box>
                    </Grid>


                    <Grid item xs={12} md={12}  >

                        <Box maxWidth={700} margin={"auto"} borderRadius={3} >
                            <Card>
                                <Grid container spacing={4} p={3}   >

                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            value={firstName}
                                            label={dict["Labels"]["first_name"][language]}
                                            onChange={handleChangeFirstName}
                                            size="small"
                                            id="outlined-name"
                                            fullWidth={true}
                                            variant="standard"
                                            margin="normal"
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            value={lastName}
                                            label={dict["Labels"]["last_name"][language]}
                                            onChange={handleChangeLastName}
                                            size="small"
                                            id="outlined-name"
                                            fullWidth={true}
                                            variant="standard"
                                            margin="normal"
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            value={email}
                                            label={dict["Labels"]["email"][language]}
                                            onChange={handleChangeEmail}
                                            size="small"
                                            id="outlined-name"
                                            fullWidth={true}
                                            variant="standard"
                                            margin="normal"
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            value={phone}
                                            label={dict["Labels"]["phone"][language]}
                                            onChange={handleChangePhone}
                                            size="small"
                                            id="outlined-name"
                                            fullWidth={true}
                                            variant="standard"
                                            margin="normal"
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            value={companyName}
                                            label={dict["Labels"]["school_name"][language]}
                                            onChange={handleChangeCompanyName}
                                            size="small"
                                            id="outlined-name"
                                            fullWidth={true}
                                            variant="standard"
                                            margin="normal"
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={12}>
                                        <TextField
                                            value={message}
                                            label={dict["Labels"]["message"][language]}
                                            onChange={handleChangeMessage}
                                            size="small"
                                            id="outlined-name"
                                            fullWidth={true}
                                            variant="outlined"
                                            margin="normal"
                                            multiline
                                            rows={4}
                                        />
                                    </Grid>


                                </Grid>
                            </Card>
                        </Box>
                        <Box pt={3}>
                            <Button variant="contained" color="primary" onClick={handleSendContactMessage} sx={{ mt: 2 }}>
                                {dict["Labels"]["send"][language]}
                            </Button>
                        </Box>
                    </Grid>

                </Grid>


                <Snackbar
                    open={openMessage}
                    onClose={() => { setOpenMessage(false); }}
                    TransitionComponent={SlideTransition}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center", }}
                    key="Snackbar"
                >
                    <Alert
                        severity={severity}
                        sx={{ marginTop: 2 }}
                        onClose={() => {
                            setOpenMessage(false);
                        }}
                    >
                        {snackbarMessage}
                    </Alert>
                </Snackbar>



            </Stack >

        </>
    );
};

function SlideTransition(props: SlideProps) {
    return <Slide {...props} direction="up" />;
}

export default Contact;
