const WebsiteDict = {
    WS_Labels: {
        Singin: {
            nl: "Inloggen",
            en: "Sign in",
            fr: "Se connecter",
        },
        Email: {
            nl: "E-mailadres",
            en: "Email address",
            fr: "Adresse e-mail",
        },
        Password: {
            nl: "Wachtwoord",
            en: "Password",
            fr: "Mot de passe",
        },
        Continue: {
            nl: "Doorgaan",
            en: "Continue",
            fr: "Continuer",
        },
        Visit_our_website: {
            nl: "Bezoek onze website",
            en: "Visit our website",
            fr: "Visitez notre site web",
        },
        text_under_signin_line1: {
            en: "Not yet known to us?",
            nl: "Ben je nog niet bekend bij ons?",
            fr: "Vous n'êtes pas encore connu chez nous?",
        },
        text_under_signin_line2: {
            en: "Then first create your new account",
            nl: "Maak dan eerst je nieuwe account aan",
            fr: "Alors créer d'abord votre nouveau compte",
        },
        sign_up_here: {
            en: "Create your account",
            nl: "Account aanmaken",
            fr: "Créer votre compte",
        },
        enter_your_email: {
            en: "Enter your email address",
            nl: "Vul je e-mailadres in",
            fr: "Entrez votre adresse e-mail",
        },
        email: {
            en: "Email address",
            nl: "E-mailadres",
            fr: "Adresse e-mail",
        },
        enter_your_password: {
            en: "Enter your password",
            nl: "Vul je wachtwoord in",
            fr: "Entrez votre mot de passe",
        },
        password: {
            en: "Password",
            nl: "Wachtwoord",
            fr: "Mot de passe",
        },
        forgot_password: {
            en: "Forgot password?",
            nl: "Wachtwoord vergeten?",
            fr: "Mot de passe oublié?",
        },
        Button_Back: {
            en: "Back",
            nl: "Terug",
            fr: "Retour",
        },
        Button_General_groups: {
            en: "Full assortment",
            nl: "Volledig assortiment",
            fr: "Assortiment complet",
        },
        Button_Most_ordered: {
            en: "Most ordered",
            nl: "Meest besteld",
            fr: "Le plus commandé",
        },
        Button_Promotions: {
            en: "Promotions",
            nl: "Promoties",
            fr: "Promotions",
        },
        Button_Forward: {
            en: "Forward",
            nl: "Vooruit",
            fr: "En avant",
        },
        Button_Add_to_cart: {
            en: "Add to bag",
            nl: "Toevoegen aan winkelmand",
            fr: "Ajouter au panier",
        },
        Button_Color: {
            en: "Color",
            nl: "Kleur",
            fr: "Couleur",
        },
        Button_Sephia: {
            en: "Sepia",
            nl: "Sepia",
            fr: "Sepia",
        },
        Button_Black_white: {
            en: "B/W",
            nl: "Z/W",
            fr: "N/B",
        },
        Button_Add_to_favorites: {
            en: "Add to favorites",
            nl: "Toevoegen aan favorieten",
            fr: "Ajouter aux favoris",
        },
        Button_Cart: {
            en: "Bag",
            nl: "Winkelmand",
            fr: "Panier",
        },
        Button_Favorites: {
            en: "Favorites",
            nl: "Favorieten",
            fr: "Favoris",
        },
        Button_Help: {
            en: "Help",
            nl: "Help",
            fr: "Aide",
        },
        Disagree: {
            nl: "Neen",
            en: "No",
            fr: "Non",
        },
        Agree: {
            nl: "Ja",
            en: "Yes",
            fr: "Oui",
        },
        Button_Change_background: {
            en: "Change background",
            nl: "Wissel achtergrond",
            fr: "Changer l'arrière-plan",
        },
        Year_of_Event: {
            en: "Year of Event",
            nl: "Jaar van evenement",
            fr: "Année de l'événement",
        },
        School_name_and_Year_of_Event: {
            en: "School name and year of event",
            nl: "Schoolnaam en jaar van event",
            fr: "Nom de l'école et année de l'événement",
        },
        Foto_Session_of_Event: {
            en: "Photo Session",
            nl: "Fotosessie",
            fr: "Séance photo",
        },
        Lastname: {
            en: "Name",
            nl: "Naam",
            fr: "Nom",
        },
        Firstname: {
            en: "First name",
            nl: "Voornaam",
            fr: "Prénom",
        },
        EmailFrom: {
            en: "Email from",
            nl: "E-mail van",
            fr: "E-mail de",
        },
        EmailTo: {
            en: "Email to",
            nl: "E-mail naar",
            fr: "E-mail à",
        },
        EmailMessage: {
            en: "Email message",
            nl: "E-mail bericht",
            fr: "Message e-mail",
        },
        SendButton: {
            en: "Send",
            nl: "Verzenden",
            fr: "Envoyer",
        },
        KL: {
            en: "Color",
            nl: "Kleur",
            fr: "Couleur",
        },
        SE: {
            en: "Sepia",
            nl: "Sepia",
            fr: "Sepia",
        },
        ZW: {
            en: "Black & white",
            nl: "Zwart-wit",
            fr: "Noir et blanc",
        },

        firstName: {
            en: "First name",
            nl: "Voornaam",
            fr: "Prénom",
        },
        lastName: {
            en: "Name",
            nl: "Naam",
            fr: "Nom",
        },
        street: {
            en: "Street",
            nl: "Straat",
            fr: "Rue",
        },
        houseNr: {
            en: "House number",
            nl: "Huisnummer",
            fr: "Numéro de maison",
        },
        bus: {
            en: "Box",
            nl: "Bus",
            fr: "Boîte",
        },
        postcode: {
            en: "Postal code",
            nl: "Postcode",
            fr: "Code postal",
        },
        city: {
            en: "City",
            nl: "Gemeente",
            fr: "Commune",
        },
        telephone: {
            en: "Telephone",
            nl: "Telefoon",
            fr: "Téléphone",
        },
        gsm: {
            en: "GSM",
            nl: "GSM",
            fr: "GSM",
        },
        deliveryDetailsTitle: {
            nl: "Mijn gegevens",
            en: "My details",
            fr: "Mes coordonnées",
        },
        gotoDeliveryDetails: {
            en: "Place order",
            nl: "Bestelling plaatsen",
            fr: "Placer la commande",
        },
        gotoOrderOverview: {
            en: "Confirm order",
            nl: "Bestelling bevestigen",
            fr: "Confirmer la commande",
        },
        gotoPayment: {
            en: "Go to payment",
            nl: "Bestelling betalen",
            fr: "Payer la commande",
        },
        OrderNumber: {
            en: "Order number",
            nl: "Bestellingnummer",
            fr: "Numéro de commande",
        },
        DateOrdered: {
            en: "Date ordered",
            nl: "Datum besteld",
            fr: "Date de commande",
        },
        Delivery: {
            en: "Delivery",
            nl: "Levering",
            fr: "Livraison",
        },
        PaymentMethod: {
            en: "Payment method",
            nl: "Betalingsmiddel",
            fr: "Mode de paiement",
        },
        deliveryHome: {
            en: "Home",
            nl: "Thuis",
            fr: "A domicile",
        },
        deliverySchool: {
            en: "School",
            nl: "School",
            fr: "École",
        },
        Button_login: {
            en: "Login",
            nl: "Inloggen",
            fr: "Se connecter",
        },

        Page_home: {
            en: "My pictures",
            nl: "Mijn foto's",
            fr: "Mes photos",
        },

        Page_order: {
            en: "Assortment",
            nl: "Assortiment",
            fr: "Assortiment",
        },

        Page_events: {
            en: "My Events",
            nl: "Mijn Events",
            fr: "Mes événements",
        },

        Page_favorites: {
            en: "Favorites",
            nl: "Favorieten",
            fr: "Favoris",
        },

        Page_share: {
            en: "Share photo's",
            nl: "Foto's delen",
            fr: "Partager mes photo's",
        },

        Page_cart: {
            en: "Bag",
            nl: "Winkelmand",
            fr: "Panier",
        },

        Page_history: {
            nl: "Bestellingen",
            en: "Orders",
            fr: "Commandes",
        },

        Page_mydetails: {
            en: "My details",
            nl: "Mijn gegevens",
            fr: "Mes coordonnées",
        },

        Page_contact: {
            en: "Contact",
            nl: "Contact",
            fr: "Contact",
        },

        Page_help: {
            en: "Help",
            nl: "Help",
            fr: "Aide",
        },
        Page_desc_home: {
            en: "Click on a photo and discover our assortment",
            nl: "Klik op een foto en ontdek ons assortiment",
            fr: "Cliquez sur une photo et découvrez notre assortiment",
        },

        Page_desc_order: {
            en: "Click on an atricle group and discover our range",
            nl: "Klik op een artikel groep en ontdek ons assortiment",
            fr: "Cliquez sur un groupe d'article et découvrez notre assortiment",
        },

        Page_desc_events: {
            en: "View events",
            nl: "Bekijk events",
            fr: "Voir les événements",
        },

        Page_desc_favorites: {
            en: "View your favorites",
            nl: "Bekijk je favorieten",
            fr: "Voir vos favoris",
        },

        Page_desc_share: {
            en: "Tick the photo's that you want to share",
            nl: "Vink de foto's aan die u wenst te delen",
            fr: "Cochez les photo's que vous désirez partager",
        },

        Page_desc_cart: {
            en: "View your bag and change where needed",
            nl: "Bekijk je winkelmand en wijzig waar nodig",
            fr: "Voir votre panier et modifiez si nécessaire",
        },

        Page_desc_history: {
            en: "View your orders",
            nl: "Bekijk je bestellingen",
            fr: "Voir vos commandes",
        },

        Page_desc_mydetails: {
            en: "View your details and modify where needed",
            nl: "Bekijk je gegevens en wijzig waar nodig",
            fr: "Voir vos détails et modifiez si nécessaire",
        },

        Page_desc_contact: {
            en: "Contact us",
            nl: "Contacteer ons",
            fr: "Contactez-nous",
        },

        Page_desc_help: {
            en: "Help",
            nl: "Help",
            fr: "Aide",
        },

        Artikle_Naam: {
            en: "Article name",
            nl: "Artikel naam",
            fr: "Nom d'article",
        },
        KL_SE_ZW: {
            en: "Color",
            nl: "Kleur",
            fr: "Couleur",
        },
        Aantal: {
            en: "Quantity",
            nl: "Aantal",
            fr: "Quantité",
        },
        Eenheidsprijs: {
            en: "Unit price",
            nl: "Eenheidsprijs",
            fr: "Prix unitaire",
        },
        priceTotal: {
            en: "Total price",
            nl: "Totale prijs",
            fr: "Prix total",
        },
        delete: {
            en: "Delete",
            nl: "Verwijderen",
            fr: "Supprimer",
        },
        my_details_saveChanges: {
            en: "Save changes",
            nl: "Wijzigingen opslaan",
            fr: "Sauvegarder les modifications",
        },
        myDetailsTitle: {
            en: "My details",
            nl: "Mijn gegevens",
            fr: "Mes coordonnées",
        },
        label_name: {
            en: "Name",
            nl: "Naam",
            fr: "Nom",
        },
        topic: {
            en: "Subject",
            nl: "Onderwerp",
            fr: "Sujet",
        },
        message: {
            en: "Message",
            nl: "Bericht",
            fr: "Message",
        },
        contact_send_message: {
            en: "Send message",
            nl: "Bericht verzenden",
            fr: "Envoyer le message",
        },
        // Barnard email
        Button_createaccount: {
            en: "Create account",
            nl: "Account aanmaken",
            fr: "Créer un compte",
        },
        Title_createaccount: {
            en: "Create account",
            nl: "Account aanmaken",
            fr: "Créer un compte",
        },
        email_repeat: {
            en: "Repeat your email address",
            nl: "Herhaal je e-mailadres",
            fr: "Répétez votre adresse e-mail",
        },
        confirm_email: {
            en: "Confirm your email address",
            nl: "Bevestig je e-mailadres",
            fr: "Confirmer votre adresse e-mail",
        },
        create_password: {
            en: "Create a password",
            nl: "Maak een wachtwoord aan",
            fr: "Créer un mot de passe",
        },
        confirm_password: {
            en: "Confirm your password",
            nl: "Bevestig je wachtwoord",
            fr: "Confirmer votre mot de passe",
        },
        photo_code: {
            en: "Photo code",
            nl: "Fotocode",
            fr: "Code photo",
        },
        ForgotPassword: {
            en: "Forgot password",
            nl: "Wachtwoord vergeten",
            fr: "Mot de passe oublié",
        },
        Button_send: {
            en: "Send",
            nl: "Verzenden",
            fr: "Envoyer",
        },
        Button_change_photo: {
            en: "Change photo",
            nl: "Wissel foto",
            fr: "Changer la photo",
        },
        Backgrounds: {
            en: "Backgrounds",
            nl: "Achtergronden",
            fr: "Arrière-plans",
        },
        Save: {
            en: "Save",
            nl: "Opslaan",
            fr: "Sauvegarder",
        },
        Overview_title: {
            en: "Overview",
            nl: "Overzicht",
            fr: "Aperçu",
        },
        Subtotal: {
            en: "Subtotal",
            nl: "Subtotaal",
            fr: "Sous-total",
        },
        Discount: {
            en: "Discount",
            nl: "Korting",
            fr: "Remise",
        },
        // Discount_with_percentage: {
        //     en: "Discount XX %",
        //     nl: "Korting XX %",
        //     fr: "Remise XX %",
        // },
        Discount_with_percentage: {
            en: "Discount",
            nl: "Korting",
            fr: "Remise",
        },
        Delivery_costs: {
            en: "Delivery costs",
            nl: "Leveringskosten",
            fr: "Frais de livraison",
        },
        Discount_coupon: {
            en: "Discount voucher",
            nl: "Korting waardebon",
            fr: "Remise bon d'achat",
        },
        Balance_coupon: {
            en: "Balance voucher",
            nl: "Saldo waardebon",
            fr: "Solde bon d'achat",
        },
        Home_delivery: {
            en: "Home delivery",
            nl: "Thuislevering",
            fr: "Livraison à domicile",
        },
        Total: {
            en: "Total",
            nl: "Totaal",
            fr: "Total",
        },
        Discount_code: {
            en: "Voucher code teacher",
            nl: "Code waardebon leerkracht",
            fr: "Code bon d’achat enseignant",
        },
        Code: {
            en: "Code",
            nl: "Code",
            fr: "Code",
        },
        Page_home_small: {
            nl: "Foto's",
            en: "Photos",
            fr: "Photos",
        },
        Page_order_small: {
            nl: "Assortiment",
            en: "Assortment",
            fr: "Assortiment",
        },
        Page_events_small: {
            nl: "Events",
            en: "Events",
            fr: "Événements",
        },
        Page_favorites_small: {
            nl: "Favorieten",
            en: "Favorites",
            fr: "Favoris",
        },
        Page_share_small: {
            nl: "Delen",
            en: "Share",
            fr: "Partager",
        },
        Page_cart_small: {
            nl: "Winkelmand",
            en: "Bag",
            fr: "Panier",
        },
        Page_history_small: {
            nl: "Bestellingen",
            en: "Orders",
            fr: "Commandes",
        },
        Page_mydetails_small: {
            nl: "Gegevens",
            en: "Details",
            fr: "Coordonnées",
        },
        Page_contact_small: {
            nl: "Contact",
            en: "Contact",
            fr: "Contact",
        },
        Page_help_small: {
            nl: "Help",
            en: "Help",
            fr: "Aide",
        },
        "Terms_&_Conditions": {
            nl: "Algemene voorwaarden",
            en: "Terms & Conditions",
            fr: "Conditions générales",
        },
        "Privacy_policy": {
            nl: "Privacy Policy",
            en: "Privacy policy",
            fr: "Politique de confidentialité",
        },
        "Logout": {
            nl: "Uitloggen",
            en: "Logout",
            fr: "Déconnexion",
        },
        "history_tbl_Nummer": {
            nl: "Nummer",
            en: "Number",
            fr: "Numéro",
        },
        "history_tbl_Besteld": {
            nl: "Besteld",
            en: "Ordered",
            fr: "Commandé",
        },
        "history_tbl_Betaald": {
            nl: "Betaald",
            en: "Paid",
            fr: "Payé",
        },
        "history_tbl_In productie": {
            nl: "In productie",
            en: "In production",
            fr: "En production",
        },
        "history_tbl_Afgewerkt": {
            nl: "Afgewerkt",
            en: "Finished",
            fr: "Terminé",
        },
        "history_tbl_Verzonden": {
            nl: "Verzonden",
            en: "Sent",
            fr: "Envoyé",
        },
        "history_tbl_Bedrag": {
            nl: "Bedrag",
            en: "Amount",
            fr: "Montant",
        },
        "history_tbl_Levering_via": {
            nl: "Levering via",
            en: "Delivery via",
            fr: "Livraison via",
        },
        "history_tbl_Betaalwijze": {
            nl: "Betaalwijze",
            en: "Payment method",
            fr: "Méthode de paiement",
        },
        "Unsubscribe": {
            nl: "Uitschrijven",
            en: "Unsubscribe",
            fr: "Se désabonner",
        },

        "history_sub_tbl_Foto": {
            nl: "Foto",
            en: "Photo",
            fr: "Photo",
        },
        "history_sub_tbl_Aantal": {
            nl: "Aantal",
            en: "Quantity",
            fr: "Quantité",
        },
        "history_sub_tbl_Article": {
            nl: "Artikel",
            en: "Article",
            fr: "Article",
        },
        "history_sub_tbl_Eenheidsprijs": {
            nl: "Eenheidsprijs",
            en: "Unit price",
            fr: "Prix unitaire",
        },
        "history_sub_tbl_Totaal": {
            nl: "Totaal",
            en: "Total",
            fr: "Total",
        },
        "history_sub_tbl_Download": {
            nl: "Download",
            en: "Download",
            fr: "Télécharger",
        },


    },









    WS_Messages: {
        "Email is required.": {
            nl: "E-mailadres is verplicht. ",
            en: "Email address is compulsory. ",
            fr: "Adresse E-mail est obligatoire. ",
        },
        "Email is not valid.": {
            nl: "E-mailadres is niet geldig. ",
            en: "Email address is not valid. ",
            fr: "Adresse e-mail non valide. ",
        },
        "Password is required.": {
            nl: "Wachtwoord is verplicht. ",
            en: "Password is compulsory. ",
            fr: "Mot de passe est obligatoire. ",
        },
        "Login failed.": {
            nl: "Aanmelden mislukt. ",
            en: "Login failed. ",
            fr: "La connexion a échoué. ",
        },
        "Succesfully logged in.": {
            nl: "Succesvol aangemeld. ",
            en: "Successfully logged in. ",
            fr: "Connecté avec succès. ",
        },
        "Loading...": {
            nl: "Laden... ",
            en: "Loading... ",
            fr: "Chargement... ",
        },
        "School year": {
            nl: "Schooljaar",
            en: "School year",
            fr: "Année scolaire",
        },
        Zoom: {
            nl: "Zoom",
            en: "Zoom",
            fr: "Zoom",
        },
        "Zoom not available": {
            nl: "Zoom niet beschikbaar",
            en: "Zoom not available",
            fr: "Zoom non disponible",
        },
        "No photos available for this school year.": {
            nl: "Geen foto's beschikbaar voor dit schooljaar.",
            en: "No photos available for this school year.",
            fr: "Pas de photos disponibles pour cette année scolaire.",
        },
        "You have not yet added the photos from this school year.": {
            en: "You have not yet added the photos from this school year. As soon as you receive the flyer from the school, you can add the photo of the current school year by entering the photo code stated on the flyer.",
            nl: "Je hebt de foto's van dit schooljaar nog niet toegevoegd. Van zodra je de flyer van de school ontvangt kan je de foto toevoegen van huidig schooljaar door de fotocode in te geven vermeld op de flyer.",
            fr: "Vous n'avez pas encore ajouté les photos de cette année scolaire. Dès que vous recevez le flyer de l'école, vous pouvez ajouter la photo de cette année scolaire en saisissant le code photo mentionné sur le flyer.",
        },
        "Please enter a photo code.": {
            nl: "Gelieve een fotocode in te geven.",
            en: "Please enter a photo code.",
            fr: "Veuillez saisir un code photo.",
        },
        "Photo code": {
            nl: "Fotocode",
            en: "Photo code",
            fr: "Code photo",
        },
        "Add photos": {
            nl: "Foto toevoegen",
            en: "Add photo",
            fr: "Ajouter une photo",
        },
        "Photo code not found.": {
            nl: "Fotocode niet gevonden.",
            en: "Photo code not found.",
            fr: "Code photo non trouvé.",
        },
        "Photo code found.": {
            nl: "Fotocode gevonden.",
            en: "Photo code found.",
            fr: "Code photo trouvé.",
        },
        "De code is niet correct": {
            nl: "De code is niet correct.",
            en: "The code is not correct.",
            fr: "Le code n'est pas correct.",
        },
        "Unable to get zoomed photo.": {
            nl: "Kan niet inzoomen op foto.",
            en: "Unable to zoom in on photo.",
            fr: "Impossible de zoomer sur la photo.",
        },
        "Loading groups failed.": {
            nl: "Groepen laden mislukt.",
            en: "Loading groups failed.",
            fr: "Échec chargement des groupes.",
        },
        "Loading subgroups failed.": {
            nl: "Artikelgroepen laden mislukt.",
            en: "Loading product groups failed.",
            fr: "Échec du chargement des groupes d'articles.",
        },
        "Loading products failed.": {
            nl: "Gelieve eerst een foto te selecteren door erop te klikken",
            en: "Please first select a photo by clicking on it",
            fr: "Veuillez d'abord sélectionner une photo en cliquant dessus",
        },
        "Loading articles failed.": {
            nl: "Artikelen laden mislukt.",
            en: "Loading articles failed.",
            fr: "Échec du chargement des articles.",
        },
        "Loading promotions failed.": {
            nl: "Promoties laden mislukt.",
            en: "Loading promotions failed.",
            fr: "Échec du chargement des promotions.",
        },
        "Favorite added.": {
            nl: "Favoriet toegevoegd.",
            en: "Favorite added.",
            fr: "Favori ajouté.",
        },
        "Favorite removed.": {
            nl: "Favoriet verwijderd.",
            en: "Favorite removed.",
            fr: "Favori supprimé.",
        },
        "Removing favorite failed.": {
            nl: "Favoriet verwijderen mislukt.",
            en: "Removing favorite failed.",
            fr: "Échec de la suppression du favori.",
        },
        "Please select a quantity.": {
            nl: "Gelieve een hoeveelheid te selecteren.",
            en: "Please select a quantity.",
            fr: "Veuillez sélectionner une quantité.",
        },
        "Adding article to cart failed.": {
            nl: "Artikel toevoegen aan winkelmand mislukt.",
            en: "Adding article to bag failed.",
            fr: "Échec de l'ajout de l'article au panier.",
        },
        "Article added to cart.": {
            nl: "Artikel toegevoegd aan winkelmand.",
            en: "Article added to bag.",
            fr: "Article ajouté au panier.",
        },
        Dialog_Title_Add_to_cart: {
            nl: "Voeg artikel toe aan winkelmand",
            en: "Add article to bag",
            fr: "Ajouter l'article au panier",
        },
        "Dialog Message Attention Picture is from other schoolyear": {
            nl: "Opgelet, je bestelt een foto van een vorig schooljaar.",
            en: "Attention, you are ordering a photo from a previous school year.",
            fr: "Attention, vous commandez une photo d'une année scolaire précédente.",
        },
        "Article details:": {
            nl: "Artikeldetails:",
            en: "Article details:",
            fr: "Détails de l'article :",
        },
        "Number of articles:": {
            nl: "Aantal artikelen:",
            en: "Number of articles:",
            fr: "Nombre d'articles :",
        },
        "Do you want to add this article to your cart?": {
            nl: "Wil je dit artikel toevoegen aan je winkelmand?",
            en: "Do you want to add this article to your bag?",
            fr: "Voulez-vous ajouter cet article à votre panier ?",
        },
        Dialog_Title_Article_details: {
            nl: "Artikeldetails",
            en: "Article details",
            fr: "Détails de l'article",
        },

        "Loading backgrounds failed.": {
            nl: "Achtergronden laden mislukt.",
            en: "Loading backgrounds failed.",
            fr: "Échec du chargement des arrière-plans.",
        },
        "Loading events failed.": {
            nl: "Evenementen laden mislukt.",
            en: "Loading events failed.",
            fr: "Échec du chargement des événements.",
        },
        "Loading event sessions failed.": {
            nl: "Evenement sessies laden mislukt.",
            en: "Loading event sessions failed.",
            fr: "Échec du chargement des sessions d'événements.",
        },
        "Photo added to My Pictures.": {
            nl: "Foto toegevoegd",
            en: "Photo added",
            fr: "Photo ajoutée",
        },
        "Adding photo to My Pictures failed.": {
            nl: "Foto toevoegen mislukt",
            en: "Adding photo failed",
            fr: "Échec de l'ajout de la photo",
        },
        "Photo removed from My Pictures.": {
            nl: "Foto verwijderd",
            en: "Photo removed",
            fr: "Photo supprimée",
        },
        "Removing photo from My Pictures failed.": {
            nl: "Foto verwijderen mislukt",
            en: "Removing photo failed",
            fr: "Échec de la suppression de la photo",
        },
        "Loading favorites failed.": {
            nl: "Favorieten laden mislukt",
            en: "Loading favorites failed",
            fr: "Échec du chargement des favoris",
        },
        "photos selected": {
            nl: "Foto's geselecteerd",
            en: "Photos selected",
            fr: "Photos sélectionnées",
        },
        "No photos selected": {
            nl: "Geen foto's geselecteerd",
            en: "No photos selected",
            fr: "Pas de photos sélectionnées",
        },
        ShareMessage: {
            nl: "Hallo, \n\nDe schoolfoto 's zijn klaar!\nIk wou die graag met jou delen zodat je ze ook eens kunt bekijken! \nOnderaan vind je een link om naar de webshop te gaan en je aan te melden.\n\nGroeten,\n",
            en: "Hello, \n\nThe school photos are ready!\nI wanted to share them with you so you can check them out too! \n Below you will find a link to go to the webshop and sign up.\n\nGreetings,\n",
            fr: "Bonjour, \n\nLes photos scolaires sont disponibles!\nJe voulais les partager avec toi pour que tu puisses les découvrir également. \nTu trouveras ci-dessous un lien pour aller sur la boutique en ligne et te connecter. \nBien à toi,\n",
        },
        "No email to": {
            nl: "Geen e-mail naar",
            en: "No email to",
            fr: "Pas d'e-mail à",
        },
        "No email from": {
            nl: "Geen e-mail van",
            en: "No email from",
            fr: "Pas d'e-mail de",
        },
        "No email message": {
            nl: "Geen e-mail bericht",
            en: "No email message",
            fr: "Pas de message e-mail",
        },
        "Invalid email from": {
            nl: "Ongeldige e-mail van",
            en: "Invalid e-mail from",
            fr: "E-mail de non valide",
        },
        "Invalid email to": {
            nl: "Ongeldige e-mail naar",
            en: "Invalid email to",
            fr: "E-mail à non valide",
        },
        "Email sent": {
            nl: "E-mail verzonden",
            en: "Email sent",
            fr: "E-mail envoyé",
        },
        "Email not sent": {
            nl: "E-mail niet verzonden",
            en: "Email not sent",
            fr: "E-mail non envoyé",
        },
        "Share pictures Email footer": {
            nl: "Klik hier en ga naar de webshop om in te loggen https://www.photobizz.eu/login \nVul volgende code(s) in : xxxxxxxx\n",
            en: "Click here en go to the webshop to sign up https://www.photobizz.eu/login\nEnter the following code(s): xxxxxxxx\n",
            fr: "Cliquez ici pour aller à la boutique en ligne https://www.photobizz.eu/login\n Entrez le(s) code(s) suivant(s) : xxxxxxxx\n",
        },
        "Order line deleted successfully.": {
            nl: "Bestellijn succesvol verwijderd.",
            en: "Order line deleted successfully.",
            fr: "Ligne de commande supprimée avec succès.",
        },
        "Failed to delete order line.": {
            nl: "Verwijderen bestellijn mislukt.",
            en: "Failed to delete order line.",
            fr: "Échec de la suppression de la ligne de commande.",
        },
        "You can't have less than 1 product in your cart.": {
            nl: "Je kan niet minder dan 1 product in je winkelmand hebben.",
            en: "You can't have less than 1 product in your bag.",
            fr: "Vous ne pouvez pas avoir moins de 1 produit dans votre panier.",
        },
        "Order line updated successfully.": {
            nl: "Bestellijn succesvol aangepast.",
            en: "Order line updated successfully.",
            fr: "Ligne de commande modifiée avec succès.",
        },
        "Failed to update order line.": {
            nl: "Updaten bestellijn mislukt.",
            en: "Failed to update order line.",
            fr: "Échec de la modification de la ligne de commande.",
        },
        "Delivery details updated successfully.": {
            nl: "Leveringsdetails succesvol aangepast.",
            en: "Delivery details updated successfully.",
            fr: "Détails de livraison modifiée avec succès.",
        },
        // "Failed to update delivery details": {
        //     nl: "Aanpassing leveringsdetails niet gelukt.",
        //     en: "Failed to update delivery details",
        //     fr: "Échec de la mise à jour des détails de livraison",
        // },
        "Creating order failed.": {
            nl: "Bestelling maken mislukt.",
            en: "Creating order failed.",
            fr: "Échec de la création de la commande.",
        },
        mollie_paid_Title: {
            nl: "Bestelling bevestigd. Dank u voor uw bestelling!",
            en: "Order confirmed. Thanks for your order!",
            fr: "Commande confirmée. Merci pour votre commande!",
        },
        mollie_gratis_Title: {
            nl: "Bedankt, uw gratis bestelling werd bevestigd",
            en: "Thank you, your free order has been confirmed",
            fr: "Merci, votre gratuite commande a été confirmée",
        },
        mollie_open_Title: {
            nl: "Uw betaling is nog niet afgerond",
            en: "Your payment has not yet been completed",
            fr: "Votre paiement n'a pas encore été effectué",
        },
        mollie_open_Message: {
            nl: "Uw betaling is nog niet afgerond. Gelieve de betaling te voltooien.",
            en: "Your payment has not yet been completed. Please complete the payment.",
            fr: "Votre paiement n'a pas encore été effectué. Veuillez compléter le paiement.",
        },
        mollie_canceled_Title: {
            nl: "Uw betaling werd geannuleerd",
            en: "Your payment has been canceled",
            fr: "Votre paiement a été annulé",
        },
        mollie_canceled_Message: {
            nl: "Uw betaling werd geannuleerd. Gelieve opnieuw te proberen.",
            en: "Your payment has been canceled. Please try again.",
            fr: "Votre paiement a été annulé. Veuillez réessayer.",
        },
        mollie_pending_Title: {
            nl: "Uw betaling is nog in behandeling",
            en: "Your payment is still pending",
            fr: "Votre paiement est toujours en attente",
        },
        mollie_pending_Message: {
            nl: "Uw betaling is nog in behandeling. Gelieve te wachten.",
            en: "Your payment is still pending. Please wait.",
            fr: "Votre paiement est toujours en attente. Veuillez patienter.",
        },
        mollie_authorized_Title: {
            nl: "Uw betaling werd geautoriseerd",
            en: "Your payment has been authorized",
            fr: "Votre paiement a été autorisé",
        },
        mollie_authorized_Message: {
            nl: "Uw betaling werd geautoriseerd. Gelieve te wachten.",
            en: "Your payment has been authorized. Please wait.",
            fr: "Votre paiement a été autorisé. Veuillez patienter.",
        },
        mollie_expired_Title: {
            nl: "Uw betaling is verlopen",
            en: "Your payment has expired",
            fr: "Votre paiement a expiré",
        },
        mollie_expired_Message: {
            nl: "Uw betaling is verlopen. Gelieve opnieuw te proberen.",
            en: "Your payment has expired. Please try again.",
            fr: "Votre paiement a expiré. Veuillez réessayer.",
        },
        mollie_failed_Title: {
            nl: "Uw betaling is mislukt",
            en: "Your payment has failed",
            fr: "Votre paiement a échoué",
        },
        mollie_failed_Message: {
            nl: "Uw betaling is mislukt. Gelieve opnieuw te proberen.",
            en: "Your payment has failed. Please try again.",
            fr: "Votre paiement a échoué. Veuillez réessayer.",
        },
        tab_Winkelwagen: {
            nl: "Winkelmand",
            en: "Bag",
            fr: "Panier",
        },
        "tab_Bestelling plaatsen": {
            nl: "Bestelling plaatsen",
            en: "Place order",
            fr: "Placer la commande",
        },
        "tab_Bestelling betalen": {
            nl: "Bestelling betalen",
            en: "Pay order",
            fr: "Payer la commande",
        },
        "tab_Bestelling bevestigd": {
            nl: "Bestelling bevestigd",
            en: "Order confirmed",
            fr: "Commande confirmée",
        },
        login_welcome: {
            en: "Already have an acccount? Welcome back!",
            nl: "Heb je al een account? Welkom terug!",
            fr: "Vous avez déjà un compte? Bienvenue!",
        },
        Close: {
            nl: "Sluiten",
            en: "Close",
            fr: "Fermer",
        },
        "Please enter a discount code": {
            nl: "Gelieve een code waardebon in te geven",
            en: "Please enter a voucher code",
            fr: "Veuillez saisir un code de bon d'achat",
        },
        "Waardebon is reeds geactiveerd": {
            nl: "Waardebon is reeds geactiveerd",
            en: "Voucher is already activated",
            fr: "Le bon d'achat est déjà activé",
        },
        "Activate coupon": {
            nl: "Waardebon activeren",
            en: "Activate voucher",
            fr: "Activer le bon d'achat",
        },
        "Loading order details failed.": {
            nl: "Laden van bestelgegevens mislukt.",
            en: "Loading order details failed.",
            fr: "Échec du chargement des détails de la commande.",
        },
        "Loading order list failed.": {
            nl: "Laden van bestellijst mislukt.",
            en: "Loading order list failed.",
            fr: "Échec du chargement de la liste de commandes.",
        },
        "There are currently no placed orders.": {
            nl: "Er zijn momenteel geen geplaatste bestellingen.",
            en: "There are currently no placed orders.",
            fr: "Il n'y a actuellement aucune commande passée.",
        },
        my_details_saveChanges_success: {
            nl: "Wijzigingen opgeslagen.",
            en: "Changes saved.",
            fr: "Modifications enregistrées.",
        },
        my_details_saveChanges_error: {
            nl: "Opslaan mislukt.",
            en: "Saving failed.",
            fr: "Échec de sauvegarde.",
        },
        my_details_saveChanges_error_firstName: {
            nl: "Voornaam is verplicht.",
            en: "First name is compulsory.",
            fr: "Le prénom est obligatoire.",
        },
        my_details_saveChanges_error_lastName: {
            nl: "Naam is verplicht.",
            en: "Last name is compulsory.",
            fr: "Le nom est obligatoire.",
        },
        my_details_saveChanges_error_street: {
            nl: "Straat is verplicht.",
            en: "Street is mandatory.",
            fr: "Rue est obligatoire.",
        },
        my_details_saveChanges_error_houseNr: {
            nl: "Huisnummer is verplicht.",
            en: "House number is compulsory.",
            fr: "Le numéro de maison est obligatoire.",
        },
        my_details_saveChanges_error_postcode: {
            nl: "Postcode is verplicht.",
            en: "Postal code is compulsory.",
            fr: "Le code postal est obligatoire.",
        },
        my_details_saveChanges_error_city: {
            nl: "Gemeente is verplicht.",
            en: "City is compulsory.",
            fr: "La commune est obligatoire.",
        },
        my_details_saveChanges_error_telephone: {
            nl: "Telefoon is verplicht.",
            en: "Telephone is compulsory.",
            fr: "Téléphone est obligatoire.",
        },
        contact_saveChanges_error_name: {
            nl: "Naam is verplicht.",
            en: "Name is compulsory.",
            fr: "Le nom est obligatoire.",
        },
        contact_saveChanges_error_email: {
            nl: "E-mailadres is verplicht.",
            en: "Email address is compulsory.",
            fr: "Adresse e-mail est obligatoire.",
        },
        contact_saveChanges_error_phone: {
            nl: "Telefoon is verplicht.",
            en: "Telephone is compulsory.",
            fr: "Téléphone est obligatoire.",
        },
        contact_saveChanges_error_message: {
            nl: "Bericht is verplicht.",
            en: "Message is compulsory.",
            fr: "Le message est obligatoire.",
        },
        contact_saveChanges_error_topic: {
            nl: "Onderwerp is verplicht.",
            en: "Topic is compulsory.",
            fr: "Le sujet est obligatoire.",
        },
        contact_saveChanges_error_email_format: {
            nl: "E-mailadres is niet geldig.",
            en: "Email address is not valid.",
            fr: "Adresse e-mail n'est pas valide.",
        },
        contact_saveChanges_error_message_length: {
            en: "Message is too long (max 1000 characters).",
            nl: "Bericht is te lang (max 1000 karakters).",
            fr: "Le message est trop long (max 1000 caractères).",
        },
        contact_send_message_success: {
            en: "Message sent successfully.",
            nl: "Bericht succesvol verzonden.",
            fr: "Message envoyé avec succès.",
        },
        contact_send_message_error: {
            en: "Message could not be sent.",
            nl: "Bericht kon niet verzonden worden.",
            fr: "Le message n'a pas pu être envoyé.",
        },

        faq_block1_title1: {
            nl: "Ik ben de flyer met de fotocode kwijt, wat kan ik doen?",
            en: "I have lost the flyer with the photo code, what can I do?",
            fr: "J'ai perdu le flyer avec le code photo, que puis-je faire?",
        },
        faq_block1_text1: {
            nl: "Voor de code van de foto kunt u best het secretariaat van de school contacteren. Via hun webapplicatie kunnen zij eenvoudig de juiste foto terugvinden en de flyer uitprinten of per e-mail versturen.",
            en: "For the code of the photo it is best to contact the school secretariat. Through their web application, they can easily find the correct photo and print the flyer or send it by e-mail.",
            fr: "Pour le code de la photo, il est préférable de contacter le secrétariat de l'école. Grâce à leur application web, ils peuvent facilement trouver la bonne photo et imprimer le flyer ou l'envoyer par e-mail.",
        },

        faq_block2_title1: {
            nl: "Hoe kan ik mijn bestelling betalen?",
            en: "How can I pay for my order?",
            fr: "Comment puis-je payer ma commande?",
        },
        faq_block2_text1: {
            nl: "Als uw bestelling afgerond is in uw winkelmand dan klikt u op bestelling plaatsen, u vult uw gegevens in en klikt op Bestelling bevestigen. U krijgt dan het overzicht van uw bestelling en klikt op Bestelling betalen. U begeeft zich dan op de betaalpagina van ons betaalplatform Mollie waar u een keuze maakt van betalingsmethode. U kiest tussen Visa, Mastercard, bankkaart (kies Maestro) of overschrijving. Indien u kiest voor overschrijving dan ontvangt u een e-mail met de betalingsinstructies. Uw order wordt enkel gevalideerd als uw betaling ontvangen werd.",
            en: " When your order is complete in your shopping basket, click on Place order, fill in your details and click on Confirm order. You will then see a summary of your order and click on Pay order. You will then enter the payment page of our payment platform Mollie, where you choose your payment method. You choose between Visa, Mastercard, bank card (choose Maestro) or bank transfer. If you choose bank transfer, you will receive an e-mail with payment instructions. Your order will only be validated once your payment has been received.",
            fr: " Lorsque votre commande est complète dans votre panier, cliquez sur Placer la commande, remplissez vos coordonnées et cliquez sur Confirmer la commande. Vous verrez alors un récapitulatif de votre commande et cliquez sur Payer la commande. Vous accédez alors à la page de paiement de notre plateforme de paiement Mollie, où vous choisissez votre mode de paiement. Vous avez le choix entre Visa, Mastercard, carte bancaire (choisissez Maestro) ou virement bancaire. Si vous choisissez le virement bancaire, vous recevrez un e-mail contenant les instructions de paiement. Votre commande ne sera validée qu'après réception de votre paiement.",
        },

        faq_block3_title1: {
            nl: "Is mijn betaling wel goed ontvangen?",
            en: "Has my payment been received correctly?",
            fr: "Mon paiement a-t-il bien été reçu?",
        },
        faq_block3_text1: {
            nl: "De status van uw betaling kunt u eveneens nagaan door te klikken op de tab 'Mijn Orders'.",
            en: "You can also check the status of your payment by clicking on the 'My Orders' tab.",
            fr: "Vous pouvez également vérifier l'état de votre paiement en cliquant sur l'onglet 'Mes commandes'.",
        },

        faq_block4_title1: {
            nl: "Ik heb mijn order per overschrijving betaald maar mijn bestelling niet ontvangen.",
            en: "I paid my order by bank transfer but did not receive my order.",
            fr: "J'ai payé ma commande par virement bancaire mais je n'ai pas reçu ma commande.",
        },
        faq_block4_text1: {
            nl: "Indien u per overschrijving betaalt maar niet de juiste gestructureerde mededeling vermeld die werd opgegeven in uw e-mailbericht  dan wordt u automatisch terugbetaald. U plaatst dan best een nieuwe bestelling en betaalt met de juiste gegevens.",
            en: "If you pay by bank transfer but do not mention the correct structured communication that was given in your email message you will be automatically refunded. You are best to place a new order and pay with the correct details.",
            fr: "Si vous payez par virement bancaire mais ne mentionnez pas la bonne communication structurée qui a été donnée dans votre message électronique, vous serez automatiquement remboursé. Vous feriez mieux de passer une nouvelle commande et de payer avec les bonnes informations.",
        },
        faq_block5_title1: {
            nl: "Wordt mijn bestelling via de school of aan huis geleverd?",
            en: "Will my order be delivered through the school or at home?",
            fr: "Ma commande sera-t-elle livrée via l'école ou à domicile?",
        },
        faq_block5_text1: {
            nl: "Er is een periode voorzien waarbij u uw bestelling kunt plaatsen zonder portkosten. De bestelling wordt dan via de school geleverd en komt terecht in de klas van uw oudste kind. De uiterste datum van bestelling zonder portkosten staat vermeld op uw flyer.",
            en: "There is a period provided where you can place your order without shipping costs. The order will then be delivered through the school and will end up in the class of your oldest child. The deadline for ordering without shipping costs is stated on your flyer.",
            fr: "Il y a une période prévue où vous pouvez passer votre commande sans frais de port. La commande sera ensuite livrée par l'école et se retrouvera dans la classe de votre enfant aîné. La date limite de commande sans frais de port est indiquée sur votre flyer.",
        },
        faq_block6_title1: {
            nl: "Kan ik nog bestellen na de uiterste datum van levering zonder portkosten?",
            en: "Can I still order after the delivery deadline without shipping costs?",
            fr: "Puis-je encore commander après la date limite de livraison sans frais de port?",
        },
        faq_block6_text1: {
            nl: "Na de uiterste datum van bestelling zonder portkosten en levering via de school kunt u nog steeds bestellen. De bestelling met aangepaste verpakking wordt dan aan huis geleverd en u betaalt 5 euro verzendingskosten.",
            en: "After the deadline for ordering without shipping costs and delivery through the school, you can still order. The order with adapted packaging will then be delivered to your home and you pay 5 euros shipping costs.",
            fr: "Apèrs la date limite de commande sans frais de port et de livraison par l'école, vous pouvez toujours commander. La commande avec un emballage adapté sera alors livrée à votre domicile et vous paierez 5 euros de frais de port.",
        },
        faq_block7_title1: {
            nl: "Wanneer ontvang ik mijn bestelling?",
            en: "When will I receive my order?",
            fr: "Quand vais-je recevoir ma commande?",
        },
        faq_block7_text1: {
            nl: "U kunt uw bestelling opvolgen door op de tab Mijn Orders te klikken.",
            en: "You can follow your order by clicking on the My Orders tab.",
            fr: "Vous pouvez suivre votre commande en cliquant sur l'onglet Mes commandes.",
        },
        faq_block8_title1: {
            nl: "Kan ik de foto gebruiken als pasfoto voor een reispas of Identiteitskaart?",
            en: "Can I use the photo as a passport photo for a passport or ID card?",
            fr: "Puis-je utiliser la photo comme photo d'identité pour un passeport ou une carte d'identité?",
        },
        faq_block8_text1: {
            nl: "Indien het product Ôreglementaire pasfoto' wordt aangeboden dan voldoet de foto aan de vereisten voor een pasfoto. Indien dit niet het geval is dan kan u de foto niet gebruiken voor de identiteitskaart of reispas.",
            en: "If the product 'regulatory passport photo' is offered, the photo meets the requirements for a passport photo. If this is not the case, you cannot use the photo for the identity card or passport.",
            fr: "Si le produit 'photo d'identité réglementaire' est proposé, la photo répond aux exigences pour une photo d'identité. Si ce n'est pas le cas, vous ne pouvez pas utiliser la photo pour la carte d'identité ou le passeport.",
        },

        if_you_have_already_account: {
            en: "If you already have an account, you can ",
            nl: "Als je al een account hebt, kan je ",
            fr: "Si vous avez déjà un compte, vous pouvez ",
        },
        login_here: {
            en: "login here",
            nl: "hier inloggen",
            fr: "vous connecter ici",
        },
        email_not_same: {
            en: "The email addresses are not the same.",
            nl: "De e-mailadressen zijn niet hetzelfde.",
            fr: "Les adresses e-mail ne sont pas les mêmes.",
        },
        "Confirm Email is required.": {
            en: "Confirm email address is compulsory.",
            nl: "Bevestig e-mail is verplicht.",
            fr: "Confirmer l'adresse e-mail est obligatoire.",
        },
        "photo code instructions": {
            en: "Enter the code mentioned on the flyer that you received.",
            nl: "Geef de code in vermeld op de flyer die u heeft ontvangen.",
            fr: "Entrez le code mentionné sur le flyer que vous avez reçu.",
        },
        "Photo code is required.": {
            en: "Photo code is required.",
            nl: "Foto code is verplicht.",
            fr: "Le code photo est obligatoire.",
        },
        "I wish promotions": {
            en: "Yes, I wish to receive promotions.",
            nl: "Ja, ik wens promoties te ontvangen.",
            fr: "Oui, je souhaite recevoir des promotions.",
        },
        "I agree to the terms and conditions": {
            en: "I agree to the terms and conditions.",
            nl: "Ik ga akkoord met de algemene voorwaarden.",
            fr: "J'accepte les conditions générales.",
        },
        "You must agree to the terms and conditions.": {
            en: "You must agree to the terms and conditions.",
            nl: "U moet akkoord gaan met de algemene voorwaarden.",
            fr: "Vous devez accepter les conditions générales.",
        },
        "Code is niet correct": {
            en: "Code is not correct",
            nl: "Code is niet correct",
            fr: "Code incorrect",
        },
        "Creating account failed.": {
            en: "Creating account failed.",
            nl: "Account aanmaken mislukt.",
            fr: "Échec de la création du compte.",
        },
        "forgot pass request text": {
            nl: "Vul het e-mailadres van uw Photobizz account in en klik op Verzenden. U ontvangt een mail met een link om uw wachtwoord te resetten.",
            en: "Enter the email address of your Photobizz account and click on Send. You will receive an email with a link to reset your password.",
            fr: "Entrez l'adresse email de votre compte Photobizz et cliquez sur Envoyer. Vous recevrez un email avec un lien pour réinitialiser votre mot de passe.",
        },
        "go back to login": {
            en: "Go back to login",
            nl: "Terug naar login",
            fr: "Retour à la connexion",
        },
        "Mail has been sent": {
            en: "Mail has been sent",
            nl: "Mail is verzonden",
            fr: "Mail a été envoyé",
        },
        "Password request failed.": {
            en: "Password request failed.",
            nl: "Wachtwoord aanvraag mislukt.",
            fr: "Échec de la demande de mot de passe.",
        },
        "Passwords do not match.": {
            en: "Passwords do not match.",
            nl: "Wachtwoorden komen niet overeen.",
            fr: "Les mots de passe ne correspondent pas.",
        },
        "Wachtwoord kon niet gewijzigd worden": {
            en: "Password could not be changed.",
            nl: "Wachtwoord kon niet gewijzigd worden.",
            fr: "Le mot de passe n'a pas pu être modifié.",
        },
        NoPictureSelected: {
            en: "No picture selected",
            nl: "Geen foto geselecteerd",
            fr: "Pas de photo sélectionnée",
        },
        "There is no open order": {
            en: "There is no open order",
            nl: "Er is geen openstaande bestelling",
            fr: "Il n'y a pas de commande ouverte",
        },
        "Order line deleted successfully": {
            en: "Order line deleted successfully",
            nl: "Bestellijn succesvol verwijderd",
            fr: "Ligne de commande supprimée avec succès",
        },
        "Failed to delete order line": {
            en: "Failed to delete order line",
            nl: "Verwijderen bestellijn mislukt",
            fr: "Échec de la suppression de la ligne de commande",
        },
        "You can't have less than 1 product in your cart": {
            en: "You can't have less than 1 product in your cart",
            nl: "Je kan niet minder dan 1 product in je winkelmand hebben",
            fr: "Vous ne pouvez pas avoir moins de 1 produit dans votre panier",
        },
        "Order line updated successfully": {
            en: "Order line updated successfully",
            nl: "Bestellijn succesvol aangepast",
            fr: "Ligne de commande modifiée avec succès",
        },
        "Failed to update order line": {
            en: "Failed to update order line",
            nl: "Aanpassen bestellijn mislukt",
            fr: "Échec de la modification de la ligne de commande",
        },
        "Home delivery changed successfully": {
            en: "Home delivery changed successfully",
            nl: "Thuislevering succesvol aangepast",
            fr: "Livraison à domicile modifiée avec succès",
        },
        "Failed to change home delivery": {
            en: "Failed to change home delivery",
            nl: "Aanpassing thuislevering mislukt",
            fr: "Échec de la modification de la livraison à domicile",
        },
        "Coupon activated successfully": {
            en: "Voucher activated successfully",
            nl: "Waardebon succesvol geactiveerd",
            fr: "Bon d'achat activé avec succès",
        },
        "Failed to activate coupon": {
            en: "Failed to activate voucher",
            nl: "Activeren waardebon mislukt",
            fr: "Échec de l'activation du bon d'achat",
        },
        "Failed to update delivery details": {
            en: "Failed to update delivery details",
            nl: "Aanpassing leveringsdetails niet gelukt",
            fr: "Échec de la mise à jour des détails de livraison",
        },
        "Loading classphotos failed.": {
            en: "Loading classphotos failed.",
            nl: "Laden klasfoto's mislukt.",
            fr: "Échec du chargement des photos de classe.",
        },
        "Free shipping": {
            en: "Free shipping from 25€",
            nl: "Gratis verzending vanaf 25€",
            fr: "Livraison gratuite à partir de 25€",
        },
        "Dialog Message Attention there are no passport fotos": {
            nl: "Dit is geen reglementaire pasfoto en kan niet gebruikt worden voor officiële documenten.",
            en: "This is not a regulatory passport photo and cannot be used for official documents.",
            fr: "Ce n'est pas une photo d'identité réglementaire et ne peut pas être utilisée pour des documents officiels.",
        },
        "Extra korting message": {
            nl: "Extra 40% korting in uw winkelmand.",
            en: "Extra 40% rebate in your bag.",
            fr: "Remise extra de 40% dans votre panier.",
        },
        "Select_class_photo": {
            nl: "Selecteer klasfoto",
            en: "Select class photo",
            fr: "Sélectionnez photo de classe",
        },
        // "Your account has been successfully unsubscribed from our mailing list.": {
        //     en: "Your account has been successfully unsubscribed from our mailing list.",
        //     nl: "Uw account is succesvol uitgeschreven van onze mailinglijst.",
        //     fr: "Votre compte a été désinscrit avec succès de notre liste de diffusion.",
        // },
        // "Failed to unsubscribe your account from our mailing list.": {
        //     en: "Failed to unsubscribe your account from our mailing list.",
        //     nl: "Uitschrijven van uw account van onze mailinglijst is mislukt.",
        //     fr: "Échec de la désinscription de votre compte de notre liste de diffusion.",
        // },
        // "You are already unsubscribed from our mailing list.": {
        //     en: "You are already unsubscribed from our mailing list.",
        //     nl: "U bent reeds uitgeschreven van onze mailinglijst.",
        //     fr: "Vous êtes déjà désinscrit de notre liste de diffusion.",
        // },
        // "You can subscribe again to our mailing list in your account settings.": {
        //     en: "You can subscribe again to our mailing list in your account settings.",
        //     nl: "U kan zich opnieuw inschrijven op onze mailinglijst in uw accountinstellingen.",
        //     fr: "Vous pouvez vous réabonner à notre liste de diffusion dans vos paramètres de compte.",
        // },
        // "There is something wrong with the link you clicked to unsubscribe from our mailing list.": {
        //     en: "There is something wrong with the link you clicked to unsubscribe from our mailing list.",
        //     nl: "Er is iets mis met de link die u heeft aangeklikt om u uit te schrijven van onze mailinglijst.",
        //     fr: "Il y a un problème avec le lien que vous avez cliqué pour vous désinscrire de notre liste de diffusion.",
        // },
        // "Please try to unsubscribe again.": {
        //     en: "Please try to unsubscribe again by clicking on the link in the email you received.",
        //     nl: "Probeer opnieuw uit te schrijven door te klikken op de link in de email die u heeft ontvangen.",
        //     fr: "Veuillez essayer de vous désinscrire à nouveau en cliquant sur le lien dans l'email que vous avez reçu.",
        // },
        // "Ongeldige Unsubscribe": {
        //     en: "Invalid Unsubscribe",
        //     nl: "Ongeldige Unsubscribe",
        //     fr: "Désinscription invalide",
        // },
        "Your account has been successfully unsubscribed from our mailing list.": {
            en: "Your account has been successfully unsubscribed from our mailing list.",
            nl: "Uw account is succesvol uitgeschreven van onze mailinglijst.",
            fr: "Votre compte a été désinscrit avec succès de notre liste de diffusion.",
        },
        "Failed to unsubscribe your account from our mailing list.": {
            en: "Failed to unsubscribe your account from our mailing list.",
            nl: "Uitschrijven van uw account van onze mailinglijst is mislukt.",
            fr: "Échec de la désinscription de votre compte de notre liste de diffusion.",
        },
        "You are already unsubscribed from our mailing list.": {
            en: "You are already unsubscribed from our mailing list.",
            nl: "U bent reeds uitgeschreven van onze mailinglijst.",
            fr: "Vous êtes déjà désinscrit de notre liste de diffusion.",
        },
        "You can subscribe again to our mailing list in your account settings.": {
            en: "You can subscribe again to our mailing list in your account settings.",
            nl: "U kan zich opnieuw inschrijven op onze mailinglijst in uw accountinstellingen.",
            fr: "Vous pouvez vous réabonner à notre liste de diffusion dans vos coordonnées.",
        },
        "There is something wrong with the link you clicked to unsubscribe from our mailing list.": {
            en: "There is something wrong with the link you clicked to unsubscribe from our mailing list.",
            nl: "Er is iets mis met de link die u heeft aangeklikt om u uit te schrijven van onze mailinglijst.",
            fr: "Il y a un problème avec le lien que vous avez cliqué pour vous désinscrire de notre liste de diffusion.",
        },
        "Please try to unsubscribe again.": {
            en: "Please try to unsubscribe again by clicking on the link in the email you received.",
            nl: "Probeer opnieuw uit te schrijven door te klikken op de link in de email die u heeft ontvangen.",
            fr: "Veuillez essayer de vous désinscrire à nouveau en cliquant sur le lien dans l'email que vous avez reçu.",
        },
        "Ongeldige Unsubscribe": {
            en: "Unsubscribe invalid",
            nl: "Uitschrijven ongeldig",
            fr: "Désinscription non valide",
        },
        "Login from dashboard failed.": {
            en: "Login from dashboard failed.",
            nl: "Inloggen van dashboard mislukt.",
            fr: "Échec de la connexion depuis le tableau de bord.",
        },
        "Failed to download image.": {
            en: "Failed to download image.",
            nl: "Downloaden van foto mislukt.",
            fr: "Échec du téléchargement de l'image.",
        },
        "Digitaal beeld niet beschikbaar": {
            nl: "Digitaal beeld pas beschikbaar 1 uur na bestelling",
            en: "Digital image only available 1 hour after order",
            fr: "Image numérique disponible seulement 1 heure après la commande",
        },
        "Description how to order artikle": {
            en: "Click on the shopping bag to order an article",
            nl: "Klik op het winkelmandje om een artikel te bestellen",
            fr: "Cliquez sur le panier pour commander un article",
        },
        "Waardebon is vervallen": {
            en: "Voucher has expired",
            nl: "Waardebon is vervallen",
            fr: "Le bon d'achat a expiré",
        },
        "No classphotos in packages.": {
            en: "These packages do not the contain a group picture. Only the individual portrait.",
            nl: "Deze paketten bevatten geen klasfoto, enkel het individueel portret.",
            fr: "Ces packets ne contiennent pas de photo de classe. Seulement le portrait individuel.",
        },
        "First name is required.": {
            en: "First name is required.",
            nl: "Voornaam is verplicht.",
            fr: "Prénom est obligatoire.",
        },
        "Last name is required.": {
            en: "Last name is required.",
            nl: "Naam is verplicht.",
            fr: "Nom est obligatoire.",
        },
        "Street is required.": {
            en: "Street is required.",
            nl: "Straat is verplicht.",
            fr: "Rue est obligatoire.",
        },
        "House number is required.": {
            en: "House number is required.",
            nl: "Huisnummer is verplicht.",
            fr: "Numéro de maison est obligatoire.",
        },
        "Postcode is required.": {
            en: "Postal code is required.",
            nl: "Postcode is verplicht.",
            fr: "Code postal est obligatoire.",
        },
        "City is required.": {
            en: "City is required.",
            nl: "Gemeente is verplicht.",
            fr: "Commune est obligatoire.",
        },
        "Telephone is required.": {
            en: "Telephone is required.",
            nl: "Telefoon is verplicht.",
            fr: "Téléphone est obligatoire.",
        },



    },

    WS_T_and_C: {
        en: "These general terms and conditions regulate the use of the website https://www.photobizz.be/ and the webshop https://www.photobizz.eu/webshop/home, held by PHOTOBIZZ BV, with registered office at 8780 OOSTROZEBEKE, Stationsstraat 75, registered in the KBO/BCE and VAT register under the number BE0474962379, tel. +32.(0)56.66.33.88, e-mail info@photobizz.be and (hereinafter 'PHOTOBIZZ').\nIn addition, any legal relationship between PHOTOBIZZ and the Customer (collectively referred to as the 'Parties'), shall be governed by these general terms and conditions, possibly supplemented by specific terms and conditions, unless expressly derogated from them in writing. These terms and conditions annul and replace all written or oral contracts, proposals and undertakings relating to the same object that would precede the date of the agreement between the\n\n Parties.",
        nl: "nl T&C",
        fr: "fr T&C",
    },

    Mollie: {
        API_url_test: "https://dmz.photobizz.be/Webshop_API_Test/api",
        API_url_live: "https://dmz.photobizz.be/Webshop_API/api",
        Delivery_Home_Fee: 5,

        //vb example :
        // <add key="payment_name" value="bancontact,maestro,masterCard,visa,overschrijving" />
        // <add key="payment_method" value="CreditCard,CreditCard,CreditCard,CreditCard,Bank transfer BE" />
        // <add key="payment_brand" value="BCMC,Maestro,Mastercard,VISA,Bank transfer BE" />

        payment_name: "bancontact,maestro,masterCard,visa,overschrijving",
        payment_method: "CreditCard,CreditCard,CreditCard,CreditCard,Bank transfer BE",
        payment_brand: "BCMC,Maestro,Mastercard,VISA,Bank transfer BE",
    },

    Labels: {
        Login: {
            nl: "Login",
            en: "Login",
            fr: "Login",
        },

        MyPhotos: {
            nl: "Mijn foto's",
            en: "My photos",
            fr: "Mes photos",
        },

        Copyright: {
            nl: "Copyright © 2023 Photobizz | All Rights Reserved",
            en: "Copyright © 2023 Photobizz | All Rights Reserved",
            fr: "Copyright © 2023 Photobizz | All Rights Reserved",
        },

        school_name: {
            nl: "School",
            en: "School",
            fr: "L'école",
        },

        //old version ============================================================================================================

        BOEK_UW_FOTOSESSIE: {
            nl: "Boek uw fotosessie",
            en: "Book your photo session",
            fr: "Réservez votre séance photo",
        },
        Vraag_een_gratis_demo: {
            nl: "Vraag een gratis demo",
            en: "Ask for a free demo",
            fr: "Demandez votre démo gratuite",
        },

        Vraag_een_gratis_demo_small: {
            nl: "Gratis demo",
            en: "Free demo",
            fr: "Demo gratuite",
        },
        Home: {
            nl: "Home",
            en: "Home",
            fr: "Home",
        },
        Over: {
            nl: "Over",
            en: "About",
            fr: "A propos",
        },
        Prijzen: {
            nl: "Prijzen",
            en: "Prices",
            fr: "Prix",
        },
        FAQ: {
            nl: "FAQ",
            en: "FAQ",
            fr: "FAQ",
        },
        Contact: {
            nl: "Contact",
            en: "Contact",
            fr: "Contact",
        },
        Contacteer_ons: {
            nl: "Contacteer ons",
            en: "Contact us",
            fr: "Contactez nous",
        },
        Telephone: {
            nl: "Telefoon",
            en: "Telephone",
            fr: "Téléphone",
        },

        contact_us: {
            nl: "Contacteer ons",
            en: "Contact us",
            fr: "Contactez-nous",
        },

        first_name: {
            nl: "Voornaam",
            en: "First name",
            fr: "Prénom",
        },

        last_name: {
            nl: "Naam",
            en: "Name",
            fr: "Nom",
        },

        email: {
            nl: "Email",
            en: "Email",
            fr: "Email",
        },

        phone: {
            nl: "Telefoon",
            en: "Telephone",
            fr: "Téléphone",
        },

        company_name: {
            nl: "Bedrijf",
            en: "Company",
            fr: "Société",
        },

        message: {
            nl: "Stuur ons een bericht",
            en: "Send us a message",
            fr: "Envoyez-nous un message",
        },

        send: {
            nl: "Verzenden",
            en: "Send",
            fr: "Envoyer",
        },

        ask_demo: {
            nl: "Vraag een graties demo account",
            en: "Ask a free demo account",
            fr: "Demander votre démo gratuite",
        },

        message_example: {
            nl: "Beste Smool,\n\nGelieve mij gratis een link door te sturen naar een demo versie van het Smool platform.\n\nDank op voorhand",
            en: "Dear Smool,\n\nPlease send me a free link to a demo version of the Smool platform.\n\nThanks in advance",
            fr: "Cher Smool,\n\nVeuillez m'envoyer un lien gratuit vers une version demo de la plateforme Smool.\n\nMerci d'avance",
        },

        street: {
            nl: "Straat",
            en: "Street",
            fr: "Rue",
        },

        house_number: {
            nl: "Huisnummer",
            en: "House number",
            fr: "Numéro",
        },

        postal_code: {
            nl: "Postcode",
            en: "Postal Code",
            fr: "Code postal",
        },

        city: {
            nl: "Gemeente",
            en: "City",
            fr: "Commune",
        },

        number_employees: {
            nl: "Aantal medewerkers voor de fotosessie",
            en: "Number of employees for the photo session",
            fr: "Nombre d'employés pour la séance photo",
        },

        btw: {
            nl: "BTW nummer",
            en: "VAT number",
            fr: "Numéro de TVA",
        },

        default_language: {
            nl: "Standaard taal",
            en: "Default language",
            fr: "Langue par défaut",
        },

        date: {
            nl: "Datum",
            en: "Date",
            fr: "Date",
        },

        am_max50: {
            nl: "AM (max 25 personen)",
            en: "AM (max 25 people)",
            fr: "AM (max 25 personnes)",
        },

        pm_max50: {
            nl: "PM (max 25 personen)",
            en: "PM (max 25 people)",
            fr: "PM (max 25 personnes)",
        },

        full_day: {
            nl: "Volledige dag (max 50 personen)",
            en: "Full day (max 50 people)",
            fr: "Journée complète (max 50 personnes)",
        },

        english_translation: {
            nl: "Engels",
            en: "English",
            fr: "Anglais",
        },

        dutch_translation: {
            nl: "Dutch",
            en: "Nederlands",
            fr: "Néerlandais",
        },

        franch_translation: {
            nl: "Frans",
            en: "French",
            fr: "Français",
        },

        xxx: {
            nl: "",
            en: "",
            fr: "",
        },

        enter_you_email: {
            nl: "Vul je email in",
            en: "Enter your email",
            fr: "Entrez votre email",
        },

        password: {
            nl: "Wachtwoord",
            en: "Password",
            fr: "Mot de passe",
        },

        sign_in: {
            nl: "Aanmelden",
            en: "Sign in",
            fr: "Se connecter",
        },

        continue: {
            nl: "Doorgaan",
            en: "Continue",
            fr: "Continuer",
        },

        visit_our_website: {
            nl: "Bezoek onze website",
            en: "Visit our website",
            fr: "Visitez notre site web",
        },

        terms_and_conditions: {
            nl: "Algemene voorwaarden",
            en: "Terms and conditions",
            fr: "Conditions générales",
        },

        privacy_policy: {
            nl: "Privacybeleid",
            en: "Privacy policy",
            fr: "Politique de confidentialité",
        },
    },

    Messages: {
        "First name is required.": {
            nl: "Voornaam is verplicht. ",
            en: "First name is required. ",
            fr: "Prénom est requis. ",
        },

        "Last name is required.": {
            nl: "Naam is verplicht. ",
            en: "Last name is required. ",
            fr: "Nom est requis. ",
        },

        "Email is required.": {
            nl: "Email is verplicht. ",
            en: "Email is required. ",
            fr: "Email est requis. ",
        },

        "Email is not valid.": {
            nl: "Email is niet geldig. ",
            en: "Email is not valid. ",
            fr: "Email n'est pas valide. ",
        },

        "Message is required.": {
            nl: "Bericht is verplicht. ",
            en: "Message is required. ",
            fr: "Message est requis. ",
        },

        "Company name is required.": {
            nl: "Bedrijfsnaam is verplicht. ",
            en: "Company name is required. ",
            fr: "Nom de la société est requis. ",
        },

        "Phone is not valid. Only numbers are allowed.": {
            nl: "Telefoonnummer is niet geldig. Enkel cijfers zijn toegelaten. ",
            en: "Phone is not valid. Only numbers are allowed. ",
            fr: "Numéro de téléphone n'est pas valide. Seuls les chiffres sont autorisés. ",
        },

        "Message is too long.": {
            nl: "Bericht is te lang (max 1000 karakters).",
            en: "Message is too long (max 1000 characters).",
            fr: "Message est trop long (max 1000 caractères).",
        },

        "Message sent successfully.": {
            nl: "Bericht succesvol verzonden.",
            en: "Message sent successfully.",
            fr: "Message envoyé avec succès.",
        },

        "Message could not be sent.": {
            nl: "Bericht kon niet verzonden worden.",
            en: "Message could not be sent.",
            fr: "Message n'a pas pu être envoyé.",
        },

        "Request sent successfully.": {
            nl: "Aanvraag succesvol verzonden.",
            en: "Request sent successfully.",
            fr: "Demande envoyée avec succès.",
        },

        "Request could not be sent.": {
            nl: "Aanvraag kon niet verzonden worden.",
            en: "Request could not be sent.",
            fr: "Demande n'a pas pu être envoyée.",
        },

        Forbidden_Exception: {
            en: "One request per hour is allowed.",
            nl: "Eén aanvraag per uur is toegelaten.",
            fr: "Une demande par heure est autorisée.",
        },

        "Street is required.": {
            nl: "Straat is verplicht.",
            en: "Street is required.",
            fr: "Rue est requise.",
        },
        "House number is required.": {
            nl: "Huisnummer is verplicht.",
            en: "House number is required.",
            fr: "Numéro est requis.",
        },
        "Postal code is required.": {
            nl: "Postcode is verplicht.",
            en: "Postal code is required.",
            fr: "Code postal est requis.",
        },
        "City is required.": {
            nl: "Gemeente is verplicht.",
            en: "City is required.",
            fr: "Ville est requise.",
        },
        "VAT is required.": {
            nl: "BTW-nummer is verplicht.",
            en: "VAT is required.",
            fr: "Numéro de TVA est requis.",
        },
        "Phone is required.": {
            nl: "Telefoonnummer is verplicht.",
            en: "Phone is required.",
            fr: "Numéro de téléphone est requis.",
        },
        "Number of employees is required.": {
            nl: "Aantal werknemers is verplicht.",
            en: "Number of employees is required.",
            fr: "Nombre d'employés est requis.",
        },
        "Photoshoot date is required.": {
            nl: "Datum fotoshoot is verplicht.",
            en: "Photoshoot date is required.",
            fr: "Date de la séance photo est requise.",
        },
        "Postal code is not valid.": {
            nl: "Postcode is niet geldig. Enkel cijfers zijn toegelaten.",
            en: "Postal code is not valid. Only numbers are allowed.",
            fr: "Code postal n'est pas valide. Seuls les chiffres sont autorisés.",
        },
        "Number of employees is not valid.": {
            nl: "Aantal werknemers is niet geldig. Enkel cijfers zijn toegelaten.",
            en: "Number of employees is not valid. Only numbers are allowed.",
            fr: "Nombre d'employés n'est pas valide. Seuls les chiffres sont autorisés.",
        },

        "School name is required.": {
            nl: "Schoolnaam is verplicht.",
            en: "School name is required.",
            fr: "Nom de l'école est requis.",
        },

        "Session could not be retrieved.": {
            nl: "Sessie kon niet opgehaald worden.",
            en: "Session could not be retrieved.",
            fr: "Session n'a pas pu être récupérée.",
        },
        "Company could not be retrieved.": {
            nl: "Bedrijf kon niet opgehaald worden.",
            en: "Company could not be retrieved.",
            fr: "Société n'a pas pu être récupérée.",
        },
        "Photoshoot request could not be sent.": {
            nl: "Aanvraag fotoshoot kon niet verzonden worden.",
            en: "Photoshoot request could not be sent.",
            fr: "Demande de séance photo n'a pas pu être envoyée.",
        },
        "Photoshoot request sent successfully.": {
            nl: "Aanvraag fotoshoot succesvol verzonden.",
            en: "Photoshoot request sent successfully.",
            fr: "Demande de séance photo envoyée avec succès.",
        },
    },

    Texts: {
        home_block1_title1: {
            nl: "Schoolfoto’s voor het leven.",
            en: "",
            fr: "Des photos scolaires pour la vie.",
        },
        home_block1_title2: {
            nl: "Professioneel, efficiënt en flexibel.",
            en: "",
            fr: "Professionnel, efficace et flexible.",
        },
        home_block1_text1: {
            nl: "Met schoolfoto’s schenkt u leerlingen en hun ouders mooie herinneringen aan een unieke periode uit hun leven. Zeker als de foto’s mooi en professsioneel zijn. Dan worden het momentopnames die we graag bekijken en herbekijken. Photobizz is uw ideale partner hiervoor. We hebben een halve eeuw ervaring, werken met een team van gespecialiseerde fotografen en hebben een online platform voor het beheren en bestellen van	foto’s.",
            en: "",
            fr: "Avec des photos scolaires, vous offrez aux élèves et à leurs parents de beaux souvenirs d'une période unique dans leur vie. Surtout si les photos sont magnifiques et professionnelles. Elles deviennent alors des moments inoubliables que l'on prend plaisir à regarder et à revoir. Photobizz est, pour cela, votre partenaire idéal. Nous avons un demi-siècle d'expérience, nous travaillons avec une équipe de photographes spécialisés et nous disposons d'une plateforme en ligne pour gérer et commander les photos.",
        },
        home_block1_text2: {
            nl: "",
            en: "",
            fr: "",
        },
        home_block2_title1: {
            nl: "Waarom Photobizz?",
            en: "",
            fr: "Pourquoi Photobizz ?",
        },
        home_block2_text1: {
            nl: "Geen improvisatie maar vakkundigheid en passie.",
            en: "",
            fr: "Pas d'improvisation mais du savoir-faire et de la passion.",
        },
        home_block2_text2: {
            nl: "Minimale moeite voor de school, met maximale tevredenheid.",
            en: "",
            fr: "Un minimum d'effort pour l'école, un maximum de satisfaction.",
        },
        home_block2_text3: {
            nl: "Een team van 15 beroepsfotografen, beschikbaar in heel België.",
            en: "",
            fr: "Une équipe de 15 photographes professionnels, disponibles dans toute la Belgique.",
        },
        home_block2_text4: {
            nl: "Studiofotografie, foto’s in de klas of in de natuur: u kiest zelf.",
            en: "",
            fr: "Photos en studio, en classe ou dans la nature : c'est vous qui choisissez.",
        },
        home_block2_text5: {
            nl: "Beheer uw schoolfoto’s eenvoudig via ons online platform. ",
            en: "",
            fr: "Gérez facilement les photos de votre école via notre plateforme en ligne.",
        },
        home_acc1_title1: {
            nl: "Meet the team:",
            en: "",
            fr: "Rencontrez l'équipe :",
        },
        home_acc1_title2: {
            nl: "onze fotografen.",
            en: "",
            fr: "nos photographes.",
        },
        home_acc1_text1: {
            nl: "Schoolfotografie, dat is een vak apart. De fotografen van Photobizz hebben vele jaren ervaring en weten hoe ze moeten omgaan met leerlingen en leerkrachten. Ze hebben het geduld en de sociale vaardigheden om alles vlot en aangenaam te laten verlopen. Dit bovenop hun ruime vakkennis en toewijding. De beste opstelling voor een klasfoto, de meest flatterende pose voor een individueel portret en kleine details – kleding die goed zit, geen haren voor de ogen of drupneuzen bij kleuters – onze fotografen weten wat het verschil maakt.",
            en: "",
            fr: "La photographie scolaire est une spécialité en soi. Les photographes de Photobizz ont de nombreuses années d'expérience et savent comment s’y prendre avec les élèves et les enseignants. Ils ont la patience et le dévouement necessaire pour que tout se passe bien et de manière agréable. Cela s'ajoute à leurs vastes connaissances professionnelles et à leur sympathie naturelle. La meilleure composition pour une photo de classe, la pose la plus flatteuse pour un portrait individuel et les petits détails - des vêtements bien ajustés, pas de cheveux devant les yeux ou de nez qui coule chez les tout-petits - nos photographes savent ce qui fait la difference.",
        },
        home_acc1_text2: {
            nl: "",
            en: "",
            fr: "",
        },
        home_acc2_title1: {
            nl: "Onze werkwijze:",
            en: "",
            fr: "Notre méthode de travail :",
        },
        home_acc2_title2: {
            nl: "makkelijk voor u.",
            en: "",
            fr: "votre facilité.",
        },

        home_acc2_text1_bold: {
            nl: "1. U kiest zelf het type fotografie op voorhand. ",
            en: "",
            fr: "1. Vous choisissez à l'avance le genre de photographie.",
        },
        home_acc2_text2: {
            nl: "Voor het secundair kan dat een studio met witte achtergrond zijn. Voor de lagere school is een ‘green key’ studio veel gevraagd. Daarbij kunnen de ouders na de fotosessie zelf de achtergrond kiezen in de webshop. Ook opnames in de klas of buitenopnames zijn mogelijk. We helpen u graag bij uw keuze.",
            en: "",
            fr: "Pour les écoles secondaires, on choisit souvent le studio sur fond blanc. Pour les écoles primaires, un studio ’green key’ est fort demandé. Les parents peuvent alors choisir eux-mêmes l'arrière-plan du portrait dans la boutique en ligne. Des photos dans la classe ou à l’extérieur dans la nature sont également possibles. Nous vous aidons volontiers à choisir.",
        },

        home_acc2_text3_bold: {
            nl: "2. Samen plannen we de opnamedag.",
            en: "",
            fr: "2.	Nous planifions ensemble le jour de prise de vue.",
        },
        home_acc2_text4: {
            nl: "Onze fotografen komen langs op de afgesproken dag en plannen met u het verloop van de opnames. Kleuters bijvoorbeeld kunnen best vóór het middageten gefotografeerd worden, als hun kleding goed zit – en vlekvrij is. Na afloop van de fotosessie checken we nauwgezet met de schoolverantwoordelijke of we alle klassen en leerlingen gefotografeerd hebben zoals afgesproken.",
            en: "",
            fr: "Nos photographes vous rendent visite le jour convenu et planifient la séance avec vous. Les tout-petits, par exemple, sont photographiés de preference avant le déjeuner, quand leurs vêtements sont encore bien ajustés et sans taches. Après la séance photo, nous vérifions soigneusement avec le responsible de l’école que nous avons photographié toutes les classes et tous les élèves comme convenu.",
        },

        home_acc2_text5_bold: {
            nl: "3. Twee weken later ontvangt u de flyers.",
            en: "",
            fr: "3.	Deux semaines plus tard, vous recevrez les flyers avec code.",
        },
        home_acc2_text6: {
            nl: "Elke flyer draagt een unieke code waarmee de ouders via onze webshop eenvoudig de foto’s bestellen. De school kan via de Nominosoft app alle bestellingen opvolgen en in geval van verlies of gescheiden ouders duplicaten van flyers printen. Ouders met vragen kunnen Photobizz rechtstreeks contacteren en krijgen snel antwoord – een zorg minder voor de school.",
            en: "",
            fr: "Chaque flyer comporte un code unique que les parents peuvent utiliser pour commander  les photos via notre boutique en ligne. L'école peut utiliser l'application Nominosoft pour suivre toutes les commandes et imprimer des duplicatas des flyers en cas de perte ou de séparation des parents. Les parents qui ont des questions peuvent contacter Photobizz directement et obtenir des réponses rapides - un souci de moins pour l'école.",
        },

        home_acc3_title1: {
            nl: "Een achtergrond naar keuze.",
            en: "",
            fr: "L'arrière-plan de votre choix.",
        },
        home_acc3_text1: {
            nl: "Wilt u de ouders zelf een specifieke achtergrond laten kiezen? Dan werken we met ‘green key’, een neutrale groene achtergrond. Het portret wordt  later naadloos gedetoureerd, zodat de ouders via de webshop met enkele klikken een achtergrond kunnen kiezen. Een zeezicht, duinen, bos, … er zijn vele mogelijkheden.",
            en: "",
            fr: "Vous souhaitez que les parents choisissent eux-mêmes un fond spécifique ? Dans ce cas, nous travaillons avec notre studio ’green key’, un fond vert neutre. Le portrait est ensuite détouré, ce qui permet aux parents de choisir un arrière-plan dans la boutique en ligne. Une vue sur mer, des dunes, une forêt... les possibilités sont nombreuses.",
        },
        home_acc3_text2: {
            nl: "",
            en: "",
            fr: "",
        },

        home_acc4_title1: {
            nl: "Beheer alle foto’s makkelijk online.",
            en: "",
            fr: "Gérez facilement toutes les photos en ligne.",
        },
        home_acc4_text1: {
            nl: "Schoolfoto’s beheren mag voor u geen last zijn. Daarom biedt Photobizz u Nominosoft, een handig online platform dat u toegang geeft tot alle foto’s in lage resolutie. Koppel namen van leerlingen aan hun beelden, exporteer deze beelden naar uw eigen schoolsoftware zoals Smartschool, School Online, Wisa of Informat. Volg de verkoop in de webshop, print een flyer opnieuw uit of verzend hem naar ouders die hem verloren zijn.",
            en: "",
            fr: "La gestion des photos scolaires ne doit pas être un fardeau pour vous. C’est pourquoi Photobizz vous propose Nominosoft, une plateforme en ligne pratique qui vous donne accès à toutes les photos en basse résolution. Associez les noms des élèves à leurs images, exportez ces images vers vos propres logiciels scolaires tels que Smartschool, School Online, Wisa ou Informat. Suivez les ventes dans la boutique en ligne, réimprimez un prospectus ou envoyez-le aux parents qui l’ont perdu.",
        },
        home_acc4_text2: {
            nl: "",
            en: "",
            fr: "",
        },
        home_acc5_title1: {
            nl: "Een webshop voor de ouders.",
            en: "",
            fr: "Une boutique en ligne pour les parents.",
        },
        home_acc5_text1: {
            nl: "Ouders bestellen via de webshop eenvoudig foto’s, in verschillende formaten. Er zijn aantrekkelijk geprijsde pakketten beschikbaar en foto’s kunnen ook afgedrukt worden op mokken, balpennen of puzzels – altijd leuk als geschenk. De foto’s blijven beschikbaar voor de ouders via hun persoonlijke account. Zo kunnen ze jaren later nog foto’s bestellen en zien ze hun kinderen evolueren over de schooljaren heen. ",
            en: "",
            fr: "Les parents peuvent facilement commander les photos sur la boutique en ligne, dans un grand choix de formats. Les photos peuvent également être imprimées sur des tasses, des stylos à bille ou des puzzles, toujours très appréciés en tant que cadeaux. Les photos restent accessibles aux parents via leur compte personnel. Ils peuvent ainsi commander des photos des années plus tard et voir leurs enfants évoluer au fil des années scolaires.",
        },
        home_acc5_text2: {
            nl: "",
            en: "",
            fr: "",
        },
        home_acc6_title1: {
            nl: "Een eigen labo voor optimale kwaliteit.",
            en: "",
            fr: "Un laboratoire interne pour une qualité optimale.",
        },
        home_acc6_text1: {
            nl: "De verwerking van de foto's gebeurt in ons eigen digitaal labo – van de selectie tot het bijsnijden en de kleurcorrectie. Doordat we kiezen voor de traditionele Fuji technologie bent u zeker van de hoogst mogelijke afdrukkwaliteit. Daar kan geen enkele van de meer recente technologieën aan tippen. Ook het bedrukken van mokken, sleutelhangers, puzzels,… doen we zelf. Kwaliteit en service primeren bij Photobizz.",
            en: "",
            fr: "Le traitement des photos est effectué dans notre propre laboratoire numérique - de la sélection au recadrage et à la correction des couleurs. Comme nous optons pour la technologie Fuji traditionnelle, vous pouvez être sûr de bénéficier d'une qualité d'impression optimale. Aucune des technologies plus récentes ne peut l'égaler. Nous imprimons également des tasses, des porte-clés, des puzzles, etc. en interne. La qualité et le service sont primordiaux chez Photobizz.",
        },
        home_acc6_text2: {
            nl: "",
            en: "",
            fr: "",
        },

        home_block4_text1: {
            nl: "Boek meteen uw fotosessie!",
            en: "",
            fr: "Réservez votre séance photo dès maintenant !",
        },

        about_block1_title1: {
            nl: "50 jaar passie,",
            en: "",
            fr: "50 ans de passion,",
        },
        about_block1_title2: {
            nl: "ervaring en vernieuwing.",
            en: "",
            fr: "d'expérience et d'innovation.",
        },
        about_block1_text1: {
            nl: "Met Photobizz kiest u voor een halve eeuw ervaring. Het begon in 1973 toen de jonge en ambitieuze fotograaf David Vanoverbeke zich specialiseerde in schoolfotografie. Zijn talent, creativiteit en enthousiasme sloegen in heel Vlaanderen aan. Al gauw verzamelde hij een hecht team van professionele schoolfotografen rond zich. Het waren echte vernieuwers, die met foto’s in de natuur of een opstelling in de klas verder gingen dan anderen.",
            en: "",
            fr: "Avec Photobizz, vous choisissez un demi-siècle d'expérience. Tout a commencé en 1973 lorsque le jeune et ambitieux photographe David Vanoverbeke s'est spécialisé dans la photographie scolaire. Son talent, sa créativité et son enthousiasme font mouche dans toute la Flandre. Il a rapidement rassemblé autour de lui une équipe soudée de photographes scolaires professionnels. Ils étaient de véritables innovateurs, qui allaient plus loin que les autres avec des photos dans la nature ou une mise en scène dans les locaux de classe.",
        },
        about_block1_text2: {
            nl: "In 2015 werd Photobizz overgenomen door Bernard Derom, een gepassioneerde ondernemer. Hij moderniseerde en digitaliseerde met onder meer een webshop waar ouders makkelijk zelf foto’s kunnen bestellen. Het familiale karakter van de onderneming bleef volledig behouden. Vandaag bezoeken de Photobizz-fotografen jaarlijks meer dan 400 scholen, goed voor meer dan 100.000 portretten.",
            en: "",
            fr: "En 2015, Photobizz est repris par Bernard Derom, un entrepreneur passioné. Il a modernisé et numérisé, notamment en créant une boutique en ligne où les parents peuvent facilement commander eux-mêmes leurs photos. Le caractère familiale de l’entreprise a été entièrement conservé. Aujourd'hui, les photographes de Photobizz visitent chaque année plus de 400 écoles et réalisent plus de 100 000 portraits.",
        },

        faq_page_title1: {
            nl: "FAQ",
            en: "FAQ",
            fr: "FAQ",
        },

        faq_block1_title1: {
            nl: "Hoe moet ik de planning voorbereiden?",
            en: "",
            fr: "Comment dois-je préparer le planning ?",
        },
        faq_block1_text1: {
            nl: "De snelheid van opname is afhankelijk van het type onderwijs en de leeftijd van de leerlingen. Zo rekenen we in het secundair ongeveer 5 minuten per klas voor de individuele foto of de groepsfoto. In het lager zullen de kleuters de meeste tijd nemen, dat is moeilijker in te schatten. We voorzien steeds het nodige aantal fotografen in functie van de opdracht. Dit wordt vooraf afgesproken met de school.",
            en: "",
            fr: "La vitesse de prise de vue dépend du type d'enseignement et de l'âge des élèves. Par exemple, en secondaire, nous comptons environ 5 minutes par classe pour la photo individuelle ou la photo de classe. En primaire, ce sont les enfants d'âge préscolaire qui prendront le plus de temps, ce qui est plus difficile à estimer. Nous fournissons toujours le nombre nécessaire de photographes en fonction de la mission. Ceci est convenu à l'avance avec l'école.",
        },
        faq_block1_text2: {
            nl: "",
            en: "",
            fr: "",
        },

        faq_block2_title1: {
            nl: "Hoe worden de flyers met fotocode uitgedeeld in de school?",
            en: "",
            fr: "Comment les flyers avec code photo seront-ils distribués dans l'école ?",
        },
        faq_block2_text1: {
            nl: "De flyers worden gegroepeerd per klas. Elke flyer heeft een zwart-wit foto van de leerling zodat de leerkracht de flyer aan de juiste leerling kan bezorgen.",
            en: "",
            fr: "Les flyers sont regroupés par classe. Chaque flyer comporte une photo en noir et blanc de l'élève afin que l'enseignant puisse remettre le flyer à l'élève concerné.",
        },
        faq_block2_text2: {
            nl: "",
            en: "",
            fr: "",
        },

        faq_block3_title1: {
            nl: "Hoeveel tijd hebben de ouders om te bestellen? ",
            en: "",
            fr: "Combien de temps les parents ont-ils pour placer leur commande ?",
        },
        faq_block3_text1: {
            nl: "Twee weken, tussen het uitdelen van de flyers en het einde van de periode om te bestellen zonder portkosten. Als dit onvoldoende blijkt, kan de school een verlenging aanvragen. Na de periode zonder portkosten kunnen de ouders nog steeds bestellen, zelfs jaren daarna. We rekenen dan 5 euro verzendingskosten om de bestelling thuis te bezorgen.",
            en: "",
            fr: "Deux semaines, entre la distribution des flyers et la fin de la période pour commander sans frais d’envoi. Si ce délai s'avère insuffisant, l'école peut demander une prolongation. Après la période sans frais d’envoi, les parents peuvent encore commander, même des années après. Nous comptons alors 5 euros de frais pour l'envoi de la commande à domicile.",
        },
        faq_block3_text2: {
            nl: "",
            en: "",
            fr: "",
        },

        faq_block4_title1: {
            nl: "Wanneer worden de bestellingen aan de school geleverd?",
            en: "",
            fr: "Quand les commandes seront-elles livrées à l'école ?",
        },
        faq_block4_text1: {
            nl: "Reken op een tweetal weken, startende vanaf het aflopen van de periode zonder verzendingskosten. De bestellingen worden per klas gegroepeerd met de individuele foto bovenaan, zichtbaar door de transparante hoes waarin de bestelling steekt. Als er meerdere kinderen zijn voor dezelfde bestelling, dan wordt de bestelling geleverd in de klas van het oudste kind.",
            en: "",
            fr: "Comptez environ deux semaines, à partir de la fin de la période sans frais d’envoiplacée. Les commandes sont regroupées par classe avec la photo individuelle en haut, visible à travers la pochette transparente dans laquelle la commande est placée. S'il y a plusieurs enfants pour une même commande, celle-ci sera livrée dans la classe de l'enfant le plus âgé.",
        },
        faq_block4_text2: {
            nl: "",
            en: "",
            fr: "",
        },

        faq_block5_title1: {
            nl: "Kunnen de ouders hun bestelling opvolgen?",
            en: "",
            fr: "Les parents peuvent-ils suivre leur commande ?",
        },
        faq_block5_text1: {
            nl: "Via hun account in de webshop kunnen ouders de bestelling opvolgen. Daar zien ze of de bestelling betaald werd, in productie is genomen of reeds verzonden werd. Als hun bestelling uit ons labo vertrekt, krijgen ze opnieuw een bericht via mail.",
            en: "",
            fr: "Les parents peuvent suivre leur commande via leur compte dans la boutique en ligne. Ils peuvent vérifier si la commande a été payée, si elle est entrée en production ou si elle a déjà été envoyée. Lorsque la commande quitte notre laboratoire, ils en sont à nouveau informés par e-mail.",
        },
        faq_block5_text2: {
            nl: "",
            en: "",
            fr: "",
        },

        faq_block6_title1: {
            nl: "Wat gebeurt er als een van de ouders de bestelling niet heeft ontvangen?",
            en: "",
            fr: "Que se passe-t-il si l'un des parents n'a pas reçu sa commande ?",
        },
        faq_block6_text1: {
            nl: "In dat geval gaan we eerst na of de bestelling wel goed werd ingebracht en betaald. We gaan dan na of de bestelling reeds verstuurd werd en waarheen. Voor bestellingen via school vragen wij de ouders het nogmaals te checken met de school. Is de bestelling werkelijk zoekgeraakt, dan drukken we de foto’s opnieuw af en versturen ze gratis naar het thuisadres van de ouder.",
            en: "",
            fr: "Dans ce cas, nous vérifions d'abord si la commande a bien été enregistrée et payée. Nous vérifions ensuite si la commande a déjà été envoyée et à quelle adresse. Pour les commandes passées avec livraison via l'école, nous demandons aux parents de vérifier à nouveau auprès de l'école. Si la commande a effectivement été perdue, nous réimprimerons les photos et les enverrons gratuitement à l'adresse du parent.",
        },
        faq_block6_text2: {
            nl: "",
            en: "",
            fr: "",
        },

        faq_block7_title1: {
            nl: "Wat is er voorzien voor gescheiden ouders?",
            en: "",
            fr: "Qu'est-ce qui est prévu pour les parents divorcés ?",
        },
        faq_block7_text1: {
            nl: "De school kan via de Nominosoft app de flyers opzoeken en nogmaals uitprinten of per mail versturen. Elke ouder krijgt dezelfde flyer, maar maakt zijn eigen account aan en plaatst zijn eigen bestelling. De andere ouder heeft daar geen inzage in.",
            en: "",
            fr: "L'école peut utiliser l'application Nominosoft pour rechercher les flyers avec code et les réimprimer ou les envoyer par mail. Chaque parent reçoit le même flyer, mais crée son propre compte et passe sa propre commande. L'autre parent n'y a pas accès.",
        },
        faq_block7_text2: {
            nl: "",
            en: "",
            fr: "",
        },

        faq_block8_title1: {
            nl: "Wat met GDPR?",
            en: "",
            fr: "Qu'en est-il du GDPR ?",
        },
        faq_block8_text1: {
            nl: "De persoonsgebonden gegevens worden strikt volgens de GDPR richtlijnen bewaard. Een externe auditor voert om de twee jaar een DPIA (Data Protection Impact Assesment) uit. Daarbij worden alle aspecten van de GDPR onder de loep genomen binnen het bedrijf en worden correctieve acties genomen indien nodig. U kan steeds meer info opvragen over onze maatregelen en procedures.",
            en: "",
            fr: "Les données personnelles sont conservées dans le strict respect des lignes directrices du GDPR. Un auditeur externe effectue tous les deux ans une DPIA (Data Protection Impact Assessment - évaluation de l'impact de la protection des données). Il s'agit d'examiner tous les aspects du GDPR au sein de l'entreprise et de prendre des mesures correctives si nécessaire. Vous pouvez toujours demander plus d'informations sur nos mesures et procédures.",
        },
        faq_block8_text2: {
            nl: "",
            en: "",
            fr: "",
        },

        vacatures_page_title1: {
            nl: "Vacatures",
            en: "",
            fr: "Offres d'emploi",
        },
        vacatures_block1_title1: {
            nl: "Fotograaf",
            en: "",
            fr: "Photographe",
        },
        vacatures_block1_text1: {
            nl: "Je bent zelfstandig fotograaf en beschikbaar voor opdrachten op weekdagen tijdens de maanden september, oktober, april en mei? En je hebt affiniteit met of interesse voor schoolfotografie? Dan maken we graag kennis.",
            en: "",
            fr: "Vous êtes un photographe indépendant, disponible pour des missions en semaine pendant les mois de septembre, octobre, avril et mai ? Vous avez une affinité ou un intérêt pour la photographie scolaire ? Si c'est le cas, nous aimerions faire votre connaissance.",
        },
        vacatures_block1_text2: {
            nl: "Wat we verwachten:",
            en: "",
            fr: "Ce que nous attendons :",
        },
        vacatures_block2_text1: {
            nl: "Je werkt als fotograaf volgens het zelfstandigenstatuut en hebt een eigen wagen.",
            en: "",
            fr: "Vous travaillez en tant que photographe indépendant et disposez de votre propre voiture.",
        },
        vacatures_block2_text2: {
            nl: "Je hebt professioneel fotomateriaal en een draagbare studio met 3 flitsen.",
            en: "",
            fr: "Vous disposez d'un équipement photographique professionnel et d'un studio portable avec 3 flashs.",
        },
        vacatures_block2_text3: {
            nl: "Je kan goed omgaan met kinderen en leerkrachten.",
            en: "",
            fr: "Vous avez une bonne interaction avec les enfants et les enseignants.",
        },
        vacatures_block2_text4: {
            nl: "Je woont in Oost- of West-Vlaanderen, Antwerpen of Vlaams-Brabant.",
            en: "",
            fr: "Vous habitez en Flandre orientale ou occidentale, dans la province  d’Anvers ou dans le Brabant Wallon.",
        },
        vacatures_block3_text1: {
            nl: "Iets voor jou, denk je? ",
            en: "",
            fr: "Vous pensez que c’est quelque chose pour vous ?",
        },
        vacatures_block3_text2: {
            nl: "Contacteer ons snel via bd@photobizz.be",
            en: "",
            fr: "Contactez-nous rapidement à l'adresse bd@photobizz.be",
        },
    },
};

export default WebsiteDict;

// import data from "../data/WebsiteDict";
