
import useLanguageContext from "../hooks/useLanguageContext";

import React, { useState, useEffect } from "react";
import { NavLink, } from "react-router-dom";
import { Box, Typography, Stack, Grid, Button, Card, CardMedia, CardContent } from "@mui/material";

import WebsiteDict from "../data/WebsiteDict";

const About = () => {

    const { language, setLanguage } = useLanguageContext();

    const [dict, setDict] = useState(WebsiteDict);

    useEffect(() => {
        setDict(WebsiteDict);
    }, [WebsiteDict]);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <>
            <Stack spacing={8} m={3}>

                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <Box pb={1}>
                            <Typography fontWeight={600} variant="h4" mt={1} >{dict["Texts"]["about_block1_title1"][language]}</Typography>
                        </Box>
                        <Box pb={2}>
                            <Typography fontWeight={600} variant="h4" >{dict["Texts"]["about_block1_title2"][language]}</Typography>
                        </Box>


                        <Box pb={2} maxWidth={700} margin={"auto"}>
                            <Typography variant="body1" align="center" pt={2} pb={2} >
                                {dict["Texts"]["about_block1_text1"][language]}
                            </Typography>
                            <Typography variant="body1" align="center">
                                {dict["Texts"]["about_block1_text2"][language]}
                            </Typography>
                        </Box>
                        <Box>
                            <Button variant="contained" color="primary" component={NavLink} to="/contact" sx={{ mt: 2 }}>
                                {dict["Labels"]["Contacteer_ons"][language]}
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={12} sx={{ display: { xs: "none", sm: 'none', md: 'none', lg: 'block' } }}>
                        <Box
                            component="img"
                            sx={{ maxWidth: 1050 }}
                            alt="Photobizz image"
                            src={require('../images/Photo_univercity_outside_wide.jpg')}
                            m={1}
                        />

                    </Grid>
                    <Grid item xs={12} md={12} sx={{ display: { xs: "none", sm: 'none', md: 'block', lg: 'none' } }}>
                        <Box
                            component="img"
                            sx={{ maxWidth: 700 }}
                            alt="Photobizz image"
                            src={require('../images/Photo_univercity_outside_wide.jpg')}
                            m={1}
                        />

                    </Grid>
                    <Grid item xs={12} md={12} sx={{ display: { xs: "block", sm: 'block', md: 'none' } }}>
                        <Box
                            component="img"
                            sx={{ width: "100%", maxWidth: 450 }}
                            alt="Photobizz image"
                            src={require('../images/Photo_univercity_outside_norrow.jpg')}
                            m={1}
                        />

                    </Grid>
                </Grid>


            </Stack>
        </>
    );
};

export default About;
