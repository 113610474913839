import axios from "../api/axiosPhotobizz";

import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, NavLink } from "react-router-dom";
import { Button, Typography, Box, Stack, FormControl, Paper } from "@mui/material";
import Link from "@mui/material/Link";

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

// import { useContext } from "react";
// import AuthContext from "../context/AuthProvider";
import useAuth from "../hooks/useAuth";
import useAccount from "../hooks/useAccount";
import usePicture from "../hooks/usePicture";
import useOrder from "../hooks/useOrder";
import useEnvironment from "../hooks/useEnvironment";

import useLanguageContext from "../hooks/useLanguageContext";
import WebsiteDict from "../data/WebsiteDict";

import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import ShoppingBasketOutlinedIcon from "@mui/icons-material/ShoppingBasketOutlined";
import FavoriteIcon from "@mui/icons-material/Favorite";

import Badge from "@mui/material/Badge";
import { BorderBottom } from "@mui/icons-material";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import MoreIcon from "@mui/icons-material/MoreVert";

//pictures
import PortraitOutlinedIcon from "@mui/icons-material/PortraitOutlined";
//event
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
//order
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
//favorites
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
//cart
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
//share
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
//history
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
//mydetails
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
//contact
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
//faq
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";

const Header = () => {
    // const { setAuth, auth } = useContext(AuthContext);
    const { auth, setAuth } = useAuth();
    const { account, setAccount } = useAccount();
    const { picture, setPicture } = usePicture();
    const { order, setOrder } = useOrder();
    const { environment, setEnvironment } = useEnvironment();

    const { language, setLanguage } = useLanguageContext();
    const [dict, setDict] = useState(WebsiteDict);

    const location = useLocation();

    // const [isButtonVisible, setIsButtonVisible] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        if (account === null || account === undefined || Object.keys(account).length === 0) {
            if (
                JSON.parse(sessionStorage.getItem("session_account")) === null ||
                JSON.parse(sessionStorage.getItem("session_account")) === undefined
            ) {
                navigate("/login");
            } else {
                setAccount(JSON.parse(sessionStorage.getItem("session_account")));
            }
        }

        fetchDataSequentially();

        console.log("Header account: ", account);
    }, []);

    async function fetchDataSequentially() {
        try {
            var _account = account;
            var _favorites = undefined;
            var _orderCombo = undefined;
            var _order = undefined;
            var _cart = undefined;

            if (account.favorites === null || account.favorites === undefined || Object.keys(account.favorites).length === 0) {
                _favorites = await fetchFavorites();
            }

            if (account.orders === null || account.orders === undefined || Object.keys(account.orders).length === 0) {
                _orderCombo = await fetchOpenOrder();
            }

            // if (_order !== null && _order !== undefined && Object.keys(_order).length !== 0) {
            //     _cart = await fetchCart(_order.order_nr);
            // }

            if (_orderCombo !== null && _orderCombo !== undefined && Object.keys(_orderCombo).length !== 0) {
                setAccount({ ..._account, Favorites: _favorites, Order: _orderCombo.Order, Cart: _orderCombo.Order_Lijnen });
            } else {
                setAccount({ ..._account, Favorites: _favorites, Order: {}, Cart: [] });
            }
        } catch (error) {
            console.error("Failed to fetch data:", error);
        }
    }

    async function fetchFavorites() {
        var sessAcc = "";

        sessAcc = account.Session.Session_ID + "/" + account.Session.Account_ID;
        try {
            const response = await axios.get("Picture/GetFavorieten/" + sessAcc);
            if (response.status === 200) {
                console.log(response.data.Picture_Article_Favorite);
                return response.data.Picture_Article_Favorite;
            } else {
                return {};
            }
        } catch (error) {
            console.error("Failed to fetch data:", error);
            return {};
        }
    }

    async function fetchCart(_order_nr) {
        var sessAcc = "";
        sessAcc = account.Session.Session_ID + "/" + account.Session.Account_ID;

        try {
            const response = await axios.get("Order/GetWinkelmandje/" + sessAcc + "/" + _order_nr + "/" + language.toUpperCase());
            if (response.status === 200) {
                console.log(response.data.Order_Lijnen);
                return response.data.Order;
            } else {
                return {};
            }
        } catch (error) {
            console.error("Failed to fetch data:", error);
            return {};
        }
    }

    async function fetchOpenOrder() {
        var sessAcc = "";
        var _orderCombo = {};

        sessAcc = account.Session.Session_ID + "/" + account.Session.Account_ID;

        try {
            const response = await axios.get("Order/GetOpenOrder/" + sessAcc + "/" + language.toUpperCase());
            if (response.status === 200) {
                console.log(response.data);
                _orderCombo = response.data;
            }
        } catch (error) {
            console.error("Failed to fetch data:", error);
            if (error.response.status === 404) {
                // no open order found, create new one
                return {};
            }
        }

        return _orderCombo;
    }

    const logout = async () => {
        // if used in more components, this should be in context
        // axios to /logout endpoint

        sessionStorage.setItem("session_account", null);
        setAccount({});
        //sessionStorage.setItem("session_picture", null);
        setPicture({});
        //sessionStorage.setItem("session_order", null);
        setOrder({});
        //sessionStorage.setItem("session_environment", null);
        setEnvironment({});

        navigate("/login");
    };

    const getLastSegment = () => {
        const pathnames = location.pathname.split("/").filter((x) => x);
        return pathnames[pathnames.length - 1] || "Home";
    };

    const handleCartClick = () => {
        // Redirect to the 'cart' page
        navigate("/webshop/cart");
    };

    const handleFavoritesClick = () => {
        // Redirect to the 'cart' page
        navigate("/webshop/favorites");
    };

    function handleLanguageChange(event) {
        //setSelectedLanguage(event.target.value);
        sessionStorage.setItem("session_lang", event.target.value);
        setLanguage(event.target.value);
    }

    return (
        <>
            <Box
                className="sml_header"
                sx={{
                    display: { xs: "none", sm: "none", md: "flex", lg: "flex", xl: "flex" },
                    borderBottom: "1px solid #023047",
                    borderRadius: "0px",
                    ml: "250px",
                }}
            >
                {/* show active page */}

                <Box>
                    <Stack direction="column" spacing={0}>
                        <Typography variant="h6" id="activePage" color={"#219EBC"} fontWeight={900}>
                            {dict["WS_Labels"]["Page_" + getLastSegment()][language]}
                        </Typography>

                        {/* if environment.selectedView in ["products", "most_ordered", "promotions"] */}

                        {environment && getLastSegment() === "order" && environment.selectedView && (environment.selectedView === "products" || environment.selectedView === "most_ordered" || environment.selectedView === "promotions") ? (
                            <Typography
                                variant="body2"
                                id="activePage"
                                sx={{
                                    display: { xs: "none", sm: "none", md: "none", lg: "block", xl: "block" },
                                }}
                            >
                                {dict["WS_Messages"]["Description how to order artikle"][language]}
                            </Typography>

                        ) : <>
                            <Typography
                                variant="body2"
                                id="activePage"
                                sx={{
                                    display: { xs: "none", sm: "none", md: "none", lg: "block", xl: "block" },
                                }}
                            >
                                {dict["WS_Labels"]["Page_desc_" + getLastSegment()][language]}
                            </Typography>
                        </>}
                    </Stack>
                </Box>

                {/* <Typography variant="h6">Welcome back, {account?.Webshop_Account?.voornaam}</Typography> */}

                <Box>
                    <Stack direction="row" spacing={3}>
                        {/* {isButtonVisible && <Button>Hidden Button</Button>} */}

                        <Badge badgeContent={account.Cart ? account.Cart.length : 0} color="primary">
                            <Button variant="contained" onClick={handleCartClick} color="secondary" startIcon={<ShoppingBasketOutlinedIcon />}>
                                {dict["WS_Labels"]["Button_Cart"][language]}
                            </Button>
                        </Badge>
                        <Badge badgeContent={account.Favorites ? account.Favorites.length : 0} color="primary">
                            <Button variant="contained" onClick={handleFavoritesClick} color="secondary" startIcon={<FavoriteIcon />}>
                                {dict["WS_Labels"]["Button_Favorites"][language]}
                            </Button>
                        </Badge>

                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                                navigate("/Webshop/help");
                            }}
                        >
                            {dict["WS_Labels"]["Button_Help"][language]}
                        </Button>

                        <FormControl sx={{ pl: 0 }}>
                            {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={language}
                                label="Language"
                                onChange={handleLanguageChange}
                                size="medium"
                                variant="standard"
                                color="secondary"
                                sx={{ paddingTop: "4px", width: "50px", fontSize: "13px" }}
                            >
                                <MenuItem value={"en"}>EN</MenuItem>
                                <MenuItem value={"nl"}>NL</MenuItem>
                                <MenuItem value={"fr"}>FR</MenuItem>
                            </Select>
                        </FormControl>

                        <Button variant="contained" color="secondary" onClick={logout}>
                            {dict["WS_Labels"]["Logout"][language]}
                        </Button>
                    </Stack>
                </Box>
            </Box>

            <Box
                className="sml_header_bottom"
                sx={{
                    display: { xs: "flex", sm: "flex", md: "none", lg: "none", xl: "none" },
                }}
            >
                <AppBar position="fixed" color="secondary" sx={{ top: "auto", bottom: 0 }}>

                    {new Date() < new Date("2024-09-01") && (
                        <Toolbar sx={{ justifyContent: "center", backgroundColor: "#FB8500" }}>
                            <Typography variant="body2" color={"#fff"} fontWeight={600} sx={{ ml: 2 }}>
                                {dict["WS_Messages"]["Extra korting message"][language]}
                            </Typography>
                        </Toolbar>
                    )}

                    <Toolbar sx={{ justifyContent: "center" }}>
                        {/* <IconButton color="inherit" aria-label="open drawer">
                            <MenuIcon />
                        </IconButton>
                        <Box sx={{ flexGrow: 1 }} /> */}

                        <Link href="/webshop/home" underline="none" pr={2}>
                            <Box display="flex" flexDirection="column" alignItems="center" textAlign="center" width={"90px"}>
                                <PortraitOutlinedIcon sx={{ color: "#FFB703" }} fontSize="small" />
                                <Typography color={"white"} display={"inline"} variant="caption">
                                    {dict["WS_Labels"]["Page_home_small"][language]}
                                </Typography>
                            </Box>
                        </Link>

                        {/* "/webshop/order" BookmarkBorderOutlinedIcon */}

                        <Link href="/webshop/order" underline="none" pr={2}>
                            <Box display="flex" flexDirection="column" alignItems="center" textAlign="center" width={"90px"}>
                                <BookmarkBorderOutlinedIcon sx={{ color: "#FFB703" }} fontSize="small" />
                                <Typography color={"white"} display={"inline"} variant="caption">
                                    {dict["WS_Labels"]["Page_order_small"][language]}
                                </Typography>
                            </Box>
                        </Link>

                        {/* "/webshop/cart" ShoppingBasketOutlinedIcon */}

                        <Link href="/webshop/cart" underline="none" pr={2}>
                            <Box display="flex" flexDirection="column" alignItems="center" textAlign="center" width={"90px"}>
                                <ShoppingBasketOutlinedIcon sx={{ color: "#FFB703" }} fontSize="small" />
                                <Typography color={"white"} display={"inline"} variant="caption">
                                    {dict["WS_Labels"]["Page_cart_small"][language]}
                                </Typography>
                            </Box>
                        </Link>

                        {/* "/webshop/share" FileUploadOutlinedIcon */}

                        <Link href="/webshop/share" underline="none">
                            <Box display="flex" flexDirection="column" alignItems="center" textAlign="center" width={"90px"}>
                                <FileUploadOutlinedIcon sx={{ color: "#FFB703" }} fontSize="small" />
                                <Typography color={"white"} display={"inline"} variant="caption">
                                    {dict["WS_Labels"]["Page_share_small"][language]}
                                </Typography>
                            </Box>
                        </Link>

                        {/* <IconButton color="inherit">
                            <MoreIcon />
                        </IconButton> */}
                    </Toolbar>
                </AppBar>
            </Box>
            {/* content at the bottom on the page */}
        </>
    );
};

export default Header;
