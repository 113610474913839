

import axios from "../api/axiosPhotobizz";

import useLanguageContext from "../hooks/useLanguageContext";
import useAccount from "../hooks/useAccount";
import usePicture from "../hooks/usePicture";
import useOrder from "../hooks/useOrder";
import useEnvironment from "../hooks/useEnvironment";
import useProducts from "../hooks/useProducts";

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import React, { useState, useEffect, useRef } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import { Box, Typography, Stack, Grid, Button, Card, CardMedia, CardContent, TextField } from "@mui/material";
import { Paper, IconButton } from '@mui/material'

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Carousel from 'react-material-ui-carousel';

import Skeleton from '@mui/material/Skeleton';
import Tooltip from '@mui/material/Tooltip';

import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import FavoriteIcon from '@mui/icons-material/Favorite';

import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Slide, { SlideProps } from "@mui/material/Slide";

import WebsiteDict from "../data/WebsiteDict";
import ZoomModal from "../components/ZoomModal";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Label } from "@mui/icons-material";

const WS_Share = () => {

    const theme = useTheme();
    const matchesMdUp = useMediaQuery(theme.breakpoints.up('md'));




    const { language, setLanguage } = useLanguageContext();
    const [dict, setDict] = useState(WebsiteDict);

    const { account, setAccount } = useAccount();
    const { picture, setPicture } = usePicture();
    const { order, setOrder } = useOrder();
    const { environment, setEnvironment } = useEnvironment();
    const { products, setProducts } = useProducts();

    const [favorites, setFavorites] = useState([]);
    const [shareFirstname, setShareFirstname] = useState("");
    const [shareEmailFrom, setShareEmailFrom] = useState("");
    const [shareEmailTo, setShareEmailTo] = useState("");
    const [shareEmailMessage, setShareEmailMessage] = useState("");



    const navigate = useNavigate();


    const [openMessage, setOpenMessage] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [severity, setSeverity] = useState("success");

    const [loading, setLoading] = useState(false);
    const [loadingPictures, setLoadingPictures] = useState(false);

    const [openZoomModal, setOpenZoomModal] = useState(false);
    const [zoomedPhoto, setZoomedPhoto] = useState(null);

    const [shareLastname, setShareLastname] = useState("");

    const [selectedPhotos, setSelectedPhotos] = useState([]);






    useEffect(() => {
        setDict(WebsiteDict);
    }, [WebsiteDict]);

    useEffect(() => {

        // if (favorites === null || favorites === undefined || favorites.length === 0) {
        //     getFavorites();
        // }

        if (picture === null || picture === undefined || picture.length === 0) {
            getPictures();
        }

        setSelectedPhotos([]);
        setShareFirstname(account.Webshop_Account.voornaam);
        setShareLastname(account.Webshop_Account.naam);
        setShareEmailFrom(account.Webshop_Account.email);
        setShareEmailMessage(dict["WS_Messages"]["ShareMessage"][language] + account.Webshop_Account.voornaam + " " + account.Webshop_Account.naam);

        setEnvironment({ ...environment, selectedView: "share" });

    }, []);

    async function getPictures() {
        setLoadingPictures(true);
    }



    async function sendShare() {
        console.log("sendShare");
        console.log("shareLastname", shareLastname);
        console.log("shareFirstname", shareFirstname);
        console.log("shareEmailFrom", shareEmailFrom);
        console.log("shareEmailTo", shareEmailTo);
        console.log("shareEmailMessage", shareEmailMessage);

        //check if selectedPhotos is empty
        if (selectedPhotos.length === 0) {
            setSeverity("error");
            setSnackbarMessage(dict["WS_Messages"]["No photos selected"][language]);
            setOpenMessage(true);
            return;
        }

        //check if shareEmailTo is empty
        if (shareEmailTo === "") {
            setSeverity("error");
            setSnackbarMessage(dict["WS_Messages"]["No email to"][language]);
            setOpenMessage(true);
            return;
        }

        //check if shareEmailFrom is empty
        if (shareEmailFrom === "") {
            setSeverity("error");
            setSnackbarMessage(dict["WS_Messages"]["No email from"][language]);
            setOpenMessage(true);
            return;
        }

        //check if shareEmailMessage is empty
        if (shareEmailMessage === "") {
            setSeverity("error");
            setSnackbarMessage(dict["WS_Messages"]["No email message"][language]);
            setOpenMessage(true);
            return;
        }

        //check if email from is valid
        if (!validateEmail(shareEmailFrom)) {
            setSeverity("error");
            setSnackbarMessage(dict["WS_Messages"]["Invalid email from"][language]);
            setOpenMessage(true);
            return;
        }

        //check if email to is valid
        if (!validateEmail(shareEmailTo)) {
            setSeverity("error");
            setSnackbarMessage(dict["WS_Messages"]["Invalid email to"][language]);
            setOpenMessage(true);
            return;
        }

        //send the email
        // body example :
        //    {
        //     "Session": {
        //         "Session_ID": "5D9321C6-9589-48ED-9D14-2E60A160879D",
        //         "Account_ID": "73BF89B5-F270-42CE-A51C-B72942FDB1C4"
        //     },
        //   "Naam": "Mijn naam",
        //   "Voornaam": "Mijn voornaam",
        //   "Email": "info@photobizz.be",
        //   "ShareWith_Email": "luc@delusoft.be",
        //   "Body_Email": "Hallo, ",
        //   "Pictures": [
        //     {
        //       "Fotomap_nr": 30487,
        //       "Fotomap_file": "0203.jpg"
        //     }
        //   ]
        // } 

        let body = {
            "Session": account.Session,
            "Naam": shareLastname,
            "Voornaam": shareFirstname,
            "Email": shareEmailFrom,
            "ShareWith_Email": shareEmailTo,
            "Body_Email": shareEmailMessage + "\n\n" + dict["WS_Messages"]["Share pictures Email footer"][language],
            "Pictures": selectedPhotos
        };

        console.log("body", body);

        try {
            const response = await axios.post("/Picture/PostSharePicture", body);
            console.log("response", response);
            if (response.status === 200) {
                setSeverity("success");
                setSnackbarMessage(dict["WS_Messages"]["Email sent"][language]);
                setOpenMessage(true);
            } else {
                setSeverity("error");
                setSnackbarMessage(dict["WS_Messages"]["Email not sent"][language]);
                setOpenMessage(true);
            }
        } catch (error) {
            console.error("error", error);
            setSeverity("error");
            setSnackbarMessage(dict["WS_Messages"]["Email not sent"][language]);
            setOpenMessage(true);
        }


    }

    function validateEmail(email) {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    function handleSelectPhoto(e, photo) {
        // console.log("handleSelectPhoto", e.target.checked, photo);
        if (e.target.checked) {
            //if the photo is not in the array then add it
            let obj = selectedPhotos.find((item) => (item.Fotomap_nr === photo.Fotomap_nr && item.Fotomap_file === photo.Foto_file));
            if (obj === undefined) {
                setSelectedPhotos([...selectedPhotos, { Fotomap_nr: photo.Fotomap_nr, Fotomap_file: photo.Foto_file }]);
            }
        } else {
            //if the photo is in the array then remove it
            let obj = selectedPhotos.find((item) => (item.Fotomap_nr === photo.Fotomap_nr && item.Fotomap_file === photo.Foto_file));
            if (obj !== undefined) {
                setSelectedPhotos(selectedPhotos.filter((item) => (item.Fotomap_nr !== photo.Fotomap_nr || item.Fotomap_file !== photo.Foto_file)));

            }

            //console.log("selectedPhotos", selectedPhotos);
        }
    }


    const onCloseZoomModal = () => {
        setOpenZoomModal(false);
        setZoomedPhoto(null);
    }

    async function zoomPhoto(photo) {
        console.log(photo);

        //fetch zoomed image
        async function fetchZoomPhoto() {

            try {

                var sessAcc = account.Session.Session_ID + "/" + account.Session.Account_ID;

                let filenameWithoutExtension = photo.Foto_file.split(".")[0];
                filenameWithoutExtension = photo.Foto_file;

                // let str = "/Picture/GetPictureZoom/" + sessAcc + "/" + filenameWithoutExtension + "/" + photo.Fotomap_nr

                // var sessAcc = account.Session.Session_ID + "/" + account.Session.Account_ID;

                const response = await axios.get("/Picture/GetPictureZoom/" + sessAcc + "/" + photo.Foto_file + "/" + photo.Fotomap_nr);

                // const response = await axios.get(str);
                if (response.status === 200) {
                    setZoomedPhoto(response.data);

                    setOpenZoomModal(true);
                    console.log(response.data);
                }
                else {

                    setSnackbarMessage(dict["WS_Messages"]["Unable to get zoomed photo."][language]);
                    setSeverity("error");
                    setOpenMessage(true);
                }
            } catch (error) {

                console.log(error);
                setSnackbarMessage(dict["WS_Messages"]["Unable to get zoomed photo."][language]);
                setSeverity("error");
                setOpenMessage(true);

            }
            setLoadingPictures(false);

        }

        await fetchZoomPhoto();

    }












    return (
        <>




            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={4} xl={4} order={{ xs: 2, sm: 2, md: 2, lg: 1, xl: 1 }}>
                    <Stack spacing={2} direction="column" sx={{ padding: 2 }}>

                        {/* controled textfiel with value (share_lastname) and onChange (setShare_lastname the new value) */}

                        <TextField
                            fullWidth
                            value={shareLastname}
                            onChange={(e) => { setShareLastname(e.target.value); }}
                            label={dict["WS_Labels"]["Lastname"][language]}
                        />

                        <TextField
                            fullWidth
                            value={shareFirstname}
                            onChange={(e) => { setShareFirstname(e.target.value); }}
                            label={dict["WS_Labels"]["Firstname"][language]}
                        />

                        <TextField
                            fullWidth
                            value={shareEmailFrom}
                            onChange={(e) => { setShareEmailFrom(e.target.value); }}
                            label={dict["WS_Labels"]["EmailFrom"][language]}
                        />

                        <TextField
                            fullWidth
                            value={shareEmailTo}
                            onChange={(e) => { setShareEmailTo(e.target.value); }}
                            label={dict["WS_Labels"]["EmailTo"][language]}
                        />

                        <TextField
                            fullWidth
                            value={shareEmailMessage}
                            onChange={(e) => { setShareEmailMessage(e.target.value); }}
                            label={dict["WS_Labels"]["EmailMessage"][language]}
                            multiline
                        />

                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => { sendShare(); }}
                        >
                            {dict["WS_Labels"]["SendButton"][language] + " (" + selectedPhotos.length + " " + dict["WS_Messages"]["photos selected"][language] + ")"}
                        </Button>

                    </Stack>






                </Grid>

                {/* Right Grid Item */}
                <Grid item xs={12} sm={12} md={12} lg={8} xl={8}
                    order={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}


                //!!!!!! if i want seperate scroll for the iames then take out comment from style
                // style={{ height: matchesMdUp ? "500px" : "auto", overflow: matchesMdUp ? 'auto' : 'visible' }}
                >

                    <Grid container spacing={2} >
                        {picture.Pictures.map((photo, index) => (

                            <Grid item xs={12} sm={6} md={6} lg={6} xl={4} mt={1} key={photo.Foto} >
                                <Card sx={{
                                    padding: "10px", width: 270, border: "none", boxShadow: "none",
                                    backgroundColor: "transparent"
                                }}>

                                    <Box
                                        component="img"
                                        sx={{
                                            height: 250,
                                            borderRadius: 5,
                                            maxWidth: 270,
                                            width: '100%',
                                            objectFit: 'contain' // or 'cover' depending on your preference
                                        }}
                                        alt="The house from the offer."
                                        src={
                                            photo.ArrImage
                                                ? "data:image/jpeg;base64," + photo.ArrImage.toString()
                                                : require("../images/no_image_available.jpg")
                                        }
                                    />
                                    <CardContent sx={{ height: "1px" }} >

                                        {/* insert a check box */}
                                        <Box sx={{ position: 'relative', right: '-75px', top: '-15px', backgroundColor: 'rgba(206, 206, 206)', width: 90, borderRadius: 3 }}>
                                            <Checkbox
                                                // checked={selectedPhotos.includes({ Fotomap_nr: photo.Fotomap_nr, Fotomap_file: photo.Foto_file }) ? true : false}
                                                checked={selectedPhotos.some(selectedPhoto => selectedPhoto.Fotomap_nr === photo.Fotomap_nr && selectedPhoto.Fotomap_file === photo.Foto_file) ? true : false}
                                                iconStyle={{ fill: 'primary' }}
                                                // sx={{
                                                //     color: "primary", // Color of the border (unchecked state)
                                                //     backgroundColor: 'rgba(0, 128, 0, 0.04)', // Gray background when unchecked
                                                //     '&.Mui-checked': {
                                                //         color: 'primary', //'#219EBC', // Color when checked
                                                //         '&:hover': {
                                                //             backgroundColor: 'primary' //'rgba(0, 128, 0, 0.04)', // Light green background on hover when checked
                                                //         }
                                                //     },
                                                //     '&:hover': {
                                                //         backgroundColor: 'rgba(128, 128, 128, 0.04)', // Light gray background on hover when unchecked
                                                //     }
                                                // }}
                                                onChange={(e) => handleSelectPhoto(e, photo)}
                                                name="selectedPhoto"
                                            //color="primary"
                                            />

                                            <IconButton
                                                color="primary" onClick={() => zoomPhoto(photo)}
                                                aria-label="zoom"
                                                disabled={!(photo.Srt_Foto === 3 || photo.Srt_Foto === 4)}
                                            >
                                                <ZoomInIcon />
                                            </IconButton>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>




                </Grid>
            </Grid>
            <Box sx={{ height: 90 }}></Box>




            {openZoomModal ? <ZoomModal open={openZoomModal} onClose={() => { onCloseZoomModal() }} imageUrl={zoomPhoto && zoomedPhoto.ArrImage} /> : null}

            < Snackbar
                open={openMessage}
                onClose={() => { setOpenMessage(false); }}
                TransitionComponent={SlideTransition}
                anchorOrigin={{ vertical: "bottom", horizontal: "center", }}
                key="Snackbar"
            >
                <Alert
                    severity={severity}
                    sx={{ marginTop: 2 }}
                    onClose={() => {
                        setOpenMessage(false);
                    }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>

        </>
    );
};

function SlideTransition(props: SlideProps) {
    return <Slide {...props} direction="up" />;
}

function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // January is 0!
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
}

export default WS_Share;
