import axios from "../api/axiosPhotobizz";

import React, { useState, useEffect, useRef } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import { Box, Typography, Stack, Grid, Button, Card, CardMedia, CardContent, TextField } from "@mui/material";
import { Paper } from "@mui/material";

import useLanguageContext from "../hooks/useLanguageContext";
import useAccount from "../hooks/useAccount";
import usePicture from "../hooks/usePicture";
import useOrder from "../hooks/useOrder";
import useEnvironment from "../hooks/useEnvironment";
import useProducts from "../hooks/useProducts";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Carousel from "react-material-ui-carousel";

import Skeleton from "@mui/material/Skeleton";
import Tooltip from "@mui/material/Tooltip";

// import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
// import ZoomInIcon from '@mui/icons-material/ZoomIn';
// import RemoveIcon from '@mui/icons-material/Remove';
// import AddIcon from '@mui/icons-material/Add';
// import FavoriteIcon from '@mui/icons-material/Favorite';

import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Slide, { SlideProps } from "@mui/material/Slide";

import WebsiteDict from "../data/WebsiteDict";
import ZoomModal from "../components/ZoomModal";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Label } from "@mui/icons-material";

import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import GetAppIcon from "@mui/icons-material/GetApp";
import CircularProgress from "@mui/material/CircularProgress";

const WS_History = () => {
    const theme = useTheme();
    const navigate = useNavigate();

    const { language, setLanguage } = useLanguageContext();
    const [dict, setDict] = useState(WebsiteDict);

    const { account, setAccount } = useAccount();
    const { picture, setPicture } = usePicture();
    const { order, setOrder } = useOrder();
    const { environment, setEnvironment } = useEnvironment();
    const { products, setProducts } = useProducts();

    const [orderList, setOrderList] = useState([]);

    const [favorites, setFavorites] = useState([]);

    const [openMessage, setOpenMessage] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [severity, setSeverity] = useState("success");

    const [loading, setLoading] = useState(false);
    const [loadingPictures, setLoadingPictures] = useState(false);
    const [redraw, setRedraw] = useState(false);

    const [orderFromUrl, setOrderFromUrl] = useState(null);

    useEffect(() => {
        setDict(WebsiteDict);
    }, [WebsiteDict]);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);

        const orderNr = urlParams.get("orderNr");
        if (orderNr !== null) {
            setOrderFromUrl(orderNr);
        }

        setEnvironment({ ...environment, selectedView: "history" });

    }, []);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);

        const orderNr = urlParams.get("orderNr");
        if (orderNr !== null) {
            setOrderFromUrl(orderNr);
        }
        if (account !== null && account !== undefined && Object.keys(account).length > 0) {
            setLoading(true);
            getConfirmedOrders(orderNr);
        }
    }, []);

    async function getConfirmedOrders_andOrderLines() {
        var sessAcc = account.Session.Session_ID + "/" + account.Session.Account_ID;

        try {
            const response = await axios.get("/Order/GetConfirmedOrders/" + sessAcc);
            if (response.status === 200) {
                console.log(response.data.Orders);
                //keep only the firsr 3 orders
                var orders = response.data.Orders;
                // var orders = response.data.Orders.slice(0, 10);

                //for each order in orderList, get orderLines
                for (let i = 0; i < orders.length; i++) {
                    const order = response.data.Orders[i];
                    const orderNr = order.order_nr;
                    try {
                        const response = await axios.get("/Order/GetOrder/" + sessAcc + "/" + orderNr + "/" + language.toUpperCase());
                        if (response.status === 200) {
                            orders[i].Order_Lijnen = response.data.Order_Lijnen;
                            console.log(response.data.Order);
                        } else {
                            setSnackbarMessage(dict["WS_Messages"]["Loading order details failed."][language]);
                            setSeverity("error");
                            setOpenMessage(true);
                        }
                    } catch (error) {
                        console.log(error);
                        setSnackbarMessage(dict["WS_Messages"]["Loading order details failed."][language]);
                        setSeverity("error");
                        setOpenMessage(true);
                    }
                }

                setOrderList(orders);

                // fetchProductSubGroups();
                // fetchPhotos();
            } else {
                setSnackbarMessage(dict["WS_Messages"]["Loading groups failed."][language]);
                setSeverity("error");
                setOpenMessage(true);
            }
        } catch (error) {
            console.log(error);
            setSnackbarMessage(dict["WS_Messages"]["Loading groups failed."][language]);
            setSeverity("error");
            setOpenMessage(true);
        }
    }

    async function getConfirmedOrders(_order) {
        var sessAcc = account.Session.Session_ID + "/" + account.Session.Account_ID;

        try {
            const response = await axios.get("/Order/GetConfirmedOrders/" + sessAcc);
            if (response.status === 200) {
                console.log(response.data.Orders);
                //keep only the firsr 3 orders
                var orders = response.data.Orders;
                // var orders = response.data.Orders.slice(0, 10);

                var openOrder = null;

                //for each order in orderList set .open to false
                for (let i = 0; i < orders.length; i++) {
                    if (_order !== null && _order !== undefined && _order !== "") {
                        if (orders[i].order_nr.toString() === _order) {
                            openOrder = orders[i];
                            orders[i].open = false;
                        } else {
                            orders[i].open = false;
                        }
                    }
                }

                setOrderList(orders);
                if (openOrder !== null) {
                    await getOrderLines(orders, openOrder);
                }
            } else {
                setSnackbarMessage(dict["WS_Messages"]["Loading order list failed."][language]);
                setSeverity("error");
                setOpenMessage(true);
            }
        } catch (error) {
            if (error?.response?.data?.Message !== undefined && error?.response?.data?.Message === "Er zijn geen momenteel geen geplaatste orders.") {
                setSeverity("info");
                setSnackbarMessage(dict["WS_Messages"]["There are currently no placed orders."][language]);
                setOpenMessage(true);
            } else {
                console.log(error);
                setSnackbarMessage(dict["WS_Messages"]["Loading order list failed."][language]);
                setSeverity("error");
                setOpenMessage(true);
            }
        }
    }

    function createData(name, calories, fat, carbs, protein, price) {
        return {
            name,
            calories,
            fat,
            carbs,
            protein,
            price,
            history: [
                {
                    date: "2020-01-05",
                    customerId: "11091700",
                    amount: 3,
                },
                {
                    date: "2020-01-02",
                    customerId: "Anonymous",
                    amount: 1,
                },
            ],
        };
    }

    const DownloadLink = ({ OrderLijn }) => {
        const [href, setHref] = useState("");
        const [loading, setLoading] = useState(false);
        const linkRef = useRef(null);

        async function handleDownload() {
            // If OrderLijn_ID is not null, get the download ArrImage from the server
            try {
                if (OrderLijn.OrderLijn_ID !== null) {
                    setLoading(true); // Set loading to true before the API call
                    const sessAcc = account.Session.Session_ID + "/" + account.Session.Account_ID;

                    //the filename is the last part of the string : "309437|145|33700|0049.JPG"
                    const filename = OrderLijn.Download_Reg.split("|").pop();

                    var response = await axios.get("/Picture/GetDigitaalBeeld/" + sessAcc + "/" + OrderLijn.OrderLijn_ID);
                    // const response = await axios.get(
                    //     "/Picture/GetDigitaalBeeld/28298E9E-3A45-429B-AFE6-81A12E37C091/7430AAF0-A982-45AB-A76D-C6C69062D2BF/43b1007e-733f-433e-8b1f-7d17131429f3"
                    // );

                    if (response.status === 200) {
                        console.log(response.data);
                        setHref("data:image/jpeg;base64," + response.data.ArrImage.toString());

                        const link = document.createElement("a");

                        if (filename !== null && filename !== "") {
                            link.download = filename;
                        } else {
                            link.download = "image.jpg";
                        }

                        link.href = "data:image/jpeg;base64," + response.data.ArrImage.toString();
                        link.click();
                    } else {
                        console.error("Failed to fetch image");
                        setSnackbarMessage(dict["WS_Messages"]["Failed to get image."][language]);
                        setSeverity("error");
                        setOpenMessage(true);
                    }

                    setLoading(false); // Set loading to false after the API call
                }
            } catch (error) {
                console.log(error);

                if (error.response.data.Message !== undefined) {
                    //and the first part is "Digitaal beeld niet beschikbaar:"
                    if (error.response.data.Message.split(":")[0] === "Digitaal beeld niet beschikbaar") {
                        setSnackbarMessage(dict["WS_Messages"]["Digitaal beeld niet beschikbaar"][language]);
                    } else {
                        setSnackbarMessage(dict["WS_Messages"]["Failed to get image."][language]);
                    }
                }
                setSeverity("error");
                setOpenMessage(true);

                setLoading(false); // Set loading to false if there's an error
            }
        }

        return (
            <React.Fragment>
                {loading ? (
                    <CircularProgress color="secondary" size={24} />
                ) : (
                    <GetAppIcon color="secondary" onClick={handleDownload} style={{ cursor: "pointer" }} />
                )}
            </React.Fragment>
        );
    };

    async function getOrderLines(_orderList, _row) {
        if (!_row.open) {
            var sessAcc = account.Session.Session_ID + "/" + account.Session.Account_ID;
            try {
                const response = await axios.get("/Order/GetOrder/" + sessAcc + "/" + _row.order_nr + "/" + language.toUpperCase());
                if (response.status === 200) {
                    console.log(response.data.Order_Lijnen);
                    var Order_Lijnen = response.data.Order_Lijnen;

                    var orders = _orderList;
                    for (let i = 0; i < orders.length; i++) {
                        if (orders[i].order_nr === _row.order_nr) {
                            orders[i].Order_Lijnen = Order_Lijnen;
                            orders[i].open = true;
                            break;
                        }
                    }
                    setOrderList(orders);
                } else {
                    setSnackbarMessage(dict["WS_Messages"]["Loading order details failed."][language]);
                    setSeverity("error");
                    setOpenMessage(true);
                }
            } catch (error) {
                console.log(error);
                setSnackbarMessage(dict["WS_Messages"]["Loading order details failed."][language]);
                setSeverity("error");
                setOpenMessage(true);
            }
        } else {
            var orders = _orderList;
            for (let i = 0; i < orders.length; i++) {
                if (orders[i].order_nr === _row.order_nr) {
                    orders[i].open = false;
                    break;
                }
            }
            setOrderList(orders);
        }
        setRedraw((prev) => !prev);
    }

    useEffect(() => {
        setLoading(!loading);
    }, [orderList]);

    function Row(props) {
        const { row } = props;

        return (
            <React.Fragment>
                <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                    <TableCell>
                        <IconButton aria-label="expand row" size="small" onClick={() => getOrderLines(orderList, row)}>
                            {row.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {row.name}
                    </TableCell>
                    <TableCell align="left">{row.order_nr}</TableCell>
                    <TableCell align="left">{formatDate(row.creatie_datum)}</TableCell>
                    <TableCell align="left">{formatDate(row.labo_datum)}</TableCell>
                    <TableCell align="left">{row.productie_datum ? formatDate(row.productie_datum) : "-"}</TableCell>
                    <TableCell align="left">{row.datum_afgewerkt ? formatDate(row.datum_afgewerkt) : "-"}</TableCell>
                    <TableCell align="left">{row.verzend_datum ? formatDate(row.verzend_datum) : "-"}</TableCell>
                    <TableCell align="right">€ {row.prijs_lln.toFixed(2)}</TableCell>
                    <TableCell align="right">{row.levering_via}</TableCell>
                    <TableCell align="left">{row.ws_betaalwijze_brand}</TableCell>
                </TableRow>
                <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                        <Collapse in={row.open} timeout="auto" unmountOnExit>
                            <Box sx={{ marginLeft: 0 }}>
                                {/* <Typography variant="h6" gutterBottom component="div">
                                    History
                                </Typography> */}
                                <Table size="small" aria-label="purchases">
                                    <TableHead>
                                        <TableRow
                                            sx={{
                                                "& th": {
                                                    color: "#fdd835",
                                                    backgroundColor: "#023047",
                                                },
                                            }}
                                        >
                                            <TableCell>{dict["WS_Labels"]["history_sub_tbl_Foto"][language]}</TableCell>
                                            <TableCell>{dict["WS_Labels"]["history_sub_tbl_Article"][language]}</TableCell>
                                            <TableCell align="right">{dict["WS_Labels"]["history_sub_tbl_Aantal"][language]}</TableCell>
                                            <TableCell align="right">{dict["WS_Labels"]["history_sub_tbl_Eenheidsprijs"][language]}</TableCell>
                                            <TableCell align="right">{dict["WS_Labels"]["history_sub_tbl_Totaal"][language]}</TableCell>
                                            <TableCell>{dict["WS_Labels"]["history_sub_tbl_Download"][language]}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {row.Order_Lijnen &&
                                            row.Order_Lijnen.map((orderLine) => (
                                                <TableRow key={orderLine.Order_Nr + "_" + orderLine.OrderLijn_Seq}>
                                                    <TableCell component="th" scope="row">
                                                        {/* <a
                                                            href={"data:image/jpeg;base64," + orderLine.ArrImage.toString()}
                                                            download="order_image.jpg"
                                                        > */}
                                                        <Box
                                                            component="img"
                                                            sx={{
                                                                height: 50,
                                                                borderRadius: 1,
                                                            }}
                                                            alt="The house from the offer."
                                                            src={
                                                                orderLine.ArrImage
                                                                    ? "data:image/jpeg;base64," + orderLine.ArrImage.toString()
                                                                    : require("../images/no_image_available.jpg")
                                                            }
                                                        />
                                                        {/* </a> */}
                                                    </TableCell>
                                                    <TableCell>{orderLine.Artikel_Naam}</TableCell>
                                                    <TableCell align="right">{orderLine.Aantal}</TableCell>
                                                    <TableCell align="right">€ {orderLine.Eenheidsprijs.toFixed(2)}</TableCell>
                                                    <TableCell align="right">€ {(orderLine.Aantal * orderLine.Eenheidsprijs).toFixed(2)}</TableCell>
                                                    {/* <TableCell>
                                                    <a href={orderLine.Download_File} download="image.jpg">
                                                        Download Image
                                                    </a>
                                                </TableCell> */}
                                                    <TableCell align="center">
                                                        {orderLine.Download_File !== "" ? <DownloadLink OrderLijn={orderLine} /> : null}
                                                        {/* <a
                                                            href={`${orderLine.Download_File}`}
                                                            download={orderLine.Download_File.split("\\").pop()}
                                                            style={{ color: "inherit" }} // Optional: to remove underline and inherit color
                                                            // style={{ textDecoration: "none", color: "inherit" }} // Optional: to remove underline and inherit color
                                                        >
                                                            Download {/* <GetAppIcon /> */}
                                                        {/* </a> */}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        );
    }

    return (
        <>
            <Stack spacing={2} m={1}>
                <Grid container spacing={0} sx={{ cursor: "default" }}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Box>
                            <Box
                                sx={{
                                    margin: 3,
                                    marginLeft: 6,
                                    // maxWidth: { xs: 200, sm: 300, md: 500, lg: "100%" }
                                }}
                            >
                                <Grid container spacing={2}>
                                    <Grid container spacing={2}>
                                        <Grid item>
                                            <TableContainer component={Paper}>
                                                <Table aria-label="collapsible table">
                                                    <TableHead>
                                                        <TableRow
                                                            sx={{
                                                                "& th": {
                                                                    color: "#fdd835",
                                                                    backgroundColor: "#023047",
                                                                },
                                                            }}
                                                        >
                                                            <TableCell />
                                                            <TableCell />
                                                            <TableCell>{dict["WS_Labels"]["history_tbl_Nummer"][language]}</TableCell>
                                                            <TableCell>{dict["WS_Labels"]["history_tbl_Besteld"][language]}</TableCell>
                                                            <TableCell>{dict["WS_Labels"]["history_tbl_Betaald"][language]}</TableCell>
                                                            <TableCell>{dict["WS_Labels"]["history_tbl_In productie"][language]}</TableCell>
                                                            <TableCell>{dict["WS_Labels"]["history_tbl_Afgewerkt"][language]}</TableCell>
                                                            <TableCell>{dict["WS_Labels"]["history_tbl_Verzonden"][language]}</TableCell>
                                                            <TableCell align="right">{dict["WS_Labels"]["history_tbl_Bedrag"][language]}</TableCell>
                                                            <TableCell>{dict["WS_Labels"]["history_tbl_Levering_via"][language]}</TableCell>
                                                            <TableCell>{dict["WS_Labels"]["history_tbl_Betaalwijze"][language]}</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>{orderList && orderList.map((row) => <Row key={row.Order_Nr} row={row} />)}</TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                        <Box sx={{ height: 90 }}></Box>
                    </Grid>
                </Grid>

                <Snackbar
                    open={openMessage}
                    onClose={() => {
                        setOpenMessage(false);
                    }}
                    TransitionComponent={SlideTransition}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    key="Snackbar"
                >
                    <Alert
                        severity={severity}
                        sx={{ marginTop: 2 }}
                        onClose={() => {
                            setOpenMessage(false);
                        }}
                    >
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </Stack>
        </>
    );
};

function SlideTransition(props: SlideProps) {
    return <Slide {...props} direction="up" />;
}

function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // January is 0!
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
}

export default WS_History;

//orderList example
// "orderList": [
//     {
//         "order_nr": 394412,
//         "skontr_nr": 16463,
//         "creatie_datum": "2024-07-31T20:02:14.987",
//         "labo_datum": "2024-08-02T09:11:53.767",
//         "opmerking": "",
//         "prijs_school": 22.50,
//         "prijs_lln": 22.50,
//         "aangemaakt_door": "",
//         "medium": "WS",
//         "medium_label": "Webshop",
//         "webshop_account_ID": "f3497de1-9b6b-48d4-9437-8b8b3825b7e6",
//         "ws_betaalwijze_brand": "Waardebon",
//         "Via": "Thuis",
//         "ws_verzendkosten": 5.00,
//         "levering_via": "Thuis (+ 5,00 €)",
//         "ws_levering_thuis": true,
//         "naam": "Derom",
//         "voornaam": "Bernard",
//         "adres": "Hubert Maelfaitlaan",
//         "postcode": "9830",
//         "gemeente": "Sint-Martens-Latem",
//         "tel": "+32497251322",
//         "gsm": "",
//         "email": "bderom@gmail.com",
//         "pw": "bderom",
//         "ws_status": 9,
//         "environment": "S",
//         "waardebon_verbruikt": 27.50,
//         "waardebon_rest": 83.75,
//         "enkel_digitaal_beeld": false,
//         "webshop_uiterste_besteldatum2": "2023-05-29T00:00:00"
//     },
//     {...}
// ]

//orderLine example
// "Order_Lijnen": [
//     {
//         "Order_Nr": 172805,
//         "OrderLijn_Seq": 1,
//         "Skontr_Nr": 15004,
//         "Skontrd_Seq": 1,
//         "Artikel_Nr": 145,
//         "Artikel_Naam": "Hoge resolutie",
//         "Artikel_Naam_Lang": "Digitaal beeld - Hoge resolutie",
//         "Gratis": false,
//         "Fotomap_Nr": 29901,
//         "Foto_File": "0597.jpg",
//         "Achtergrond_ID": "00000000-0000-0000-0000-000000000000",
//         "KL_SE_ZW": "KL",
//         "Aantal": 1,
//         "Klasfoto": "",
//         "Eenheidsprijs": 15.00,
//         "Verzendkosten": false,
//         "Artikel_Groep_ID": "DIG",
//         "Artikel_Subgroep_ID": "DIGB",
//         "Download_File": "\\\\HYPERV\\Webshop_DB\\0172805\\29901_KL_1_0597.jpg",
//         "Download_Reg": "172805|145|29901|0597.jpg",
//         "OrderLijn_ID": "c8a7d269-3012-41c8-8c4b-46d59256e4b2",
//         "Width": 0,
//         "Height": 0,
//         "Prijs": 17.5000,
//         "Prijs_Promo": 0.0
//     },
//     {...}
// ]
