import React, { useState, useEffect, useRef } from "react";
import { Box, Modal, Typography } from "@mui/material";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

function ZoomModal({ open = false, onClose = () => {}, imageUrl = "" }) {
    const modalRef = useRef();

    // Close popup if clicked outside of it
    useEffect(() => {
        function handleClickOutside(event) {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                onClose();
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [onClose]);

    return (
        <Modal open={open} onClose={onClose}>
            <Box
                ref={modalRef}
                sx={{
                    position: "absolute",
                    top: { xs: "1%", sm: "1%", md: "50%" },
                    left: { xs: "0%", sm: "0%", md: "50%" },
                    transform: { xs: "translate(0%, 0%)", sm: "translate(0%, 0%)", md: "translate(-50%, -50%)" },
                    width: { xs: "auto", sm: "auto", md: "auto" },
                    height: { xs: "auto", sm: "auto", md: "auto" },
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <IconButton
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                    }}
                >
                    <CloseIcon />
                </IconButton>
                {imageUrl && (
                    <img
                        src={imageUrl ? "data:image/jpeg;base64," + imageUrl.toString() : require("../images/no_image_available.jpg")}
                        alt="Popup"
                        style={{ maxWidth: "100%", maxHeight: "100%" }}
                    />
                )}
            </Box>
        </Modal>
    );
}

export default ZoomModal;
