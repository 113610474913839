import { createContext, useState, useEffect } from "react";

const EnvironmentContext = createContext({});

export const EnvironmentProvider = ({ children }) => {

    const [environment, setEnvironment] = useState(sessionStorage.getItem("session_environment") === null ? {} : JSON.parse(sessionStorage.getItem("session_environment")));

    //store in session storage
    useEffect(() => {
        sessionStorage.setItem("session_environment", JSON.stringify(environment));
    }, [environment]);

    return (
        <EnvironmentContext.Provider value={{ environment, setEnvironment }}>
            {children}
        </EnvironmentContext.Provider>
    )
}

export default EnvironmentContext;