import axios from "../api/axiosPhotobizz";

import React, { useState, useEffect, useRef } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import { Box, Typography, Stack, Grid, Button, Card, CardMedia, CardContent, TextField } from "@mui/material";
import { Paper, IconButton } from "@mui/material";

import useLanguageContext from "../hooks/useLanguageContext";
import useAccount from "../hooks/useAccount";
import usePicture from "../hooks/usePicture";
import useOrder from "../hooks/useOrder";
import useEnvironment from "../hooks/useEnvironment";
import useProducts from "../hooks/useProducts";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Carousel from "react-material-ui-carousel";

import Skeleton from "@mui/material/Skeleton";
import Tooltip from "@mui/material/Tooltip";

// import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
// import ZoomInIcon from '@mui/icons-material/ZoomIn';
// import RemoveIcon from '@mui/icons-material/Remove';
// import AddIcon from '@mui/icons-material/Add';
// import FavoriteIcon from '@mui/icons-material/Favorite';

import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Slide, { SlideProps } from "@mui/material/Slide";

import WebsiteDict from "../data/WebsiteDict";
import ZoomModal from "../components/ZoomModal";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Label } from "@mui/icons-material";

const WS_Empty = () => {
    const theme = useTheme();
    const navigate = useNavigate();

    const { language, setLanguage } = useLanguageContext();
    const [dict, setDict] = useState(WebsiteDict);

    const [openMessage, setOpenMessage] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [severity, setSeverity] = useState("success");

    const [loading, setLoading] = useState(false);
    const [loadingPictures, setLoadingPictures] = useState(false);

    const [ActionMessage, setActionMessage] = useState("");
    const [SubscribeAgainMessage, setSubscribeAgainMessage] = useState("");

    useEffect(() => {
        setDict(WebsiteDict);
    }, [WebsiteDict]);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const accountId = urlParams.get("AccountId");
        const unsubscribeId = urlParams.get("UnsubscribeId");

        if (accountId === null || unsubscribeId === null) {
            setActionMessage(
                dict["WS_Messages"]["There is something wrong with the link you clicked to unsubscribe from our mailing list."][language]
            );
            setSubscribeAgainMessage(dict["WS_Messages"]["Please try to unsubscribe again."][language]);
        }

        if (accountId !== null && unsubscribeId !== null) {
            fetchUnsubscribe(accountId, unsubscribeId);
        }
    }, []);

    async function fetchUnsubscribe(accountId, unsubscribeId) {
        try {
            console.log(`/Account/GetNewsletter_Unsubscribe/${accountId}/${unsubscribeId}`);
            const response = await axios.get("/Account/GetNewsletter_Unsubscribe/"+accountId+"/"+unsubscribeId);
            console.log(response.data);

            if (response.status === 200) {
                setActionMessage(dict["WS_Messages"]["Your account has been successfully unsubscribed from our mailing list."][language]);
                setSubscribeAgainMessage(dict["WS_Messages"]["You can subscribe again to our mailing list in your account settings."][language]);
            } else {
                setActionMessage(dict["WS_Messages"]["Failed to unsubscribe your account from our mailing list."][language]);
                setSubscribeAgainMessage(dict["WS_Messages"]["Please try to unsubscribe again."][language]);
            }
        } catch (error) {
            console.error("There was an error!", error);

            if (error?.response?.data?.Message === "Ongeldige Unsubscribe") {
                setActionMessage(dict["WS_Messages"]["Ongeldige Unsubscribe"][language]);
            } else {
                setActionMessage(dict["WS_Messages"]["Failed to unsubscribe your account from our mailing list."][language]);
            }

            setSubscribeAgainMessage(dict["WS_Messages"]["Please try to unsubscribe again."][language]);
        }
    }

    return (
        <>
            <Stack spacing={8} m={3}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Stack spacing={4}>
                            <Grid item>
                                <Box pb={2}>
                                    <Typography align="left" color={"#023047"} fontWeight={600} variant="h4">
                                        {dict["WS_Labels"]["Unsubscribe"][language]}
                                    </Typography>
                                </Box>
                            </Grid>

                            {/* <Grid item > */}
                            <Box display="flex" alignItems="center">
                                <Typography variant="body1" align="left">
                                    {ActionMessage}
                                </Typography>
                            </Box>

                            <Box display="flex" alignItems="center">
                                <Typography variant="body1" align="left">
                                    {SubscribeAgainMessage}
                                </Typography>
                            </Box>

                            {/* </Grid> */}
                        </Stack>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Grid item xs={12} md={12} sx={{ display: { xs: "none", sm: "none", md: "none", lg: "block" } }}>
                            <Box component="img" sx={{ maxWidth: 500 }} alt="Photobizz image" src={require("../images/Unsubscribe_full.png")} m={1} />
                        </Grid>
                        <Grid item xs={12} md={12} sx={{ display: { xs: "none", sm: "none", md: "block", lg: "none" } }}>
                            <Box component="img" sx={{ maxWidth: 400 }} alt="Photobizz image" src={require("../images/Unsubscribe_full.png")} m={1} />
                        </Grid>
                    </Grid>
                </Grid>

                <Snackbar
                    open={openMessage}
                    onClose={() => {
                        setOpenMessage(false);
                    }}
                    TransitionComponent={SlideTransition}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    key="Snackbar"
                >
                    <Alert
                        severity={severity}
                        sx={{ marginTop: 2 }}
                        onClose={() => {
                            setOpenMessage(false);
                        }}
                    >
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </Stack>
        </>
    );
};

function SlideTransition(props: SlideProps) {
    return <Slide {...props} direction="up" />;
}

function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // January is 0!
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
}

export default WS_Empty;
