
import useLanguageContext from "../hooks/useLanguageContext";

import React, { useState, useEffect } from "react";
import { NavLink, } from "react-router-dom";
import { Box, Typography, Stack, Grid, Button, Card, CardMedia, CardContent } from "@mui/material";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Carousel from 'react-material-ui-carousel'

import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';

import { Paper } from '@mui/material'

import WebsiteDict from "../data/WebsiteDict";


const HomeEN = () => {

    const { language, setLanguage } = useLanguageContext();

    const [dict, setDict] = useState(WebsiteDict);
    const [landscapePhotos, setLandscapePhotos] = useState([]);
    const [portraitPhotos, setPortraitPhotos] = useState([]);

    useEffect(() => {
        setDict(WebsiteDict);
    }, [WebsiteDict]);

    useEffect(() => {
        window.scrollTo(0, 0)


        async function getPhotos() {
            var landscapePhotosArray = [];

            for (var i = 1; i < 20; i++) {
                let photo;
                if (i < 10) {


                    await import("../images/land_0" + i + ".jpg")
                        .then((module) => {
                            photo = module.default; // Assuming the exported value is the default export
                            landscapePhotosArray.push({ name: photo });
                            // Use the imported photo
                            // console.log(photo);
                        })
                        .catch((error) => {
                            // Handle the import error
                            console.error(error);
                        });
                } else {
                    await import("../images/land_" + i + ".jpg")
                        .then((module) => {
                            photo = module.default; // Assuming the exported value is the default export
                            landscapePhotosArray.push({ name: photo });
                            // Use the imported photo
                            // console.log(photo);
                        })
                        .catch((error) => {
                            // Handle the import error
                            console.error(error);
                        });
                }
            }

            await setLandscapePhotos(landscapePhotosArray);

            var portraitPhotosArray = [];

            for (i = 1; i < 20; i++) {
                let photo;
                if (i < 10) {

                    await import("../images/port_0" + i + ".jpg")
                        .then((module) => {
                            photo = module.default; // Assuming the exported value is the default export
                            portraitPhotosArray.push({ name: photo });
                            // Use the imported photo
                            // console.log(photo);
                        })
                        .catch((error) => {
                            // Handle the import error
                            console.error(error);
                        });
                } else {
                    await import("../images/port_" + i + ".jpg")
                        .then((module) => {
                            photo = module.default; // Assuming the exported value is the default export
                            portraitPhotosArray.push({ name: photo });
                            // Use the imported photo
                            // console.log(photo);
                        })
                        .catch((error) => {
                            // Handle the import error
                            console.error(error);
                        });
                }
            }

            await setPortraitPhotos(portraitPhotosArray);

        }

        getPhotos();





        // setPortraitPhotos([
        //     {
        //         name: "../images/port_01.JPG"
        //     },
        //     {
        //         name: "../images/port_02.JPG"
        //     },
        // ]);


    }, []);

    const [expanded, setExpanded] = useState('');

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    function CarouselItemLandscapeWide(props) {
        return (
            <Box
                component="img"
                sx={{ width: 450 }}
                alt="Photobizz image"
                src={props.item.name}
                m={1}
            />
        )
    }

    function CarouselItemLandscapeNarrow(props) {
        return (
            <Box
                component="img"
                sx={{ width: "100%" }}
                alt="Photobizz image"
                src={props.item.name}
                m={1}
            />
        )
    }

    function CarouselItemPortraitWide(props) {
        return (
            <Box
                component="img"
                sx={{ width: 350 }}
                alt="Photobizz image"
                src={props.item.name}
                m={1}
            />
        )
    }

    function CarouselItemPortraitNarrow(props) {
        return (
            <Box
                component="img"
                sx={{ width: "90%", maxWidth: 300 }}
                alt="Photobizz image"
                src={props.item.name}
                m={1}
            />
        )
    }


    return (
        <>
            <Stack spacing={8} m={3}>

                <Grid container spacing={2}>
                    <Grid item xs={12} md={5} sx={{ display: { xs: "block", sm: 'block', md: 'none' } }}>

                        {landscapePhotos && landscapePhotos.length === 0
                            ?
                            <Box
                                component="img"
                                sx={{ width: 350 }}
                                alt="Photobizz image"
                                src={require('../images/Photo_class_outside.png')}
                                m={1}
                            />
                            : null
                        }

                        {landscapePhotos && landscapePhotos.length > 0
                            ?
                            <Carousel>
                                {
                                    landscapePhotos.map((item, i) => <CarouselItemLandscapeNarrow key={i} item={item} />)
                                }
                            </Carousel>
                            : null
                        }

                    </Grid>


                    <Grid item xs={12} md={5} >
                        <Box pb={2}>
                            <Typography align="left" color={"primary"} mt={1} letterSpacing={2} fontWeight={600} variant="body1">{dict["Texts"]["home_block1_title1"][language]}</Typography>
                        </Box>
                        <Box pb={2}>
                            <Typography align="left" color={"#023047"} fontWeight={600} variant="h4">{dict["Texts"]["home_block1_title2"][language]}</Typography>
                        </Box>
                        <Box pb={2}>
                            <Typography variant="body1" align="left">
                                {dict["Texts"]["home_block1_text1"][language]}
                            </Typography>
                        </Box>
                        <Box pb={2}>
                            <Typography variant="body1" align="left">
                                {dict["Texts"]["home_block1_text2"][language]}
                            </Typography>
                        </Box>
                        <Box display={"flex"}>
                            <Button variant="contained" color="primary" component={NavLink} to="/contact" sx={{ mt: 2 }}>
                                {dict["Labels"]["Contacteer_ons"][language]}
                            </Button>
                        </Box>
                    </Grid>


                    <Grid item xs={12} md={7} sx={{ display: { xs: "none", sm: 'none', md: 'block' } }}>


                        {landscapePhotos && landscapePhotos.length === 0 ?
                            <Box
                                component="img"
                                sx={{ width: 500 }}
                                alt="Photobizz image"
                                src={require('../images/Photo_class_outside.png')}
                                m={1}
                            />
                            : null
                        }

                        {landscapePhotos && landscapePhotos.length > 0 ?
                            <Carousel height={320}>
                                {
                                    landscapePhotos.map((item, i) => <CarouselItemLandscapeWide key={i} item={item} />)
                                }
                            </Carousel>
                            : null
                        }


                    </Grid>
                </Grid>



                <Grid container spacing={2}>
                    <Grid item xs={12} md={6} sx={{ display: { xs: "none", sm: 'none', md: 'block' } }}>

                        {portraitPhotos && portraitPhotos.length === 0 ?
                            <Box
                                component="img"
                                sx={{ width: 300 }}
                                alt="Photobizz image"
                                src={require('../images/Girl_smiling.png')}
                                m={1}
                            />
                            : null}

                        {portraitPhotos && portraitPhotos.length > 0 ?
                            <Carousel height={550}>
                                {
                                    portraitPhotos.map((item, i) => <CarouselItemPortraitWide key={i} item={item} />)
                                }
                            </Carousel>
                            : null}

                    </Grid>
                    <Grid item xs={12} md={6} sx={{ display: { xs: "block", sm: 'block', md: 'none' } }}>
                        {portraitPhotos && portraitPhotos.length === 0 ?
                            <Box
                                component="img"
                                sx={{ width: 350 }}
                                alt="Photobizz image"
                                src={require('../images/Girl_smiling.png')}
                                m={1}
                            />
                            : null}

                        {portraitPhotos && portraitPhotos.length > 0 ?
                            <Carousel height={470}>
                                {
                                    portraitPhotos.map((item, i) => <CarouselItemPortraitNarrow key={i} item={item} />)
                                }
                            </Carousel>
                            : null}

                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box pb={2}>

                            <Typography ml={2} align="left" color={"#023047"} fontWeight={600} variant="h4">{dict["Texts"]["home_block2_title1"][language]}</Typography>
                        </Box>

                        <Box pb={1}>
                            <Grid container spacing={2}>
                                <Grid item xs={2}>
                                    <Box
                                        component="img"
                                        sx={{ width: 30 }}
                                        alt="Photobizz image"
                                        src={require('../images/Tik_orange.png')}
                                        m={1}
                                    />
                                </Grid>
                                <Grid item xs={10}>
                                    <Typography pt={1} fontWeight={900} variant="body2" align="left">{dict["Texts"]["home_block2_text1"][language]}</Typography>
                                    <Typography fontWeight={900} variant="body2" align="left" ></Typography>
                                </Grid>
                            </Grid>
                        </Box>

                        <Box pb={1}>
                            <Grid container spacing={2}>
                                <Grid item xs={2}>
                                    <Box
                                        component="img"
                                        sx={{ width: 30 }}
                                        alt="Photobizz image"
                                        src={require('../images/Tik_orange.png')}
                                        m={1}
                                    />
                                </Grid>
                                <Grid item xs={10}>
                                    <Typography fontWeight={900} variant="body2" align="left">{dict["Texts"]["home_block2_text2"][language]}</Typography>
                                    <Typography fontWeight={900} variant="body2" align="left" ></Typography>
                                </Grid>
                            </Grid>
                        </Box>

                        <Box pb={1}>
                            <Grid container spacing={2}>
                                <Grid item xs={2}>
                                    <Box
                                        component="img"
                                        sx={{ width: 30 }}
                                        alt="Photobizz image"
                                        src={require('../images/Tik_orange.png')}
                                        m={1}
                                    />
                                </Grid>
                                <Grid item xs={10}>
                                    <Typography fontWeight={900} variant="body2" align="left">{dict["Texts"]["home_block2_text3"][language]}</Typography>
                                    <Typography fontWeight={900} variant="body2" align="left" ></Typography>
                                </Grid>
                            </Grid>
                        </Box>

                        <Box pb={1}>
                            <Grid container spacing={2}>
                                <Grid item xs={2}>
                                    <Box
                                        component="img"
                                        sx={{ width: 30 }}
                                        alt="Photobizz image"
                                        src={require('../images/Tik_orange.png')}
                                        m={1}
                                    />
                                </Grid>
                                <Grid item xs={10}>
                                    <Typography fontWeight={900} variant="body2" align="left">{dict["Texts"]["home_block2_text4"][language]}</Typography>
                                    <Typography fontWeight={900} variant="body2" align="left" ></Typography>
                                </Grid>
                            </Grid>
                        </Box>

                        <Box pb={2}>
                            <Grid container spacing={2}>
                                <Grid item xs={2}>
                                    <Box
                                        component="img"
                                        sx={{ width: 30 }}
                                        alt="Photobizz image"
                                        src={require('../images/Tik_orange.png')}
                                        m={1}
                                    />
                                </Grid>
                                <Grid item xs={10}>
                                    <Typography fontWeight={900} variant="body2" align="left">{dict["Texts"]["home_block2_text5"][language]}</Typography>
                                    <Typography fontWeight={900} variant="body2" align="left" ></Typography>
                                </Grid>
                            </Grid>
                        </Box>

                        {/* <Box display={"flex"} ml={2}>
                            <Button variant="contained" color="primary" component={NavLink} to="/contact" sx={{ mt: 2 }}>
                                {dict["Labels"]["Vraag_een_gratis_demo"][language]}
                            </Button>
                        </Box> */}
                    </Grid>

                </Grid>



                <Grid container spacing={2}>


                    <Grid item xs={12} md={6} mt={2} >

                        {/* Accordion 1 */}
                        <Accordion className="accordion_round_corners" sx={{ minHeight: "70px", border: '1px solid orange', boxShadow: 0 }} expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >

                                <Box
                                    component="img"
                                    sx={{ width: 60, height: 60 }}
                                    alt="Photobizz image"
                                    src={require('../images/icon_group.png')}
                                    m={1} pt={0}
                                />

                                <Box>
                                    <Typography align="left" pt={1} color={"#023047"} fontWeight={600} variant="h6">{dict["Texts"]["home_acc1_title1"][language]}</Typography>
                                    <Typography align="left" color={"#023047"} fontWeight={600} variant="h6">{dict["Texts"]["home_acc1_title2"][language]}</Typography>
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box maxWidth={900}>

                                    <Typography align="left" pl={2} pr={2}>
                                        {dict["Texts"]["home_acc1_text1"][language]}
                                    </Typography>

                                    <Typography align="left" pl={2} pr={2}>
                                        {dict["Texts"]["home_acc1_text2"][language]}
                                    </Typography>
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>

                    {/* Accordion 2 */}
                    <Grid item xs={12} md={6} mt={2} >
                        <Accordion className="accordion_round_corners" sx={{ minHeight: "70px", border: '1px solid orange', boxShadow: 0, borderRadius: 20 }} expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >

                                <Box
                                    component="img"
                                    sx={{ width: 60, height: 60 }}
                                    alt="Photobizz image"
                                    src={require('../images/icon_star.png')}
                                    m={1} pt={0}
                                />
                                <Box>
                                    <Typography align="left" pt={1} color={"#023047"} fontWeight={600} variant="h6">{dict["Texts"]["home_acc2_title1"][language]}</Typography>
                                    <Typography align="left" color={"#023047"} fontWeight={600} variant="h6">{dict["Texts"]["home_acc2_title2"][language]}</Typography>
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box maxWidth={900}>

                                    <Typography align="left" pl={2} pr={2} fontWeight={600}>
                                        {dict["Texts"]["home_acc2_text1_bold"][language]}
                                    </Typography>

                                    <Typography align="left" pl={2} pr={2}>
                                        {dict["Texts"]["home_acc2_text2"][language]}
                                    </Typography>


                                    <Typography align="left" pl={2} pr={2} fontWeight={600}>
                                        {dict["Texts"]["home_acc2_text3_bold"][language]}
                                    </Typography>

                                    <Typography align="left" pl={2} pr={2}>
                                        {dict["Texts"]["home_acc2_text4"][language]}
                                    </Typography>


                                    <Typography align="left" pl={2} pr={2} fontWeight={600}>
                                        {dict["Texts"]["home_acc2_text5_bold"][language]}
                                    </Typography>

                                    <Typography align="left" pl={2} pr={2}>
                                        {dict["Texts"]["home_acc2_text6"][language]}
                                    </Typography>




                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>

                    {/* Accordion 3 */}
                    <Grid item xs={12} md={6} mt={2} >
                        <Accordion className="accordion_round_corners" sx={{ minHeight: "70px", border: '1px solid orange', boxShadow: 0, borderRadius: 20 }} expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >

                                <Box
                                    component="img"
                                    sx={{ width: 60, height: 60 }}
                                    alt="Photobizz image"
                                    src={require('../images/icon_background.png')}
                                    m={1} pt={0}
                                />

                                <Typography align="left" pt={1} color={"#023047"} fontWeight={600} variant="h6">{dict["Texts"]["home_acc3_title1"][language]}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box maxWidth={900}>

                                    <Typography align="left" pl={2} pr={2}>
                                        {dict["Texts"]["home_acc3_text1"][language]}
                                    </Typography>

                                    <Typography align="left" pl={2} pr={2}>
                                        {dict["Texts"]["home_acc3_text2"][language]}
                                    </Typography>
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>


                    {/* Accordion 4 */}
                    <Grid item xs={12} md={6} mt={2} >
                        <Accordion className="accordion_round_corners" sx={{ minHeight: "70px", border: '1px solid orange', boxShadow: 0, borderRadius: 20 }}
                            expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >

                                <Box
                                    component="img"
                                    sx={{ width: 60, height: 60 }}
                                    alt="Photobizz image"
                                    src={require('../images/icon_blocks.png')}
                                    m={1} pt={0}
                                />

                                <Typography align="left" pt={1} color={"#023047"} fontWeight={600} variant="h6">{dict["Texts"]["home_acc4_title1"][language]}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box maxWidth={900}>

                                    <Typography align="left" pl={2} pr={2}>
                                        {dict["Texts"]["home_acc4_text1"][language]}
                                    </Typography>

                                    <Typography align="left" pl={2} pr={2}>
                                        {dict["Texts"]["home_acc4_text2"][language]}
                                    </Typography>

                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>





                    {/* Accordion 5 */}
                    <Grid item xs={12} md={6} mt={2} >
                        <Accordion className="accordion_round_corners" sx={{ minHeight: "70px", border: '1px solid orange', boxShadow: 0, borderRadius: 20 }}
                            expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >

                                <Box
                                    component="img"
                                    sx={{ width: 60, height: 60 }}
                                    alt="Photobizz image"
                                    src={require('../images/icon_pointer.png')}
                                    m={1} pt={0}
                                />

                                <Typography align="left" pt={1} color={"#023047"} fontWeight={600} variant="h6">{dict["Texts"]["home_acc5_title1"][language]}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box maxWidth={900}>

                                    <Typography align="left" pl={2} pr={2}>
                                        {dict["Texts"]["home_acc5_text1"][language]}
                                    </Typography>

                                    <Typography align="left" pl={2} pr={2}>
                                        {dict["Texts"]["home_acc5_text2"][language]}
                                    </Typography>

                                    <Button variant="contained" color="secondary" component={NavLink} to="/contact" sx={{ mt: 2 }}>
                                        {dict["Labels"]["Vraag_een_gratis_demo"][language]}
                                    </Button>


                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>




                    {/* Accordion 6 */}
                    <Grid item xs={12} md={6} mt={2} >
                        <Accordion className="accordion_round_corners" sx={{ minHeight: "70px", border: '1px solid orange', boxShadow: 0, borderRadius: 20 }}
                            expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >

                                <Box
                                    component="img"
                                    sx={{ width: 60, height: 60 }}
                                    alt="Photobizz image"
                                    src={require('../images/icon_home.png')}
                                    m={1} pt={0}
                                />

                                <Typography align="left" pt={1} color={"#023047"} fontWeight={600} variant="h6">{dict["Texts"]["home_acc6_title1"][language]}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box maxWidth={900}>

                                    <Typography align="left" pl={2} pr={2}>
                                        {dict["Texts"]["home_acc6_text1"][language]}
                                    </Typography>

                                    <Typography align="left" pl={2} pr={2}>
                                        {dict["Texts"]["home_acc6_text2"][language]}
                                    </Typography>

                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>




                </Grid>



                <Grid container spacing={2}>
                    <Grid item xs={12} md={12} mt={2} >

                        <Box  >
                            <Card sx={{ borderRadius: '20px', backgroundColor: "#219EBC", height: 150, pl: 2 }} >
                                <Grid container spacing={2}>
                                    <Grid item sm={12} md={6} sx={{ display: { xs: "none", sm: 'none', md: 'block' } }}>
                                        <Box pb={1} pt={6}>
                                            <Typography mt={1} variant="h6">{dict["Texts"]["home_block4_text1"][language]}</Typography>
                                        </Box>

                                    </Grid>
                                    <Grid item sm={12} md={6}>
                                        <Box pt={1}>
                                            <Button variant="contained" color="secondary" component={NavLink} to="/contact"
                                                sx={{ mt: 2, height: 100, width: 150, backgroundColor: "white", color: "#023047" }}
                                                endIcon={<ArrowOutwardIcon />}
                                            >
                                                {dict["Labels"]["BOEK_UW_FOTOSESSIE"][language]}
                                            </Button>
                                        </Box>


                                    </Grid>
                                </Grid>
                            </Card>
                        </Box>
                    </Grid>

                </Grid>


            </Stack >
        </>
    );
};

export default HomeEN;
