import { Outlet } from "react-router-dom"
import Box from '@mui/material/Box';
import Sidebar from "./Sidebar"
import Header from "./Header"


// xs (extra-small): 0px
// sm (small): 600px
// md (medium): 900px
// lg (large): 1200px
// xl (extra-large): 1536px

const Layout = () => {
    return (

        <Box className="parent">
            <Sidebar />

            <Header />
            <Box className="main" id="mainDiv"
            sx={{
                '@media (max-width: 900px)': {
                    marginLeft: 0,
                },
            }}
            >

                <Box>
                    < main className="App" >
                        <Outlet />
                    </main >
                </Box>

            </Box>


        </Box>
    )
}

export default Layout
