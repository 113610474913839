
import useLanguageContext from "../hooks/useLanguageContext";

import React, { useState, useEffect } from "react";
import { NavLink, } from "react-router-dom";
import { Box, Typography, Stack, Grid, Button, Card, CardMedia, CardContent } from "@mui/material";

import WebsiteDict from "../data/WebsiteDict";

const Vacatures = () => {

    const { language, setLanguage } = useLanguageContext();

    const [dict, setDict] = useState(WebsiteDict);

    useEffect(() => {
        setDict(WebsiteDict);
    }, [WebsiteDict]);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <>
            <Stack spacing={8} m={3}>

                <Grid container spacing={2}>


                    <Grid item xs={12} md={12}>
                        <Box pb={2}>
                            <Typography fontWeight={600} mt={1} variant="h4">{dict["Texts"]["vacatures_page_title1"][language]}</Typography>
                        </Box>

                    </Grid>

                    <Grid item xs={12} md={12}>
                        <Card sx={{ minWidth: 275, boxShadow: 10 }}>
                            <CardContent>

                                <Box pb={2}>
                                    <Typography align="left" color={"primary"} pt={2} fontWeight={600} variant="h6">{dict["Texts"]["vacatures_block1_title1"][language]}</Typography>
                                </Box>

                                <Box pb={2}>
                                    <Typography variant="body1" align="left">
                                        {dict["Texts"]["vacatures_block1_text1"][language]}
                                    </Typography>
                                </Box>

                                <Box pb={2}>
                                    <Typography variant="body1" align="left">
                                        {dict["Texts"]["vacatures_block1_text2"][language]}
                                    </Typography>
                                </Box>

                                <Box pb={1}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={2}>
                                            <Box
                                                component="img"
                                                sx={{ width: 30 }}
                                                alt="Photobizz image"
                                                src={require('../images/Tik_orange.png')}
                                                m={1}
                                            />
                                        </Grid>
                                        <Grid item xs={10}>
                                            <Typography pt={1} variant="body1" align="left">{dict["Texts"]["vacatures_block2_text1"][language]}</Typography>
                                        </Grid>
                                    </Grid>
                                </Box>

                                <Box pb={1}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={2}>
                                            <Box
                                                component="img"
                                                sx={{ width: 30 }}
                                                alt="Photobizz image"
                                                src={require('../images/Tik_orange.png')}
                                                m={1}
                                            />
                                        </Grid>
                                        <Grid item xs={10}>
                                            <Typography pt={1} variant="body1" align="left">{dict["Texts"]["vacatures_block2_text2"][language]}</Typography>
                                        </Grid>
                                    </Grid>
                                </Box>

                                <Box pb={1}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={2}>
                                            <Box
                                                component="img"
                                                sx={{ width: 30 }}
                                                alt="Photobizz image"
                                                src={require('../images/Tik_orange.png')}
                                                m={1}
                                            />
                                        </Grid>
                                        <Grid item xs={10}>
                                            <Typography pt={1} variant="body1" align="left">{dict["Texts"]["vacatures_block2_text3"][language]}</Typography>
                                        </Grid>
                                    </Grid>
                                </Box>


                                <Box pb={1}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={2}>
                                            <Box
                                                component="img"
                                                sx={{ width: 30 }}
                                                alt="Photobizz image"
                                                src={require('../images/Tik_orange.png')}
                                                m={1}
                                            />
                                        </Grid>
                                        <Grid item xs={10}>
                                            <Typography pt={1} variant="body1" align="left">{dict["Texts"]["vacatures_block2_text4"][language]}</Typography>
                                        </Grid>
                                    </Grid>
                                </Box>

                                <Box pb={2} pt={2} align="left" >
                                    <Typography variant="body1" align="left" display={"inline-block"}>
                                        {dict["Texts"]["vacatures_block3_text1"][language]}
                                    </Typography>
                                    <Typography pl={1} variant="body1" display={"inline-block"}>
                                        {dict["Texts"]["vacatures_block3_text2"][language]}
                                    </Typography>
                                </Box>

                            </CardContent>
                        </Card>
                    </Grid>

                </Grid>

            </Stack>
        </>
    );
};

export default Vacatures;
