import { createContext, useState, useEffect } from "react";

const ProductsContext = createContext({});

export const ProductsProvider = ({ children }) => {

    const [products, setProducts] = useState(sessionStorage.getItem("session_products") === null ? {} : JSON.parse(sessionStorage.getItem("session_products")));

    useEffect(() => {
        if (products === undefined) {
            sessionStorage.removeItem('session_products');
        }
        else {
            sessionStorage.setItem("session_products", JSON.stringify(products));
        }
    }, [products]);

    return (
        <ProductsContext.Provider value={{ products, setProducts }}>
            {children}
        </ProductsContext.Provider>
    )
}

export default ProductsContext;